import React, {
  ReactElement,
  useState,
  useEffect,
  useRef,
  useCallback
} from 'react';
import { orderBy } from 'lodash';
import { Loader } from '@ovis-technologies/ovis-blueprint';
import { useSelector } from 'react-redux';
import { getElementInfo, filterInactive } from '../../utils';
import TileTemplate from './TileTemplate';
import { GlobalState } from '../../reducers/reducers';
import classNames from 'classnames';
import { selectSideNavOpen } from '../../selectors';
import { selectDmGroups } from '../../selectors/dmSelectors';
import ListTemplate from './ListTemplate';
import FeedTemplate from './FeedTemplate';

export interface TemplateProps {
  elements: any[];
  dateFormat?: string;
  timeDateFormat?: string;
  layoutClass?: string;
  isCalendarEvent?: boolean;
  sortMethodOverride?: boolean;
}

interface Props {
  templateId: number;
  elements?: any;
  defaultView?: number;
  tileLayout?: number;
  groupId?: number;
  sortMethodOverride?: number;
  noSort?: boolean;
  isRequesting?: boolean;
  noResults?: boolean;
}

const DMTemplate = ({
  templateId,
  elements,
  defaultView = 15,
  groupId,
  sortMethodOverride = 2,
  noSort,
  isRequesting,
  noResults
}: Props) => {
  // Default vars
  const dmTemplateRef = useRef<HTMLDivElement>(null);
  const [inView, setInView] = useState<number>(() => defaultView);
  const [content, setContent] = useState<any[]>([]);

  // Selectors
  const { dir, col } = useSelector((state: GlobalState) => {
    const { byId } = selectDmGroups(state);
    const { sortMethodId } = byId[groupId || 0] || {};
    const sortMethod = {
      1: { dir: 'asc', col: 'title', sortId: 1 },
      2: { dir: 'desc', col: 'postDate', sortId: 2 },
      3: { dir: 'asc', col: 'sortOrder', sortId: 3 }
    };
    return sortMethod[sortMethodId] || sortMethod[sortMethodOverride];
  });

  const sideNavOpen = useSelector(selectSideNavOpen);

  // Variables
  const sideNavOpenClass = classNames({
    '--side-nav-open': sideNavOpen
  });

  // Side Effects
  useEffect(() => {
    if (!noSort) {
      setContent(
        filterInactive(
          orderBy(elements, [col], [dir]).slice(0, inView),
          {},
          true
        )
      );
    } else {
      setContent(filterInactive(elements.slice(0, inView), {}, true));
    }
  }, [elements, col, dir, noSort, inView, templateId]);

  const handleIncrementOnScroll = useCallback(() => {
    const element = dmTemplateRef.current;
    if (element) {
      const { bottom } = getElementInfo(element);
      if (bottom < window.innerHeight * 2) {
        setInView(inView + 10);
      }
    }
  }, [inView]);

  useEffect(() => {
    const scrollElement = document.querySelector('.content-side-nav')!;
    scrollElement.addEventListener('scroll', handleIncrementOnScroll);
    return () =>
      scrollElement.removeEventListener('scroll', handleIncrementOnScroll);
  }, [handleIncrementOnScroll]);

  // const handleIncrement = () => {
  //   setInView((prev) => {
  //     return prev + layout[templateId];
  //   });
  // };

  const template: Record<number, ReactElement> = {
    1: (
      <TileTemplate
        elements={content}
        layoutClass={`dm-template --tile --tile-3-col ${sideNavOpenClass}`}
      />
    ),
    8: <FeedTemplate elements={content} layoutClass='dm-template --feed' />,
    3: <ListTemplate elements={content} />
  };

  return (
    <div className='document-template' ref={dmTemplateRef}>
      {isRequesting ? (
        <div>
          <p>Loading</p>
          <Loader
            type='dots'
            colors={['#6c757d', '#6c757d', '#6c757d', '#6c757d']}
          />
        </div>
      ) : (
        <>{template[templateId]}</>
      )}
      {noResults && <p>No results found.</p>}
    </div>
  );
};

export default DMTemplate;
