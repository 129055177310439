import React, { useState, useEffect } from 'react';
import { getWssDataRequest } from '../../services/wssDataService';
import { Loader } from '@ovis-technologies/ovis-blueprint';
import { RequestState } from '../../../constants/requestStates';
import Layout from '../../components/Page';
import './_home.scss';

/**  
URL: /tom
NAME: Tom Learning page
AKA: WSS Test page
**/

interface Props {}
let id = 1;

const Home = (props: Props) => {
  const [wssData, setWssData] = useState<any>({ name: 'Loading' });
  const [selCat, setSelCat] = useState<number>(0);
  const [loading, setLoading] = useState<RequestState>();
  const [misconfigurationError, setMisconfigurationError] =
    useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(RequestState.sending);
        const { response } = await getWssDataRequest(37025);
        // console.log(response);
        setWssData(response);
        setLoading(RequestState.success);
      } catch (error: any) {
        if (error.response.error === 'Misconfiguration error') {
          setMisconfigurationError(true);
          setLoading(RequestState.success);
        } else {
          setLoading(RequestState.error);
        }
      }
    };
    getData();
  }, []);

  useEffect(() => {
    console.log(selCat);
  }, [selCat]);

  return (
    <Layout title={wssData.name} className='quicksight-wrapper'>
      {loading !== RequestState.success ? (
        <Loader />
      ) : (
        <>
          {misconfigurationError ? (
            <>
              <h3>
                Misconfiguration error in advisor dashboard, please contact your
                administrator and let them know. Please include this message in
                your e-mail.
              </h3>
            </>
          ) : (
            <div>
              <table className='companySummaryTable'>
                <tr>
                  <td>
                    {wssData.ticker} / {wssData.isin}
                  </td>
                  <td>
                    Lens: <span>{wssData.lensName}</span>
                  </td>
                  <td>
                    Score: <span>{wssData.overallScore}</span>
                  </td>
                </tr>
              </table>
              <div className='tableContainer'>
                <div>
                  <h3>Categories</h3>
                  <table className='categoryTable'>
                    <tr className='head'>
                      <td>Category</td>
                      <td>Score</td>
                    </tr>
                    {wssData.categories.map((i) => (
                      <tr key={i.categoryId}>
                        <td
                          onClick={() => setSelCat(i.categoryId)}
                          className='link'
                        >
                          {i.categoryName}
                        </td>
                        <td>{i.overallScore}</td>
                      </tr>
                    ))}
                  </table>
                </div>
                {displayDetails(selCat, wssData)}
              </div>
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

const displayDetails = (selCat, wssData) => {
  switch (selCat) {
    case 1:
      return displayCountryOfIncorporation(wssData);
    case 2:
      return displayForeignOwnership(wssData);
    case 3:
      return displayGeo(wssData, 3);
    case 4:
      return displayGeo(wssData, 4);
    case 5:
      return displayCountry(wssData, 5);
    case 6:
      return displayCountry(wssData, 6);
    case 7:
      return displayCountry(wssData, 7);
    case 8:
      return displayCountry(wssData, 8);
    case 9:
      return displayBoard(wssData);
    case 10:
      return displaySAM(wssData);
  }
  return '';
};

const displayCountryOfIncorporation = (wssData) => {
  return (
    <div className='categoryDetails'>
      <h3>Country of incorporation Details</h3>
      <span>{wssData.categories[0].details[1][1]}</span>
    </div>
  );
};

const displayForeignOwnership = (wssData) => {
  const cat = wssData.categories[1].details;
  const rowCnt = cat[1].length;
  const data: Array<any> = [];
  for (let i = 1; i < rowCnt; i++) {
    const row: Array<any> = [cat[1][i], cat[2][i], cat[3][i]];
    data.push(row);
  }
  return (
    <div className='categoryDetails'>
      <h3>Foreign Ownership Details</h3>
      <table className='categoryTable'>
        <tr className='head'>
          <td>Company Name</td>
          <td>Country Headquartered</td>
          <td>Percent Ownership</td>
        </tr>
        {data.map((i) => (
          <tr key={id++}>
            <td>{i[0]}</td>
            <td>{i[1]}</td>
            <td>{i[2]}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

const displayGeo = (wssData, catId) => {
  const cat = wssData.categories[catId - 1].details;
  const rowCnt = cat[1].length;
  const data: Array<any> = [];
  let name = 'Geographic Revenue';
  if (catId === 4) {
    name = 'Geographic Assets';
  }
  for (let i = 1; i < rowCnt; i++) {
    const row: Array<any> = [cat[1][i], cat[2][i]];
    data.push(row);
  }
  return (
    <div className='categoryDetails'>
      <h3>{name} Details</h3>
      <table className='categoryTable'>
        <tr className='head'>
          <td>Region</td>
          <td>Value in $M</td>
        </tr>
        {data.map((i) => (
          <tr key={id++}>
            <td>{i[0]}</td>
            <td className='aRight'>{numberWithCommas(i[1])}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

const displayCountry = (wssData, catId) => {
  const cat = wssData.categories[catId - 1].details;
  const rowCnt = cat[1].length;
  const data: Array<any> = [];
  let name = 'JVs';
  if (catId === 6) {
    name = 'Customers';
  } else if (catId === 7) {
    name = 'Suppliers';
  } else if (catId === 8) {
    name = 'Strategic Alliances';
  }
  for (let i = 1; i < rowCnt; i++) {
    const row: Array<any> = [cat[1][i], cat[2][i]];
    data.push(row);
  }
  return (
    <div className='categoryDetails'>
      <h3>{name} Details</h3>
      <table className='categoryTable'>
        <tr className='head'>
          <td>Company</td>
          <td>Headquartered</td>
        </tr>
        {data.map((i) => (
          <tr key={id++}>
            <td>{i[0]}</td>
            <td>{i[1]}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

const displayBoard = (wssData) => {
  const cat = wssData.categories[8].details;
  let rowCnt = 0;
  const data: Array<any> = [];

  try {
    rowCnt = cat[2].length;
    for (let i = 1; i < rowCnt; i++) {
      const row: Array<any> = [cat[1][i], cat[2][i], cat[3][i], cat[4][i]];
      data.push(row);
    }
  } catch (error) {
    // do nothing
  }

  return (
    <div className='categoryDetails'>
      <h3>Board of Director Details</h3>
      <table className='categoryTable'>
        <tr className='head'>
          <td>Member Name</td>
          <td>Affiliations</td>
          <td>Company Name</td>
          <td>Country Headquartered</td>
        </tr>
        {data.map((i) => (
          <tr key={id++}>
            <td>{i[0]}</td>
            <td>{i[1]}</td>
            <td>{i[2]}</td>
            <td>{i[3]}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

const displaySAM = (wssData) => {
  const cat = wssData.categories[9].details;

  return (
    <div className='categoryDetails'>
      <h3>SAM Inclusion Details</h3>
      {cat}
    </div>
  );
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default Home;
