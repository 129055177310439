export const GET_DM_REQUEST = 'GET_DM_REQUEST';
export const GET_DM_SUCCESS = 'GET_DM_SUCCESS';
export const GET_DM_FAILURE = 'GET_DM_FAILURE';

export const GET_DMS_REQUEST = 'GET_DMS_REQUEST';
export const GET_DMS_SUCCESS = 'GET_DMS_SUCCESS';
export const GET_DMS_FAILURE = 'GET_DMS_FAILURE';

export const UPDATE_DM_REQUEST = 'UPDATE_DM_REQUEST';
export const UPDATE_DM_SUCCESS = 'UPDATE_DM_SUCCESS';
export const UPDATE_DM_FAILURE = 'UPDATE_DM_FAILURE';

export const CREATE_DM_REQUEST = 'CREATE_DM_REQUEST';
export const CREATE_DM_SUCCESS = 'CREATE_DM_SUCCESS';
export const CREATE_DM_FAILURE = 'CREATE_DM_FAILURE';

export const GET_DM_GROUPS_REQUEST = 'GET_DM_GROUPS_REQUEST';
export const GET_DM_GROUPS_SUCCESS = 'GET_DM_GROUPS_SUCCESS';
export const GET_DM_GROUPS_FAILURE = 'GET_DM_GROUPS_FAILURE';

export const CREATE_DM_GROUP_REQUEST = 'CREATE_DM_GROUP_REQUEST';
export const CREATE_DM_GROUP_SUCCESS = 'CREATE_DM_GROUP_SUCCESS';
export const CREATE_DM_GROUP_FAILURE = 'CREATE_DM_GROUP_FAILURE';

export const UPDATE_DM_GROUP_REQUEST = 'UPDATE_DM_GROUP_REQUEST';
export const UPDATE_DM_GROUP_SUCCESS = 'UPDATE_DM_GROUP_SUCCESS';
export const UPDATE_DM_GROUP_FAILURE = 'UPDATE_DM_GROUP_FAILURE';

export const DELETE_DM_GROUP_REQUEST = 'DELETE_DM_GROUP_REQUEST';
export const DELETE_DM_GROUP_SUCCESS = 'DELETE_DM_GROUP_SUCCESS';
export const DELETE_DM_GROUP_FAILURE = 'DELETE_DM_GROUP_FAILURE';

export const UPDATE_DM_GROUP_ORDER = 'UPDATE_DM_GROUP_ORDER';
export const UPDATE_DM_FILTER_ORDER = 'UPDATE_DM_FILTER_ORDER';
export const UPDATE_DM_FILTER_ITEM_ORDER = 'UPDATE_DM_FILTER_ITEM_ORDER';

export const GET_DM_FILTERS_REQUEST = 'GET_DM_FILTERS_REQUEST';
export const GET_DM_FILTERS_SUCCESS = 'GET_DM_FILTERS_SUCCESS';
export const GET_DM_FILTERS_FAILURE = 'GET_DM_FILTERS_FAILURE';

export const CREATE_DM_FILTER_REQUEST = 'CREATE_DM_FILTER_REQUEST';
export const CREATE_DM_FILTER_SUCCESS = 'CREATE_DM_FILTER_SUCCESS';
export const CREATE_DM_FILTER_FAILURE = 'CREATE_DM_FILTER_FAILURE';

export const UPDATE_DM_FILTER_REQUEST = 'UPDATE_DM_FILTER_REQUEST';
export const UPDATE_DM_FILTER_SUCCESS = 'UPDATE_DM_FILTER_SUCCESS';
export const UPDATE_DM_FILTER_FAILURE = 'UPDATE_DM_FILTER_FAILURE';

export const DELETE_DM_FILTER_REQUEST = 'DELETE_DM_FILTER_REQUEST';
export const DELETE_DM_FILTER_SUCCESS = 'DELETE_DM_FILTER_SUCCESS';
export const DELETE_DM_FILTER_FAILURE = 'DELETE_DM_FILTER_FAILURE';

export const GET_FILTER_ITEMS_REQUEST = 'GET_FILTER_ITEMS_REQUEST';
export const GET_FILTER_ITEMS_SUCCESS = 'GET_FILTER_ITEMS_SUCCESS';
export const GET_FILTER_ITEMS_FAILURE = 'GET_FILTER_ITEMS_FAILURE';

export const CREATE_FILTER_ITEM_REQUEST = 'CREATE_FILTER_ITEM_REQUEST';
export const CREATE_FILTER_ITEM_SUCCESS = 'CREATE_FILTER_ITEM_SUCCESS';
export const CREATE_FILTER_ITEM_FAILURE = 'CREATE_FILTER_ITEM_FAILURE';

export const UPDATE_FILTER_ITEM_REQUEST = 'UPDATE_FILTER_ITEM_REQUEST';
export const UPDATE_FILTER_ITEM_SUCCESS = 'UPDATE_FILTER_ITEM_SUCCESS';
export const UPDATE_FILTER_ITEM_FAILURE = 'UPDATE_FILTER_ITEM_FAILURE';

export const DELETE_FILTER_ITEM_REQUEST = 'DELETE_FILTER_ITEM_REQUEST';
export const DELETE_FILTER_ITEM_SUCCESS = 'DELETE_FILTER_ITEM_SUCCESS';
export const DELETE_FILTER_ITEM_FAILURE = 'DELETE_FILTER_ITEM_FAILURE';

export const GET_ELEMENTS_REQUEST = 'GET_ELEMENTS_REQUEST';
export const GET_ELEMENTS_SUCCESS = 'GET_ELEMENTS_SUCCESS';
export const GET_ELEMENTS_FAILURE = 'GET_ELEMENTS_FAILURE';

export const CREATE_DM_ELEMENT_REQUEST = 'CREATE_DM_ELEMENT_REQUEST';
export const CREATE_DM_ELEMENT_SUCCESS = 'CREATE_DM_ELEMENT_SUCCESS';
export const CREATE_DM_ELEMENT_FAILURE = 'CREATE_DM_ELEMENT_FAILURE';

export const UPDATE_DM_ELEMENT_REQUEST = 'UPDATE_DM_ELEMENT_REQUEST';
export const UPDATE_DM_ELEMENT_SUCCESS = 'UPDATE_DM_ELEMENT_SUCCESS';
export const UPDATE_DM_ELEMENT_FAILURE = 'UPDATE_DM_ELEMENT_FAILURE';

export const UPDATE_DM_ELEMENT_LIKE_REQUEST = 'UPDATE_DM_ELEMENT_LIKE_REQUEST';
export const UPDATE_DM_ELEMENT_LIKE_SUCCESS = 'UPDATE_DM_ELEMENT_LIKE_SUCCESS';
export const UPDATE_DM_ELEMENT_LIKE_FAILURE = 'UPDATE_DM_ELEMENT_LIKE_FAILURE';

export const UPDATE_DM_ELEMENT_COMMENTS = 'UPDATE_DM_ELEMENT_COMMENTS';

export const DELETE_DM_ELEMENT_REQUEST = 'DELETE_DM_ELEMENT_REQUEST';
export const DELETE_DM_ELEMENT_SUCCESS = 'DELETE_DM_ELEMENT_SUCCESS';
export const DELETE_DM_ELEMENT_FAILURE = 'DELETE_DM_ELEMENT_FAILURE';

export const GET_DM_TAGS_REQUEST = 'GET_DM_TAGS_REQUEST';
export const GET_DM_TAGS_SUCCESS = 'GET_DM_TAGS_SUCCESS';
export const GET_DM_TAGS_FAILURE = 'GET_DM_TAGS_FAILURE';

export const CREATE_DM_TAG_REQUEST = 'CREATE_DM_TAG_REQUEST';
export const CREATE_DM_TAG_SUCCESS = 'CREATE_DM_TAG_SUCCESS';
export const CREATE_DM_TAG_FAILURE = 'CREATE_DM_TAG_FAILURE';

export const SET_ELEMENT_ORDER = 'SET_ELEMENT_ORDER';

export const GET_EXPORTED_DOCUMENTS_SUCCESS = 'GET_EXPORTED_DOCUMENTS_SUCCESS';
export const GET_EXPORTED_DOCUMENTS_FAILURE = 'GET_EXPORTED_DOCUMENTS_FAILURE';

export const GET_EXPORTED_DOCUMENTS_REQUEST = 'GET_EXPORTED_DOCUMENTS_REQUEST';
export const UPDATE_EXPORTED_DOCUMENTS_REQUEST =
  'UPDATE_EXPORTED_DOCUMENTS_REQUEST';
export const UPDATE_EXPORTED_DOCUMENTS_SUCCESS =
  'UPDATE_EXPORTED_DOCUMENTS_SUCCESS';
export const UPDATE_EXPORTED_DOCUMENTS_FAILURE =
  'UPDATE_EXPORTED_DOCUMENTS_FAILURE';
export const REMOVE_EXPORTED_DOCUMENTS_REQUEST =
  'REMOVE_EXPORTED_DOCUMENTS_REQUEST';
export const REMOVE_EXPORTED_DOCUMENTS_SUCCESS =
  'REMOVE_EXPORTED_DOCUMENTS_SUCCESS';
export const REMOVE_EXPORTED_DOCUMENTS_FAILURE =
  'REMOVE_EXPORTED_DOCUMENTS_FAILURE';

export const TOGGLE_ELEMENT_STATUS_REQUEST = 'TOGGLE_ELEMENT_STATUS_REQUEST';
export const TOGGLE_ELEMENT_STATUS_SUCCESS = 'TOGGLE_ELEMENT_STATUS_SUCCESS';
export const TOGGLE_ELEMENT_STATUS_FAILURE = 'TOGGLE_ELEMENT_STATUS_FAILURE';