import { interruptTypes } from '../../constants';
import { GlobalState } from '../reducers/reducers';
import { get, sortBy } from 'lodash';

export const selectGuidedTourData = (
  state: GlobalState,
  activeStep,
  interrupt
) => {
  const { allIds, byId: helpById } = state.help.allHelp;
  const { byId: navElementsById } = state.nav.elements;
  const navElementsByIdCopy = { ...navElementsById, '1': true };
  const helpIds = interrupt
    ? get(state, ['interrupt', interruptTypes.guidedTour, 'helpIds'], [])
    : allIds;
  const tourIds = helpIds.filter(
    (id) =>
      get(helpById, `${id}.typeId`, 0) === 3 &&
      navElementsByIdCopy[get(helpById, `${id}.navId`, 0)]
  );
  const tourItems = sortBy(tourIds, [
    function (id) {
      return helpById[id].sortOrder;
    }
  ]);
  const helpData = get(helpById, tourItems[activeStep], {});
  const audioUrl = get(helpData, 'audioUrl', '');

  return { tourItems, helpData, audioUrl, helpById };
};
