import axios from 'axios';
import { handleServiceError } from '.';
import { TRACKING_ENDPOINT } from './endpoints';

export interface TrackRequestBody {
  context: number | string;
  typeId?: number;
  elementId?: number;
}

export const track = (reqBody: TrackRequestBody) => {
  return axios
    .post(TRACKING_ENDPOINT, reqBody)
    .then((res) => res.data)
    .catch(handleServiceError);
};
