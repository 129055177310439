import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  useWindowWidth,
  Image,
  Directory,
  Apps,
  HelpIcon
} from '@ovis-technologies/ovis-blueprint';
import classnames from 'classnames';

import HeaderUser from './HeaderUser';
import GuidedTourSelectableElement from '../GuidedTour/GuidedTourSelectableElement';
import AlertsToggle from '../AlertsToggle';
import { setAlertsOpen, setModalOpen } from '../../actions/uiActions';
import { GlobalState, TourState } from '../../reducers/reducers.d';
import { setHelpOpen } from '../../actions/helpAction';
import HeaderGlobalSearch from './GlobalSearch';
import { track } from '../../services/trackingService';
import HeaderIcon from './HeaderIcon';
import config from '../../config';

import './header.scss';

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { open: openAlerts } = useSelector(
    (state: GlobalState) => state.ui.alerts
  );

  const screenWidth = useWindowWidth();

  const toggleAlerts = () => {
    if (tourStatus !== TourState.edit) dispatch(setAlertsOpen(true));
    if (openAlerts) dispatch(setAlertsOpen(false));
  };

  const handleOpenHelp = () => {
    dispatch(setHelpOpen(true));
    dispatch(setModalOpen(true));
    track({ context: 'help_center' });
  };

  const searchClass = classnames('header_right-icon', 'header_search-icon');

  const alertsClass = classnames('header_alerts-icon header_right-icon', {
    '--open': openAlerts
  });

  const rightIconsClass = classnames('header_right-wrapper');

  const tourStatus = useSelector((state: GlobalState) => state.ui.tour.status);

  const lessThan979 = screenWidth < 979;

  return (
    <nav className='header'>
      <GuidedTourSelectableElement id={1}>
        <div
          className='header_logo'
          style={{ maxWidth: '19rem', minWidth: 'initial' }}
        >
          {lessThan979 ? (
            <Link to='/'>
              <Image
                className='header_logo'
                src={config.app.logo}
                alt={config.app.appName}
              />
            </Link>
          ) : (
            <Link to='/'>
              <Image
                className='header_logo'
                src={config.app.logo}
                alt={config.app.appName}
              />
            </Link>
          )}
        </div>
      </GuidedTourSelectableElement>
      <div className={rightIconsClass}>
        <HeaderIcon
          navId={88}
          title='Search'
          className={classnames('header_search', {
            '--search-override': pathname === '/search'
          })}
          noButton
        >
          <div className={searchClass}>
            <HeaderGlobalSearch />
          </div>
        </HeaderIcon>

        <HeaderIcon navId={58} title='Directory' className='header_directory'>
          <Link to='/directory'>
            <div className='header_right-icon'>
              <Directory />
            </div>
          </Link>
        </HeaderIcon>

        <HeaderIcon
          navId={62}
          title='Alerts'
          onClick={toggleAlerts}
          className='header_alert'
          noButton
        >
          <div className={alertsClass}>
            <AlertsToggle />
          </div>
        </HeaderIcon>
        <HeaderIcon navId={83} title='Apps' className='header_apps'>
          <Link to='/apps'>
            <div className='header_right-icon'>
              <Apps />
            </div>
          </Link>
        </HeaderIcon>
        <HeaderIcon
          navId={7}
          title='Apps'
          className='header_help'
          onClick={handleOpenHelp}
        >
          <div className='header_right-icon'>
            <HelpIcon />
          </div>
        </HeaderIcon>
        <GuidedTourSelectableElement id={85}>
          <div className='header_icon'>
            <HeaderUser />
          </div>
        </GuidedTourSelectableElement>
      </div>
    </nav>
  );
};

export default Header;
