import React from 'react';

export { default as MapPinIcon } from './MapPin';
export { default as Export } from './Export';
export { default as ExportDisabled } from './ExportDisabled';
export { default as Apps } from './AppsIcon';
export { default as AppsHover } from './AppsHover';
export { default as Ecosystem } from './Ecosystem';
export { default as EcosystemHover } from './EcosystemHover';
export { default as Marketplace } from './Marketplace';
export { default as MarketplaceHover } from './MarketplaceHover';
export { default as Like } from './Like';
export { default as Comment } from './Comment';
export { default as Share } from './Share';
export { default as Copy } from './Copy';
export { default as ExportIcon } from './ExportIcon';
export { default as DirectoryLogo } from './DirectoryLogo';
export { default as DotMenu } from './DotMenu';
export { Trash } from './Trash';

export const Mail = () => (
  <svg
    className='large-mail-icon'
    version='1.1'
    id='Capa_1'
    x='0px'
    y='0px'
    viewBox='0 0 477.867 477.867'
  >
    <g>
      <g>
        <path
          className='cls-1'
          d='M460.8,68.267H17.067l221.867,182.75L463.309,68.779C462.488,68.539,461.649,68.368,460.8,68.267z'
        />
      </g>
    </g>
    <g>
      <g>
        <path
          className='cls-1'
          d='M249.702,286.31c-6.288,5.149-15.335,5.149-21.623,0L0,98.406v294.127c0,9.426,7.641,17.067,17.067,17.067H460.8
           c9.426,0,17.067-7.641,17.067-17.067V100.932L249.702,286.31z'
        />
      </g>
    </g>
  </svg>
);

export const LocationPin = () => (
  <svg version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 512 512'>
    <g>
      <g>
        <path
          d='M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5
			c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021
			C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333
			s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z'
        />
      </g>
    </g>
  </svg>
);

export const Phone = () => (
  <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 513.64 513.64'>
    <g>
      <g>
        <path
          d='M499.66,376.96l-71.68-71.68c-25.6-25.6-69.12-15.359-79.36,17.92c-7.68,23.041-33.28,35.841-56.32,30.72
			c-51.2-12.8-120.32-79.36-133.12-133.12c-7.68-23.041,7.68-48.641,30.72-56.32c33.28-10.24,43.52-53.76,17.92-79.36l-71.68-71.68
			c-20.48-17.92-51.2-17.92-69.12,0l-48.64,48.64c-48.64,51.2,5.12,186.88,125.44,307.2c120.32,120.32,256,176.641,307.2,125.44
			l48.64-48.64C517.581,425.6,517.581,394.88,499.66,376.96z'
        />
      </g>
    </g>
  </svg>
);

export const AvatarIcon = () => (
  <svg x='0px' y='0px' viewBox='0 0 553.536 553.536' fill='#307fc3'>
    <g>
      <g>
        <path d='M369.432,231c0,0,0.003,0.001,0.004,0.002l0.003-0.002C369.432,231,369.432,231,369.432,231z' />
        <path
          d='M521.426,387.936l-180.957-59.22l3.132-37.654c4.716-2.911,10.067-7.46,11.934-10.392
          c3.436-5.393,11.678-24.123,15.626-35.324c4.978,0.256,10.64-1.639,13.973-7.675c2.303-4.162,7.979-18.633,12.439-32.738
          c8.85-27.981,6.735-33.748,3.803-37.26c-0.94-1.127-2.005-1.978-3.146-2.593c5.164-12.017,12.39-31.245,11.961-43.912
          c-0.221-6.568-5.655-54.22-20.023-65.11c-3.796-7.384-18.523-32.87-46.014-43.649c-26.37-10.35-52.858-14.167-80.978-11.671
          c-23.799,2.102-46.92,26.239-50.204,29.786c-4.888,4.1-45.578,39.604-37.737,81.213l3.187,16.926
          c-4.473-0.975-9.555,0.256-12.701,3.844c-6.07,6.928-5.497,20.051-1.922,46.843c4.003,30.028,11.318,36.804,16.476,39.286
          c1.507,0.726,3.077,1.14,4.66,1.251c2.538,7.64,6.797,20.977,8.007,27.933c1.32,7.62,3.782,16.684,10.924,22.568l-6.603,50.674
          L31.628,389.118l-3.582,9.438c46.871,95.595,142.078,154.98,248.48,154.98c105.267,0,202.992-61.231,248.965-156.01
          L521.426,387.936z M276.526,539.708c-98.609,0-187.11-53.695-232.716-140.647l162.426-66.726l4.225-5.504l7.668-58.902
          l-3.671-7.032c-4.01-2.074-6.368-6.693-7.889-15.439c-1.942-11.167-10.163-34.861-10.516-35.864l-9.77-3.409
          c-0.055-0.027-5.421-3.505-8.774-28.652c-1.114-8.338-3.976-29.848-1.729-35.262c2.379,0.85,5.67,5.856,7.205,10.205l13.33-3.547
          l-7.488-39.756c-6.624-35.179,32.897-67.938,33.298-68.263l0.767-0.733c5.981-6.644,25.092-24.206,41.499-25.658
          c26.336-2.303,50.058,1.099,74.714,10.765c26.308,10.316,39.314,38.207,39.445,38.484l3.492,3.429
          c5.269,3.837,13.711,35.884,14.333,54.442c0.422,12.736-10.986,38.968-15.425,48.005l9.12,8.069
          c-0.885,11.456-13.247,46.421-17.036,53.28c-0.728,1.284-3.551,0.027-3.598,0.009l-9.829,4.983
          c-0.961,5.248-11.996,31.376-15.681,37.184c-1.162,1.548-6.838,6.084-9.576,7.225l-4.218,5.808l-3.9,46.823l4.736,7.142
          l178.502,58.424C464.452,484.575,373.884,539.708,276.526,539.708z'
        />
      </g>
    </g>
  </svg>
);
