import axios from 'axios';
import { UPLOAD_ENDPOINT } from './endpoints';
import {
  validateImage,
  validateFile,
  validateAsset
} from '../utils/validateFiles';
import config from '../config';

const onFileUpload: (file) => Promise<string> = async (file) => {
  // Get the presigned URL
  if (typeof file === 'string' || !file) return file;
  let response;
  try {
    response = await axios({
      method: 'GET',
      url: UPLOAD_ENDPOINT,
      params: {
        fileName: file.name.replaceAll(' ', '_') // TODO?: More top level and general sanitization?
      }
    });
  } catch (err: any) {
    console.error('Error getting signed URL from API', err);
    // return the error, not promise.reject(err)
    return Promise.reject(err);
  }
  const { response: responseBody } = response.data;
  const { uploadURL = '', contentType = '', key } = responseBody;

  let valid;
  let message;
  switch (contentType.split('/')[0]) {
    case 'image':
      ({ valid, message } = validateImage(file));
      break;
    case 'text':
    case 'application':
      ({ valid, message } = validateFile(file));
      break;
    case 'audio':
    case 'video':
      ({ valid, message } = validateAsset(file));
      break;
    default:
      throw new Error(
        `Not a supported file type. Contact the administrator at ${config.app.contactEmail}`
      );
  }

  if (!valid) return Promise.reject(message);

  try {
    await fetch(uploadURL, {
      method: 'PUT',
      headers: { 'Content-Type': contentType },
      body: file
    });
  } catch (err: any) {
    console.error('Error: ', err);
    return Promise.reject(err);
  }
  return key;
};
export default onFileUpload;
