import types from '../actions/types/moduleActionTypes';
import createAllIds from '../utils/createAllIds';
import createById from '../utils/createById';
import { union } from 'lodash';
import { ModulesState } from './reducers.d';

const initialState: ModulesState = {
  items: {
    allIds: [],
    byId: {}
  },
  isRequesting: false
};

const modulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.searchItemsRequest:
    case types.getModuleFiltersRequest:
      return { ...state, isRequesting: true };
    case types.getModuleFiltersSuccess:
      return { ...state, isRequesting: false };
    case types.searchItemsSuccess: {
      const {
        payload: { data = [] }
      } = action;

      const allIds = union(
        createAllIds(data, 'modulePkId'),
        state.items.allIds
      );
      const byId = {
        ...state.items.byId,
        ...createById(data, 'modulePkId')
      };
      return {
        ...state,
        items: { allIds, byId },
        isRequesting: false
      };
    }
    case types.searchItemsFailure:
    case types.getModuleFiltersFailure:
      return { ...state, isRequesting: false };
    default:
      return state;
  }
};

export default modulesReducer;
