import * as commentTypes from '../actions/types/commentActionTypes';
import { Action } from '../App.d';
import { CommentState } from './reducers.d';

const initialState: CommentState = {
  byId: {},
  allIds: [],
  isRequesting: false
};

const commentsReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case commentTypes.COMMENT_REQUEST: {
      return {
        ...state,
        isRequesting: true
      };
    }
    case commentTypes.GET_COMMENTS_SUCCESS: {
      const { payload = [] } = action;
      const commentsAllIds = payload.map((comment: any) => {
        return comment.commentId;
      });
      const commentsById = payload.reduce((carry: object, value: any) => {
        return {
          ...carry,
          [value.commentId]: value
        };
      }, {});
      return {
        ...state,
        allIds: commentsAllIds,
        byId: commentsById,
        isRequesting: false
      };
    }
    case commentTypes.ADD_COMMENTS_SUCCESS: {
      const { payload: comment = {} } = action;
      const { commentId, parentCommentId } = comment;
      const byId = { ...state.byId, [commentId]: comment };
      const allIds = [...state.allIds];
      if (parentCommentId) {
        byId[parentCommentId].childComments.push(commentId);
      } else {
        allIds.push(commentId);
      }
      return {
        ...state,
        byId,
        allIds,
        isRequesting: false
      };
    }
    case commentTypes.UPDATE_COMMENTS_SUCCESS: {
      const { payload } = action;
      const { commentId, parentCommentId, status } = payload;
      const allIds = state.allIds;
      const byId = { ...state.byId };
      if (parentCommentId && status === 0) {
        if (status === 0) {
          byId[parentCommentId].childComments.splice(
            byId[parentCommentId].childComments.indexOf(Number(commentId)),
            1
          );
        } else if (status === 1) {
          byId[parentCommentId].childComments.push(commentId);
        }
      }
      byId[commentId] = {
        ...byId[commentId],
        ...payload
      };
      return {
        ...state,
        byId,
        allIds,
        isRequesting: false
      };
    }
    case commentTypes.UPDATE_COMMENT_LIKE_SUCCESS: {
      const { commentId, like } = action.payload;
      const { byId } = state;
      if (like) {
        byId[commentId].likes = !byId[commentId].likes
          ? 1
          : byId[commentId].likes + 1;
        byId[commentId].hasLiked = 1;
      } else {
        byId[commentId].likes = byId[commentId].likes - 1;
        byId[commentId].hasLiked = 0;
      }
      return {
        ...state,
        byId
      };
    }
    default:
      return state;
  }
};

export default commentsReducer;
