import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { notification } from '@ovis-technologies/ovis-blueprint';

import { GlobalState } from '../../reducers/reducers';

interface Props {}

const Notifications = (props: Props) => {
  let {
    type,
    message,
    description,
    duration = 7,
    increment
  } = useSelector((state: GlobalState) => state.notification);

  if (!duration && type === 'error') duration = 0; // visible until cleared

  useEffect(() => {
    if (message.length && type.length)
      notification[type]({ message, description, duration });
  }, [description, type, message, duration, increment]);

  return <div className='notification-overlay' />;
};

export default Notifications;
