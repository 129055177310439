import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  Layout,
  Input,
  Select,
  useDebounce,
  Button,
  ProfilePhoto
} from '@ovis-technologies/ovis-blueprint';

import { selectAllUsers, selectAllOrgs, selectUserData } from '../../selectors';
import searchCollection from '../../utils/searchCollection';
import { GlobalState } from '../../reducers/reducers';
import { PanelType } from '../../reducers/reducers.d';
import { setSidePanelState } from '../../actions/uiActions';

import ExportUsers from '../../components/ExportUsers/ExportUsers';
import FlexContainer from '../FlexContainer';

import './user-hub.scss';
import classNames from 'classnames';

type UserHubProps = {
  filterOptions?: boolean;
};

const UserHub = ({ filterOptions = true }: UserHubProps) => {
  // State
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchOrgId, setSearchOrgId] = useState<number>();
  const [filteredUserData, setFilteredUserData] = useState<number[]>();

  // Selectors
  const sideNavOpen = useSelector((state: GlobalState) => state.ui.sideNavOpen);
  const { allIds: allUserIds, byId: usersById } = useSelector(
    (state: GlobalState) => selectAllUsers(state)
  );
  const { orgsById, orgOptions } = useSelector((state: GlobalState) => {
    const { byId, allIds } = selectAllOrgs(state);
    const options = allIds.map((id) => ({
      value: id,
      label: byId[id].name
    }));
    return {
      orgsById: byId,
      orgOptions: options
    };
  });

  const debouncedSearchQuery = useDebounce(searchQuery.toLowerCase(), 500);

  // Side Effects
  useEffect(() => {
    const searchSet = allUserIds.map((id) => ({
      key: id,
      ...usersById[id]
    }));

    const filteredData = searchCollection(
      debouncedSearchQuery,
      searchSet,
      ['name', 'title', 'email'],
      (user) =>
        (searchOrgId
          ? user.orgId === searchOrgId && user.status === 1
          : user.status === 1) &&
        !user.email.toLowerCase().includes('ovistechnologies') &&
        !user.email.toLowerCase().includes('oviscreative')
    ).map((user) => user.userId);
    setFilteredUserData(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersById, debouncedSearchQuery, searchOrgId, orgsById]);

  const classes = classNames('user-hub', { '--side-nav-open': sideNavOpen });

  return (
    <div className={classes}>
      {filterOptions && (
        <FlexContainer>
          <Input
            isClearable
            type='text'
            value={searchQuery}
            onChange={(value) => setSearchQuery(value)}
            placeholder='Name, title, email'
          />
          <Select
            options={orgOptions}
            value={searchOrgId}
            onChange={(value) => setSearchOrgId(value)}
            placeholder='Organization'
            isClearable
          />
          <ExportUsers keyword={searchQuery} orgId={searchOrgId} />
        </FlexContainer>
      )}

      {filteredUserData?.length === 0 ? (
        <p className='section-title'>Sorry, no users found.</p>
      ) : (
        <Layout layoutType='grid'>
          {filteredUserData?.map((id) => (
            <UserThumbnail key={id} userId={id} />
          ))}
        </Layout>
      )}
    </div>
  );
};

export const UserThumbnail = ({ userId }: { userId: number }) => {
  const dispatch = useDispatch();
  let { name, photo, orgName, title } =
    useSelector((state: GlobalState) => selectUserData(state, userId)) || {};
  photo = photo === '' ? '/assets/user.png' : photo;
  const handleOpenProfile = () => {
    dispatch(
      setSidePanelState({ title: name, panelType: PanelType.profile, userId })
    );
  };
  return (
    <div className='user-thumbnail' id='user-thumbnail'>
      <ProfilePhoto source={photo} />
      <Button
        className='user-thumbnail_name'
        onClick={handleOpenProfile}
        styleType='none'
      >
        {name}
      </Button>
      {title && <p className='user-thumbnail_title'>{title}</p>}
      <p className='user-thumbnail_title'>{orgName}</p>
    </div>
  );
};

export default UserHub;
