import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import axios from 'axios';
import { useHistory } from 'react-router';
import { Loader, notification } from '@ovis-technologies/ovis-blueprint';

import TourStep from './TourStep';
import { GlobalState } from '../../reducers/reducers.d';
import { guidedTourAction } from '../../actions/types/uiActionTypes';
import { removeInterrupt } from '../../actions/interruptActions';
import { interruptTypes } from '../../../constants';
import { selectNavElements } from '../../selectors/navSelectors';
import useTourPositionProps from '../../hooks/useTourPositionProps';
import { selectGuidedTourData } from '../../selectors';

import './_guided_tour.scss';

interface Props {
  interrupt?: boolean;
}

const GuidedTour: React.FC<Props> = ({ interrupt = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [audio, setAudio] = useState('');
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [loadingState, setLoadingState] = useState<boolean>(true);
  const { byId: navElementsById } = useSelector(selectNavElements);
  const previewing = useSelector(
    (state: GlobalState) => state.ui.tour.previewing
  );
  const { tourItems, helpData, audioUrl, helpById } = useSelector(
    (state: GlobalState) => selectGuidedTourData(state, activeStep, interrupt)
  );

  useEffect(() => {
    if (!loadingState && initialLoad) {
      setInitialLoad(false);
    }
  }, [loadingState, initialLoad]);

  const handleNextStep = async () => {
    setLoadingState(true);
    if (activeStep < tourItems.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      await dispatch(removeInterrupt(interruptTypes.guidedTour));
      dispatch({ type: guidedTourAction.inactive });
      if (previewing) history.push('/settings/guided_tour');
    }
  };

  const handlePreviousStep = () => {
    setLoadingState(true);
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const {
    description = '',
    navId = 0,
    componentRef = ''
  }: {
    description: string;
    navId: number;
    componentRef: string;
  } = get(helpById, tourItems[activeStep], {});

  useEffect(() => {
    async function getAudio() {
      try {
        // GET audioUrl
        const result = await axios.get(audioUrl);

        setAudio(get(result, 'data.response.signedUrl', ''));
        setLoadingState(false);
      } catch (err: any) {
        console.error(err);
        setLoadingState(false);
        notification.error({ message: 'Error', description: err });
      }
    }
    audioUrl && getAudio();
  }, [helpById, activeStep, helpData, audioUrl]);

  useEffect(() => {
    const navUrl = get(navElementsById, [navId, 'url'], '');
    if (navUrl[0] === '/') {
      history.push(navUrl);
    }
  }, [navId, navElementsById, history]);
  const { tourStepStyles, highlightStyles } = useTourPositionProps(
    componentRef,
    Boolean(loadingState || initialLoad)
  );

  const handleClose = async () => {
    if (previewing) history.push('/settings/guided_tour');
    await dispatch(removeInterrupt(interruptTypes.guidedTour));
    dispatch({ type: guidedTourAction.inactive });
  };

  return initialLoad ? (
    <div className='guided-tour-overlay loading'>
      <Loader size='large' />
    </div>
  ) : (
    <>
      <div className='guided-tour-overlay' onClick={handleClose}>
        <div className='circle' style={highlightStyles} />
        {/* <div className='circle-arrow' style={arrowStyles} /> */}
      </div>
      <div className='guided-tour'>
        {(tourStepStyles?.top !== '0px' || tourStepStyles?.left !== '0px') && (
          <TourStep
            key={audio}
            audioUrl={audio}
            text={description}
            tourId={navId}
            onNext={handleNextStep}
            onPrevious={handlePreviousStep}
            loadingState={loadingState}
            position={tourStepStyles}
            steps={tourItems.length}
            activeStep={activeStep}
            onClose={handleClose}
          />
        )}
      </div>
    </>
  );
};

export default GuidedTour;
