import { union } from 'lodash';

import { parserActionTypes } from '../actions/types/parserActionTypes';
import { Action } from '../App.d';
import createAllIds from '../utils/createAllIds';
import createById from '../utils/createById';
import { ParserState } from './reducers.d';

const initialState: ParserState = {
  parsers: {
    allIds: [],
    byId: {}
  },
  instances: {
    allIds: [],
    byId: {}
  },
  mappings: {
    allIds: [],
    byId: {}
  },
  isRequesting: false
};

const parserReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case parserActionTypes.getRequest:
    case parserActionTypes.getInstanceRequest:
    case parserActionTypes.putInstanceRequest:
    case parserActionTypes.deleteInstanceRequest:
    case parserActionTypes.getMappingsRequest:
    case parserActionTypes.postMappingsRequest:
    case parserActionTypes.postLoadRequest:
      return {
        ...state,
        isRequesting: true
      };
    case parserActionTypes.getSuccess: {
      const { payload = [] } = action;
      const allIds = createAllIds(payload, 'parserId');
      const byId = createById(payload, 'parserId');
      return {
        ...state,
        parsers: {
          allIds,
          byId
        },
        isRequesting: false
      };
    }
    case parserActionTypes.getInstanceSuccess: {
      const { payload = [] } = action;
      const allIds = createAllIds(payload, 'instanceId');
      const byId = createById(payload, 'instanceId');
      return {
        ...state,
        instances: {
          allIds,
          byId
        },
        isRequesting: false
      };
    }
    case parserActionTypes.getMappingsSuccess: {
      const { payload = {} } = action;
      const allIds = createAllIds(payload, 'mappingId');
      const byId = createById(payload, 'mappingId');
      return {
        ...state,
        mappings: {
          allIds,
          byId
        },
        isRequesting: false
      };
    }
    case parserActionTypes.putInstanceSuccess: {
      return {
        ...state,
        isRequesting: false
      };
    }
    case parserActionTypes.postLoadSuccess: {
      const { payload = {} } = action;
      const { instances: newInstances } = payload;
      const instances = {
        allIds: union(
          createAllIds(newInstances, 'instanceId'),
          state.instances.allIds
        ),
        byId: {
          ...state.instances.byId,
          ...createById(newInstances, 'instanceId')
        }
      };
      return {
        ...state,
        instances,
        isRequesting: false
      };
    }
    case parserActionTypes.deleteInstanceSuccess: {
      const { payload: instanceId = 0 } = action;
      const byId = { ...state.instances.byId };
      delete byId[instanceId];
      const instances = {
        allIds: state.instances.allIds.filter((id) => id !== instanceId),
        byId
      };
      return {
        ...state,
        instances,
        isRequesting: false
      };
    }
    case parserActionTypes.postMappingsSuccess: {
      return {
        ...state,
        isRequesting: false
      };
    }
    case parserActionTypes.getFailure:
      return {
        ...state,
        isRequesting: false
      };
    case parserActionTypes.getInstanceFailure:
    case parserActionTypes.putInstanceFailure:
    case parserActionTypes.getMappingsFailure:
    case parserActionTypes.postMappingsFailure:
    case parserActionTypes.deleteInstanceFailure:
    case parserActionTypes.postLoadFailure:
      return {
        ...state,
        isRequesting: false
      };
    default:
      return state;
  }
};

export default parserReducer;
