import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import GuidedTour from './GuidedTour';
import CreateStep from './CreateStep';
import { GlobalState, TourState } from '../../reducers/reducers.d';
import { hasProperty } from '../../utils';
import { interruptTypes } from '../../../constants';
import { guidedTourAction } from '../../actions/types/uiActionTypes';

interface Props {}

const GuidedTourWrapper: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [autoPlayAvailable, setAutoPlayAvailable] = useState<boolean>(false);

  // pass in the tour state (check if active)
  const helpItems = useSelector(
    (state: GlobalState) => state.help.allHelp.byId
  );
  const helpIdToEdit = useSelector(
    (state: GlobalState) => state.ui.tour.editHelpId
  );
  const tourState = useSelector((state: GlobalState) => state.ui.tour.status);

  // this is to check if it exists
  const interrupt = useSelector((state: GlobalState) =>
    hasProperty(state.interrupt, interruptTypes.guidedTour)
  );
  const helpItemToEdit = get(helpItems, helpIdToEdit, {});
  const initialPage = get(helpItemToEdit, 'url', '/');
  const navId = get(helpItemToEdit, 'navId', 1);

  const handleUserInteraction = () => setAutoPlayAvailable(true);
  if (interrupt && tourState !== 'Active') {
    // Hack to check for user interation so autoplay later down the component chain can work.
    window.addEventListener('mousedown', handleUserInteraction);
    if (autoPlayAvailable) {
      dispatch({ type: guidedTourAction.active });
      window.removeEventListener('mousedown', handleUserInteraction);
    }
  }
  return (
    <>
      {tourState === TourState.active ? (
        <GuidedTour interrupt={interrupt} />
      ) : tourState === TourState.edit ? (
        <CreateStep initialPage={initialPage} navId={navId} />
      ) : null}
    </>
  );
};

export default GuidedTourWrapper;
