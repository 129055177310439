import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import UserHub from '../../components/UserHub';
import { getUsers } from '../../actions/userActions';
import Layout from '../../components/Page';
import { track } from '../../services/trackingService';
import RefreshLoader from '../../components/RefreshLoader/RefreshLoader';
import './EnhancedDirectory.scss';

/**  
URL: /directory
NAME: Directory
AKA:
**/

const EnhancedDirectory = () => {
  // Hooks
  const dispatch = useDispatch();

  // State
  const [loading, setLoading] = useState<boolean>(false);

  // Side Effects
  useEffect(() => {
    track({ context: 104, typeId: 1 });
  }, []);

  useEffect(() => {
    const requestData = async () => {
      setLoading(true);
      await dispatch(getUsers());
      setLoading(false);
    };
    requestData();
  }, [dispatch]);

  return (
    <Layout
      centerContent
      className='directory'
      title='Directory'
      id='directory'
    >
      <RefreshLoader visible={loading} />
      <UserHub />
    </Layout>
  );
};

export default EnhancedDirectory;
