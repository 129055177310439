import {
  ORG_ACTION_TYPE,
  GET_ORG_CHART,
  ADD_ORG_CHART_USER,
  GET_ORG_CHART_REQUEST
} from './types/orgActionTypes';
import { Org, ResponseData } from '../App.d';
import { Dispatch } from 'redux';
import {
  getAllOrgsRequest,
  createOrgRequest,
  updateOrgRequest,
  searchOrgChartRequest,
  createOrgChartUserRequest,
  removeUserOrgChartRequest,
  createOrgChartDeptGroupRequest,
  createOrgChartDeptRequest,
  updateOrgChartDeptGroupRequest,
  updateOrgChartDeptRequest,
  removeOrgChartRequest
} from '../services/orgService';

export const setOrgList = (list: Array<Org>) => ({
  type: ORG_ACTION_TYPE.GetAllSuccess,
  payload: list
});

export const setActiveOrg = (orgId: number) => ({
  type: ORG_ACTION_TYPE.SetActiveOrg,
  payload: orgId
});

// Rare, used to update both activeOrg and orgList
export const setOrgState = (orgState) => ({
  type: ORG_ACTION_TYPE.SetActiveOrg,
  payload: orgState
});

export const getOrgList = () => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  dispatch({ type: ORG_ACTION_TYPE.Request });
  try {
    const responseData: ResponseData = await getAllOrgsRequest();
    const orgList: Array<Org> = responseData.response;
    dispatch(setOrgList(orgList));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: ORG_ACTION_TYPE.Failure, error });
    throw error;
  }
};

export const updateOrgList = (newOrg: Org) => ({
  type: ORG_ACTION_TYPE.RegisterSuccess,
  payload: newOrg
});

export const addOrg = (orgData: Org) => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  try {
    const responseData: ResponseData = await createOrgRequest(orgData);
    dispatch(updateOrgList(responseData.response));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: ORG_ACTION_TYPE.Failure, error });
    throw error;
  }
};

export const updateOrg = (orgData: Org) => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  try {
    const responseData: ResponseData = await updateOrgRequest(orgData);
    dispatch(updateOrgList(responseData.response));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: ORG_ACTION_TYPE.Failure, error });
    throw error;
  }
};

export const setOrgChartState = (orgChart) => ({
  type: GET_ORG_CHART,
  payload: orgChart
});

export const getOrgChart = () => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  dispatch({ type: GET_ORG_CHART_REQUEST });
  try {
    const responseData: ResponseData = await searchOrgChartRequest();
    const orgChart: Array<Org> = responseData.response;
    dispatch(setOrgChartState(orgChart));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: ORG_ACTION_TYPE.Failure, error });
    throw error;
  }
};

export const setCreateOrgChartUser = (chartData) => ({
  type: ADD_ORG_CHART_USER,
  payload: chartData
});

export const addOrgChartUser = (chartData) => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  try {
    const responseData: ResponseData = await createOrgChartUserRequest(
      chartData
    );
    dispatch(setOrgChartState(responseData.response));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: ORG_ACTION_TYPE.Failure, error });
    throw error;
  }
};

export const removeOrgChartUser = (chartId, userId) => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  try {
    const responseData: ResponseData = await removeUserOrgChartRequest(
      chartId,
      userId
    );
    dispatch(setOrgChartState(responseData.response));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: ORG_ACTION_TYPE.Failure, error });
    throw error;
  }
};

export const addDeptGroup = (chartData) => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  try {
    const responseData: ResponseData = await createOrgChartDeptGroupRequest(
      chartData
    );
    dispatch(setOrgChartState(responseData.response));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: ORG_ACTION_TYPE.Failure, error });
    throw error;
  }
};

export const updateDeptGroup = (chartData) => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  try {
    const responseData: ResponseData = await updateOrgChartDeptGroupRequest(
      chartData
    );
    dispatch(setOrgChartState(responseData.response));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: ORG_ACTION_TYPE.Failure, error });
    throw error;
  }
};

export const addDept = (chartData) => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  try {
    const responseData: ResponseData = await createOrgChartDeptRequest(
      chartData
    );
    dispatch(setOrgChartState(responseData.response));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: ORG_ACTION_TYPE.Failure, error });
    throw error;
  }
};

export const updateDept = (chartData) => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  try {
    const responseData: ResponseData = await updateOrgChartDeptRequest(
      chartData
    );
    dispatch(setOrgChartState(responseData.response));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: ORG_ACTION_TYPE.Failure, error });
    throw error;
  }
};

export const removeOrgChart = (chartId) => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  try {
    const responseData: ResponseData = await removeOrgChartRequest(chartId);
    dispatch(setOrgChartState(responseData.response));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: ORG_ACTION_TYPE.Failure, error });
    throw error;
  }
};
