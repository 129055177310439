import config from '../config';

export const URI = config.api.domain;

// Org Endpoints
export const ORG_ENDPOINT = URI + '/org'; // Gets info for all orgs

// User Endpoints
export const USER_ENDPOINT = URI + '/user';
export const USER_ASSET = URI + '/user_asset';

// Nav Endpoints
export const NAV_ENDPOINT = URI + '/nav';

// Static Endpoint
export const STATIC_ENDPOINT = URI + '/static';

// Help Endpoints
export const SEARCH_HELP_ENDPOINT = URI + '/help/search?';
export const ADD_HELP_DIRECTORY = URI + '/help/directory';
export const EDIT_HELP_DIRECTORY = URI + '/help/directory/';
export const EDIT_HELP_PAGE = URI + '/help/page/';
export const GUIDED_TOUR = URI + '/help/tour';
export const HELP = URI + '/help';

export const HELP_ENDPOINTS = {
  SEARCH: SEARCH_HELP_ENDPOINT,
  ADD_DIRECTORY: ADD_HELP_DIRECTORY,
  EDIT_DIRECTORY: EDIT_HELP_DIRECTORY,
  EDIT_PAGE: EDIT_HELP_PAGE,
  ASSETS: HELP,
  DELETE: HELP,
  TOUR: GUIDED_TOUR
};

export const HELP_ENDPOINT = URI + '/help';

// File Upload Endpoint
export const UPLOAD_ENDPOINT = URI + '/asset/upload'; // ?example-file.jpg

// Sort Endpoint
export const SORT_ENDPOINT = URI + '/sort';

// Document Manager Endpoint
export const DM_ENDPOINT = URI + '/dm';
export const DM_ASSET = URI + '/dm_asset';

// Contact Form Endpoint
export const PUBLIC_ENDPOINT = URI + '/public';

export const DASHBOARD_ENDPOINT = URI + '/dashboard';

// Parser Endpoint
export const PARSER_ENDPOINT = URI + '/parsers';

export const FEATURES_ENDPOINT = URI + '/features';

export const NOTIFICATIONS_ENDPOINT = URI + '/notifications';

export const MODULES_ENDPOINT = URI + '/systemModules';

export const TRACKING_ENDPOINT = URI + '/track';

export const MAIL_AUDIT_ENDPOINT = URI + '/mail_audit';

export const POLL_ENDPOINT = URI + '/poll';

export const EXPORT_ENDPOINT = URI + '/excel';

export const FILE_MANAGER_ENDPOINT = URI + '/file_manager';

export const GLOBAL_SEARCH_ENDPOINT = URI + '/global_search';
export const QUICKSIGHT_EMBED = URI + '/quicksight';
export const WSS_DATA = URI + '/wssdata';
export const DW_STATUS = URI + '/dw_status';
export const BI_ACCESS = URI + '/bi_access';

export const AI_ACCESS = URI + '/ai';
