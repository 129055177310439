import React from 'react';
import { TemplateProps } from './';
import CoverImage from '../CoverImage/CoverImage';
import { ActionToolbar } from '../DMBlogging';
import Details from './Details';

export const TileTemplate = ({
  layoutClass = '',
  elements = [],
  isCalendarEvent = false,
  sortMethodOverride
}: TemplateProps) => {
  if (isCalendarEvent && !sortMethodOverride) {
    elements.sort((a, b) => {
      return (
        new Date(b.calStartDate).getTime() - new Date(a.calStartDate).getTime()
      );
    });
  }
  return (
    <div className={layoutClass}>
      <div className='grid'>
        {elements.map((doc) => {
          const { elementId } = doc;
          return (
            <Tile key={elementId} doc={doc} isCalendarEvent={isCalendarEvent} />
          );
        })}
      </div>
    </div>
  );
};

interface TileProps {
  doc: any;
  children?;
  isCalendarEvent?;
}

export const Tile = ({ doc, children, isCalendarEvent }: TileProps) => {
  const { elementId, coverImage, title, fileLocation, link } = doc;

  return (
    <div key={elementId} className='document --tile-document'>
      <CoverImage
        contentUrl={fileLocation || link}
        className=''
        coverImageSrc={coverImage}
        alt='Cover Image'
        elementId={elementId}
      />
      <div className='document_content'>
        <Details
          elementId={elementId}
          isCalendarEvent={isCalendarEvent}
          children={children}
        />
        <ActionToolbar elementId={elementId} title={title} moduleId={1} />
      </div>
    </div>
  );
};

export default TileTemplate;
