import React from 'react';
import { Loader } from '@ovis-technologies/ovis-blueprint';

interface Props {
  visible: boolean;
}
const RefreshLoader: React.FC<Props> = ({ visible }) => (
  <>
    {visible && (
      <div className='refresh-loading-overlay'>
        <Loader />
      </div>
    )}
  </>
);

export default RefreshLoader;
