import axios from 'axios';
import { ORG_ENDPOINT } from './endpoints';
import { AddOrg, FetchOrgs, FetchOrg, EditOrg } from '../types/service';
import { ResponseData } from '../App.d';
import { handleServiceError } from '.';

enum ServiceActions {
  getAll = 'GET_ORGS',
  getOrg = 'GET_ORG',
  create = 'CREATE',
  update = 'UPDATE',
  getOrgChart = 'ORG_CHART_GET',
  addOrgChartUser = 'ORG_CHART_ADD_INDIVIDUAL',
  addOrgChartDeptGroup = 'ORG_CHART_ADD_DEPARTMENT_GROUP',
  editOrgChartDeptGroup = 'ORG_CHART_EDIT_DEPARTMENT_GROUP',
  addOrgChartDept = 'ORG_CHART_ADD_DEPARTMENT',
  editOrgChartDept = 'ORG_CHART_EDIT_DEPARTMENT',
  deleteOrgChart = 'ORG_CHART_DELETE_CHART',
  deleteUserOrgChart = 'ORG_CHART_DELETE_CHART_USER'
}

export const getAllOrgsRequest: FetchOrgs = async (): Promise<ResponseData> => {
  return axios
    .post(ORG_ENDPOINT, { action: ServiceActions.getAll })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const getOrgRequest: FetchOrg = async (
  id: number
): Promise<ResponseData> => {
  return axios
    .post(ORG_ENDPOINT, {
      action: ServiceActions.getOrg,
      data: { orgId: id }
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const createOrgRequest: AddOrg = async (
  newOrg
): Promise<ResponseData> => {
  return axios
    .post(ORG_ENDPOINT, {
      action: ServiceActions.create,
      data: newOrg
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const updateOrgRequest: EditOrg = async (
  orgData
): Promise<ResponseData> => {
  return axios
    .post(ORG_ENDPOINT, {
      action: ServiceActions.update,
      data: {
        ...orgData
      }
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

// Org Chart
export const searchOrgChartRequest: FetchOrgs = async (): Promise<ResponseData> => {
  return axios
    .post(ORG_ENDPOINT, { action: ServiceActions.getOrgChart })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const createOrgChartUserRequest = async (
  data
): Promise<ResponseData> => {
  return axios
    .post(ORG_ENDPOINT, { action: ServiceActions.addOrgChartUser, data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const createOrgChartDeptGroupRequest = async (
  data
): Promise<ResponseData> => {
  return axios
    .post(ORG_ENDPOINT, { action: ServiceActions.addOrgChartDeptGroup, data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const createOrgChartDeptRequest = async (
  data
): Promise<ResponseData> => {
  return axios
    .post(ORG_ENDPOINT, { action: ServiceActions.addOrgChartDept, data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const updateOrgChartDeptGroupRequest = async (
  data
): Promise<ResponseData> => {
  return axios
    .post(ORG_ENDPOINT, { action: ServiceActions.editOrgChartDeptGroup, data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const updateOrgChartDeptRequest = async (
  data
): Promise<ResponseData> => {
  return axios
    .post(ORG_ENDPOINT, { action: ServiceActions.editOrgChartDept, data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const removeOrgChartRequest = async (chartId): Promise<ResponseData> => {
  return axios
    .post(ORG_ENDPOINT, {
      action: ServiceActions.deleteOrgChart,
      data: { chartId }
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const removeUserOrgChartRequest = async (
  chartId,
  userId
): Promise<ResponseData> => {
  return axios
    .post(ORG_ENDPOINT, {
      action: ServiceActions.deleteUserOrgChart,
      data: { chartId, userId }
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};
