import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {
  Loader,
  useDebounce,
  usePagination,
  Pagination,
  Select,
  Portal,
  Modal
} from '@ovis-technologies/ovis-blueprint';

import { selectGlobalSearchQuery } from '../../selectors/globalSearchSelectors';
import { getGlobalSearchRequest } from '../../services/globalSearchService';
import { parseQueryString } from '../../utils';

import { GlobalState } from '../../reducers/reducers';
import { selectAllUsers, selectSideNavOpen } from '../../selectors';
import CoverImage from '../CoverImage/CoverImage';
import LinkRender from '../LinkRender/LinkRender';
import { UserThumbnail } from '../UserHub';

import Grid from '../MoveToBP/Grid';

import './_global_search.scss';
import Html from '../HTMLBuilder/HtmlBuilder';
import { months } from '../../../constants';
import Page from '../Page';

type ResultsHashType = {
  user: {}[];
  dm: {}[];
  alert: {}[];
  help: {}[];
  orgs: {}[];
};

const resultsHashDefault: ResultsHashType = {
  user: [],
  dm: [],
  alert: [],
  help: [],
  orgs: []
};

// enum moduleMap {
//   dm = 'Documents',
//   alert = 'Alerts',
//   help = 'Support',
//   user = 'Users',
//   org = 'Organizations'
// }

const GlobalSearch = () => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { query: URLQuery } = parseQueryString(search);

  const [searchElements, setSearchElements] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortType, setSortType] = useState<any>('relevance');
  const [resultsHash, setResultsHash] = useState<any>(resultsHashDefault);

  const { page, updatePageData, ...controls } = usePagination(searchElements, {
    pageSize: 10
  });

  const sideNavOpen = useSelector(selectSideNavOpen);
  const searchQuery = useSelector(selectGlobalSearchQuery) || URLQuery;
  const debouncedSearchQuery = useDebounce(searchQuery, 400);
  const memoizedSearchQuery = useMemo(
    () => debouncedSearchQuery,
    [debouncedSearchQuery]
  );

  const { allIds } = useSelector((state: GlobalState) =>
    selectAllUsers(state, true)
  );

  useEffect(() => {
    if (memoizedSearchQuery) {
      history.push('/search?query=' + memoizedSearchQuery || '');
      (async function () {
        setLoading(true);
        try {
          const { response } = await getGlobalSearchRequest(
            memoizedSearchQuery,
            sortType
          );
          if (memoizedSearchQuery === debouncedSearchQuery) {
            setResultsHash(response);
            setSearchElements([]);
          }
        } catch (err: any) {
          setSearchElements([]);
        }
        setLoading(false);
      })();
    }
  }, [dispatch, history, memoizedSearchQuery, sortType]); // eslint-disable-line

  useEffect(() => {
    updatePageData(searchElements);
  }, [searchElements]); // eslint-disable-line

  const pagination = Boolean(searchElements.length) && !loading && (
    <div className='global-search_pagination'>
      <Pagination config={{ allowSizeChange: true }} {...controls} />
    </div>
  );

  const containerClass = classNames('global-search_container', {
    '--side-nav-open': sideNavOpen
  });

  const leftHandCheck =
    !resultsHash.help?.length &&
    !resultsHash.dm?.length &&
    !resultsHash.org?.length;

  const checkSearch = !resultsHash.user?.length && leftHandCheck;

  return (
    <Page className={containerClass} noTitle>
      <div className='global-search' ref={ref}>
        {memoizedSearchQuery && !loading && (
          <>
            <div className='global-search_result-count'>
              <div className='global-search_sort-by'>
                <div className='global-search_sort-by-select'>
                  <Select
                    placeholder='Sort By:  '
                    value={sortType}
                    defaultValue={1}
                    isClearable={false}
                    onChange={(val) => setSortType(val)}
                    options={[
                      { value: 'relevance', label: 'Most Relevant' },
                      { value: 'date', label: 'Most Recent' }
                    ]}
                  />
                </div>
              </div>
            </div>
            {pagination}
          </>
        )}
        <div className='global-search_results'>
          <Grid>
            {loading ? (
              <div className='global-search_loading'>
                <Loader size='large' />
              </div>
            ) : (
              <>
                {checkSearch ? (
                  <div className='global-search_no-results'>
                    {memoizedSearchQuery
                      ? 'Nothing to show!'
                      : 'Use the search box in the upper right corner to find what you need'}
                  </div>
                ) : (
                  <div className='row'>
                    {!leftHandCheck && (
                      <div className='col-8'>
                        <div className='search-results_dm'>
                          {resultsHash?.dm && (
                            <DMSearchTemplate elements={resultsHash?.dm} />
                          )}
                          {resultsHash?.help && (
                            <HelpSearchTemplate elements={resultsHash?.help} />
                          )}
                          {resultsHash?.org && (
                            <OrgSearchTemplate elements={resultsHash?.org} />
                          )}
                        </div>
                      </div>
                    )}

                    <div className='col-4'>
                      <UserSearchTemplate
                        allIds={allIds}
                        elements={resultsHash?.user || []}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </Grid>
        </div>
        {pagination}
      </div>
    </Page>
  );
};

const DMSearchTemplate = ({ elements }) => {
  if (!elements.length) return <></>;
  return (
    <div className='search_template'>
      <p className='component-title'>Documents</p>
      <div className='dm-search_template --panel'>
        {elements.slice(0, 6).map((el, index) => {
          const { title, description, link, coverImage } = el;
          return (
            <div key={title + index} className='global-search_result'>
              <div className='dm-search_link'>
                <LinkRender contentUrl={link}>
                  <Html content={title} />
                </LinkRender>

                <Html content={description} />
              </div>
              <CoverImage contentUrl={link} coverImageSrc={coverImage} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const UserSearchTemplate = ({ elements, allIds }) => {
  const [sortedUsers, setSortedUsers] = useState<any[]>([]);
  const setUsers = useCallback(() => {
    const userIdsFromStore = [...allIds];
    let userResults: number[] = [];
    if (!allIds.length && !elements.length) return;
    if (elements.length === 0) {
      while (userResults.length < 3) {
        const ranId = Math.floor(Math.random() * userIdsFromStore.length);
        if (!userIdsFromStore[userIdsFromStore.indexOf(ranId)]) continue;
        userResults.push(userIdsFromStore[userIdsFromStore.indexOf(ranId)]);
        userIdsFromStore.splice(userIdsFromStore.indexOf(ranId), 1);
      }
    } else {
      userResults = elements.map((user) => user.userId);
    }
    setSortedUsers(userResults);
  }, [allIds, elements]);

  useEffect(() => {
    if (!sortedUsers.length) setUsers();
  }, [setUsers, sortedUsers]);

  return (
    <div className='search-results_users'>
      <p className='component-title'>
        {elements.length ? 'Users' : 'Suggested Users'}
      </p>
      {sortedUsers?.map((userId) => {
        return <UserThumbnail key={userId} userId={userId} />;
      })}
    </div>
  );
};

const OrgSearchTemplate = ({ elements }) => {
  if (!elements.length) return <></>;
  return (
    <div className='search_template'>
      <p className='component-title'>Organizations</p>
      <div className='global-search_template  --panel'>
        {elements.map((org) => {
          const { coverImage, title, date } = org;
          const formattedDate = new Date(date);
          const dateString = `${
            months[formattedDate.getMonth()]
          } ${formattedDate.getFullYear()}`;
          return (
            <div key={title} className='global-search_result --org'>
              {coverImage !== '' && <img src={`${coverImage}`} alt={title} />}
              <Html content={title} />
              <p>Joined: {dateString}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const HelpSearchTemplate = ({ elements }) => {
  if (!elements.length) return <></>;
  return (
    <div className='search_template'>
      <p className='component-title'>Support</p>
      <div className='search-help --panel'>
        {elements.map((help, index) => (
          <HelpElement help={help} key={index} />
        ))}
      </div>
    </div>
  );
};

const HelpElement = ({ help }) => {
  const [visible, setVisible] = useState(false);
  const handleHelpOpen = () => {
    setVisible(!visible);
  };
  const { title, description } = help || {};
  const trimmedDescription =
    description.length >= 200
      ? description.slice(0, 200).trim() + '...'
      : description;
  return (
    <>
      <div onClick={handleHelpOpen} className='global-search_result'>
        <div className='search-help_title'>
          <Html content={title} />
        </div>
        <Html content={trimmedDescription} />
      </div>
      <Portal>
        <Modal visible={visible} title={help.title} closeButton>
          <Html content={help.description} />
        </Modal>
      </Portal>
    </>
  );
};

export default GlobalSearch;
