import { union, sortBy } from 'lodash';
import * as userAction from '../actions/types/userActionTypes';
import { Action, User } from '../App.d';
import { UserState } from './reducers.d';

const userState: UserState = {
  activeUserId: 0,
  users: {
    byId: { 0: {} },
    allIds: [],
    isRequesting: false
  },
  gettingUser: false,
  postingNewUser: false,
  puttingAllUsers: false,
  putSuccessMessage: '',
  putErrorMessage: '',
  error: null
};

const userReducer = (state = userState, action: Action) => {
  switch (action.type) {
    case userAction.GETALL_USERS_REQUEST: {
      return {
        ...state,
        users: { ...state.users, isRequesting: true }
      };
    }
    case userAction.GET_USER_REQUEST: {
      return { ...state, gettingUser: true };
    }
    case userAction.GET_USER_FAILURE: {
      return {
        ...state,
        error: action.error,
        gettingUser: false
      };
    }
    case userAction.PUT_USER_REQUEST: {
      return { ...state, users: { ...state.users, isRequesting: true } };
    }
    case userAction.GET_USER_SUCCESS:
    case userAction.PUT_USER_SUCCESS: {
      const { allIds, byId } = state.users;
      const {
        payload: { userId }
      } = action;
      const userIds = union(allIds, [userId]);
      const usersById = { ...byId };
      // Extending rather than completely overwriting
      usersById[userId] = {
        ...usersById[userId],
        ...action.payload,
        name: `${action.payload.fname} ${action.payload.lname}`
      };
      return {
        ...state,
        users: {
          ...state.users,
          allIds: userIds,
          byId: usersById,
          isRequesting: false
        },
        gettingUser: false
      };
    }
    case userAction.PUT_USER_FAILURE: {
      return {
        ...state,
        users: { ...state.users, isRequesting: false },
        error: action.error
      };
    }
    case userAction.SET_ACTIVE_USER: {
      const { payload = 0 } = action;

      return {
        ...state,
        activeUserId: payload,
        gettingUser: false
      };
    }
    case userAction.GETALL_USERS_SUCCESS: {
      const { payload = [] } = action;
      const sortedArray = sortBy(payload, [
        (user) => `${user.fname} ${user.lname}`
      ]);
      const allIds = sortedArray.map((user: User) => user.userId);
      const byId = {
        ...state.users.byId,
        ...payload.reduce(
          (carry: object, value: User) => ({
            ...carry,
            [value.userId]: {
              name: `${value.fname} ${value.lname}`,
              ...state.users.byId[value.userId],
              ...value
            }
          }),
          {}
        )
      };
      return {
        ...state,
        users: { ...state.users, allIds, byId, isRequesting: false }
      };
    }
    case userAction.GETALL_USERS_FAILURE: {
      return {
        ...state,
        error: action.error,
        users: { ...state.users, isRequesting: false }
      };
    }
    default:
      return { ...state };
  }
};

export default userReducer;
