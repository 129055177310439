import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAllUsers,
  selectActiveUserId
} from '../../../selectors/userSelectors';
import { GlobalState } from '../../../reducers/reducers';
import {
  addComments,
  updateCommentLike,
  updateComments
} from '../../../actions/commentActions';
import { selectElementCommentIds } from '../../../selectors/dmSelectors';
import {
  Button,
  formatDate,
  TextArea,
  Loader,
  Dropdown,
  ProfilePhoto
} from '@ovis-technologies/ovis-blueprint';
import { Like as LikeIcon, DotMenu } from '../../svgs';

type Props = {
  elementId: number;
  requesting: boolean;
  openInput: boolean;
};

const Comments = ({ elementId, requesting, openInput }: Props) => {
  // State
  const [toggleTopComments, setToggleTopComments] = useState(false);
  const [commentsInView, setCommentsInView] = useState(2);

  // Selectors
  const {
    byId: commentsById,
    allIds: commentIds,
    topComments
  } = useSelector((state: GlobalState) => {
    const { byId, allIds } = selectElementCommentIds(state, elementId);
    const ids = [...allIds];
    const mostRecent = allIds.reverse();
    const topComments = ids.sort((a, b) => {
      return byId[b].likes - byId[a].likes;
    });
    return {
      byId,
      allIds: mostRecent,
      topComments
    };
  });

  // Handlers
  const handleToggleTopComments = (bool) => {
    setToggleTopComments(bool);
  };

  const handleUpdateCommentsInView = () => {
    setCommentsInView(commentsInView + 2);
  };

  // Vars
  const classes = classnames('comments ');

  const defaultPlaceholder =
    commentIds.length === 0 ? 'Be the first to comment...' : undefined;

  // Subcomponets
  const comments = useMemo(() => {
    const ids = toggleTopComments ? topComments : commentIds;

    return ids
      .map((id) => {
        const { childComments } = commentsById[id];

        return (
          <Comment
            key={id}
            {...commentsById[id]}
            childComments={childComments}
            elementId={elementId}
          />
        );
      })
      .splice(0, commentsInView);
  }, [
    toggleTopComments,
    commentIds,
    elementId,
    topComments,
    commentsById,
    commentsInView
  ]);

  return (
    <div className={classes}>
      {Boolean(openInput) && (
        <>
          <div className='comments_main-input-container'>
            <CommentInput
              elementId={elementId}
              defaultPlaceholder={defaultPlaceholder}
            />
          </div>
        </>
      )}
      {Boolean(commentIds.length || requesting) && (
        <>
          {requesting && commentIds.length === 0 ? (
            <div className='comments_loading'>
              <p className='subtext'>Retrieving Comments</p>
              <Loader
                type='dots'
                colors={['#6c757d', '#6c757d', '#6c757d', '#6c757d']}
              />
            </div>
          ) : (
            <div>
              <Dropdown
                noArrow
                body={
                  <>
                    <Button
                      styleType='text'
                      onClick={() => handleToggleTopComments(true)}
                    >
                      Most Relevant
                    </Button>
                    <Button
                      onClick={() => handleToggleTopComments(false)}
                      styleType='text'
                    >
                      Most Recent
                    </Button>
                  </>
                }
              >
                <Button styleType='none'>
                  {toggleTopComments ? 'Most Relevant' : 'Most Recent'}
                </Button>
              </Dropdown>
              {comments}
              {commentIds.length > comments.length && (
                <Button
                  styleType='text'
                  onClick={handleUpdateCommentsInView}
                  className='view-more'
                >
                  View more comments
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Comments;

type CommentInputProps = {
  elementId: number;
  parentCommentId?: number;
  defaultPlaceholder?: string;
  autoFocus?: boolean;
  replyUserId?: number;
};

export const CommentInput = ({
  elementId,
  parentCommentId,
  defaultPlaceholder,
  autoFocus,
  replyUserId = 0
}: CommentInputProps) => {
  // State
  const [commentString, setCommentString] = useState<string>('');
  const [loading, setLoading] = useState(false);

  // Selectors
  const activeUserId = useSelector((state: GlobalState) =>
    selectActiveUserId(state)
  );

  const { byId: usersById } = useSelector((state: GlobalState) =>
    selectAllUsers(state, true, true)
  );

  // Vars
  const { photo } = usersById[activeUserId];
  const { name = '' } = usersById[replyUserId] || {};

  const dispatch = useDispatch();

  // Functions
  const handleOnChange = (commentString) => {
    setCommentString(commentString);
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    const data: {
      comment: string;
      elementId: number;
      parentCommentId?: number;
      lastModBy?: number;
    } = {
      comment: replyUserId
        ? `Replying to ${name}: ${commentString}`
        : commentString,
      elementId,
      lastModBy: activeUserId
    };
    if (parentCommentId) {
      data.parentCommentId = parentCommentId;
    }
    try {
      await dispatch(addComments(data));
      setCommentString('');
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <div className='comments_input'>
      <div className='comments_input-wrapper'>
        <ProfilePhoto size='small' source={photo} />
        <TextArea
          size='small'
          noResize
          placeholder={defaultPlaceholder || 'Add a comment...'}
          onChange={handleOnChange}
          value={commentString}
          autofocus={autoFocus}
        />
      </div>
      {commentString.length > 1 && (
        <div>
          <Button
            onClick={handleOnSubmit}
            size='small'
            loading={loading}
            styleType='text'
          >
            Post
          </Button>
        </div>
      )}
    </div>
  );
};

type CommentProps = {
  childComments?: number[];
  comment: string;
  userId: number;
  postDate: Date;
  commentId: number;
  elementId: number;
  parentCommentId?: number;
  likes: number;
  hasLiked: 0 | 1;
  onReplyOpen?: (userId, parentCommentId) => {};
};

const Comment = ({
  childComments = [],
  comment,
  userId,
  postDate,
  commentId,
  elementId,
  parentCommentId,
  likes,
  hasLiked,
  onReplyOpen
}: CommentProps) => {
  // States
  const [openReply, setOpenReply] = useState<boolean>(false);
  const [replyUserId, setReplyUserId] = useState<number>(0);
  const [liked, setLiked] = useState<boolean>(Boolean(hasLiked));
  const [likesTotal, setLikes] = useState(likes);
  const [likeRequesting, setLikeRequesting] = useState(false);
  const [deleteRequesting, setDeleteRequesting] = useState(false);

  const dispatch = useDispatch();

  // Selectors
  const { byId: usersById } = useSelector((state: GlobalState) =>
    selectAllUsers(state, true, true)
  );

  const { byId: commentsById } = useSelector((state: GlobalState) =>
    selectElementCommentIds(state, elementId)
  );

  // Functions
  const handleOpenReply = (userId, parentCommentId) => {
    if (onReplyOpen) {
      onReplyOpen(userId, parentCommentId);
    } else {
      setOpenReply(true);
      if (parentCommentId) {
        setReplyUserId(userId);
      }
    }
    // Move into useEffect to check when reply is open
    const section = document.querySelector(`#__${commentId}`) as HTMLElement;
    const scrollContainer = document.querySelector('.content-side-nav');
    if (section && scrollContainer && childComments.length > 0) {
      scrollContainer.scrollTop = section.offsetTop;
    }
  };

  const handleLikeToggle = async () => {
    if (likeRequesting) return;
    setLikeRequesting(true);
    if (liked) {
      setLikes(likesTotal - 1);
    } else {
      setLikes(likesTotal + 1);
    }
    setLiked(!liked);
    try {
      await dispatch(updateCommentLike(commentId, !liked));
    } catch (err) {
      console.error(err);
      setLiked(false);
    }
    setLikeRequesting(false);
  };

  const handleDeleteComment = async () => {
    setDeleteRequesting(true);
    try {
      await dispatch(
        updateComments({ commentId, elementId, parentCommentId, status: 0 })
      );
    } catch (err) {
      console.error(err);
    }
    setDeleteRequesting(false);
  };

  // Sub Components

  const { photo, name } = usersById[userId] || {};

  const { name: replyUserName } = usersById[replyUserId] || {};

  const likeButtonClass = classnames('reply-btn small-text --light', {
    '--has-liked': liked
  });

  const commentClass = classnames('comment', {
    '--requesting-delete': deleteRequesting
  });

  return (
    <div className='comment_container' key={commentId} id={`${commentId}`}>
      <div className={commentClass}>
        <div className='comment_user-photo'>
          <ProfilePhoto size='small' source={photo} />
        </div>
        <div className='comment_body-wrapper'>
          <div className='comment_body'>
            <div className='comment_user'>
              <p className='comment_name --medium small-text --dark'>
                {name}
                <span className='--light comment_date'>
                  {formatDate(postDate, { format: 'MM.DD.YYYY' })}
                </span>
              </p>
            </div>
            <p className='description-text comment_body-text --dark small-text'>
              {comment}
            </p>
            <div className='comment_info'>
              <Dropdown
                containerClassName='comment_menu'
                direction='down'
                noArrow
                body={
                  <button
                    onClick={handleDeleteComment}
                    className='--default-btn'
                  >
                    <p className='subtext --dark'>Remove</p>
                  </button>
                }
              >
                <DotMenu />
              </Dropdown>
            </div>
            <div className='comment_toolbar'>
              <button
                className='reply-btn small-text --light'
                onClick={() => handleOpenReply(userId, parentCommentId)}
              >
                Reply
              </button>
              {childComments.length > 0 && (
                <span className='small-text --light'>
                  {' '}
                  <div className='dot-divider' /> {childComments.length} Repl
                  {childComments.length < 2 ? 'y' : 'ies'}
                </span>
              )}
              <div className='divider'>|</div>
              <button className={likeButtonClass} onClick={handleLikeToggle}>
                Like
              </button>
              {likesTotal > 0 ? (
                <>
                  <div className='dot-divider' />
                  <div className='dm-blogging_likes-icon'>
                    <LikeIcon />
                  </div>
                  <span className='small-text --light'>{likesTotal}</span>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {childComments.length > 0 && (
        <ChildrenComments
          childComments={childComments}
          handleOpenReply={handleOpenReply}
          commentsById={commentsById}
          replyOpen={openReply}
        />
      )}
      <div id={`__${commentId}`}>
        {openReply && !parentCommentId && (
          <CommentInput
            elementId={elementId}
            parentCommentId={parentCommentId || commentId}
            autoFocus
            defaultPlaceholder={`Replying to ${
              replyUserId ? replyUserName : name
            }`}
            replyUserId={replyUserId}
          />
        )}
      </div>
    </div>
  );
};

const ChildrenComments = ({
  childComments,
  handleOpenReply,
  commentsById,
  replyOpen
}) => {
  const [inView, setInView] = useState(1);

  // useEffect(() => {
  //   if (replyOpen) {
  //     setInView(childComments.length + 1);
  //   }
  // }, [replyOpen, childComments]);

  return (
    <>
      {childComments.length > inView && (
        <Button
          onClick={() => setInView(childComments.length)}
          styleType='link'
          className='child-comments_view-more-btn'
        >
          View earlier replies ({childComments.length - inView})
        </Button>
      )}
      {childComments
        .filter((id) => commentsById[id].status === 1)
        .map((id) => {
          return (
            <Comment
              key={id}
              onReplyOpen={handleOpenReply}
              {...commentsById[id]}
            />
          );
        })
        .splice(childComments.length - inView, inView)}
    </>
  );
};
