// TODO - Do better
export const getIdFromUrl = (urlLookup, pathname, idString) => {
  if (urlLookup[pathname] && urlLookup[pathname][idString]) {
    return urlLookup[pathname][idString];
  } else {
    const urlArr = pathname.split('/');
    const urlLength = urlArr.length;
    for (let i = urlLength; i >= 0; i--) {
      urlArr.pop();
      const joinedUrl = urlArr.join('/');
      if (urlLookup[joinedUrl]) {
        if (urlLookup[joinedUrl][idString]) {
          return urlLookup[joinedUrl][idString];
        }
      }
    }
  }
};
