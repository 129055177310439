import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ProfilePhoto } from '@ovis-technologies/ovis-blueprint';

import { setSidePanelState } from '../../actions/uiActions';
import { PanelType } from '../../reducers/reducers.d';

interface Props {
  mobile?: boolean;
  onUserClick?: () => any;
}

const HeaderUser = ({ mobile = false, onUserClick = () => {} }: Props) => {
  // Hooks
  const dispatch = useDispatch();

  // Selectors
  const activeUser = useSelector(
    (state: any) => state.user.users.byId[state.user.activeUserId]
  );

  let { name, photo: profileImage = '/assets/user.png' } = activeUser;
  // empty string overrides initalized value when destructuring
  if (!profileImage.length) profileImage = '/assets/user.png';

  const handleOpenProfile = () => {
    dispatch(setSidePanelState({ title: name, panelType: PanelType.profile }));
  };

  return (
    <div className='nav-user'>
      <Button
        className='profile-img-btn'
        onClick={handleOpenProfile}
        styleType='text'
      >
        <ProfilePhoto source={profileImage} size='medium' />
        <p>{activeUser?.name}</p>
      </Button>
    </div>
  );
};

export default HeaderUser;
