import React from 'react';

interface Props {
  direction?: 'right' | 'left' | 'up' | 'down';
  fill?: any;
  className?: string;
}

const Arrow = (props: Props) => {
  const { direction = 'right', fill, className = '' } = props;
  return (
    <svg
      className={`${direction}-arrow ${className}`}
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 44.32 22.16'
      fill={fill}
    >
      <rect className='cls-1' x='0.66' y='10.44' width='42.1' height='1.27' />
      <polygon
        className='cls-1'
        points='33.33 21.4 32.44 20.51 41.86 11.08 32.44 1.65 33.33 0.76 43.66 11.08 33.33 21.4'
      />
    </svg>
  );
};

export default Arrow;
