import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableRecord,
  notification,
  formatDate,
  Toggle,
  Select,
  DatePicker,
  Modal
} from '@ovis-technologies/ovis-blueprint';

import { searchDms } from '../../../actions/dmActions';
import { selectDmElements, selectDms } from '../../../selectors/dmSelectors';
import { GlobalState } from '../../../reducers/reducers';
import { truncate } from 'lodash';
import { updateComments } from '../../../actions/commentActions';
import FlexContainer from '../../../components/FlexContainer';

/**  
URL: /settings/review_comments
NAME: Review Comments
AKA: 
**/

interface Props {
  initialLoad: boolean;
  orgId: number;
}

const ManageComments = ({ initialLoad, orgId }: Props) => {
  const dispatch = useDispatch();
  const [toggleLoading, setToggleLoading] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [rowData, setRowData] = useState<TableRecord[]>([]);
  const [postBefore, setPostBefore] = useState(null);
  const [postAfter, setPostAfter] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  const [dmFilter, setDmFilter] = useState(null);
  const [openModal, setOpenModal] = useState<string>();

  const { byId: commentsById, allIds: commentsAllIds } = useSelector(
    (state: GlobalState) => state.comments
  );

  const { byId: usersById } = useSelector(
    (state: GlobalState) => state.user.users
  );

  const { allIds: dmIds, byId: dmsById } = useSelector(selectDms);
  const { byId: elementsById } = useSelector(selectDmElements);

  useEffect(() => {
    const getDmElements = async () => {
      setTableLoading(true);
      await dispatch(searchDms({}));
      setTableLoading(false);
    };
    if (dmIds.length < 1) {
      getDmElements();
    }
  }, [dispatch, dmIds]);

  // Filter Elements
  const filterElements = useCallback(() => {
    return commentsAllIds.filter((id) => {
      let statusCheck = true;
      let postBeforeCheck = true;
      let postAfterCheck = true;
      let dmCheck = true;
      const {
        status: commentStatus,
        postDate,
        elementId
      } = commentsById[id] || {};
      const { dmId } = elementsById[elementId] || {};
      if (filterStatus !== null) {
        statusCheck = commentStatus === filterStatus;
      }
      if (postBefore) {
        postBeforeCheck =
          new Date(postBefore).getTime() > new Date(postDate).getTime();
      }
      if (postAfter) {
        postAfterCheck =
          new Date(postAfter).getTime() < new Date(postDate).getTime();
      }
      if (dmFilter) {
        dmCheck = dmFilter === dmId;
      }
      return postAfterCheck && postBeforeCheck && statusCheck && dmCheck;
    });
  }, [
    postBefore,
    postAfter,
    commentsAllIds,
    commentsById,
    filterStatus,
    dmFilter,
    elementsById
  ]);

  // Create Table Data
  const createTableData = useCallback(
    (filteredIds) => {
      return filteredIds.map((id) => {
        const {
          postDate,
          lastModBy,
          lastModDate,
          userId,
          status,
          comment,
          parentCommentId,
          elementId
        } = commentsById[id];

        const { status: parentStatus } = commentsById[parentCommentId] || {};

        const { dmId } = elementsById[elementId] || {};

        const { name: dmName } = dmsById[dmId] || {};

        const handleToggleStatus = async () => {
          if (toggleLoading) return;
          setToggleLoading(true);
          if (!parentStatus && !status && parentCommentId) {
            notification.error({
              message:
                'This comment\'s parent comment is inactive. To reactivate this comment please you must set the parent comment\'s status to "Active"',
              duration: 10
            });
            setToggleLoading(false);
            return;
          }
          try {
            await dispatch(
              updateComments({
                elementId,
                parentCommentId,
                commentId: id,
                status: status === 1 ? 0 : 1
              })
            );
            setToggleLoading(false);
          } catch (error) {
            console.error(error);
          }
        };

        const handleOpenModal = () => {
          setOpenModal(comment);
        };
        return {
          key: id,
          ...commentsById[id],
          postDate: formatDate(postDate, { format: 'MM/DD/YYYY', time: false }),
          lastModDate: formatDate(lastModDate, {
            format: 'MM/DD/YYYY',
            time: false
          }),
          lastModBy: {
            date: formatDate(lastModDate, {
              format: 'MM/DD/YYYY',
              time: false
            }),
            name: usersById[lastModBy]?.name || ''
          },
          commenter: usersById[userId]?.name || '',
          toggleStatus: {
            value: status,
            handleToggle: handleToggleStatus,
            noToggle:
              (!parentStatus && !status && parentCommentId) || toggleLoading
          },
          comment: {
            short: truncate(comment, {
              length: 100,
              separator: '... '
            }),
            full: comment,
            openCommentModal: handleOpenModal
          },
          dmName: dmName
        };
      });
    },
    [dispatch, dmsById, toggleLoading, commentsById, usersById, elementsById]
  );

  useEffect(() => {
    const filteredIds = filterElements();
    const formattedTableData = createTableData(filteredIds);
    setRowData(formattedTableData);
  }, [filterElements, createTableData]);

  const handleChangePostBefore = (date) => {
    setPostBefore(date);
  };

  const handleChangePostAfter = (date) => {
    setPostAfter(date);
  };

  const handleChangeStatusFilter = (status) => {
    setFilterStatus(status);
  };

  const handleChangeDmFilter = (dm) => {
    setDmFilter(dm);
  };

  const handleCloseModal = () => {
    setOpenModal(undefined);
  };

  const statusOptions = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 0 }
  ];

  const dmOptions = () => {
    const dms = new Set<number>([]);
    commentsAllIds.forEach((id) => {
      const { elementId } = commentsById[id] || {};
      const { dmId } = elementsById[elementId] || {};
      if (!dms.has(dmId) && typeof dmId === 'number') {
        dms.add(dmId);
      }
    });
    return Array.from(dms).map((id) => {
      const { name } = dmsById[id] || {};
      return {
        value: id,
        label: name
      };
    });
  };

  const tableColumns = () => [
    {
      title: 'Status',
      dataIndex: 'toggleStatus',
      key: 'toggleStatus',
      render: (prop) => {
        const { handleToggle, value, noToggle } = prop || {};
        return (
          <div style={{ userSelect: 'none' }}>
            <Toggle
              size='small'
              onChange={handleToggle}
              status={Boolean(value)}
              disabled={noToggle}
            />
            <p className='subtext'>{value === 1 ? 'Active' : 'Inactive'}</p>
          </div>
        );
      }
    },
    {
      title: 'Post Date',
      dataIndex: 'postDate',
      key: 'postDate',
      sort: true
    },
    {
      title: 'Commenter',
      dataIndex: 'commenter',
      key: 'commenter',
      sort: true
    },
    {
      title: 'Document Manager',
      dataIndex: 'dmName',
      key: 'dmName',
      sort: true
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      render: ({ short, full = '', openCommentModal }) => (
        <>
          <p style={{ userSelect: 'none' }}>
            {short}
            {full.length >= 100 && (
              <button onClick={openCommentModal}> Read more.</button>
            )}
          </p>
        </>
      )
    },
    {
      title: 'ID',
      dataIndex: 'commentId',
      key: 'commentId',
      sort: true
    },
    {
      title: 'Parent Id',
      dataIndex: 'parentCommentId',
      key: 'parentCommentId',
      sort: true,
      render: (value) => <>{value !== null ? value : 'No parent'}</>
    },
    {
      title: 'Last Mod By',
      dataIndex: 'lastModBy',
      key: 'lastModBy',
      render: ({ name, date }) => (
        <>
          <p style={{ margin: 0 }}>{name}</p>
          <p>{date}</p>
        </>
      )
    }
  ];

  return (
    <div className='manage-comments'>
      <FlexContainer classname='filters-wrapper'>
        <p className='component-subtitle'>Filter by:</p>
        <div className='filters-panel'>
          <label className='description-text'>
            Status:{' '}
            <Select
              options={statusOptions}
              className='status-select'
              value={filterStatus}
              isClearable
              onChange={(value) => handleChangeStatusFilter(value)}
            />
          </label>
          <label className='description-text'>
            Document Manager:{' '}
            <Select
              options={dmOptions()}
              className='status-select'
              value={dmFilter}
              isClearable
              onChange={(value) => handleChangeDmFilter(value)}
            />
          </label>
          <label className='description-text --date_picker'>
            Posted After:{' '}
            <DatePicker
              onChange={(value) => handleChangePostAfter(value)}
              isClearable
              type='date'
            />
          </label>
          <label className='description-text --date_picker'>
            Posted Before:{' '}
            <DatePicker
              onChange={(value) => handleChangePostBefore(value)}
              isClearable
              type='date'
            />
          </label>
        </div>
      </FlexContainer>
      <Table
        resultCount={rowData.length > 0}
        rowData={rowData}
        columns={tableColumns()}
        loading={initialLoad || tableLoading}
      />
      <Modal
        size='small'
        visible={Boolean(openModal)}
        onCancel={handleCloseModal}
        closeButton
      >
        {openModal}
      </Modal>
    </div>
  );
};

export default ManageComments;
