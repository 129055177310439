import React, { ReactNode } from 'react';
import { isPreviewable, isVideoUrl } from '@ovis-technologies/ovis-blueprint';
import AuthRequiredLink from '../AuthRequiredLink/AuthRequiredLink';
import PreviewButton from '../PreviewButton/PreviewButton';
import { Link } from 'react-router-dom';
import { checkIfInternalUrl } from '../../utils/checkIfInternalUrl';
import classNames from 'classnames';
import { checkIfExternal } from '../../utils';

interface Props {
  contentUrl?: string;
  onClick?: (id?: any) => void;
  onClose?: () => void;
  className?: string;
  buttonText?: string;
  children?: ReactNode;
  hasCustomFunctionality?: boolean;
  priority?: 'download' | 'preview';
  elementId?: number; // Required if is an element from DM, for tracking
  shouldTrack?: boolean;
}

enum RenderType {
  previewButton = 'previewButton',
  authRequiredLink = 'authRequiredLink',
  routerLink = 'routerLink',
  null = 'null'
}

const LinkRender = ({
  onClick = () => {},
  onClose = () => {},
  contentUrl = '',
  className = 'auth-required-link',
  children,
  buttonText = '',
  hasCustomFunctionality,
  priority = 'download',
  elementId,
  shouldTrack = true
}: Props) => {
  const isVideo =
    contentUrl && !hasCustomFunctionality && isVideoUrl(contentUrl);
  const isExternal = contentUrl && checkIfExternal(contentUrl) && !isVideo;

  const canPreview =
    contentUrl &&
    !hasCustomFunctionality &&
    isPreviewable(contentUrl) &&
    !isExternal;

  const isInternalUrl =
    contentUrl && !hasCustomFunctionality && checkIfInternalUrl(contentUrl);
  const isAuthRequiredLink =
    !canPreview && contentUrl && !hasCustomFunctionality;

  const handleClick = () => {
    onClick(elementId);
  };

  const handleClose = () => {
    onClose();
  };

  let renderType: RenderType = RenderType.null;
  if (canPreview) {
    if (priority === 'download') {
      if (isVideo) renderType = RenderType.previewButton;
      else renderType = RenderType.authRequiredLink;
    }
    if (priority === 'preview') renderType = RenderType.previewButton;
  } else if (isInternalUrl) renderType = RenderType.routerLink;
  else if (isAuthRequiredLink) renderType = RenderType.authRequiredLink;

  const linkClass = classNames('link-render', className, {
    '--no-content': !contentUrl && !hasCustomFunctionality
  });

  const link = (type: RenderType) => {
    switch (type) {
      case RenderType.previewButton: {
        return (
          <>
            <PreviewButton
              onClick={handleClick}
              onClose={handleClose}
              contentUrl={contentUrl}
              className={linkClass}
              buttonText={buttonText}
              elementId={elementId}
              shouldTrack={shouldTrack}
            >
              {children}
            </PreviewButton>
          </>
        );
      }
      case RenderType.authRequiredLink: {
        return (
          <>
            <AuthRequiredLink
              onClick={handleClick}
              className={linkClass}
              href={contentUrl}
              name={buttonText}
              elementId={elementId}
              shouldTrack={shouldTrack}
            >
              {children}
            </AuthRequiredLink>
          </>
        );
      }
      case RenderType.routerLink: {
        return (
          <div onClick={handleClick}>
            <Link className={linkClass} to={contentUrl}>
              {children || buttonText}
            </Link>
          </div>
        );
      }
      default: {
        return (
          <div
            className={linkClass}
            onClick={hasCustomFunctionality ? handleClick : undefined}
          >
            {children || buttonText}
          </div>
        );
      }
    }
  };

  return <>{link(renderType)}</>;
};

export default LinkRender;
