import {
  ORG_ACTION_TYPE,
  GET_ORG_CHART,
  ADD_ORG_CHART_USER,
  GET_ORG_CHART_REQUEST
} from '../actions/types/orgActionTypes';
import { Action, Org } from '../App.d';
import { OrgState } from './reducers.d';
import { get } from 'lodash';

const initialState: OrgState = {
  activeOrgId: 0,
  allOrgs: {
    byId: {},
    allIds: [],
    isRequesting: false
  },
  orgChart: {
    topLevel: {},
    allIds: [],
    byId: {},
    isRequesting: false
  },
  error: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: Action) => {
  switch (action.type) {
    case ORG_ACTION_TYPE.Request:
      return {
        ...state,
        allOrgs: {
          ...state.allOrgs,
          isRequesting: true
        }
      };
    case ORG_ACTION_TYPE.GetAllSuccess: {
      const { payload = [] } = action;
      const allIds = payload.map((org: Org) => org.orgId);
      const byId = payload.reduce(
        (carry: object, value: Org) => ({
          ...carry,
          [value.orgId]: value
        }),
        {}
      );
      return {
        ...state,
        allOrgs: {
          ...state.allOrgs,
          allIds,
          byId,
          isRequesting: false
        }
      };
    }
    case ORG_ACTION_TYPE.Failure:
      return {
        ...state,
        allOrgs: { ...state.allOrgs, isRequesting: false },
        error: action.error
      };
    case ORG_ACTION_TYPE.SetActiveOrg: {
      const { orgId } = state.allOrgs.byId[action.payload];
      return { ...state, activeOrgId: orgId };
    }
    case ORG_ACTION_TYPE.RegisterSuccess: {
      const { payload = {} } = action;
      if (!Object.values(payload).length)
        return { ...state, allOrgs: { ...state.allOrgs, isRequesting: false } };
      const byId = { ...state.allOrgs.byId };
      const newOrgInfo: Org = payload;
      const { orgId } = newOrgInfo;
      const org: Org = byId[orgId] || {};
      const newBody = {
        ...org,
        ...newOrgInfo
      };
      if (state.allOrgs.allIds.includes(orgId)) {
        byId[orgId] = newBody;
        return {
          ...state,
          allOrgs: {
            ...state.allOrgs,
            byId,
            isRequesting: false
          }
        };
      } else {
        const byId = { ...state.allOrgs.byId, [orgId]: newBody };
        const allIds = [orgId, ...state.allOrgs.allIds].sort(function (a, b) {
          if (get(byId[a], 'name', '') < get(byId[b], 'name', '')) {
            return -1;
          }
          if (get(byId[a], 'name', '') > get(byId[b], 'name', '')) {
            return 1;
          }
          return 0;
        });
        return {
          ...state,
          allOrgs: {
            byId,
            allIds,
            isRequesting: false
          }
        };
      }
    }
    case GET_ORG_CHART_REQUEST: {
      return {
        ...state,
        orgChart: {
          ...state.orgChart,
          isRequesting: true
        }
      };
    }
    case GET_ORG_CHART: {
      const { payload } = action;
      const allIds = payload.map((chart) => chart.chartId);
      const byId = payload.reduce(
        (carry: object, value) => ({
          ...carry,
          [value.chartId]: value
        }),
        {}
      );
      const topLevel = {};
      payload.forEach((chart) => {
        const { level, chartTypeId, userId } = chart;
        if (chartTypeId === 1) {
          if (!topLevel[level]) {
            topLevel[level] = [];
          }
          topLevel[level].push(userId);
        }
      });
      return {
        ...state,
        orgChart: {
          topLevel,
          allIds,
          byId,
          isRequesting: false
        }
      };
    }
    case ADD_ORG_CHART_USER: {
      const { payload } = action;
      const { chartId, level, userId } = payload;
      const topLevel = { ...state.orgChart.topLevel };
      const byId = { ...state.orgChart.byId };
      const allIds = { ...state.orgChart.allIds };
      byId[chartId] = payload;
      allIds.push(chartId);
      topLevel[level].push(userId);
      return {
        ...state,
        orgChart: {
          ...state.orgChart,
          topLevel,
          allIds,
          byId
        }
      };
    }
    default:
      return state;
  }
};
