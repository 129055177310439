import React from 'react';
import {
  SidePanel as SidePanelInstance,
  Close,
  Arrow
} from '@ovis-technologies/ovis-blueprint';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { setSidePanelState } from '../../actions/uiActions';
import { GlobalState, PanelType } from '../../reducers/reducers.d';
import { searchAlerts } from '../../actions/alertsActions';
import Alerts from '../Alerts';

import UserProfileStatic from '../../pages/UserProfile';
import UserProfileEdit from '../../pages/UserProfile/UserProfileEdit';

import './_side_panel.scss';

const SidePanel = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { title, panelType } = useSelector(
    (state: GlobalState) => state.ui.sidePanel
  );

  const activeUser = useSelector(
    (state: GlobalState) => state.user.users.byId[state.user.activeUserId].name
  );

  const changeHistory = () => {
    if (history.location.state) {
      history.goBack();
    } else {
      history.push(pathname);
    }
  };

  const handleClose = () => {
    dispatch(setSidePanelState({ panelType: PanelType.closed, title: '' }));
    if (panelType === PanelType.alerts) {
      // setAllSeen(true);
      dispatch(searchAlerts({ setToSeen: 1 }));
    }
    if (panelType === PanelType.profileEdit) {
      dispatch(
        setSidePanelState({ panelType: PanelType.profile, title: activeUser })
      );
    }
    changeHistory();
  };

  const panelContent = () => {
    switch (panelType) {
      case 'alerts': {
        return {
          title: 'Alerts',
          classname: 'alerts_side-panel',
          children: <Alerts />,
          closeBtn: <Close />
        };
      }
      case 'profile': {
        return {
          title: title,
          classname: 'user-profile_side-panel',
          children: <UserProfileStatic />,
          closeBtn: <Close />
        };
      }
      case 'profileedit': {
        return {
          title: 'Edit Profile',
          classname: 'profile-edit_side-panel',
          children: <UserProfileEdit />,
          closeBtn: <Arrow direction='back' />
        };
      }
      default: {
        return {
          title: '',
          classname: 'side-panel_container',
          children: <></>,
          closeBtn: <Arrow direction='back' />
        };
      }
    }
  };

  const {
    title: panelTitle,
    classname: panelClass,
    children,
    closeBtn
  } = panelContent();

  return (
    <div>
      <SidePanelInstance
        onClose={handleClose}
        visible={Boolean(panelType !== 'closed')}
        title={panelTitle}
        className={panelClass}
        closeButton={closeBtn}
      >
        {children}
      </SidePanelInstance>
    </div>
  );
};

export default SidePanel;
