import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '@ovis-technologies/ovis-blueprint';
import classNames from 'classnames';

interface Props {
  visible?: boolean;
  className?: string;
}
const GlobalLoading: React.FC<Props> = ({ visible, className }) => {
  const loadingVisible = useSelector((state: any) => state.loading);
  const overlayClass = classNames('loading-overlay', className);

  return (
    <>
      {(loadingVisible || visible) && (
        <div className={overlayClass}>
          <Loader size='large' />
        </div>
      )}
    </>
  );
};

export default GlobalLoading;
