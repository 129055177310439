import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import ManageTerms from './ManageTerms';
import TermsForm from './TermsForm';

import Layout from '../../../components/Page';
import RefreshLoader from '../../../components/RefreshLoader/RefreshLoader';
import { track } from '../../../services/trackingService';

import './terms_management.scss';

const linkPaths = [
  { url: 'terms', name: 'Manage Terms' },
  { url: 'add-terms', name: 'Add Terms Of Use' }
];

const TermsManagement = () => {
  const { url } = useRouteMatch();

  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    track({ context: 6, typeId: 1 });
  }, []);

  return (
    <Layout pageNavigation={linkPaths} id='terms-management'>
      <RefreshLoader visible={refresh} />
      <Switch>
        <Route exact path={`${url}/`}>
          <Redirect to={`${url}/terms`} />
        </Route>
        <Route
          path={`${url}/terms`}
          render={() => (
            <ManageTerms refresh={refresh} setRefresh={setRefresh} />
          )}
        />
        <Route
          path={`${url}/add-terms`}
          render={() => <TermsForm refresh={refresh} setRefresh={setRefresh} />}
        />
      </Switch>
    </Layout>
  );
};

export default TermsManagement;
