import { Dispatch } from 'redux';
import {
  fetchNav,
  fetchNavGroups,
  fetchNavLinks,
  fetchNavDepts,
  fetchNavOrgConfig,
  fetchAllNavOrgConfig,
  postNavGroups,
  postNavLink,
  fetchNavElements,
  createNavLinkRequest,
  createNavGroupRequest
} from '../services/navService';
import * as types from './types/navActionTypes';
import { ORG_ACTION_TYPE } from './types/orgActionTypes';
import { GET_USER_FAILURE, GET_USER_REQUEST } from './types/userActionTypes';
import { setActiveUserId, setRetrievedUser } from './userActions';
import { setActiveOrg, setOrgList } from './orgActions';
import {
  NavGroupCreate,
  NavGroupEdit,
  ResponseData,
  SideNavElement,
  NavDeptQuery,
  NavOrgConfigQuery
} from '../App.d';

export const setNavElements = (navInfo: Array<SideNavElement>) => ({
  type: types.GET_NAV_SUCCESS,
  payload: navInfo
});
export const setNavGroups = (navGroups: any) => ({
  type: types.GET_NAV_GROUPS_SUCCESS,
  payload: navGroups
});
export const setNavLinks = (navLinks: any) => ({
  type: types.GET_NAV_LINKS_SUCCESS,
  payload: navLinks
});
export const setNavOrgConfig = (navOrgConfig: any) => ({
  type: types.GET_NAV_ORG_SUCCESS,
  payload: navOrgConfig
});
export const setAllNavOrgConfig = (navOrgConfig: any) => ({
  type: types.GET_ALL_NAV_ORG_REQUEST,
  payload: navOrgConfig
});
export const setNavOrgConfigByNavId = (navOrgConfig: any) => ({
  type: types.GET_NAV_ORG_BY_NAV_ID_SUCCESS,
  payload: navOrgConfig
});
export const setDeletedNavLink = (navId: number) => ({
  type: types.DELETE_NAV_LINK_SUCCESS,
  payload: navId
});

// Get all side-navigation elements
export const getNavInfo =
  () =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.GET_NAV_REQUEST });
    dispatch({ type: ORG_ACTION_TYPE.Request });
    dispatch({ type: GET_USER_REQUEST });
    try {
      // const response: Response = navTest;
      const responseData: ResponseData = await fetchNav();

      const {
        orgs,
        user,
        nav,
        activeOrg: activeOrgId
      } = responseData?.response || {};

      const { userId = 0 } = user;
      dispatch(setOrgList(orgs));
      dispatch(setActiveOrg(activeOrgId));
      dispatch(setRetrievedUser(user));
      dispatch(setActiveUserId(userId));
      dispatch(setNavElements(nav));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.GET_NAV_FAILURE, error });
      dispatch({ type: ORG_ACTION_TYPE.Failure, error });
      dispatch({ type: GET_USER_FAILURE, error });
      dispatch({ type: 'LOADING_FALSE' });
      throw error;
    }
  };
// Get all side-navigation elements
export const getNavElements =
  (orgId) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.GET_NAV_REQUEST });

    try {
      const responseData: ResponseData = await fetchNavElements(orgId);
      const { nav } = responseData.response;
      dispatch(setNavElements(nav));
      dispatch({ type: 'LOADING_FALSE' });

      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.GET_NAV_FAILURE, error });
      dispatch({ type: 'LOADING_FALSE' });
      throw error;
    }
  };

// Get all nav groups
export const getNavGroups =
  () =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.GET_NAV_GROUPS_REQUEST });
    try {
      const responseData: ResponseData = await fetchNavGroups();

      const { response: groups } = responseData;
      // This for loop is necessary for populating form data. The items require a generic 'id' field
      // This can repaired by having the API send navId as id instead.
      for (const group of groups) if (group.navId) group.id = group.navId;
      dispatch(setNavGroups(groups));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.GET_NAV_GROUPS_FAILURE, error });
      throw error;
    }
  };

export const updateNavGroup =
  (groupData: NavGroupEdit) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.POST_NAV_GROUPS_REQUEST });
    try {
      const responseData: ResponseData = await postNavGroups(groupData);

      const { response: groups } = responseData;
      dispatch(setNavGroups(groups));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.POST_NAV_GROUPS_FAILURE, error });
      throw error;
    }
  };

export const createNavGroup =
  (groupData: NavGroupCreate) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.POST_NAV_GROUPS_REQUEST });
    try {
      const responseData: ResponseData = await createNavGroupRequest(groupData);

      const { response: groups } = responseData;
      dispatch(setNavGroups(groups));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.POST_NAV_GROUPS_FAILURE, error });
      throw error;
    }
  };

// Get all nav link elements
export const getNavLinks =
  () =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.GET_NAV_LINKS_REQUEST });
    try {
      const responseData: ResponseData = await fetchNavLinks();

      const { response: links } = responseData;
      dispatch(setNavLinks(links));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.GET_NAV_LINKS_FAILURE, error });
      throw error;
    }
  };

// Used for Nav link update
export const createNavLink =
  (data) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.POST_NAV_LINKS_REQUEST });
    try {
      const responseData: ResponseData = await createNavLinkRequest(data);
      dispatch({ type: types.POST_NAV_LINKS_SUCCESS });
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.POST_NAV_LINKS_FAILURE, error });
      throw error;
    }
  };

// Used for Nav link update
export const updateNavLink =
  (data) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.POST_NAV_LINKS_REQUEST });
    try {
      const responseData: ResponseData = await postNavLink(data);

      const { response: navLink } = responseData;
      dispatch({ type: types.POST_NAV_LINKS_SUCCESS, payload: navLink });
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.POST_NAV_LINKS_FAILURE, error });
      throw error;
    }
  };

export const getNavOrgConfig =
  (body: NavOrgConfigQuery) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.GET_NAV_ORG_REQUEST });
    try {
      const responseData: ResponseData = await fetchNavOrgConfig(body);
      const { response: config } = responseData;

      dispatch(setNavOrgConfig(config));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.GET_NAV_ORG_FAILURE, error });
      throw error;
    }
  };

export const getAllNavOrgConfig =
  () =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    try {
      const responseData: ResponseData = await fetchAllNavOrgConfig();
      const { response: config } = responseData;
      dispatch(setAllNavOrgConfig(config));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.GET_NAV_ORG_FAILURE, error });
      throw error;
    }
  };

export const getNavOrgConfigByNavId =
  (body: NavOrgConfigQuery) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.GET_NAV_ORG_BY_NAV_ID_REQUEST });
    try {
      const responseData: ResponseData = await fetchNavOrgConfig(body);
      const { response: config } = responseData;
      dispatch(setNavOrgConfigByNavId({ config, navId: body.navId }));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.GET_NAV_ORG_BY_NAV_ID_FAILURE, error });
      throw error;
    }
  };

// Get all nav links that are departments - includes contacts
export const getNavDepartments =
  (body?: NavDeptQuery) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.GET_NAV_DEPARTMENTS_REQUEST });
    try {
      const responseData: ResponseData = await fetchNavDepts(body || {});
      const { response: depts } = responseData;
      dispatch({
        type: types.GET_NAV_DEPARTMENTS_SUCCESS,
        payload: depts
      });
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.GET_NAV_DEPARTMENTS_REQUEST, error });
      throw error;
    }
  };
