import * as types from '../actions/types/dashboardActionTypes';
import { DashboardState } from './reducers.d';
import { Action } from '../App.d';
import { Dashboard } from '@ovis-technologies/ovis-blueprint';

const initialState: DashboardState = {
  byId: {},
  allIds: [],
  isRequesting: false
};

const dashboardReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case types.FETCH_DASHBOARDS_REQUEST:
    case types.CREATE_DASHBOARD_REQUEST:
    case types.EDIT_DASHBOARD_REQUEST:
    case types.DELETE_DASHBOARD_REQUEST:
      return {
        ...state,
        isRequesting: true
      };
    case types.CREATE_DASHBOARD_SUCCESS: {
      const { payload } = action;
      const allIds = [...state.allIds, payload.dashboardId];
      const byId = { ...state.byId, [payload.dashboardId]: payload };
      return {
        allIds,
        byId,
        isRequesting: false
      };
    }
    case types.EDIT_DASHBOARD_SUCCESS: {
      const { payload } = action;
      const byId = { ...state.byId, [payload.dashboardId]: payload };
      return {
        ...state,
        byId,
        isRequesting: false
      };
    }
    case types.FETCH_DASHBOARDS_SUCCESS: {
      const { payload = [] } = action;
      const allIds = payload.map(
        (dashboard: Dashboard) => dashboard.dashboardId
      );
      const byId = payload.reduce(
        (previous: { [id: number]: Dashboard }, dashboard: Dashboard) => ({
          ...previous,
          [dashboard.dashboardId as number]: dashboard
        }),
        {}
      );
      return {
        allIds,
        byId,
        isRequesting: false
      };
    }
    case types.DELETE_DASHBOARD_SUCCESS: {
      const { payload } = action;
      const allIds = state.allIds.filter((id) => id !== payload);
      const byId = { ...state.byId };
      delete byId[payload];
      return {
        allIds,
        byId,
        isRequesting: false
      };
    }
    case types.FETCH_DASHBOARDS_FAILURE:
    case types.CREATE_DASHBOARD_FAILURE:
    case types.EDIT_DASHBOARD_FAILURE:
    case types.DELETE_DASHBOARD_FAILURE:
      return {
        ...state,
        isRequesting: false
      };
    default:
      return state;
  }
};

export default dashboardReducer;
