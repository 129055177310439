import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import { Search, Close, Button } from '@ovis-technologies/ovis-blueprint';

import './_search_input.scss';

interface Props {
  onChange: (string: string) => void;
  onClose: (arg?: any) => void;
  onFocus?: (arg?: any) => void;
  onBlur?: (arg?: any) => void;
  value: string;
  placeholder?: string;
  className?: string;
}

const SearchInput = ({
  onChange,
  onClose,
  onFocus,
  onBlur,
  value = '',
  placeholder = 'Search...',
  className = ''
}: Props) => {
  const [searchFocus, setSearchFocus] = useState(false);
  const [forceClose, setForceClose] = useState(false);

  const focusInput = useRef<any>();

  const handleSearchFocus = (event) => {
    if (!event.target.closest('.close-btn')) {
      setForceClose(false);
      setSearchFocus(true);
      if (focusInput.current) focusInput.current.focus();
      onFocus && onFocus();
    }
  };

  const handleBlur = (event) => {
    setSearchFocus(false);
    onBlur && onBlur(event);
  };

  const handleSearchClose = () => {
    setForceClose(true);
    if (focusInput.current?.value) focusInput.current.value = '';
    onClose && onClose();
  };

  const open = (focusInput?.current?.value || searchFocus) && !forceClose;

  const wrapperClass = classNames(
    'search-wrapper',
    { '--open': open, '--closed': !open },
    className
  );

  return (
    <div className={wrapperClass}>
      <div className='search-input' onClick={handleSearchFocus}>
        <div className='search-icon'>
          <Search />
        </div>
        <input
          value={value}
          type='text'
          ref={focusInput}
          placeholder={placeholder}
          onFocus={handleSearchFocus}
          onBlur={handleBlur}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
      <Button
        styleType='text'
        className='close-btn'
        onClick={handleSearchClose}
      >
        <Close />
      </Button>
    </div>
  );
};

export default SearchInput;
