import React, { useState } from 'react';
import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';
import { OKTA_DOMAIN } from '../../config';
import {
  Button,
  notification,
  Modal,
  Portal
} from '@ovis-technologies/ovis-blueprint';

interface Props {}

enum EmailState {
  success = 'success',
  error = 'error',
  notSent = 'notSent',
  sending = 'pending'
}

const ChangePassword = (props: Props) => {
  const { oktaAuth } = useOktaAuth();
  const [emailState, setEmailState] = useState<string>(EmailState.notSent);
  const [openModal, setOpenModal] = useState(false);

  // Immediately invoked async function to get user email
  let userInfo: any = {};
  const sendResetEmail = async () => {
    setEmailState(EmailState.sending);
    userInfo = await oktaAuth.token.getUserInfo();
    try {
      await axios.post(`${OKTA_DOMAIN}/api/v1/authn/recovery/password`, {
        username: userInfo.email,
        factorType: 'EMAIL'
      });

      setEmailState(EmailState.success);
      notification.success({
        message: 'Success',
        description: 'Reset link sent! Check your email',
        duration: 10
      });
    } catch (error: any) {
      setEmailState(EmailState.error);
      notification.error({
        message: 'Error',
        description: 'Error encountered. This is most likely our issue',
        duration: 9
      });
    }
  };

  return (
    <>
      <Button
        styleType='primary'
        onClick={() => setOpenModal(true)}
        size='fill'
      >
        Change Password
      </Button>
      <Portal>
        <Modal
          visible={openModal}
          title='Change Password'
          onCancel={() => setOpenModal(false)}
          size='small'
        >
          <p style={{ marginBottom: '1.5rem' }}>
            Note: This will change your Okta password. You will receive an email
            from Okta with a reset link.
          </p>
          <Button
            type='button'
            styleType='primary'
            size='large'
            loading={emailState === EmailState.sending}
            onClick={sendResetEmail}
          >
            Send Password Reset
          </Button>
        </Modal>
      </Portal>
    </>
  );
};

export default ChangePassword;
