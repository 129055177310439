import { filterActions as actions } from '../actions/types/filterActionTypes';
import { Action } from '../App.d';
import { FiltersState } from '../types/filters';

const initialState: FiltersState = {
  filters: {},
  dmId: 0,
  text: '',
  groups: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: Action) => {
  switch (action.type) {
    case actions.setFilterDM: {
      const { payload } = action;
      return {
        ...state,
        dmId: payload
      };
    }
    case actions.removeFilterDM: {
      return initialState;
    }
    case actions.updateFilterText: {
      const { payload } = action;
      return {
        ...state,
        text: payload
      };
    }
    case actions.updateFilterType: {
      const { payload } = action;
      const { filter, filterItem } = payload;
      const currentFilters = { ...state.filters };

      currentFilters[filter] = filterItem;
      return {
        ...state,
        filters: currentFilters
      };
    }
    case actions.removeFilterType: {
      const { payload } = action;
      const currentFilters = { ...state.filters };
      delete currentFilters[payload];
      return {
        ...state,
        filters: currentFilters
      };
    }
    case actions.updateFilterGroup: {
      const { payload } = action;
      return {
        ...state,
        groups: payload
      };
    }
    default:
      return state;
  }
};
