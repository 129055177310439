// @ts-nocheck
import React from 'react';

const Pause = () => (
  <svg
    className='pause-icon'
    width='512'
    height='512'
    x='0'
    y='0'
    viewBox='0 0 327 327'
    space='preserve'
  >
    <g>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='m158 0h71c4.417969 0 8 3.582031 8 8v311c0 4.417969-3.582031 8-8 8h-71c-4.417969 0-8-3.582031-8-8v-311c0-4.417969 3.582031-8 8-8zm0 0'
        fill='#ffffff'
        data-original='#000000'
      />
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='m8 0h71c4.417969 0 8 3.582031 8 8v311c0 4.417969-3.582031 8-8 8h-71c-4.417969 0-8-3.582031-8-8v-311c0-4.417969 3.582031-8 8-8zm0 0'
        fill='#ffffff'
        data-original='#000000'
      />
    </g>
  </svg>
);

export default Pause;
