import axios from 'axios';
import { get } from 'lodash';
import config from '../config';

const getPrivateAssetUrl = async (url: string) => {
  let response;

  if (process.env.NODE_ENV === 'development' && !url.startsWith('https'))
    url = config.api.domain + url;
  else if (process.env.NODE_ENV === 'production' && !url.startsWith('https'))
    url = config.api.domain + url;
  try {
    response = await axios.get(url);
    return get(response, 'data.response.signedUrl', '');
  } catch (err: any) {
    console.error('Error getting authenticated url from API', err);
    return Promise.reject(err);
  }
};

export default getPrivateAssetUrl;
