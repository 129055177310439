import React from 'react';

import { exportUsersRequest } from '../../services/exportService';
import { ExportExcel } from '@ovis-technologies/ovis-blueprint';

interface Props {
  keyword?: string;
  orgId?: number;
  buttonSize?: 'large' | 'default' | 'small';
}

const ExportUsers = ({
  keyword = '',
  orgId,
  buttonSize = 'default'
}: Props) => {
  return (
    <ExportExcel
      exportServiceFunction={exportUsersRequest}
      requestParameters={{ keyWord: keyword, orgId }}
      buttonSize={buttonSize}
      buttonText='Export Users'
    />
  );
};

export default ExportUsers;
