import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { Button } from '@ovis-technologies/ovis-blueprint';

import { track } from '../../services/trackingService';
import { GlobalState } from '../../reducers/reducers';
import { searchDms, getDmTags } from '../../actions/dmActions';
import { getNavId } from '../../utils';
import PageNav, { Path } from '../PageNavigation';
import SearchForm from '../Search';
import DocumentTemplate from '../../components/DocumentTemplates';
import { dmElementsSelector, selectNavigationLookup } from '../../selectors';

import './page.scss';

interface Props {
  title?: string;
  children?: ReactNode;
  subTitle?: string;
  id?: string;
  className?: string;
  pageNavigation?: Path[];
  pageActions?: any[];
  isLibrary?: boolean;
  dmId?: number;
  excludeTracking?: boolean;
  mostViewedOn?: boolean;
  centerContent?: boolean;
  pageWidth?: 'small' | 'medium' | 'large' | 'full';
  noTitle?: boolean;
}

const Page = ({
  title,
  children,
  subTitle,
  id = '',
  className,
  pageNavigation,
  pageActions = [],
  isLibrary,
  dmId = 0,
  excludeTracking,
  mostViewedOn = false,
  centerContent = false,
  pageWidth = 'full',
  noTitle
}: Props) => {
  // hooks
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  // State
  const [toggleMostViewed, setToggleMostViewed] = useState(false);

  // selectors
  const urlLookup = useSelector(selectNavigationLookup);
  const pageTitle = useSelector((state: GlobalState) => {
    if (title) {
      return `${title}${subTitle ? `- ${subTitle}` : ''}`;
    }
    const { byId, allIds } = state.nav.elements;

    let pageTitle = '';
    if (allIds.length > 0) {
      const navId = getNavId(urlLookup, pathname);
      if (navId) {
        const parentId = byId[navId]?.parentNavId || navId;
        const ignoreParentNav =
          parentId === 54 || parentId === 6 || parentId === 74;

        if (ignoreParentNav) {
          pageTitle = byId[navId]?.name;
        } else if (pathname.includes('#')) {
          const grandParentId = byId[parentId]?.parentId;
          pageTitle = byId[grandParentId]?.name;
        } else {
          pageTitle = byId[parentId]?.name;
        }
      }
      return `${pageTitle}${subTitle ? `- ${subTitle}` : ''}`;
    } else {
      return '';
    }
  });

  const { elements, mostViewed, templateId, isRequesting, filtersAvailable } =
    useSelector((state: GlobalState) =>
      dmElementsSelector(state, dmId, mostViewedOn)
    );

  // Side Effects
  useEffect(() => {
    const getDms = async () => {
      await Promise.all([
        dispatch(
          searchDms({
            dmIds: [dmId]
          })
        ),
        dispatch(getDmTags(dmId))
      ]);
    };
    if (dmId) {
      getDms();
    }
  }, [dispatch, dmId]);

  useEffect(() => {
    if (!excludeTracking) track({ context: url, typeId: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Functions
  const handleToggleMostViewed = (bool) => {
    setToggleMostViewed(bool);
  };

  // vars
  const pageClass = classNames('page', className, `--${pageWidth}`, {
    '--has-page-nav': pageNavigation,
    '--center-content': centerContent,
    '--include-filters': isLibrary
  });

  const buttonClassAll = classNames('toggle-btn', {
    '--active': !toggleMostViewed
  });

  const buttonClassMostViewed = classNames('toggle-btn', {
    '--active': toggleMostViewed
  });

  const noResults = Boolean(elements.length === 0 && filtersAvailable);

  return (
    <div className={pageClass} id={id}>
      {pageNavigation && (
        <PageNav linkPaths={pageNavigation} actions={pageActions} />
      )}
      <div className='page-content'>
        <div
          className='page_sticky-header'
          style={{ paddingBottom: isLibrary ? '2.5rem' : '1.5rem' }}
        >
          {pageTitle && !noTitle && <h1 className='page-title'>{pageTitle}</h1>}
          {isLibrary && dmId && <SearchForm dmId={dmId} />}
        </div>
        {mostViewedOn && (
          <div className='page_documents-toggle'>
            <Button
              className={buttonClassAll}
              onClick={() => handleToggleMostViewed(false)}
              styleType='text'
            >
              All Documents
            </Button>
            <Button
              className={buttonClassMostViewed}
              onClick={() => handleToggleMostViewed(true)}
              styleType='text'
            >
              Most Viewed
            </Button>
          </div>
        )}

        <div className='page_body'>
          {isLibrary && dmId && (
            <div className='documents-page-body'>
              <DocumentTemplate
                templateId={templateId}
                elements={toggleMostViewed ? mostViewed : elements}
                isRequesting={isRequesting}
                noResults={noResults}
              />
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Page;
