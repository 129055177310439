import React from 'react';
import { Panel } from '@ovis-technologies/ovis-blueprint';

function Forbidden() {
  return (
    <div className='content'>
      <Panel>
        This page requires administrative privilege.
        <br />
        Contact your organization administrator.
      </Panel>
    </div>
  );
}

export default Forbidden;
