import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from '../../reducers/reducers';
import { setToggleDocumentModal } from '../../actions/uiActions';
import { searchDms } from '../../actions/dmActions';

// Components
import {
  Loader,
  Modal,
  notification,
  Portal
} from '@ovis-technologies/ovis-blueprint';
import Comments from '../DMBlogging/Comments';
import { ActionToolbar } from '../DMBlogging';
import Details from '../DocumentTemplates/Details';
import CoverImage from '../CoverImage/CoverImage';

const DocumentModal = () => {
  // Hooks
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // Selectors
  const { byId: elementsById } = useSelector(
    (state: GlobalState) => state.dm.elements
  );

  const { byId: groupsById } = useSelector(
    (state: GlobalState) => state.dm.groups
  );

  const { visible, activeElement } = useSelector(
    (state: GlobalState) => state.ui.documentModal
  );

  // Subscriptions
  const getDm = useCallback(
    async (elementId) => {
      try {
        setLoading(true);
        await dispatch(
          setToggleDocumentModal({ visible: true, activeElement: elementId })
        );
        await dispatch(
          searchDms({
            elementId: elementId
          })
        );
      } catch (error: any) {
        setLoading(false);
        notification.error({ message: 'Error retrieving document' });
      }
      setLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    const query = new URL(document.location as any).searchParams;
    const elementIdFromUrl = query.get('elementId');
    if (elementIdFromUrl) {
      getDm(elementIdFromUrl);
    }
  }, [getDm]);

  useEffect(() => {
    if (Boolean(elementsById[activeElement]) === false && visible) {
      getDm(activeElement);
    }
  }, [dispatch, elementsById, activeElement, visible, getDm]);

  // Functions
  const handleClose = () => {
    dispatch(setToggleDocumentModal({ visible: false }));
  };

  // Vars
  const {
    groupId,
    title = '',
    coverImage,
    contentUrl,
    fileLocation,
    link
  } = elementsById[activeElement] || {};

  const { allowComments = 0 } = (groupId && groupsById[groupId]) || {};

  return (
    <>
      <Portal>
        <div className='dm-blogging_dm-blog dm-blog'>
          <Modal
            visible={visible}
            closeButton
            onCancel={handleClose}
            size='small'
            smallPadding
          >
            {loading ? (
              <div
                className='dm-blog_loader'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  minHeight: 300,
                  paddingBottom: '2rem'
                }}
              >
                <Loader />
                <p className='component-subtitle'>Retrieving document...</p>
              </div>
            ) : (
              <div className='dm-blog_content'>
                {coverImage && (
                  <CoverImage
                    className='dm-blog_image'
                    contentUrl={contentUrl || fileLocation || link}
                    coverImageSrc={coverImage}
                    alt={title}
                  />
                )}
                <div className='document_details-wrapper'>
                  <Details elementId={activeElement} fullDescription />
                  <ActionToolbar
                    elementId={activeElement}
                    title={title}
                    moduleId={1}
                    noCommentToggle
                  />
                </div>
                {Boolean(allowComments) && (
                  <Comments
                    elementId={activeElement}
                    requesting={false}
                    openInput
                  />
                )}
              </div>
            )}
          </Modal>
        </div>
      </Portal>
    </>
  );
};

export default DocumentModal;
