import types from '../actions/types/userNotificationActionTypes';
import createAllIds from '../utils/createAllIds';
import createById from '../utils/createById';
import { NotificationManagementState } from './reducers.d';
import { union } from 'lodash';

const initialState: NotificationManagementState = {
  allIds: [],
  byId: {},
  isRequesting: false
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getNotificationRequest:
    case types.searchRequest:
    case types.createRequest:
    case types.updateRequest:
    case types.deleteRequest:
      return { ...state, isRequesting: true };

    case types.searchSuccess: {
      const { payload = [] } = action;
      const allIds = union(
        state.allIds,
        createAllIds(payload, 'notificationId')
      );
      const byId = { ...state.byId, ...createById(payload, 'notificationId') };
      return {
        ...state,
        allIds,
        byId,
        isRequesting: false
      };
    }
    case types.createSuccess: {
      const { payload = {} } = action;
      const { notificationId } = payload;

      const { allIds, byId } = state;

      const notificationsIds = [...allIds, notificationId];
      const notificationsById = { ...byId, [notificationId]: payload };

      return {
        ...state,
        allIds: notificationsIds,
        byId: notificationsById,
        isRequesting: false
      };
    }
    case types.getNotificationSuccess:
    case types.updateSuccess: {
      const { payload = {} } = action;
      const { notificationId } = payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [notificationId]: { ...state.byId[notificationId], ...payload }
        },
        isRequesting: false
      };
    }
    case types.deleteSuccess: {
      const { payload: notificationId }: { payload: number } = action;
      const newAllIds = state.allIds.filter((id) => id !== notificationId);
      const newById = { ...state.byId };
      delete newById[notificationId];

      return {
        ...state,
        allIds: newAllIds,
        byId: newById,
        isRequesting: false
      };
    }
    case types.getNotificationFailure:
    case types.searchFailure:
    case types.createFailure:
    case types.updateFailure:
    case types.deleteFailure:
      return { ...state, isRequesting: false };

    default:
      return state;
  }
};

export default notificationsReducer;
