import axios from 'axios';
import { STATIC_ENDPOINT } from './endpoints';
import { FetchStatic, ResponseData } from '../App.d';
import { handleServiceError } from '.';

export const fetchStaticInfo: FetchStatic = (): Promise<ResponseData> => {
  return axios
    .get(STATIC_ENDPOINT)
    .then((res) => {
      return res.data;
    })
    .catch(handleServiceError);
};
