import {
  DmElementsState,
  DmFilterItemsState,
  DmFiltersState,
  DmGroupsState,
  DmsState,
  DmTagsState,
  GlobalState
} from '../reducers/reducers';
import { IDmElement, IDmFilter, IDmFilterItem, IDmGroup } from '../types/dm.d';

export const selectDms = (state: GlobalState): DmsState => state.dm.dms;
export const selectDmGroups = (state: GlobalState): DmGroupsState =>
  state.dm.groups;
export const selectDmElements = (state: GlobalState): DmElementsState =>
  state.dm.elements;
export const selectDmFilters = (state: GlobalState): DmFiltersState =>
  state.dm.filters;
export const selectDmFilterItems = (state: GlobalState): DmFilterItemsState =>
  state.dm.filterItems;
export const selectDmTags = (
  state: GlobalState,
  dmId?: number
): DmTagsState => ({
  allIds: dmId ? state.dm.dmTags[dmId] : state.dm.tags.allIds,
  byId: state.dm.tags.byId
});

export const selectActiveDmElementIds = (
  state: GlobalState,
  dmId: number
): number[] => state.dm.dms.byId[dmId].elements;

export const selectActiveDmGroupIds = (
  state: GlobalState,
  dmId: number
): number[] => state.dm.dms.byId[dmId].groups;

export const selectActiveDmFilterIds = (
  state: GlobalState,
  dmId: number
): number[] => state.dm.dms.byId[dmId].filters;

export const selectActiveDmFilterItemIds = (
  state: GlobalState,
  dmId: number
): number[] => state.dm.dms.byId[dmId].filterItems;

export const selectActiveDmElements = (
  state: GlobalState,
  dmId: number
): IDmElement[] => {
  const elements = selectDmElements(state);
  return selectActiveDmElementIds(state, dmId).map((id) => elements[id]);
};

export const selectActiveDmGroups = (
  state: GlobalState,
  dmId: number
): IDmGroup[] => {
  const groups = selectDmGroups(state);
  return selectActiveDmGroupIds(state, dmId).map((id) => groups[id]);
};

export const selectActiveDmFilters = (
  state: GlobalState,
  dmId: number
): IDmFilter[] => {
  const filters = selectDmFilters(state);
  return selectActiveDmFilterIds(state, dmId).map((id) => filters[id]);
};

export const selectActiveDmFilterItems = (
  state: GlobalState,
  filterId: number
): IDmFilterItem[] => {
  const filterItems = selectDmFilterItems(state);
  return selectActiveDmFilterItemIds(state, filterId).map(
    (id) => filterItems[id]
  );
};

export const selectElementCommentIds = (
  state: GlobalState,
  parentElementId: number
) => {
  const { allIds, byId, isRequesting } = state.comments;
  const ids = allIds.filter((id) => {
    const { elementId, parentCommentId, status } = byId[id];
    const topLevel = Boolean(!parentCommentId);
    const isElementComment = elementId === parentElementId;
    const isActive = status === 1;
    return isElementComment && topLevel && isActive;
  });
  return { allIds: ids, byId, isRequesting };
};
