// Only exists because logic exists in two places. Hopefully will be unnecessary in future nav changes.

const getPathState = (navById, subNavArr, defaultUrl, navId?) => {
  const subNavHasExternal =
    subNavArr.length > 0 &&
    subNavArr.find((id) => {
      const subNavUrl = navById[id]?.url;
      return subNavUrl?.[0] === '/';
    });
  const { url: redirectUrl = '' } = navById[subNavArr?.[0]] || {};
  let path = subNavHasExternal > 0 ? redirectUrl : defaultUrl;
  const stateNavId = navId || subNavArr?.[0];
  if (defaultUrl === '/settings') {
    path = '/settings';
  }
  return [path, stateNavId];
};

export default getPathState;
