import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { get } from 'lodash';
import {
  OptionProps,
  Button,
  Select,
  TextArea,
  notification,
  SidePanel
} from '@ovis-technologies/ovis-blueprint';
import { guidedTourAction } from '../../actions/types/uiActionTypes';
import { notifActions } from '../../actions/types/notifActionTypes';
import {
  createGuidedTourStepRequest,
  updateGuidedTourStepRequest
} from '../../services/helpService';
import { GlobalState } from '../../reducers/reducers.d';
import { RequestState } from '../../../constants/requestStates';
import { getHelpList } from '../../actions/helpAction';

interface Props {
  initialPage?: string;
  navId?: number;
}

const CreateStep: React.FC<Props> = ({
  initialPage = '/',
  navId = 1
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    history.push(initialPage);
  }, [history, initialPage]);

  const [selectedElementName, setSelectedElementName] =
    useState('None selected');
  const [requestState, setRequestState] = useState(RequestState.null);
  const navAllIds = useSelector(
    (state: GlobalState) => state.nav.elements.allIds
  );
  const navById = useSelector((state: GlobalState) => state.nav.elements.byId);
  const [descriptionState, setDescription] = useState<string>('');
  const [page, setPage] = useState<number>(navId);
  const elementTarget = useSelector(
    (state: GlobalState) => state.ui.tour.elementTarget
  );
  const activeUserId = useSelector(
    (state: GlobalState) => state.user.activeUserId
  );
  let pages: OptionProps[] = navAllIds
    .filter(
      (id: number) => navById[id].parentNavId === null && navById[id].url.length
    )
    .map((id: number) => {
      const navItem = navById[id];
      return {
        value: id,
        label: navItem.name
      };
    });
  // Home is not an active nav element currently, but has to be added to the pages options
  if (!navAllIds.includes(1)) {
    pages = [
      {
        value: 1,
        label: 'Home'
      },
      ...pages
    ];
  }

  const { byId: helpById } = useSelector(
    (state: GlobalState) => state.help.allHelp
  );
  const {
    description = '',
    navId: navIdToEdit = 0,
    helpId,
    componentRef = '',
    ...rest
  } = useSelector((state: GlobalState) =>
    get(helpById, state.ui.tour.editHelpId, {})
  );
  useEffect(() => {
    if (description) setDescription(description);
  }, [description]);

  useEffect(() => {
    if (navIdToEdit) {
      // adding temp Home data
      const navByIdCopy = { ...navById, 1: { name: 'Home' } };
      setSelectedElementName(navByIdCopy[navIdToEdit]?.name);
      dispatch({
        type: guidedTourAction.setTourElementTarget,
        payload: componentRef
      });
    }
  }, [navIdToEdit, navById, componentRef, dispatch]);

  const handlePageChange = (navId) => {
    setPage(navId);
    history.push(get(navById[navId], 'url', ''));
  };

  const handleClick = useCallback(
    (e) => {
      notification.destroy();
      const navDOMIdSplit = e.target.id.split('-');
      if (navDOMIdSplit.shift() !== 'gt') {
        notification.error({
          message:
            'Not eligible for a guided tour step. Please select an element from the side or top navigation'
        });
      } else {
        const navByIdCopy = { ...navById, 1: { name: 'Home' } };
        const navId = navDOMIdSplit.pop() || 0;
        const navItem = get(navByIdCopy, navId, 0);
        const name = get(navItem, 'name', '');
        notification.success({ message: 'Selected: ' + name });
        setSelectedElementName(name);
        dispatch({
          type: guidedTourAction.setTourElementTarget,
          payload: e.target.id
        });
        window.removeEventListener('click', handleClick);
      }
    },
    [dispatch, navById]
  );

  useEffect(() => {
    return () => window.removeEventListener('click', handleClick);
  }, [handleClick]);

  const enableElementSelection = (e) => {
    notification.destroy();
    notification.info({
      message: 'Select an element from the side or top navigation panels'
    });
    window.addEventListener('click', handleClick);
    e.stopPropagation();
  };

  const handleEditClose = () => {
    window.removeEventListener('click', handleClick);
    notification.destroy();
    dispatch({ type: guidedTourAction.inactive });
    dispatch({ type: guidedTourAction.setTourEditId, payload: 0 });
    history.push('/settings/guided_tour');
  };

  const handleSubmit = async () => {
    setRequestState(RequestState.sending);
    const newStep = navIdToEdit === 0;
    if (!elementTarget) {
      dispatch({
        type: notifActions.setNotification,
        payload: { type: 'error', notification: 'Select an element to submit' }
      });
      setRequestState(RequestState.null);
      return;
    }

    const tempHomeData = {
      url: '/',
      navId: 1,
      name: 'Home'
    };
    // add temp home data
    const navByIdCopy = { ...navById, '1': tempHomeData };
    const navId = elementTarget.split('-').pop() || 0;
    const { url = '/' } = navByIdCopy[page] || {};
    const requestBody = {
      ...rest,
      name: selectedElementName + '-GT',
      url,
      ownerId: activeUserId,
      componentRef: elementTarget,
      description: descriptionState,
      navId
    };
    try {
      if (newStep) {
        await createGuidedTourStepRequest(requestBody);
      } else {
        const reqbody = { helpId, ...requestBody };
        await updateGuidedTourStepRequest(reqbody);
      }

      const message = newStep
        ? 'Step created for ' + navById[navId]?.name
        : 'Step for ' + navById[navId]?.name + ' has been edited';
      await dispatch(getHelpList());
      handleEditClose();
      notification.success({ duration: 2000, message });
    } catch (err: any) {
      console.error(err);
    } finally {
      setRequestState(RequestState.null);
    }
  };
  return (
    <>
      <div className='guided-tour-edit-overlay' />
      <SidePanel
        visible
        title={(navIdToEdit ? 'Edit ' : 'Add ') + 'Step'}
        onClose={handleEditClose}
        className='guided-tour-create-step-container '
      >
        <div className='guided-tour-create-step-window'>
          <div className='scroll-container'>
            <label>
              <span className='small-text'>Selected Element: </span>
              <span className='selected-element'>{selectedElementName}</span>
            </label>

            <Button
              size='default'
              onClick={enableElementSelection}
              styleType='primary'
            >
              Select an Element
            </Button>
            <label className='small-text'>Page</label>
            <Select
              placeholder='Select a page'
              value={page}
              defaultValue={1}
              onChange={handlePageChange}
              options={pages}
            />
            <label className='small-text'>Description</label>
            <TextArea
              onChange={(value) => setDescription(value)}
              defaultValue={description}
              value={descriptionState}
            />
          </div>
          <Button
            className='small'
            onClick={handleSubmit}
            loading={requestState === RequestState.sending}
            size='default'
            styleType='secondary'
          >
            Submit
          </Button>
        </div>
      </SidePanel>
    </>
  );
};

export default CreateStep;
