import React, { ReactNode } from 'react';
import classnames from 'classnames';
import './flex-container.scss';

type Props = {
  children: ReactNode;
  classname?: string;
  id?: string;
  background?: boolean;
  align?:
    | 'flex-end'
    | 'center'
    | 'flex-start'
    | 'space-between'
    | 'space-evenly'
    | 'space-around';
  justify?:
    | 'flex-end'
    | 'center'
    | 'flex-start'
    | 'space-between'
    | 'space-evenly'
    | 'space-around';
};

const FlexContainer = ({
  children,
  id,
  classname,
  background,
  align = 'flex-start',
  justify = 'flex-start'
}: Props) => {
  const classes = classnames('flex-container', classname, {
    '--panel': background
  });
  const styles = {
    alignItems: align,
    justifyContent: justify
  };
  return (
    <div className={classes} id={id} style={styles}>
      {children}
    </div>
  );
};

export default FlexContainer;
