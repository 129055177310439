import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import useAudioPlayer from '../../hooks/useAudioPlayer';
import Play from '../svgs/Play';
import Pause from '../svgs/Pause';
import AudioControl from '../svgs/AudioControl';
import { formatAudioTime } from '../../utils';
import CloseButton from '../svgs/Close';
import { GlobalState } from '../../reducers/reducers.d';
import { get } from 'lodash';
import { Loader, Tooltip } from '@ovis-technologies/ovis-blueprint';
import './tour_step.scss';
interface Props {
  tourId: number | string; // helpId of guided tour entry/row
  audioUrl: string;
  text: string;
  onNext: () => void;
  onPrevious: () => void;
  loadingState?: boolean;
  position?: {
    top: string;
    left: string;
  };
  steps?: number;
  activeStep: number;
  onClose: () => void;
}

const TourStep: React.FC<Props> = ({
  tourId,
  audioUrl,
  text,
  onNext,
  onPrevious,
  loadingState,
  position = { top: '0px', left: '0px' },
  steps = 0,
  activeStep,
  onClose
}) => {
  const history = useHistory();
  const previewing = useSelector(
    (state: GlobalState) => state.ui.tour.previewing
  );
  const [elementPosition, setElementPosition] = useState<{
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  }>();

  useLayoutEffect(() => {
    const element = document.querySelector('.tour-step') as HTMLElement;
    const height = element.offsetHeight;
    const { top } = element.getBoundingClientRect();
    if (element && top + height > window.innerHeight) {
      setElementPosition({
        top: `${window.innerHeight - height}px`,
        left: get(position, 'left', '0px')
      });
    } else setElementPosition(position);
  }, [position]);

  const {
    currentTime = 0,
    duration = 0,
    playing,
    togglePlaying,
    setClickedTime,
    setAudioRef
  } = useAudioPlayer(audioUrl);
  const currentPercentage = (currentTime / duration) * 100;

  const handleClose = async () => {
    if (previewing) history.push('/settings/guided_tour');
    onClose();
  };

  return (
    <>
      {!loadingState && <audio ref={(a) => setAudioRef(a)} loop={false} />}
      <div className='tour-step' style={elementPosition}>
        <div className='close'>
          <CloseButton onClick={handleClose} className='tour-close-btn' />
        </div>
        <p className='tour-step_status description-text'>
          {activeStep + 1} of {steps}
        </p>
        <div className='tour-text'>
          {loadingState ? <Loader size='large' /> : text}
        </div>
        <Bar
          currentPercentage={currentPercentage}
          duration={duration}
          onTimeUpdate={(time) => setClickedTime(time)}
        />
        <div className='tour-control'>
          <div className='tour-step_time --current subtext'>
            {' '}
            {formatAudioTime(currentTime)}
          </div>
          <div className='tour-step_time --total subtext'>
            {' '}
            {formatAudioTime(duration)}
          </div>
          <div className='container'>
            <Tooltip content='Previous Guide' direction='bottom'>
              <div
                className='audio-control-btn previous'
                onClick={() => onPrevious()}
              >
                <AudioControl />
              </div>
            </Tooltip>

            <div className='audio-control-btn' onClick={() => togglePlaying()}>
              {playing ? <Pause /> : <Play />}
            </div>
            <Tooltip content='Next Guide' direction='bottom'>
              <div className='audio-control-btn next' onClick={() => onNext()}>
                <AudioControl />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

const Bar = ({ duration, onTimeUpdate, currentPercentage }) => {
  const calcClickedTime = (e) => {
    const clickPositionInPage = e.pageX;
    const bar: HTMLElement = document.querySelector(
      '.bar__progress'
    ) as HTMLElement;
    if (!bar) {
      throw new Error('No element found with class: .bar__progress');
    }
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  };

  const handleTimeDrag = (e) => {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = (eMove) => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener('mousemove', updateTimeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  };

  return (
    <div className='bar'>
      <div
        className='bar__progress'
        style={{
          background: `linear-gradient(to right, #4eaad5 ${currentPercentage}%, #F1F3F5 0)`
        }}
        onMouseDown={(e) => handleTimeDrag(e)}
      >
        <span
          className='bar__progress__knob'
          style={{ left: `${currentPercentage - 0.73}%` }}
        />
      </div>
    </div>
  );
};

export default TourStep;
