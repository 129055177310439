// eslint-disable-next-line
import React, { ReactNode, ReactElement, useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '../../components/OktaSignIn/OktaSignIn';

import './_login.scss';

/**  
URL: /login
NAME: Login
AKA: 
**/

interface Props {
  content?: Array<any>;
  children?: ReactNode | ReactElement;
}

const Login: React.FC<Props> = () => {
  const { oktaAuth } = useOktaAuth();
  useEffect(() => {
    // TODO?: More Okta checks prior to clear?
    oktaAuth.tokenManager.clear();
  }, [oktaAuth.tokenManager]);

  useEffect(() => {
    document.documentElement.style.position = 'relative';
    document.documentElement.style.top = '0px';
  }, []);

  return (
    <div className='login-page'>
      <div className='wrapper'>
        <div id='login-main' />
        <OktaSignIn />
      </div>
    </div>
  );
};

export default Login;
