import * as featuresActions from '../actions/types/featuresActionTypes';
import { FeaturesState } from './reducers.d';
import { Action } from '../App.d';
import { Feature } from '../types/features.d';

const initialState: FeaturesState = {
  byId: {},
  allIds: [],
  beEnv: {},
  isRequesting: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: Action) => {
  switch (action.type) {
    case featuresActions.FETCH_FEATURES_REQUEST:
    case featuresActions.UPDATE_FEATURES_REQUEST:
      return {
        ...state,
        isRequesting: true
      };
    case featuresActions.FETCH_FEATURES_SUCCESS: {
      const payload = action.payload.features || [];
      const allIds = payload.map((feature: Feature) => feature.constant);
      const byId = payload.reduce(
        (previous: { [id: string]: Feature }, feature: Feature) => ({
          ...previous,
          [feature.constant]: feature
        }),
        {}
      );

      return {
        allIds,
        byId,
        beEnv: action.payload.env,
        isRequesting: false
      };
    }
    case featuresActions.UPDATE_FEATURES_SUCCESS: {
      const { payload = [] } = action;
      const allIds = payload.map((feature: Feature) => feature.constant);
      const byId = payload.reduce(
        (previous: { [id: string]: Feature }, feature: Feature) => ({
          ...previous,
          [feature.constant]: feature
        }),
        {}
      );
      return {
        allIds,
        byId,
        isRequesting: false
      };
    }
    case featuresActions.FETCH_FEATURES_FAILURE:
    case featuresActions.UPDATE_FEATURES_FAILURE:
      return {
        ...state,
        isRequesting: false
      };
    default:
      return state;
  }
};
