import { User } from '../App.d';
import { IDmElement } from '../types/dm';
// import { IDmGroup, IDocumentManager } from '../types/dm.d';
import { Feature } from '../types/features';
import { Dashboard, WidgetMeta } from '../types/dashboards';
import { FiltersState } from '../types/filters';

// Update to move to generic types
export type GenericObject = {
  [key: string]: any;
};

export enum TourState {
  inactive = 'Inactive',
  active = 'Active',
  edit = 'Edit'
}

export enum PanelType {
  alerts = 'alerts',
  guidedTour = 'guidedtour',
  okta = 'okta',
  profile = 'profile',
  directory = 'directory',
  closed = 'closed',
  profileEdit = 'profileedit'
}

export type SidePanelState = {
  panelType: PanelType;
  title?: string;
  userId?: number;
};

export interface UiState {
  tour: {
    status: TourState;
    elementTarget: string;
    editHelpId: number;
    previewing: boolean;
  };
  modal: { open: boolean };
  enhancedDirectory: { open: boolean };
  alerts: { open: boolean };
  okta: boolean;
  featureOverride: { [id: string]: number };
  sideNavOpen: boolean;
  documentModal: {
    activeElement: number;
    visible: boolean;
  };
  sidePanel: SidePanelState;
}

export interface DmsState {
  allIds: any;
  byId: {
    [id: number]: IDocumentManager;
  };
}
export interface DmElementsState {
  allIds: any;
  byId: {
    [id: number]: IDmElement;
  };
}
export interface DmGroupsState {
  allIds: any;
  byId: {
    [id: number]: IDmGroup;
  };
}
export interface DmTagsState {
  allIds: any;
  byId: {
    [id: number]: IDmTag;
  };
}

export interface DmFiltersState {
  allIds: number[];
  byId: { [id: number]: IDmFilter };
}
export interface DmFilterItemsState {
  allIds: number[];
  byId: { [id: number]: IDmFilterItem };
}
export interface DmState {
  dms: DmsState;
  elements: DmElementsState;
  groups: DmGroupsState;
  filters: DmFiltersState;
  filterItems: DmFilterItemsState;
  tags: DmTagsState;
  dmTags: { [dmId: number]: number[] };
  exportedElements: number[];
  isRequesting: boolean;
}

export interface InterruptState {}

export interface OrgState {
  activeOrgId: number;
  allOrgs: {
    byId: GenericObject;
    allIds: number[];
    isRequesting: boolean;
  };
  orgChart: {
    topLevel: GenericObject;
    allIds: number[];
    byId: GenericObject;
    isRequesting: boolean;
  };
  error?: Error | null;
}

export interface HelpState {
  activeHelp: number;
  open: boolean;
  allHelp: {
    byId: GenericObject;
    allIds: number[];
    isRequesting: boolean;
  };
  error?: Error | null;
}

export interface NavState {
  elements: {
    byId: GenericObject;
    allIds: number[];
    gettingElements: boolean;
  };
  groups: {
    byId: GenericObject;
    allIds: number[];
    gettingGroups: boolean;
  };
  links: {
    byId: GenericObject;
    allIds: number[];
    gettingLinks: boolean;
  };
  departments: {
    byId: NavDeparmentDictionary;
    allIds: number[];
    gettingDepartments: boolean;
  };
  orgConfig: {
    byId: GenericObject;
    allIds: number[];
    gettingOrgConfig: boolean;
  };
  orgConfigByNavId: GenericObject;
  navOrgMappings: GenericObject;
  error?: Error | null;
}

export interface AlertState {
  byId: GenericObject;
  allIds: number[];
  notSeenCount: number;
  gettingAlerts: boolean;
}

export interface StaticState {
  notificationFrequency: any;
  notificationAction: any;
  privs: any;
  orgStatus: any;
  orgType: any;
  orgRegion: any;
  navType: any;
  dmActions: any;
  dmElementTargetingActions: any;
  dmTemplate: any;
  dmSortMethod: any;
  featureStatus: any;
  isRequesting: boolean;
  error: Error | null;
  dataTimeRequested?: number;
  modules: any;
  dmElementFrequency: any;
  orgRegion: {
    allIds: number[];
    byId: GenericObject;
  };
  states: {
    allIds: number[];
    byId: GenericObject;
  };
  settingsType: {
    allIds: number[];
    byId: GenericObject;
  };
}

export interface UserState {
  activeUserId: number;
  users: {
    byId: { [userId: any]: User };
    allIds: number[];
    isRequesting: boolean;
  };
  gettingUser: boolean;
  postingNewUser: boolean;
  puttingAllUsers: boolean;
  putSuccessMessage: string;
  putErrorMessage: string;
  error: null | boolean;
}

export interface ParserState {
  parsers: {
    allIds: Array<number>;
    byId: { [id: number]: { [property: string]: any } };
  };
  instances: {
    allIds: Array<number>;
    byId: { [id: number]: { [property: string]: any } };
  };
  mappings: {
    allIds: Array<number>;
    byId: { [id: number]: { [property: string]: any } };
  };
  isRequesting: boolean;
}

export interface NotificationState {
  type: string;
  message: string;
  description: string;
  duration: number;
  increment: number;
}

export interface DashboardState {
  byId: { [id: number]: Dashboard };
  allIds: number[];
  isRequesting: boolean;
}
export interface FeaturesState {
  byId: { [id: string]: Feature };
  allIds: Feature[];
  beEnv: GenericObject;
  isRequesting: boolean;
}

export interface DirectoryState {
  open: boolean;
}

export interface WidgetsState {
  allIds: Array<number>;
  byId: { [id: number]: { [property: string]: any } };
  widgetMeta: { [id: string | number]: WidgetMeta };
  reportFormat: {
    [id: number]: Array<number | number[]>;
  };
  isRequesting: boolean;
}

export interface NotificationManagementState {
  allIds: Array<number>;
  byId: { [id: number]: { [property: string]: any } };
  isRequesting: boolean;
}
export interface ModulesState {
  items: {
    allIds: Array<number>;
    byId: { [id: number]: { [property: string]: any } };
  };
  isRequesting: boolean;
}

export interface DwStatusState {
  vendors: GenericObject;
  alerts: GenericObject;
  sources: GenericObject;
}

export interface ContentState {
  byId: GenericObject;
  allIds: number[] | null;
  isRequesting: boolean;
  initRequesting: GenericObject;
}

export interface GlobalSearchState {
  query: string;
}

export interface CommentState {
  byId: GenericObject;
  allIds: number[];
  isRequesting: booolean;
}

export type PageState = {
  byId: GenericObject;
  allIds: number[];
};

export interface GlobalState {
  modules: ModulesState;
  user: UserState;
  org: OrgState;
  nav: NavState;
  dm: DmState;
  ui: UiState;
  static: StaticState;
  interrupt: InterruptState;
  help: HelpState;
  loading: boolean;
  parser: ParserState;
  notification: NotificationState;
  userNotifications: UserNotificationState;
  features: FeatureState;
  widgets: WidgetsState;
  dwStatus: DwStatusState;
  dashboard: DashboardState;
  alert: AlertState;
  content: ContentState;
  globalSearch: GlobalSearchState;
  comments: CommentState;
  filters: FiltersState;
}
