import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, Loader } from '@ovis-technologies/ovis-blueprint';
import { ExportExcel }from '@ovis-technologies/ovis-blueprint';
import { getElementIdFromUrl } from '../../utils';
import {
  exportFilePickupRequest,
  exportFileCheckRequest
} from '../../services/exportService';

/**  
URL: /file_pickup
NAME: File Pickup
AKA:
**/

interface FileInformation {
  fileName: string;
  fileDescription: string;
  fileLocation: string;
  status: number;
  createdOn?: string;
  expireOn?: string;
  errorMessage?: string;
}

const fileInformationState = {
  fileName: '',
  fileDescription: '',
  fileLocation: '',
  status: 0,
  createdOn: '',
  expireOn: '',
  errorMessage: ''
};

export const FilePickup = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedFileInfo, setSelectedFileInfo] =
    useState<FileInformation>(fileInformationState);

  const history = useHistory();
  const pickupId = getElementIdFromUrl(
    history.location.pathname,
    'file_pickup'
  );

  const getFileInfo = async (pickupId: number) => {
    try {
      const fileInfo = await exportFileCheckRequest({ pickupId });
      setSelectedFileInfo(fileInfo);
      setLoading(false);
    } catch (e) {
      console.error('error', e);
    }
  };

  useEffect(() => {
    getFileInfo(pickupId);
  }, [pickupId]);

  return (
    <div className='file-pickup'>
      <h1 className='page-title'>File Pickup</h1>
      <Panel theme='dark' dividers={false}>
        {!loading ? (
          selectedFileInfo.status ? (
            <>
              <div className='file-pickup_file-details'>
                <p>{selectedFileInfo.fileName}</p>
                <p>{selectedFileInfo.fileDescription}</p>
              </div>
              <ExportExcel
                exportServiceFunction={exportFilePickupRequest}
                requestParameters={{ pickupId }}
                buttonSize='large'
                buttonText='Download File'
              />
            </>
          ) : (
            <>
              <div className='file-pickup_file-details error-msg'>
                <p>{selectedFileInfo.errorMessage}</p>
              </div>
            </>
          )
        ) : (
          <Loader />
        )}
      </Panel>
    </div>
  );
};

export default FilePickup;
