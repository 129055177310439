export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GETALL_USERS_REQUEST = 'GETALL_USERS_REQUEST';
export const GETALL_USERS_SUCCESS = 'GETALL_USERS_SUCCESS';
export const GETALL_USERS_FAILURE = 'GETALL_USERS_FAILURE';

export const PUT_USER_REQUEST = 'POST_USER_REQUEST';
export const PUT_USER_SUCCESS = 'POST_USER_SUCCESS';
export const PUT_USER_FAILURE = 'POST_USER_FAILURE';

export const LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const LOGOUT = 'USER_LOGOUT';

export const DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const DELETE_FAILURE = 'USER_DELETE_FAILURE';

export const DEATIVATE_REQUEST = 'USER_DEATIVATE_REQUEST';
export const DEATIVATE_SUCCESS = 'USER_DEATIVATE_SUCCESS';
export const DEATIVATE_FAILURE = 'USER_DEATIVATE_FAILURE';

export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';

export const GET_ACCESS_BY_PRIV_REQUEST = 'GET_ACCESS_BY_PRIV_REQUEST';
export const GET_ACCESS_BY_PRIV_SUCCESS = 'GET_ACCESS_BY_PRIV_SUCCESS';
export const GET_ACCESS_BY_PRIV_FAILURE = 'GET_ACCESS_BY_PRIV_FAILURE';

export const GET_ALL_TERMS_REQUEST = 'GET_ALL_TERMS_REQUEST';
export const GET_ALL_TERMS_SUCCESS = 'GET_ALL_TERMS_SUCCESS';
export const GET_ALL_TERMS_FAILURE = 'GET_ALL_TERMS_FAILURE';

export const ADD_TERMS_REQUEST = 'PUT_TERMS_REQUEST';
export const ADD_TERMS_SUCCESS = 'PUT_TERMS_SUCCESS';
export const ADD_TERMS_FAILURE = 'PUT_TERMS_FAILURE';
