import React, { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { useLocation } from 'react-router-dom';

import getPrivateAssetUrl from '../../services/getPrivateAssetUrl';
import { getUrlFileName } from '../../utils';
import { notification } from '@ovis-technologies/ovis-blueprint';
import errorMessage from '../../../constants/standardErrorMessage';

// TODO? Convert to hook and use within AuthRequiredLink?

const GetDocument = () => {
  const [getDocument, setGetDocument] = useState(false);

  const { search = '', pathname = '' } = useLocation();
  // Remove '?url='
  const url = search ? decodeURIComponent(search.slice(5)) : '';

  useEffect(() => {
    if (getDocument) return;
    if (url && pathname === '/document') {
      setGetDocument(true);
      notification.info({
        message:
          'Retrieving your file. This may take some time depending on the size of your file.',
        duration: 0
      });
      const processAssetLink = async (url: string) => {
        try {
          const signedUrl = await getPrivateAssetUrl(url);
          const config: AxiosRequestConfig = {
            responseType: 'blob'
          };
          const result = await axios.get(signedUrl, config);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(result.data);
          link.download = getUrlFileName(url);
          link.click();
          notification.destroy();
          notification.success({
            message: 'File retrieved successfully!',
            duration: 10
          });
        } catch (err: any) {
          notification.error({
            message: 'Failed to retrieve asset. ' + errorMessage,
            duration: 0
          });
        }
      };
      processAssetLink(url);
    }
  }, [url, pathname, getDocument]);

  return <></>;
};

export default GetDocument;
