export const getNavId = (urlLookup, pathname) => {
  if (urlLookup[pathname]) {
    return urlLookup[pathname].navId;
  } else {
    const urlArr = pathname.split('/');
    const urlLength = urlArr.length;
    for (let i = urlLength; i >= 0; i--) {
      urlArr.pop();
      const joinedUrl = urlArr.join('/');
      if (urlLookup[joinedUrl]) {
        return urlLookup[joinedUrl].navId;
      }
    }
  }
};
