import React from 'react';

const AudioControl = () => (
  <svg
    className='audio-control-icon'
    version='1.1'
    id='Capa_1'
    x='0px'
    y='0px'
    viewBox='0 0 416.004 416.004'
  >
    <g>
      <g>
        <path
          d='M281.602,195.204l-256-192C20.802-0.444,14.274-1.02,8.866,1.7c-5.44,2.72-8.864,8.256-8.864,14.304v384
			c0,6.048,3.424,11.584,8.832,14.304c2.272,1.12,4.736,1.696,7.168,1.696c3.392,0,6.784-1.088,9.6-3.2l256-192
			c4.032-3.008,6.4-7.776,6.4-12.8S285.634,198.212,281.602,195.204z'
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d='M400.002,0.004h-32c-8.832,0-16,7.168-16,16v384c0,8.832,7.168,16,16,16h32c8.832,0,16-7.168,16-16v-384
			C416.002,7.172,408.834,0.004,400.002,0.004z'
        />
      </g>
    </g>
  </svg>
);

export default AudioControl;
