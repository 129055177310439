import axios from 'axios';
import { FEATURES_ENDPOINT } from './endpoints';
import { ResponseData } from '../App.d';
import { UpdateFeaturesBody } from '../types/features.d';
import { handleServiceError } from '.';

export const getFeatures = async (): Promise<ResponseData> => {
  return axios
    .get(FEATURES_ENDPOINT)
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const putFeatures = async (
  featureConfig: UpdateFeaturesBody
): Promise<ResponseData> => {
  return axios
    .put(FEATURES_ENDPOINT, featureConfig)
    .then((response) => response.data)
    .catch(handleServiceError);
};
