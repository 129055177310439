import React from 'react';

const DotMenu = () => {
  return (
    <svg className='dot-menu-svg' viewBox='0 0 24 24' width='512' height='512'>
      <circle cx='12' cy='2' r='2' />
      <circle cx='12' cy='12' r='2' />
      <circle cx='12' cy='22' r='2' />
    </svg>
  );
};

export default DotMenu;
