import React from 'react';
import Page from '../../components/Page';

/**  
URL: /resource-library
NAME: Resource Library
AKA:
**/

const ResourceLibrary = () => {
  return (
    <Page
      id='resource-library'
      dmId={300}
      isLibrary
      centerContent
      pageWidth='large'
      mostViewedOn
    />
  );
};

export default ResourceLibrary;
