export const GETALL_HELP_REQUEST = 'GETALL_HELP_REQUEST';
export const GETALL_HELP_SUCCESS = 'GETALL_HELP_SUCCESS';
export const GETALL_HELP_FAILURE = 'GETALL_HELP_FAILURE';

export const HELP_ADD_REQUEST = 'HELP_ADD_REQUEST';
export const HELP_UPDATE_SUCCESS = 'HELP_UPDATE_SUCCESS';
export const HELP_EDIT_SUCCESS = 'HELP_ADD_SUCCESS';
export const HELP_ADD_FAILURE = 'HELP_ADD_FAILURE';

export const OPEN_HELP_MODAL = 'OPEN_HELP_MODAL';
export const CLOSE_HELP_MODAL = 'CLOSE_HELP_MODAL';

export const DELETE_HELP_ITEM = 'DELETE_HELP_ITEM';
