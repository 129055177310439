import { Action } from '../App.d';

const initialState: boolean = false;

const loading = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'LOADING_TRUE':
      return true;
    case 'LOADING_FALSE':
      return false;
    default:
      return state;
  }
};

export default loading;
