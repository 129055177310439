import axios from 'axios';
import { GLOBAL_SEARCH_ENDPOINT } from './endpoints';
import { FetchGlobalSearch, ResponseData } from '../App.d';
import { handleServiceError } from '.';

export const getGlobalSearchRequest: FetchGlobalSearch = (
  searchString: string,
  sortType: string
): Promise<ResponseData> => {
  return axios
    .post(GLOBAL_SEARCH_ENDPOINT, {
      action: 'SEARCH',
      data: {
        searchString,
        sortType,
        moduleFilters: {
          userSearch: 1,
          dmSearch: 1,
          helpSearch: 1,
          alertsSearch: 1,
          orgSearch: 1,
          contentSearch: 1
        }
      }
    })
    .then((res) => {
      return res.data;
    })
    .catch(handleServiceError);
};
