import React from 'react';
import classNames from 'classnames';

import config, { OKTA_DOMAIN } from '../../config';
// import useOktaIframe from '../../hooks/useOktaIframe';
import { useSelector } from 'react-redux';
import { selectSideNavOpen } from '../../selectors/uiSelectors';

interface Props {
  iframeClass?: string;
  mobile?: boolean;
}

const initialState = {
  iframeControlHideHome: true,
  iframeControlHideSettings: true,
  iframeControlHideSignOut: true,
  iframeControlHideCatalog: true,
  iframeControlHideAll: true,
  iframeControlHideSearch: true
};

const OktaApps = ({ iframeClass: iframeClassProp, mobile }: Props) => {
  // const [oktaConfig] = useOktaIframe();
  const sideNavOpen = useSelector(selectSideNavOpen);
  const iframeClass = classNames('okta-dashboard-iframe', iframeClassProp, {
    '--side-nav-open': sideNavOpen
  });
  const rules = mobile
    ? '&iframeControlHideAll=true'
    : '&iframeControlHideSignOut=true';

  return (
    <iframe
      className={iframeClass}
      title={`${config.app.clientName} Apps`}
      src={`https://${OKTA_DOMAIN}/app/UserHome?iframe=true&${initialState}&fromAdmin=true${rules}`}
    />
  );
};

export default OktaApps;
