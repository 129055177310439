import { GlobalState } from '../reducers/reducers';

import {
  searchCollectionById,
  searchCollection
} from '../utils/searchCollection';

export const dmElementsSelector = (
  state: GlobalState,
  dmId: number,
  returnMostViewd
) => {
  const { elements, dms, isRequesting, tags } = state.dm;
  const { byId: tagsById } = tags;
  const { byId: dmById } = dms;
  const { templateId } = dmById[dmId] || {};
  let collection = elements.allIds
    .filter((id) => elements.byId[id].dmId === dmId)
    .map((id) => {
      return {
        ...elements.byId[id],
        tagString: elements.byId[id].tags
          ?.map((id) => {
            const { name = '' } = tagsById[id] || {};
            return name;
          })
          .join(' ')
      };
    });

  const { filters, groups: filterGroups, text } = state.filters;
  const filterIds = Object.keys(filters);
  if (text !== '') {
    collection = searchCollection(text, collection, [
      'description',
      'title',
      'tagString'
    ]);
  }
  if (filterIds.length !== 0) {
    filterIds.forEach((id) => {
      const filterItem = filters[id];
      collection = searchCollectionById(
        filterItem,
        collection,
        'filterItems',
        true
      );
    });
  }

  if (filterGroups.length) {
    collection = searchCollectionById(
      filterGroups,
      collection,
      'groupId',
      true
    );
  }

  const filtersAvailable =
    filterGroups.length || filterIds.length !== 0 || text !== '';

  let mostViewed = [];

  if (returnMostViewd)
    mostViewed = collection
      .sort((a, b) => b.viewCount - a.viewCount)
      .slice(0, 1);

  return {
    elements: collection,
    mostViewed,
    isRequesting,
    templateId,
    filtersAvailable
  };
};
