import axios from 'axios';
import { QUICKSIGHT_EMBED } from './endpoints';
import { FetchQuicksightSignedUrl } from '../App.d';
import { handleServiceError } from '.';

export const getQuicksightEmbedRequest: FetchQuicksightSignedUrl = async (
  data: string
): Promise<any> => {
  try {
    const { data: response } = await axios.post(QUICKSIGHT_EMBED, {
      action: 'GET_URL',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};
