import { combineReducers } from 'redux';
import orgReducer from './orgReducer';
import userReducer from './userReducer';
import navReducer from './navReducer';
import staticReducer from './staticReducer';
import dmReducer from './dmReducer';
import loadingReducer from './loadingReducer';
import helpReducer from './helpReducer';
import interruptReducer from './interruptReducer';
import uiReducer from './uiReducer';
import notificationReducer from './notificationReducer';
import parserReducer from './parserReducer';
import featuresReducer from './featuresReducer';
import modulesReducer from './moduleReducer';
import userNotificationReducer from './userNotificationReducer';
import widgetsReducer from './widgetsReducer';
import dashboardReducer from './dashboardReducer';
import alertReducer from './alertReducer';
import globalSearchReducer from './globalSearchReducer';
import dwStatusReducer from './dwReducer';
import filtersReducer from './filtersReducer';

import commentsReducer from './commentReducer';

const rootReducer = combineReducers({
  org: orgReducer,
  user: userReducer,
  nav: navReducer,
  dm: dmReducer,
  dwStatus: dwStatusReducer,
  static: staticReducer,
  modules: modulesReducer,
  loading: loadingReducer,
  interrupt: interruptReducer,
  help: helpReducer,
  notification: notificationReducer,
  userNotifications: userNotificationReducer,
  parser: parserReducer,
  features: featuresReducer,
  ui: uiReducer,
  widgets: widgetsReducer,
  dashboard: dashboardReducer,
  alert: alertReducer,
  globalSearch: globalSearchReducer,
  comments: commentsReducer,
  filters: filtersReducer
});

export default rootReducer;
