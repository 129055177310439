import React from 'react';
import {
  Button,
  Copy,
  notification,
  Tooltip
} from '@ovis-technologies/ovis-blueprint';

const CopyBearerToken = () => {
  const handleCopyToken = (e) => {
    const myRequest = localStorage.getItem('okta-token-storage');
    const parsedData = JSON.parse(myRequest!).idToken.idToken;
    navigator.clipboard.writeText(parsedData);
    e.target.focus();
    notification.success({ message: 'Token successfully copied', duration: 3 });
  };
  return (
    <>
      <Tooltip content='This button will copy your Okta User Bearer Token.'>
        <Button
          type='button'
          styleType='primary'
          onClick={handleCopyToken}
          size='fill'
        >
          Copy Token <Copy />
        </Button>
      </Tooltip>
    </>
  );
};

export default CopyBearerToken;
