export enum parserActionTypes {
  getRequest = 'GET_PARSERS_REQUEST',
  getSuccess = 'GET_PARSERS_SUCCESS',
  getFailure = 'GET_PARSERS_FAILURE',
  getInstanceRequest = 'GET_PARSER_INSTANCE_REQUEST',
  getInstanceSuccess = 'GET_PARSER_INSTANCE_SUCCESS',
  getInstanceFailure = 'GET_PARSER_INSTANCE_FAILURE',
  putInstanceRequest = 'PUT_PARSER_INSTANCE_REQUEST',
  putInstanceSuccess = 'PUT_PARSER_INSTANCE_SUCCESS',
  putInstanceFailure = 'PUT_PARSER_INSTANCE_FAILURE',
  deleteInstanceRequest = 'DELETE_PARSER_INSTANCE_REQUEST',
  deleteInstanceSuccess = 'DELETE_PARSER_INSTANCE_SUCCESS',
  deleteInstanceFailure = 'DELETE_PARSER_INSTANCE_FAILURE',
  postLoadRequest = 'POST_PARSER_LOAD_REQUEST',
  postLoadSuccess = 'POST_PARSER_LOAD_SUCCESS',
  postLoadFailure = 'POST_PARSER_LOAD_FAILURE',
  getMappingsRequest = 'GET_PARSER_MAPPINGS_REQUEST',
  getMappingsSuccess = 'GET_PARSER_MAPPINGS_SUCCESS',
  getMappingsFailure = 'GET_PARSER_MAPPINGS_FAILURE',
  postMappingsRequest = 'POST_PARSER_MAPPINGS_REQUEST',
  postMappingsSuccess = 'POST_PARSER_MAPPINGS_SUCCESS',
  postMappingsFailure = 'POST_PARSER_MAPPINGS_FAILURE'
}
