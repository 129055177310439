// TODO?: Are separate action creators necessary for the successes?

import { Dispatch } from 'redux';
import {
  fetchUserInfo,
  putUserInfo,
  fetchUsers,
  addUser,
  fetchAccessByPrivReport,
  getAllTermsOfUse,
  addTerm,
  fetchLatestTerm
} from '../services/userService';
import {
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  SET_ACTIVE_USER,
  PUT_USER_REQUEST,
  PUT_USER_SUCCESS,
  PUT_USER_FAILURE,
  GETALL_USERS_REQUEST,
  GETALL_USERS_SUCCESS,
  GETALL_USERS_FAILURE,
  GET_ACCESS_BY_PRIV_REQUEST,
  GET_ACCESS_BY_PRIV_SUCCESS,
  GET_ACCESS_BY_PRIV_FAILURE,
  GET_ALL_TERMS_REQUEST,
  GET_ALL_TERMS_SUCCESS,
  GET_ALL_TERMS_FAILURE,
  ADD_TERMS_REQUEST,
  ADD_TERMS_SUCCESS,
  ADD_TERMS_FAILURE
} from './types/userActionTypes';
import { ResponseData, User } from '../App.d';

export const setActiveUserId = (userId: User) => ({
  type: SET_ACTIVE_USER,
  payload: userId
});

export const setUserList = (list: Array<User>) => ({
  type: GETALL_USERS_SUCCESS,
  payload: list
});

export const setUpdatedUser = (userData: User) => ({
  type: PUT_USER_SUCCESS,
  payload: userData
});
export const setRetrievedUser = (userData: User) => ({
  type: GET_USER_SUCCESS,
  payload: userData
});

export const setAccessByPrivsList = (privAccessList: any) => ({
  type: GET_ACCESS_BY_PRIV_SUCCESS,
  payload: privAccessList
});

export const setTermsList = (touList: any) => ({
  type: GET_ALL_TERMS_SUCCESS,
  payload: touList
});

export const getUser =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: GET_USER_REQUEST, payload: userId });

    try {
      const responseData: ResponseData = await fetchUserInfo(userId);
      const userInfo: User = responseData.response;
      dispatch(setRetrievedUser(userInfo));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: GET_USER_FAILURE, error });
      throw error;
    }
  };

export const updateUserData =
  (data: User) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: PUT_USER_REQUEST });
    try {
      const responseData: ResponseData = await putUserInfo(data);
      const updatedUserData: User = responseData.response;
      dispatch(setUpdatedUser(updatedUserData));
      return responseData;
    } catch (error: any) {
      dispatch({ type: PUT_USER_FAILURE, error });
      // If edit success with exception
      if (error.response?.userId) {
        dispatch(setUpdatedUser(error.response));
      }
      throw error;
    }
  };

export const requestAddUser =
  (data: User) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: PUT_USER_REQUEST });
    try {
      const responseData: ResponseData = await addUser(data);
      const user: User = responseData.response;
      dispatch(setUpdatedUser(user));
      return responseData;
    } catch (error: any) {
      dispatch({ type: PUT_USER_FAILURE, error });
      throw error;
    }
  };

export const getUsers =
  () =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: GETALL_USERS_REQUEST });
    try {
      const responseData: ResponseData = await fetchUsers();
      const userList: User[] = responseData.response;
      dispatch(setUserList(userList));
      return responseData;
    } catch (error: any) {
      dispatch({ type: GETALL_USERS_FAILURE, error });
      return error;
    }
  };

export const getAccessByPriv =
  (privId: number) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: GET_ACCESS_BY_PRIV_REQUEST });
    try {
      const responseData: ResponseData = await fetchAccessByPrivReport(privId);
      const privsList = responseData.response;
      dispatch(setAccessByPrivsList(privsList));
      return responseData;
    } catch (error: any) {
      dispatch({ type: GET_ACCESS_BY_PRIV_FAILURE, error });
      return error;
    }
  };

export const getAllTerms =
  () =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: GET_ALL_TERMS_REQUEST });
    try {
      const responseData: ResponseData = await getAllTermsOfUse();
      const result = responseData.response;
      dispatch(setTermsList(result));
      return result;
    } catch (error: any) {
      dispatch({ type: GET_ALL_TERMS_FAILURE, error });
      return error;
    }
  };

export const getLatestTerm =
  () =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: GET_ALL_TERMS_REQUEST });
    try {
      const responseData: ResponseData = await fetchLatestTerm();
      const [result] = responseData.response;
      return result;
    } catch (error: any) {
      dispatch({ type: GET_ALL_TERMS_FAILURE, error });
      return error;
    }
  };

export const requestAddTerm =
  (data: any) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: ADD_TERMS_REQUEST });
    try {
      const responseData: ResponseData = await addTerm(data);
      dispatch({ type: ADD_TERMS_SUCCESS });
      return responseData;
    } catch (error: any) {
      dispatch({ type: ADD_TERMS_FAILURE, error });
      return error;
    }
  };
