import history from './history.js';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { useDispatch } from 'react-redux';

/** use this to populate an initial application state that may be pulled from the DB or browser **/
// const initialState = {};

const reduxRouterMiddleware = routerMiddleware(history);
const middleware = [thunk, reduxRouterMiddleware];
const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
