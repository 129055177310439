import axios from 'axios';
import { WSS_DATA } from './endpoints';
import { WssData } from '../App.d';
import { handleServiceError } from '.';

export const getWssDataRequest: WssData = async (
  company_id: number
): Promise<any> => {
  try {
    const { data: response } = await axios.post(WSS_DATA, {
      action: 'GET_COMPANY_SCORE_DETAIL',
      data : {
        lens_id: 1,
        companyId: company_id,
        mode: 'detailed'
      }
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const getWssCompanySearch = async (
    name: string 
): Promise<any> => {
    try {
        const { data: response } = await axios.post(WSS_DATA, { 
            action: 'SEARCH_COMPANY', 
            data: {
                name,
                lens_id: 1
            }
        });
        return response;
    } catch (err: any) {
        handleServiceError(err);
    }
};

export const getWssCompanyHistory = async (
    company_id: number
): Promise<any> => {
    try {
        const { data: response } = await axios.post(WSS_DATA, { 
            action: 'GET_COMPANY_HISTORY', 
            data: {
                companyId: company_id,
                lens_id: 1
            }
        });
        return response;
    } catch (err: any) {
        handleServiceError(err);
    }
};