import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../reducers/reducers';
import { formatTime, checkDate } from '../../utils/formatDate';
import { formatDate, CalendarEvent } from '@ovis-technologies/ovis-blueprint';
import LinkRender from '../LinkRender/LinkRender';

type Props = {
  elementId: number;
  isCalendarEvent?: boolean;
  children?: ReactNode;
  fullDescription?: boolean;
};

const Details = ({ elementId, isCalendarEvent, children }: Props) => {
  // Selectors
  const { byId } = useSelector((state: GlobalState) => state.dm.elements);

  // Vars
  const {
    postDate,
    title,
    description,
    fileLocation,
    link,
    calStartDate,
    calEndDate,
    calFromEmail,
    author,
    contentUrl,
    moduleId
  }: any = byId[elementId] || {};

  const dateFormat = isCalendarEvent
    ? `${formatDate(calStartDate, { format: 'MM/DD/YYYY' })} | ${formatTime(
        calStartDate
      )}`
    : `${formatDate(postDate, {
        format: 'MM/DD/YYYY'
      })}`;

  const url = contentUrl || fileLocation || link;

  return (
    <div className='document-details'>
      <>
        {isCalendarEvent && !checkDate(calEndDate) && (
          <CalendarEvent
            fileName={title}
            event={{
              organizer: { name: '', email: calFromEmail },
              title: title,
              startTime: calStartDate,
              endTime: calEndDate,
              ...byId[elementId]
            }}
          />
        )}
        {children || (
          <>
            <div className='document_title document-title'>
              {' '}
              <LinkRender
                buttonText={title}
                contentUrl={url}
                hasCustomFunctionality={moduleId === 3}
                elementId={elementId}
              />
            </div>
            <p className='document_date'>{dateFormat}</p>
            {isCalendarEvent && <p className='document_author'>{author}</p>}
            {Boolean(description) && (
              <p className='document_desc'>{description}</p>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default Details;
