export const COMMENT_REQUEST = 'COMMENT_REQUEST';
export const COMMENT_FAIL = 'COMMENT_FAIL';

export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS ';
export const ADD_COMMENTS_SUCCESS = 'ADD_COMMENTS_SUCCESS ';
export const UPDATE_COMMENTS_SUCCESS = 'UPDATE_COMMENTS_SUCCESS ';

export const UPDATE_COMMENT_LIKE_SUCCESS = 'UPDATE_COMMENT_LIKE_SUCCESS';
export const UPDATE_COMMENT_LIKE_REQUEST = 'UPDATE_COMMENT_LIKE_REQUEST';
export const UPDATE_COMMENT_LIKE_FAILURE = 'UPDATE_COMMENT_LIKE_FAILURE';
