import { GlobalSearchActions as searchTypes } from '../actions/types/globalSearchActionTypes';
import { Action } from '../App.d';
import { GlobalSearchState } from './reducers.d';

const initialState: GlobalSearchState = {
  query: ''
};

const searchReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case searchTypes.updateQuery:
      return {
        ...state,
        query: action.payload || ''
      };
    default:
      return state;
  }
};

export default searchReducer;
