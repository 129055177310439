export enum ORG_ACTION_TYPE {
  Request = 'ORG_REQUEST',
  Failure = 'ORG_FAILURE',
  RegisterSuccess = 'ORG_REGISTER_SUCCESS',
  GetAllSuccess = 'ORG_GETALL_SUCCESS',
  SetActiveOrg = 'SET_ACTIVE_ORG'
}

export const ORG_REGISTER_REQUEST = 'ORG_REQUEST';
export const ORG_REGISTER_SUCCESS = 'ORG_REGISTER_SUCCESS';
export const ORG_REGISTER_FAILURE = 'ORG_FAILURE';

export const ORG_INFO_UPDATE_REQUEST = 'ORG_INFO_UPDATE';
export const ORG_INFO_UPDATE_SUCCESS = 'ORG_INFO_UPDATE';
export const ORG_INFO_UPDATE_FAILURE = 'ORG_INFO_UPDATE';

export const ORG_GETALL_REQUEST = 'ORG_REQUEST';
export const ORG_GETALL_SUCCESS = 'ORG_GETALL_SUCCESS';
export const ORG_GETALL_FAILURE = 'ORG_FAILURE';

export const DELETE_REQUEST = 'ORG_DELETE_REQUEST';
export const DELETE_SUCCESS = 'ORG_DELETE_SUCCESS';
export const DELETE_FAILURE = 'ORG_DELETE_FAILURE';

export const DEACTIVATE_REQUEST = 'ORG_DEACTIVATE_REQUEST';
export const DEACTIVATE_SUCCESS = 'ORG_DEACTIVATE_SUCCESS';
export const DEACTIVATE_FAILURE = 'ORG_DEACTIVATE_FAILURE';

export const SET_ACTIVE_ORG = 'SET_ACTIVE_ORG';
export const SET_ORG_STATE = 'SET_ACTIVE_ORG';

export const GET_ORG_CHART = 'GET_ORG_CHART';
export const GET_ORG_CHART_REQUEST = 'GET_ORG_CHART_REQUEST';
export const ADD_ORG_CHART_USER = 'ADD_ORG_CHART_USER';
export const ADD_ORG_CHART_DEPT_GROUP = 'ADD_ORG_CHART_DEPT_GROUP';
export const ADD_ORG_CHART_DEPT = 'ADD_ORG_CHART_DEPT';
export const EDIT_ORG_CHART_DEPT_GROUP = 'EDIT_ORG_CHART_DEPT_GROUP';
export const EDIT_ORG_CHART_DEPT = 'EDIT_ORG_CHART_DEPT';
export const REMOVE_ORG_CHART = 'REMOVE_ORG_CHART';
export const REMOVE_ORG_CHART_USER = 'REMOVE_ORG_CHART_USER';
