import { useState, useEffect } from 'react';
import { IS_SAFARI } from '../../constants';

function useAudioPlayer(url) {
  const [duration, setDuration] = useState<number>();
  const [currentTime, setCurrentTime] = useState<number>();
  const [playing, setPlaying] = useState<boolean>(false);
  const [clickedTime, setClickedTime] = useState<number>();
  const [volume, setVolume] = useState<number>(0.5);
  const [audioRef, setAudioRef] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (audioRef) {
      audioRef.src = url;
      audioRef.load();
    }
  }, [audioRef, url]);

  const togglePlaying = () => {
    if (audioRef) {
      if (audioRef.src !== url) {
        audioRef.src = url;
        audioRef.load();
      }

      if (!playing) {
        audioRef.play();
      } else {
        audioRef.pause();
      }
    }

    setPlaying(!playing);
  };

  useEffect(() => {
    // state setters wrappers
    if (audioRef) {
      const setAudioData = () => {
        setDuration(audioRef.duration);
        setCurrentTime(audioRef.currentTime);
        setVolume(audioRef.volume);
        if (!IS_SAFARI) {
          togglePlaying();
        }
      };

      const setEnded = () => {
        setPlaying(false);
        audioRef.pause();
        audioRef.currentTime = 0;
        setClickedTime(0);
      };

      const setAudioTime = () => setCurrentTime(audioRef.currentTime);
      const setAudioVolume = () => setVolume(audioRef.volume);

      // DOM listeners: update React state on DOM events
      audioRef.addEventListener('loadeddata', setAudioData);

      audioRef.addEventListener('timeupdate', setAudioTime);

      audioRef.addEventListener('volumechange', setAudioVolume);

      audioRef.addEventListener('ended', setEnded);

      if (clickedTime && clickedTime !== currentTime) {
        audioRef.currentTime = clickedTime;
        setClickedTime(0);
      }

      // Cleanup
      return () => {
        audioRef.removeEventListener('ended', setEnded);
        audioRef.removeEventListener('loadeddata', setAudioData);
        audioRef.removeEventListener('timeupdate', setAudioTime);
        audioRef.removeEventListener('volumechange', setAudioVolume);
      };
    }
  }, [audioRef, clickedTime, currentTime]); // eslint-disable-line

  return {
    currentTime,
    duration,
    playing,
    volume,
    togglePlaying,
    setClickedTime,
    setAudioRef
  };
}

export default useAudioPlayer;
