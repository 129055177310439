import React, { PropsWithChildren } from 'react';
import { Button } from '@ovis-technologies/ovis-blueprint';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { GlobalState, TourState } from '../../reducers/reducers.d';
import GuidedTourSelectableElement from '../GuidedTour/GuidedTourSelectableElement';

interface Props {
  navId: number;
  defaultSvg?: () => JSX.Element;
  title?: string;
  hoverTitle?: string;
  onClick?: (e?: any) => any;
  hoverSvg?: () => JSX.Element;
  className?: string;
  noButton?: boolean;
}

const HeaderIcon = ({
  navId,
  onClick,
  children,
  className,
  noButton = false
}: PropsWithChildren<Props>) => {
  const tourStatus = useSelector((state: GlobalState) => state.ui.tour.status);
  const { byId } = useSelector((state: GlobalState) => state.nav.elements);

  const handleClick = (event) => {
    onClick && tourStatus !== TourState.edit && onClick(event);
  };
  const containerClass = classNames('header_icon', className, {
    '--no-button': noButton,
    '--button': !noButton
  });

  const { status = false } = byId[navId] || {};

  if (!status) {
    return <></>;
  }

  return (
    <GuidedTourSelectableElement id={navId}>
      {noButton ? (
        <div className={containerClass} onClick={handleClick}>
          {children}
        </div>
      ) : (
        <Button
          styleType='text'
          className={containerClass}
          onClick={handleClick}
        >
          {children}
        </Button>
      )}
    </GuidedTourSelectableElement>
  );
};

export default HeaderIcon;
