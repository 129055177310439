import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import Forbidden from './Forbidden';
import { GlobalState } from '../reducers/reducers';

type Props = {
  privId?: number;
  component?: FunctionComponent;
  render?: (e?: any) => JSX.Element | any;
  path: string;
  children?: any;
};

const PrivateRoute = ({
  component: Component,
  render,
  privId,
  children,
  ...rest
}: Props) => {
  const userPrivs = useSelector((state: GlobalState) => {
    const activeUser = state.user.users.byId[state.user.activeUserId] || {};
    return activeUser.privs;
  });
  if (userPrivs) {
    const hasPriv = userPrivs.includes(privId);
    return hasPriv ? (
      <Route {...rest} component={Component} render={render}>
        {children}
      </Route>
    ) : (
      <Route component={Forbidden} />
    );
  } else {
    return null;
  }
};

export default PrivateRoute;
