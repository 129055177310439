import config from '../app/config';

export const SUPERUSER_ID = 1;

export const IS_SAFARI =
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  // My best guess for soft comparitors is that certain browsers may return different types.
  navigator.userAgent.indexOf('CriOS') == -1 && // eslint-disable-line
  navigator.userAgent.indexOf('FxiOS') == -1; // eslint-disable-line

export const userTypes = {
  active: 1,
  inactive: 2
};

export const interruptTypes = {
  terms: 'terms',
  guidedTour: 'guided_tour'
};

export const NAV_TOGGLE = 'nav-toggle';
export const STATIC_IMAGE_PATH = `${config.assets.domain}/pub/appassets/`;

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
