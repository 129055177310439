import React from 'react';

type Props = {
  onClick: (arg?: any) => void;
  className?: string;
};
const CloseButton = ({ onClick, className = 'close-btn' }: Props) => {
  const handleClick = (event) => {
    onClick && onClick(event);
  };

  return (
    <button type='button' className={className} onClick={handleClick}>
      <svg version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 94.1 94.1'>
        <g>
          <rect
            x='45'
            y='-17.5'
            transform='matrix(0.7071 -0.7071 0.7071 0.7071 -19.5192 47.0347)'
            width='4'
            height='129.1'
          />
        </g>
        <g>
          <rect
            x='-17.5'
            y='45'
            transform='matrix(0.7071 -0.7071 0.7071 0.7071 -19.4694 47.0141)'
            width='129.1'
            height='4'
          />
        </g>
      </svg>
    </button>
  );
};

export default CloseButton;
