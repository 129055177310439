import * as types from '../actions/types/widgetsActionTypes';
import * as dashboardTypes from '../actions/types/dashboardActionTypes';
import { WidgetsState } from './reducers';
import { union } from 'lodash';

const initialState: WidgetsState = {
  byId: {},
  allIds: [],
  widgetMeta: {},
  reportFormat: {},
  isRequesting: false
};

const widgetsReducer = (state = initialState, action) => {
  switch (action.type) {
    // TODO: Deprecate widget action types
    case dashboardTypes.FETCH_WIDGET_META_REQUEST:
    case dashboardTypes.FETCH_DASHBOARD_WIDGET_REQUEST:
    case types.GET_WIDGETS_REQUEST:
      return { ...state, isRequesting: true };

    case types.GET_WIDGETS_SUCCESS: {
      const { payload = {} } = action;
      const { widgets, format, navId } = payload;
      const widgetIds: number[] = [];
      for (const key in widgets) {
        widgetIds.push(Number(key));
      }
      const allIds = union(widgetIds, state.allIds);
      const byId = {
        ...state.byId,
        ...widgets
      };
      const reportFormat = {
        ...state.reportFormat,
        [navId]: format
      };
      return {
        ...state,
        allIds,
        byId,
        reportFormat,
        isRequesting: false,
        error: ''
      };
    }

    case dashboardTypes.FETCH_WIDGET_META_SUCCESS: {
      const { payload = [] } = action;
      const widgetMeta = payload.reduce(
        (acc, widget) => ({ ...acc, [widget.widgetId]: { ...widget } }),
        {}
      );

      return {
        ...state,
        widgetMeta,
        isRequesting: false
      };
    }

    case dashboardTypes.FETCH_DASHBOARD_WIDGET_SUCCESS: {
      const { payload = {} } = action;
      const allIds = union([payload.widgetId], state.allIds);
      const byId = {
        ...state.byId,
        [payload.widgetId]: payload
      };

      return {
        ...state,
        allIds,
        byId,
        isRequesting: false
      };
    }

    case dashboardTypes.FETCH_WIDGET_META_FAILURE:
    case dashboardTypes.FETCH_DASHBOARD_WIDGET_FAILURE:
    case types.GET_WIDGETS_FAILURE: {
      const { payload = '' } = action;
      return { ...state, error: payload, isRequesting: false };
    }

    default:
      return state;
  }
};

export default widgetsReducer;
