/*
  TODO: Discuss using reselect... Would be for cleaner code in components and less renders but can make 
  things far more complicated. If we suspect re-renders will be a concern, we should introduce this now
  rather than later. Unless the data warehouse has complicated data that we need to heavily manipulate,
  we probably won't need this at all... if that's the case, delete this `/selectors` dir.
*/
// import { createSelector } from 'reselect';

// import {
//   linkLoading,
//   elementsLoading,
//   deptLoading,
//   usersLoading
// } from './loading';

// export const NavLinkManagementLoading = createSelector(
//   linkLoading,
//   usersLoading,
//   (links, users) => links || users
// );

// reselect
// export const DeptManagementLoading = createSelector(
//   linkLoading,
//   elementsLoading,
//   deptLoading,
//   usersLoading,
//   (links, departments, users) => links || departments || users
// );
import { GlobalState } from '../reducers/reducers';

export const selectNavElements = (state: GlobalState) => state.nav.elements;

export const selectNavDepartments = (state: GlobalState) =>
  state.nav.departments;

export const selectNavigationLookup = (state) => {
  const { byId: elementsById, allIds: elementsIds } = selectNavElements(state);
  const pathLookup = {} as Record<
    string,
    {
      navId: number;
      parentNavId: number | null;
      subNav: number[];
      dmId: number | null;
      url: string;
      helpId?: number;
    }
  >;
  elementsIds.forEach((id) => {
    const {
      url: elementUrl = '',
      parentNavId,
      navId,
      subNav = [],
      dmId
    } = elementsById[id];
    if (!pathLookup[elementUrl] && elementUrl !== '') {
      pathLookup[elementUrl] = {
        navId,
        parentNavId,
        subNav,
        dmId,
        url: elementUrl
      };
    }
  });
  return pathLookup;
};

export const helpLookup = (state) => {
  const navLookup = selectNavigationLookup(state);
  const { byId: helpByIds, allIds: helpIds } = state.help.allHelp;
  helpIds.forEach((id) => {
    const { url, typeId } = helpByIds[id];
    if (navLookup[url] && typeId !== 3) {
      navLookup[url].helpId = id;
    }
  });
  return navLookup;
};

// Pass in base url to return a redirect url
// Get base url from `const { url } = useRouteMatch();`
export const redirectElement = (state, url) => {
  const pathLookup = selectNavigationLookup(state);
  const { byId, allIds } = selectNavElements(state);
  if (allIds.length < 1) return '';
  const { subNav } = pathLookup[url];
  const redirect = byId[subNav[0]] ?? {};
  return redirect;
};

export const selectNavGroups = (state: GlobalState) => state.nav.groups;
