import { useState, useEffect, useCallback } from 'react';

interface HighlightStyles {
  top: string;
  left: string;
  width: string;
  height: string;
}

interface TourStepStyles {
  top: string;
  left: string;
  right: string;
}
interface ArrowStyles {
  top: string;
  left: string;
}

interface TourProps {
  highlightStyles: HighlightStyles;
  tourStepStyles: TourStepStyles;
  arrowStyles: ArrowStyles;
}

function useTourPositionProps(
  elementRef: string,
  loading: boolean = true
): TourProps {
  const [highlightPosition, setHighlightPosition] = useState<{
    [key: string]: number;
  }>({
    top: 0,
    left: 0
  });
  const [stepPosition, setStepPosition] = useState<{
    [key: string]: number | string;
  }>({
    top: 0,
    left: 0,
    right: 'initial'
  });

  const [highlight, setHighlight] = useState<{ [key: string]: number }>({
    width: 0,
    height: 0,
    borderRadius: 0
  });
  const [windowHeight, setWindowHeight] = useState<number>(901);
  const header = document.querySelector('.header') as HTMLElement;
  const headerHeight: number = header.offsetHeight || 0;
  const sideNav = document.querySelector('.side-nav-panel') as HTMLElement;
  const sideNavWidth: number = (sideNav && sideNav.offsetWidth) || 0;
  const calculateHighlightPosition = useCallback((element) => {
    const { top, left } = element?.getBoundingClientRect();

    setHighlightPosition({
      top,
      left
    });
  }, []);

  const calculateHighlight = useCallback(
    (element) => {
      const { left, top } = element.getBoundingClientRect();
      const isSide = left === 0 && top >= 88;
      setHighlight({
        height: element.clientHeight,
        width: isSide ? sideNavWidth : element.clientWidth, // this accounts for whether or not the side nav panel is open
        borderRadius: element.clientWidth / 2
      });
    },
    [sideNavWidth]
  );

  const calculateStepPosition = useCallback(
    (element) => {
      const tourStep = document.querySelector('.tour-step') as HTMLElement;
      const { top, left } = element.getBoundingClientRect();
      let right: string | number = 'initial';
      let leftPos: string | number = left;
      let topPos = top;
      if (top < headerHeight) {
        topPos = headerHeight - top;
      }

      const isSide = left === 0;
      if (isSide) leftPos = left + sideNavWidth + 5;
      if (top + tourStep?.offsetHeight > window.innerHeight) {
        topPos = window.innerHeight - tourStep?.offsetHeight;
        leftPos = left - 20;
        right = 'initial';
      }
      if (left + 590 > window.innerWidth) {
        leftPos = 'initial';
        right = 20;
      } // Add 20px to account for scrollbar
      setStepPosition({ top: topPos, left: leftPos, right });
    },
    [sideNavWidth, headerHeight]
  );

  useEffect(() => {
    if (!loading && elementRef) {
      const tourElement = document.getElementById(elementRef) as HTMLElement;
      calculateHighlightPosition(tourElement);
      calculateStepPosition(tourElement);
      calculateHighlight(tourElement);
    }
  }, [
    loading,
    elementRef,
    calculateStepPosition,
    calculateHighlightPosition,
    calculateHighlight,
    windowHeight
  ]);

  useEffect(() => {
    const updateWindowHeight = () => {
      const newHeight = window.innerHeight;
      setWindowHeight(newHeight);
    };

    window.addEventListener('resize', updateWindowHeight);

    return () => window.removeEventListener('resize', updateWindowHeight);
  }, []);

  const isSide = stepPosition.left === sideNavWidth;
  return {
    tourStepStyles: {
      top: `${stepPosition.top}px`,
      left: `${
        stepPosition.left === 'initial'
          ? stepPosition.left
          : stepPosition.left + 'px'
      }`,
      right: `${stepPosition.right}px`
    },
    highlightStyles: {
      top: `${highlightPosition.top}px`,
      left: `${highlightPosition.left}px`,
      width: `${highlight.width}px`,
      height: `${highlight.height}px`
    },
    arrowStyles: {
      top: `${
        highlightPosition.top + (isSide ? 0 : highlightPosition.width / 2)
      }px`,
      left: `${
        highlightPosition.left + (isSide ? highlightPosition.width / 2 : 0)
      }px`
    }
  };
}

export default useTourPositionProps;
