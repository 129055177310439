import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Dropdown, Input, stripHTML } from '@ovis-technologies/ovis-blueprint';

import { setSidePanelState } from '../../actions/uiActions';
import { getGlobalSearchRequest } from '../../services/globalSearchService';
import { PanelType } from '../../reducers/reducers.d';
import { selectAllOrgs } from '../../selectors';
import { Link } from 'react-router-dom';

const HeaderGlobalSearch = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchElements, setSearchElements] = useState<any[]>([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { byId } = useSelector(selectAllOrgs);

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      setSearchOpen(true);
    }
    if (searchQuery !== '') {
      (async function () {
        // setLoading(true);
        try {
          const { response } = await getGlobalSearchRequest(
            searchQuery,
            'relevance'
          );
          const users = response.user;
          setSearchElements(users.slice(0, 5));
        } catch (err: any) {
          setSearchElements([]);
        }
        // setLoading(false);
      })();
    }
  }, [dispatch, history, searchQuery]); // eslint-disable-line

  useEffect(() => {
    const handleOnQuerySubmit = (e) => {
      if (e.key === 'Enter') {
        history.push('/search?query=' + searchQuery || '');
      }
    };
    const getElement = document.querySelector(
      '.ovis-dropdown-container .ovis-input'
    ) as HTMLInputElement;
    getElement.addEventListener('keypress', handleOnQuerySubmit);
    return () => {
      getElement.removeEventListener('keypress', handleOnQuerySubmit);
    };
  }, [searchQuery, history]);

  const handleChange = (value: string) => {
    setSearchQuery(value);
  };

  const handleOpenProfile = (userId, name) => {
    dispatch(
      setSidePanelState({ title: name, panelType: PanelType.profile, userId })
    );
  };

  const handleClose = () => {
    setSearchOpen(false);
  };

  return (
    <div className='global-search_input'>
      <Dropdown
        visible={searchOpen}
        noArrow
        outsideClickClose
        onDropdownClose={handleClose}
        containerClassName='global-search_dropdown'
        noOpenOnClick
        bodyClickClose
        body={
          <div className='global-search_preview-results'>
            {searchElements.map((element, index) => (
              <div
                className='global-search_preview-result'
                key={`${element.fname}-${element.lname}${index}}`}
                onClick={() =>
                  handleOpenProfile(
                    element.userId,
                    `${element.fname}-${element.lname}`
                  )
                }
              >
                <p>
                  {element.fname} {element.lname}&nbsp;&nbsp;&nbsp;
                  <span>
                    {stripHTML(element.subTitle)} - {byId[element.orgId].name}
                  </span>
                </p>
              </div>
            ))}
            <Link to={`/search?query=${searchQuery}`}>
              <div className='global-search_preview-result'>
                <p>See all results</p>
              </div>
            </Link>
          </div>
        }
      >
        <Input
          onChange={handleChange}
          value={searchQuery}
          placeholder='Search...'
        />
      </Dropdown>
    </div>
  );
};

export default HeaderGlobalSearch;
