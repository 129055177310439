export const privilege = {
  orgManagement: 1,
  orgSwap: 2,
  orgDeletion: 3,
  userManagement: 5,
  navManagement: 6,
  navDelete: 7,
  helpCenterManagement: 8,
  imageLibrary: 14,
  tagContentForExport: 21,
  dmManagement: 22,
  parsers: 23,
  featuresManagement: 29,
  notificationManagement: 35,
  calculatorManagement: 52,
  dwStatus: 53,
  quicksight: 53,
  commentManagement: 1,
  termsManagement: 5,
  executiveDashboard: 36,
  bedrock: 11
};
