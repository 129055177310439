import React, { useCallback, useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import classnames from 'classnames';
import {
  Button,
  Input,
  notification,
  Loader,
  FolderPrevious,
  Folder,
  FolderAdd,
  Download,
  Tooltip,
  Plus,
  Panel
} from '@ovis-technologies/ovis-blueprint';
import {
  createFMDirectoryRequest,
  createFMFileRequest,
  listFMDirectoryRequest
} from '../../services/fileManager';
import getPrivateAssetUrl from '../../services/getPrivateAssetUrl';
import { getUrlFileName } from '../../utils';
import useHover from '../../hooks/userHover';
import MultiUpload from '../MultiUpload/MultiUpload';
import Arrow from '../svgs/Arrow';
import FileIcon from './FileIcon';
import PreviewButton from '../PreviewButton/PreviewButton';
import './_file_manager.scss';

interface FileManagerProps {
  onFileSelect?: (path: string, url: string) => void;
  onUploadClick?: (context: string) => void;
  setCurrentDir?: (context: string) => void;
  root: string;
  currentDir: string;
}

interface FileManagerDirState {
  directories: any[];
  files: any[];
  url: string;
}

const FileManager = ({
  root,
  currentDir,
  setCurrentDir = () => {},
  onUploadClick = () => {},
  onFileSelect
}: FileManagerProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentDirData, setCurrentDirData] = useState<FileManagerDirState>({
    directories: [],
    files: [],
    url: ''
  });
  const [addDirectoryState, setAddDirectoryState] = useState<boolean>(false);

  const fetchDirectory = useCallback(
    async (path) => {
      setLoading(true);
      try {
        const { response } = await listFMDirectoryRequest(path);

        setCurrentDir(path);
        setCurrentDirData(response);
        setLoading(false);
      } catch (error: any) {
        console.log(error);
      }
    },
    [setCurrentDir]
  );

  const createDirectory = async (directory = '') => {
    const value = currentDir + directory + '/';
    setLoading(true);
    setAddDirectoryState(false);
    try {
      await createFMDirectoryRequest(value);
      const { response } = await listFMDirectoryRequest(currentDir);
      setCurrentDirData(response);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const downloadObject = async (url) => {
    try {
      const signedUrl = await getPrivateAssetUrl(url);
      const config: AxiosRequestConfig = {
        responseType: 'blob'
      };
      const result = await axios.get(signedUrl, config);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(result.data);
      link.download = getUrlFileName(url);
      link.click();
    } catch (e) {
      notification.error({
        message: 'Failed to retrieve asset.',
        duration: 0
      });
    }
  };

  useEffect(() => {
    fetchDirectory(currentDir);
  }, [fetchDirectory, currentDir]);

  const handleFileSelect = (path, url) => {
    if (!loading && onFileSelect) {
      onFileSelect(path, url);
    }
  };

  const handleObjectDownloadClick = (url) => {
    if (!loading) {
      return downloadObject(url);
    }
  };

  // Limit to root?
  const handlePreviousDirClick = () => {
    if (!loading && currentDir !== root) {
      let dirArr: string[] = currentDir?.split('/');
      dirArr = dirArr.slice(0, dirArr.length - 2);
      const newDir: string = dirArr.join('/');
      if (newDir !== '') {
        fetchDirectory(newDir + '/');
      }
    }
  };

  const handleAddFolderClick = () => {
    if (!loading) {
      setAddDirectoryState(false);
      setAddDirectoryState(!addDirectoryState);
    }
  };

  const handleCloseAddFolder = () => {
    setAddDirectoryState(false);
  };

  const handleUploadClick = () => {
    if (!loading) {
      onUploadClick(currentDir);
    }
  };

  const loadingOverlayClass = classnames('file-manager_loader-overlay', {
    '--active': loading
  });

  const iconClass = classnames('file-manager_icon', {
    '--disabled': loading
  });

  const previousFolderClass = classnames(iconClass, {
    '--disabled': currentDir === root
  });

  const directoryAddWrapper = classnames('file-manager_directory-add-wrapper', {
    '--expanded': addDirectoryState
  });
  const directoryAddClass = classnames('file-manager_directory', '--add', {
    '--expanded': addDirectoryState
  });

  return (
    <div className='file-manager_container'>
      <div className='file-manager_controls'>
        <div className='file-manager_current-dir'>
          <div
            className='file-manager_previous'
            onClick={handlePreviousDirClick}
          >
            <FolderPrevious className={previousFolderClass} />
          </div>
          <div className='file-manager_path'>
            {currentDir.replace(root, '/')}
          </div>
          {/* <Button
              onClick={() => handleObjectDownloadClick(currentDirData.url)}
            >
              Download
            </Button> */}
        </div>
        <Button styleType='primary' onClick={handleUploadClick}>
          Upload Files
        </Button>
      </div>
      <div>
        <div className={loadingOverlayClass}>
          <Loader />
        </div>
        <div className='file-manager_directories'>
          {currentDirData.directories.map((pathObj) => (
            <Directory
              key={pathObj.key}
              iconClass={iconClass}
              displayPath={pathObj.key.replace(currentDir, '').replace('/', '')}
              pathKey={pathObj.key}
              url={pathObj.url}
              onClick={fetchDirectory}
            />
          ))}
          <div className={directoryAddWrapper}>
            <div
              className={directoryAddClass}
              onClick={addDirectoryState ? undefined : handleAddFolderClick}
            >
              {addDirectoryState ? (
                <FolderForm
                  onSubmit={createDirectory}
                  onCancelClick={handleCloseAddFolder}
                />
              ) : (
                <FolderAdd className={iconClass} />
              )}
            </div>
          </div>
        </div>
        <div className='file-manager_files'>
          {currentDirData.files.map((pathObj) => {
            const pathSplit = pathObj.key.split('/');
            return (
              <Tooltip
                key={pathObj.key}
                content={pathSplit[pathSplit.length - 1]}
              >
                <File
                  iconClass={iconClass}
                  displayPath={pathObj.key.replace(currentDir, '')}
                  pathKey={pathObj.key}
                  url={pathObj.url}
                  onFileSelect={onFileSelect ? handleFileSelect : undefined}
                  onDownload={handleObjectDownloadClick}
                />
              </Tooltip>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// TODO?: Move these to /components/FileManager/

const Directory = ({ displayPath, pathKey, url, onClick, iconClass }) => {
  const [hovered, hoverEvents] = useHover();
  const handleClick = () => {
    onClick(pathKey);
  };

  return (
    <Button
      className='file-manager_directory'
      onClick={handleClick}
      // @ts-ignore: Unreachable code error
      {...hoverEvents}
      styleType='none'
    >
      {hovered ? (
        <Folder className={iconClass} />
      ) : (
        <Folder className={iconClass} />
      )}
      <div>{displayPath}</div>
    </Button>
  );
};

const File = ({
  displayPath,
  pathKey,
  url,
  onFileSelect,
  onDownload,
  iconClass
}) => {
  const [mounted, setMounted] = useState<boolean>(true);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [signedUrl, setSignedUrl] = useState<string>('');
  const getImageUrl = useCallback(async () => {
    const signedUrl = await getPrivateAssetUrl(url);
    setSignedUrl(signedUrl);
  }, [url]);

  useEffect(() => {
    if (url) {
      if (mounted) getImageUrl();
    }
    return () => setMounted(false);
  }, [url, getImageUrl, mounted]);

  const handleFileSelect = () => {
    onFileSelect(pathKey, url);
  };

  const handleDownload = async () => {
    if (!downloadLoading) {
      setDownloadLoading(true);
      await onDownload(url);
      setDownloadLoading(false);
    }
  };

  return (
    <div className='file-manager_file'>
      <FileIcon url={signedUrl} />
      <div className='file-manager_file-details'>
        <p>{displayPath}</p>
        <div className='file-manager_file-actions'>
          <Tooltip content='Download'>
            <div onClick={handleDownload}>
              {downloadLoading ? (
                <Loader />
              ) : (
                <Download className={iconClass} />
              )}
            </div>
          </Tooltip>
          <Tooltip content='Preview'>
            <PreviewButton asIcon contentUrl={url} />
          </Tooltip>
          {onFileSelect && (
            <Tooltip content='Select File'>
              <div onClick={handleFileSelect}>
                <Plus />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

// A tad hacky to get around some Input quirkiness DY 11/11/2021
const FolderForm = ({ onSubmit, onCancelClick }) => {
  const [value, setValue] = useState<string>('');
  const validRegEx = /[^a-zA-Z0-9_-]+/g;
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleButtonClick = () => {
    console.log(value);
    if (value !== '' && !value.match(validRegEx)) {
      onSubmit(value);
    }
  };

  const rules = {
    required: true,
    validate: {
      text: (value) => !value.match(validRegEx)
    }
  };

  return (
    <>
      <div className='file-manager_directory-form'>
        <Input onChange={handleChange} rules={rules} size='small' />
        <Button size='small' onClick={handleButtonClick} styleType='primary'>
          Create Folder
        </Button>
        <Button
          size='small'
          onClick={onCancelClick}
          style={{ marginLeft: '.25rem' }}
          styleType='warning'
        >
          Cancel
        </Button>
      </div>
      <p>Alphanumerical, dashes, and underscores are accepted characters.</p>
    </>
  );
};

const FMUpload = ({ root, uploadContext = '', onBackClick }) => {
  const [fileLookup, setFileLookup] = useState<{
    [fileName: string]: string | undefined;
  }>({});
  const [uploadAvailable, setUploadAvailable] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  useEffect(() => {
    const values = Object.values(fileLookup);
    if (values.length > 0 && !values.includes(undefined)) {
      setUploadAvailable(true);
    } else {
      setUploadAvailable(false);
    }
  }, [fileLookup]);

  const handleUpload = async () => {
    setUploading(true);
    await Promise.allSettled(
      Object.entries(fileLookup).map(([fileName, tempPath]) => {
        const path = uploadContext + fileName;
        return createFMFileRequest(path, tempPath ?? '');
      })
    );
    setUploading(false);
    onBackClick();
  };

  const handleMultiUploadChange = (fileLookup) => {
    setFileLookup(fileLookup);
  };

  const loadingOverlayClass = classnames('file-manager_loader-overlay', {
    '--active': uploading
  });

  return (
    <div className='file-manager_upload'>
      <div className={loadingOverlayClass}>
        <Loader />
      </div>
      <div className='file-manager_upload-context'>
        <div
          className='link-to left'
          onClick={onBackClick}
          style={{ margin: 0 }}
        >
          <Arrow /> Back
        </div>
        <div> Files will be uploaded to {uploadContext.replace(root, '/')}</div>
      </div>
      <MultiUpload onChange={handleMultiUploadChange} />
      <Button onClick={handleUpload} disabled={!uploadAvailable}>
        Upload
      </Button>
    </div>
  );
};

// Should take root
const FileManagerWrapper = ({
  standAlone = true,
  onFileSelect = (path: string, url: string) => {},
  root = 'prv/file_manager/'
}) => {
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [currentDir, setCurrentDir] = useState<string>(root);

  const handleUploadBack = () => {
    setShowUpload(false);
  };

  const handleUploadClick = (context: string) => {
    setShowUpload(true);
  };

  const renderFileManager = showUpload ? (
    <FMUpload
      root={root}
      uploadContext={currentDir}
      onBackClick={handleUploadBack}
    />
  ) : (
    <FileManager
      onUploadClick={handleUploadClick}
      onFileSelect={onFileSelect}
      setCurrentDir={setCurrentDir}
      currentDir={currentDir}
      root={root}
    />
  );
  if (standAlone) {
    return (
      <div className='content file-manager' id='file-manager'>
        <Panel theme='light'>{renderFileManager}</Panel>
      </div>
    );
  } else {
    return (
      <div className='file-manager' id='file-manager'>
        {renderFileManager}
      </div>
    );
  }
};

export default FileManagerWrapper;
