import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  FormItemProps,
  notification,
  ImageUpload,
  patternValidate,
  useMobile
} from '@ovis-technologies/ovis-blueprint';
import { sortBy } from 'lodash';

import { selectAllOrgs } from '../../selectors';
import { updateUserData, setUpdatedUser } from '../../actions/userActions';
import { User } from '../../App.d';
import fileUpload from '../../services/fileUpload';
import { RequestState } from '../../../constants/requestStates';
import { GlobalState } from '../../reducers/reducers';
import { track } from '../../services/trackingService';
import {
  selectNotificationFrequencies,
  selectStates
} from '../../selectors/staticSelectors';
import { selectActiveUser } from '../../selectors/userSelectors';
import RefreshLoader from '../../components/RefreshLoader/RefreshLoader';

const UserProfileEdit = () => {
  const dispatch = useDispatch();
  const [mobile] = useMobile(1150);

  // States
  const [selectedFile, setSelectedFile] = useState('');
  const [requestState, setRequestState] = useState<string>(RequestState.null);

  // Selectors
  const activeUser: User = useSelector((state: GlobalState) =>
    selectActiveUser(state)
  );

  const { allIds: allOrgs, byId: orgsById } = useSelector(selectAllOrgs);
  const { allIds: stateIds, byId: statesById } = useSelector(selectStates);
  const { allIds: frequencyIds, byId: notificationFrequencies } = useSelector(
    selectNotificationFrequencies
  );

  const userRequesting: boolean = useSelector(
    (state: GlobalState) => state.user.gettingUser
  );

  const statesOptions = sortBy(
    stateIds.map((id) => {
      const { code, name } = statesById[id];
      return {
        value: id,
        label: `${name} - ${code}`
      };
    }),
    'label'
  );

  const profileImage =
    useSelector(
      (state: GlobalState) =>
        state.user.users.byId[state.user.activeUserId].photo
    ) || '/assets/avatar.svg';

  // Side Effects
  useEffect(() => {
    track({ context: '/profile', typeId: 1 });
  }, []); // eslint-disable-line

  // Functions
  const handleFileChange = (file: any) => {
    if (file === '') {
      file = '';
      setSelectedFile(file);
    } else {
      if (file.type.includes('image')) {
        setSelectedFile(file);
      } else {
        notification.error({
          message: 'File must be an image'
        });
      }
    }
  };

  const handleSubmit = async (data: any) => {
    setRequestState(RequestState.sending);
    let { photo } = activeUser;
    try {
      if (selectedFile) {
        notification.info({ message: 'Uploading profile image', duration: 0 });
        photo = await fileUpload(selectedFile);
        notification.destroy();
      }
      const userData = {
        ...activeUser,
        ...data,
        photo,
        officePhone:
          data.officePhone !== ''
            ? `+${data.officePhone.replace('+', '')}`
            : '',
        cellPhone:
          data.cellPhone !== '' ? `+${data.cellPhone.replace('+', '')}` : ''
      };

      // TODO: ResponseTyping
      notification.info({ message: 'Submitting profile data', duration: 0 });
      const response: any = await dispatch(
        updateUserData({ ...userData, userId: activeUser.userId })
      );
      const updatedUserData: User = response.response;
      notification.destroy();
      notification.success({
        message: 'Success!',
        description: response.message || 'Profile updated'
      });
      dispatch(setUpdatedUser(updatedUserData));
    } catch (e) {
      console.error(e);
    }
    setRequestState(RequestState.null);
  };

  // Variables

  const formFields: FormItemProps[] = [
    {
      name: 'fname',
      label: 'First Name',
      type: 'text',
      rules: { required: true, maxLength: 30 },
      labelAboveInput: mobile,
      rowSize: 'half'
    },
    {
      name: 'lname',
      label: 'Last Name',
      type: 'text',
      rules: { required: true, maxLength: 30 },
      labelAboveInput: mobile,
      rowSize: 'half'
    },
    {
      name: 'title',
      label: 'Title',
      type: 'text',
      rules: { required: false, maxLength: 50 },
      labelAboveInput: mobile
    },
    {
      name: 'address1',
      label: 'Address Line 1',
      type: 'text',
      rules: {
        maxLength: 300
      }
    },
    {
      name: 'address2',
      label: 'Address Line 2',
      type: 'text',
      rules: {
        maxLength: 300
      }
    },
    {
      name: 'city',
      label: 'City',
      type: 'text',
      isClearable: true,
      rules: {
        maxLength: 50
      }
    },
    {
      name: 'stateId',
      label: 'State',
      type: 'select',
      isClearable: false,
      options: statesOptions
    },
    {
      name: 'zip',
      label: 'Zip Code',
      type: 'text',
      isClearable: true,
      rules: {
        maxLength: 11
      }
    },
    {
      name: 'officePhone',
      label: 'Office Phone',
      type: 'tel',
      rules: {
        required: true,
        maxLength: 30,
        validate: {
          phone: (e) => patternValidate(e, { type: 'tel' })
        }
      },
      subLabel: "Include country code. ex. '+1'",
      labelAboveInput: mobile
    },
    {
      name: 'cellPhone',
      label: 'Cell Phone',
      type: 'tel',
      required: false,
      maxLength: 31,
      subLabel: "Include country code. ex. '+1'",
      rules: {
        validate: {
          phone: (e) => patternValidate(e, { type: 'tel' })
        }
      },
      labelAboveInput: mobile
    },
    {
      name: 'frequencyId',
      type: 'select',
      label: 'Notification Frequency',
      subLabel: 'How often you would like to receive news summaries',
      options: frequencyIds.map((id) => ({
        value: id,
        label: notificationFrequencies[id].name
      })),
      rules: {
        required: true
      },
      labelAboveInput: mobile
    },
    {
      name: 'email',
      label: 'Email Address',
      type: 'static',
      rules: { required: false, maxLength: 100 },
      labelAboveInput: mobile
    },
    {
      name: 'orgId',
      label: 'Organization',
      type: 'static',
      rules: { required: false, maxLength: 100 },
      render: ({ value }) => orgsById[value]?.name || null,
      labelAboveInput: mobile
    },
    {
      name: 'createdStampFormat',
      label: 'Member Since',
      type: 'static',
      rules: { required: false, maxLength: 30 },
      labelAboveInput: mobile
    },
    {
      name: 'linkedIn',
      type: 'text',
      label: 'LinkedIn',
      labelAboveInput: mobile
    },
    {
      name: 'bio',
      label: 'Bio',
      type: 'rich-text',
      style: { itemCol: { backgroundColor: 'transparent', color: 'black' } },
      labelAboveInput: mobile
    }
  ];

  return (
    <div className='profile-edit'>
      <RefreshLoader visible={userRequesting} />
      {Object.keys(activeUser).length > 0 && allOrgs.length > 0 && (
        <>
          <div className='profile_form-and-img'>
            <div className='profile-img-and-file-upload'>
              <ImageUpload
                src={profileImage}
                onChange={handleFileChange}
                shape='circle'
                plusButton
                sizeLimit={25000000}
                size='large'
                subLabel='Image must be JPG or PNG, within 5mb in size. The image should be 300x300 or maintain a 1:1 aspect ratio (a square). Borders will appear around images not in the 1:1 aspect ratio.'
              />
            </div>
            <div className='profile_panel_forms'>
              <Form
                fields={formFields}
                defaultValues={activeUser}
                submitButtonText='Update'
                submitButtonClass='--secondary'
                onSubmit={handleSubmit}
                loading={requestState === RequestState.sending}
                disabled={userRequesting}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserProfileEdit;
