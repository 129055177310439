// eslint-disable-next-line
import React, { useEffect, useState, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import { useOktaAuth } from '@okta/okta-react';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import { Tokens } from '../../App.d';
import config from '../../config.js';
import { Loader } from '@ovis-technologies/ovis-blueprint';
import { useHistory } from 'react-router-dom';
import { track } from '../../services/trackingService';

interface Props {}

const OktaSignInWidget: React.FC<Props> = () => {
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const widgetRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect((): any => {
    if (!widgetRef.current) {
      return false;
    }
    const { issuer, clientId, redirectUri, scopes } = config.oidc;
    const widget = new OktaSignIn({
      /**
       * Note: when using the Sign-In Widget for an OIDC flow, it still
       * needs to be configured with the base URL for your Okta Org. Here
       * we derive it from the given issuer for convenience.
       */
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      redirectUri,
      logo: `${config.app.logo}`,
      i18n: {
        en: {
          'primaryauth.title': ' ',
          'primaryauth.submit': 'Log in'
        }
      },
      authParams: {
        // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
        issuer,
        scopes
      },
      el: '#login-main'
    });
    const oktaAuthClient = widget.authClient;
    oktaAuthClient.session.exists().then((exists) => {
      if (exists) {
        setLoading(true);
        oktaAuthClient.token
          .getWithoutPrompt({
            responseType: ['token', 'id_token']
          })
          .then((res) => {
            oktaAuth.handleLoginRedirect(res.tokens);
          });
      } else {
        widget
          .showSignInToGetTokens({
            el: widgetRef.current,
            clientId,
            scopes
          })
          .then((tokens: Tokens) => {
            oktaAuth.handleLoginRedirect(tokens).then(() => {
              // Add tokens to storage
              track({ context: history.location.pathname, typeId: 2 });
            });
          })
          .catch((err: Error) => {
            throw err;
          });
      }
    });
    return () => widget.remove();
  }, [oktaAuth]); // eslint-disable-line

  return (
    <div className='okta-widget-container' ref={widgetRef}>
      {loading && <Loader />}
    </div>
  );
};

export default OktaSignInWidget;
