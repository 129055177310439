import React, { ReactNode } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { notification } from '@ovis-technologies/ovis-blueprint';
import getPrivateAssetUrl from '../../services/getPrivateAssetUrl';
import config from '../../config';
import errorMessage from '../../../constants/standardErrorMessage';
import { getUrlFileName } from '../../utils';
import { track } from '../../services/trackingService';

interface Props {
  href: string;
  name?: ReactNode;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
  shouldTrack?: boolean;
  elementId?: number;
}

const openAssetInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noreferrer');
  if (newWindow) newWindow.opener = null;
};

const AuthRequiredLink = React.forwardRef(
  (
    {
      name = '',
      href = '',
      className = 'auth-required-link',
      onClick = () => {},
      children,
      shouldTrack = true,
      elementId
    }: Props,
    ref
  ) => {
    const domain = config.api.domain;

    const processAssetLink = async (url: string) => {
      if (url.includes(domain!)) {
        notification.info({ message: 'Retrieving document' });
        try {
          const signedUrl = await getPrivateAssetUrl(url);
          const config: AxiosRequestConfig = {
            responseType: 'blob'
          };

          const result = await axios.get(signedUrl, config);
          notification.destroy();
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(result.data);
          link.download = getUrlFileName(url);
          link.click();
        } catch (err: any) {
          notification.error({
            message: 'Failed to retrieve asset. ' + errorMessage,
            duration: 0
          });
        }
      } else {
        openAssetInNewTab(url);
      }
    };

    const handleTrackClick = () => {
      track({ elementId, context: url, typeId: 5 });
    };

    const handleClick = (e) => {
      onClick();
      e.preventDefault();
      if (elementId && shouldTrack) handleTrackClick();
      processAssetLink(href);
    };

    const url = href
      ? href.includes(domain!)
        ? '/document?url=' + encodeURIComponent(href)
        : href
      : '';

    const defaultContent = <>{name}</>;

    return (
      <>
        <a href={url} className={className} onClick={handleClick}>
          {children || defaultContent}
        </a>
      </>
    );
  }
);
export default AuthRequiredLink;
