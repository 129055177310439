import { EXPORT_ENDPOINT } from './endpoints';
import axios from 'axios';
import { handleServiceError } from '.';
import { base64ToArrayBuffer } from '../utils';

export const exportUsersRequest = async (data: {
  orgId?: number;
  keyWord?: string;
}) => {
  return axios
    .post(EXPORT_ENDPOINT, {
      action: 'EXPORT_USER',
      data
    })
    .then((res) => ({
      buffer: base64ToArrayBuffer(res.data),
      fileName: res.headers['content-disposition']
        .split(';')[1]
        .split('=')[1]
        .trim()
    }))
    .catch(handleServiceError);
};

export const exportPollRequest = async (data: { pollId }) => {
  return axios
    .post(EXPORT_ENDPOINT, {
      action: 'EXPORT_POLL',
      data
    })
    .then((res) => ({
      buffer: base64ToArrayBuffer(res.data),
      fileName: res.headers['content-disposition']
        .split(';')[1]
        .split('=')[1]
        .trim()
    }))
    .catch(handleServiceError);
};

export const exportOrgsRequest = async (
  data: {
    keyWord?;
    orgIds?;
    activeOnly?;
  } = {}
) => {
  return axios
    .post(EXPORT_ENDPOINT, {
      action: 'EXPORT_ORG',
      data
    })
    .then((res) => ({
      buffer: base64ToArrayBuffer(res.data),
      fileName: res.headers['content-disposition']
        .split(';')[1]
        .split('=')[1]
        .trim()
    }))
    .catch(handleServiceError);
};

export const exportEngagementReportRequest = async (
  data: {
    elementId?;
  } = {}
) => {
  return axios
    .post(EXPORT_ENDPOINT, {
      action: 'EXPORT_DM_ENGAGEMENT_REPORT',
      data
    })
    .then((res) => ({
      buffer: base64ToArrayBuffer(res.data),
      fileName: res.headers['content-disposition']
        .split(';')[1]
        .split('=')[1]
        .trim()
    }))
    .catch(handleServiceError);
};

export const exportFileCheckRequest = async (
  data: { pickupId?: number } = {}
) => {
  return axios
    .post(EXPORT_ENDPOINT, { action: 'FILE_PICKUP_CHECK', data })
    .then((res) => res.data.response)
    .catch(handleServiceError);
};
export const exportFilePickupRequest = async (
  data: { pickupId?: number } = {}
) => {
  return axios
    .post(EXPORT_ENDPOINT, { action: 'FILE_PICKUP_GET', data })
    .then((res) => ({
      buffer: base64ToArrayBuffer(res.data),
      fileName: res.headers['content-disposition']
        .split(';')[1]
        .split('=')[1]
        .trim()
    }))
    .catch(handleServiceError);
};

export const exportTOUreportRequest = async (termId?) => {
  const data = { termId };
  return axios
    .post(EXPORT_ENDPOINT, { action: 'EXPORT_TERMS_REPORT', data })
    .then((res) => ({
      buffer: base64ToArrayBuffer(res.data),
      fileName: res.headers['content-disposition']
        .split(';')[1]
        .split('=')[1]
        .trim()
    }))
    .catch(handleServiceError);
};
