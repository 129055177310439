export const getVendors = 'GET_VENDORS';
export const getSources = 'GET_SOURCES';
export const getAlerts = 'GET_ALERTS';
export const updateAlert = 'IGNORE_ALERT';
export const getSummary = 'GET_SUMMARY';
export const addVendor = 'ADD_VENDOR';
export const editVendor = 'EDIT_VENDOR';
export const addSource = 'ADD_SOURCE';
export const editSource = 'EDIT_SOURCE';
export const ignoreAlert = 'IGNORE_ALERT';
