enum notificationActionTypes {
  getNotificationRequest = 'GET_NOTIFICATION_REQUEST',
  getNotificationSuccess = 'GET_NOTIFICATION_SUCCESS',
  getNotificationFailure = 'GET_NOTIFICATION_FAILURE',
  searchRequest = 'SEARCH_NOTIFICATIONS_REQUEST',
  searchSuccess = 'SEARCH_NOTIFICATIONS_SUCCESS',
  searchFailure = 'SEARCH_NOTIFICATIONS_FAILURE',
  createRequest = 'CREATE_NOTIFICATION_REQUEST',
  createSuccess = 'CREATE_NOTIFICATION_SUCCESS',
  createFailure = 'CREATE_NOTIFICATION_FAILURE',
  updateRequest = 'UPDATE_NOTIFICATION_REQUEST',
  updateSuccess = 'UPDATE_NOTIFICATION_SUCCESS',
  updateFailure = 'UPDATE_NOTIFICATION_FAILURE',
  deleteRequest = 'DELETE_NOTIFICATION_REQUEST',
  deleteSuccess = 'DELETE_NOTIFICATION_SUCCESS',
  deleteFailure = 'DELETE_NOTIFICATION_FAILURE',
  testRequest = 'TEST_NOTIFICATION_REQUEST',
  testSuccess = 'TEST_NOTIFICATION_SUCCESS',
  testFailure = 'TEST_NOTIFICATION_FAILURE'
}

export default notificationActionTypes;
