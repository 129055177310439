import * as alertsTypes from '../actions/types/alertsActionTypes';
import { Action } from '../App.d';
import { AlertState } from './reducers.d';

const initialState: AlertState = {
  byId: {},
  allIds: [],
  notSeenCount: 0,
  gettingAlerts: false
};

const alertReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case alertsTypes.SEARCH_SUCCESS: {
      const { payload = [] } = action;
      const { alerts, setToSeen } = payload;
      const alertsSorted = alerts.sort((a, b) => {
        const aDate = a.userEntryDateFormat || a.entryDateFormat;
        const bDate = b.userEntryDateFormat || b.entryDateFormat;
        return new Date(bDate).getTime() - new Date(aDate).getTime();
      });
      const allIds = alertsSorted.map((alert: any) => {
        return alert.alertId;
      });
      let notSeenCount = 0;

      const byId = alerts.reduce((carry: object, value: any) => {
        if (!value?.hasSeen) notSeenCount += 1;
        return {
          ...carry,
          [value.alertId]: { ...value, hasSeen: setToSeen ? 1 : value.hasSeen }
        };
      }, {});
      return {
        ...state,
        allIds,
        byId,
        notSeenCount: setToSeen ? 0 : notSeenCount,
        gettingAlerts: false
      };
    }
    case alertsTypes.SEARCH_FAILURE:
      return {
        ...state,
        gettingAlerts: false
      };
    default:
      return state;
  }
};

export default alertReducer;
