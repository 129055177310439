import { GlobalState } from '../reducers/reducers';

export const selectPrivs = (state: GlobalState) => state.static.privs;
export const selectDmActions = (state: GlobalState) => state.static.dmActions;
export const selectTargetingActions = (state: GlobalState) =>
  state.static.dmElementTargetingActions;
export const selectOrgStatuses = (state: GlobalState) => state.static.orgStatus;
export const selectOrgTypes = (state: GlobalState) => state.static.orgType;
export const selectModules = (state: GlobalState) => state.static.modules;
export const selectNotificationFrequencies = (state: GlobalState) =>
  state.static.notificationFrequency;
export const selectNotificationActions = (state: GlobalState) =>
  state.static.notificationAction;
export const selectDmTemplates = (state: GlobalState) =>
  state.static.dmTemplate;
export const selectDmSortMethods = (state: GlobalState) =>
  state.static.dmSortMethod;
export const selectOrgRegions = (state: GlobalState) => state.static.orgRegion;
export const selectStates = (state: GlobalState) => state.static.states;
