import contains from './contains';

const MAX_FILE_SIZE = 500000000; // 500 MB
const MAX_IMAGE_SIZE = 25000000; // 25 MB

export const imageTypes = {
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpeg',
  '.png': 'image/png',
  '.svg': 'image/svg+xml'
};
export const videoTypes = {
  '.mpeg': 'video/mpeg',
  '.mp4': 'video/mp4',
  '.mpg': 'video/mpeg'
};
export const audioTypes = {
  '.mp3': 'audio/mpeg'
};
export const fileTypes = {
  '.pdf': 'application/pdf',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx':
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.rar': 'application/vnd.rar',
  '.txt': 'text/plain',
  '.xls': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.zip': 'application/zip',
  '.csv': 'text/csv',
  '.doc': 'application/msword',
  '.docx':
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.ics': 'text/calendar'
};
export const allMimeTypes = {
  ...fileTypes,
  ...audioTypes,
  ...videoTypes,
  ...imageTypes
};

export const validateImage = (file) => {
  const includeImage = contains(file.type, Object.values(imageTypes));
  if (!includeImage) {
    const message = 'Wrong file type - JPEG, PNG, or SVG';
    return { valid: false, message };
  }
  if (file.size > MAX_IMAGE_SIZE) {
    const message = 'Image is loo large. Max Image size: 25 MB';
    return { valid: false, message };
  }
  return { valid: true };
};
export const validateAsset = (file) => {
  const includeType = contains(file.type, Object.values(allMimeTypes));
  if (!includeType) {
    const message =
      'Wrong file type - Accepted types: JPEG, PNG, DOC, PDF, SVG, MP4, MPEG';
    return { valid: false, message };
  }
  if (file.size > MAX_FILE_SIZE) {
    const message = 'File is loo large. Max file size: 500 MB';
    return { valid: false, message };
  }
  return { valid: true };
};

export const validateFile = (file) => {
  const includeType = contains(file.type, Object.values(fileTypes));
  if (!includeType) {
    const message =
      'Incompatible file. The file you selected may be unsafe or corrupt.';
    return { valid: false, message };
  }
  if (file.size > MAX_FILE_SIZE) {
    const message = 'File is loo large. Max file size: 500 MB';
    return { valid: false, message };
  }
  return { valid: true };
};

export const getExtension = (fileString) => {
  const extensionWithParams = `${fileString}`.split('.').pop();
  const subStringEnd = extensionWithParams.indexOf('?');
  return extensionWithParams
    .substring(
      0,
      subStringEnd === -1 ? extensionWithParams.length : subStringEnd
    )
    .toLowerCase();
};
