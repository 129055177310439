import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../components/Page';
import { GlobalState } from '../../../reducers/reducers';
import ManageComments from './ManageComments';
import RefreshLoader from '../../../components/RefreshLoader/RefreshLoader';
import { selectActiveUser } from '../../../selectors/userSelectors';
import { track } from '../../../services/trackingService';
import { getComments } from '../../../actions/commentActions';
import './BloggingManagement.scss';

export const BloggingManagement = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoad, setInitialLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { orgId: activeOrgId } = useSelector((state: GlobalState) =>
    selectActiveUser(state)
  );

  const requestData = useCallback(async () => {
    setLoading(true);
    if (!activeOrgId) return;
    await Promise.all([dispatch(getComments())]);
    setLoading(false);
    setInitialLoading(false);
  }, [dispatch, activeOrgId]);

  useEffect(() => {
    track({ context: 33, typeId: 1 });
  }, []);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const refresh: boolean = useSelector(
    (state: GlobalState) => state.nav.elements.allIds.length < 1 && loading
  );

  return (
    <>
      <RefreshLoader visible={refresh} />
      <Layout id='blogging-management'>
        <ManageComments initialLoad={initialLoad} orgId={activeOrgId} />
      </Layout>
    </>
  );
};

export default BloggingManagement;
