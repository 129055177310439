import React from 'react';
import FullscreenRedirect from './components/FullscreenRedirect/FullscreenRedirect';

interface Props {}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.error(`error`, error, info);
    this.setState({ hasError: true });
  }

  goHome = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <FullscreenRedirect onHomeClick={this.goHome}>
          <h3>Oops! Something unexpected happened </h3>
        </FullscreenRedirect>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
