import React from 'react';

const LogoSmall = () => {
  return (
    <div className='icon logo-small'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        viewBox='0 0 72 72'
        enableBackground='new 0 0 72 72'
        xmlSpace='preserve'
      >
        <path
          fill='#F98A3C'
          className='st0'
          d='M71,17.5C66.5,6.1,46.6,5.7,39.5,20c-0.1,0.2-0.1,0.3-0.2,0.4c-0.1-0.2-0.2-0.4-0.2-0.3  C30.2,7.6,10.4,5.8,2.5,18.7C-5,31,5.4,44.1,17.9,40.9c6.8-1.8,10.6-10.6,7.1-16.7c8.6,10-3.7,19.5-6.5,20.1  c3.4,1.1,11.2,1.4,14.3-2.9c-1.8,1.2-5.9,1.6-7.7,0.9C36.7,32,27.9,15.5,14.5,17.6c0,0,2.8,1.2,3.6,4.4c-5.9-0.2-10.2,5-8.6,9  c1,2.6,3.8,3.5,6.4,2.7c5.8-1.7,7.9-10.5,2.4-14.6c3.7,1.3,6,5.9,5.7,10.6c-0.3,3.3-2.6,6.6-5.8,8.2C10.3,41.7,0.2,34,3.5,23.5  C8,9,37.5,9.3,39.3,35.1C37,36,35,33.7,32.4,34.3c1.8,1.4,1.8,4.5,3,4.7c6.8,0.9,13.9,13.4,11.8,19.9c-2.4,1.1-7.8-0.9-9.5-2.2  c-3.8-2.8-7.2-8.7-10.1-10c-1.6-0.7-2.7-0.4-2.7-0.4c0.9,0.2,4.6,5.5,6.8,8.5c1,1.4,7.3,8.2,9.9,8.2c3.2,0,7.6,0.2,8.9-0.2  c4-1.2-0.3-19.6,1.8-23.9c2.9-2.5,1.5-3.8,0.9-4.2c-0.8-0.6-3.1-3.2-3.5-5.8c-0.4-2.6,0.5-7.4,3.1-9C49.2,26,53,32,58.2,33.3  C67.1,35.5,74.8,27,71,17.5z M56.7,31c-2.3-1.5-3.8-4.2-3.7-6.8c0.3-4.2,3-7.2,6.1-7.8c-5.4,2.5-4.9,9.9-0.6,11.6  c1.8,0.7,4.6,0.3,6-2.2c1.8-3.3-1-7-5.4-7.2c0.9-1.6,2-2.5,3.4-3.3c-6.3-1.7-15.9,1-18.6,10.4c-0.3-4.1,3-10.6,9-12.8  c7-2.6,16.2-0.1,17.1,8.9C70.7,30.3,62.3,34.6,56.7,31z'
        />
      </svg>
    </div>
  );
};

export default LogoSmall;
