interface AnyObject {
  [key: string]: any;
}

// Searches an array of objects by the string values of given keys.
export const searchCollection = (
  query: string | string[],
  array: AnyObject[] = [],
  keys: string[] = [],
  condition?: (AnyObject) => boolean
): AnyObject[] => {
  return array.filter((item) => {
    let stringToMatch = '';
    for (let i = 0; i < keys.length; i++) {
      if (item[keys[i]] && typeof item[keys[i]] === 'string') {
        stringToMatch += item[keys[i]];
        stringToMatch += ' ';
      }
    }

    stringToMatch = stringToMatch.toLowerCase();

    if (!(query instanceof Array)) {
      query = query.split(' ');
    }

    const match = query.every((query) =>
      stringToMatch.includes(query.toLowerCase().trim())
    );

    if (condition) {
      return match && condition(item);
    }
    return match;
  });
};

export const searchCollectionById = (
  query: number | string | Array<string | number>,
  array: AnyObject[] = [],
  key: string,
  inclusive?: boolean
) => {
  if (!query) return array;
  return array.filter((item) => {
    let match;
    if (query instanceof Array) {
      match = inclusive
        ? query.some((id) => {
            if (typeof id === 'string' && isNaN(parseInt(id))) return false;
            return checkMatch(item, key, id);
          })
        : query.every((id) => {
            if (typeof id === 'string' && isNaN(parseInt(id))) return false;
            return checkMatch(item, key, id);
          });
    } else {
      if (typeof query === 'string' && isNaN(parseInt(query))) return false;
      match = checkMatch(item, key, query);
    }
    return match;
  });
};

const checkMatch = (item, key, query) => {
  let match = false;
  if (item[key] && item[key] instanceof Array) {
    match = item[key].includes(query);
  } else {
    match = item[key] === query;
  }
  return match;
};

export default searchCollection;
