import React from 'react';
import Page from '../../components/Page';

/**  
URL: /news
NAME: News
AKA:
**/

const News = () => {
  return <Page id='News' isLibrary dmId={2} centerContent pageWidth='medium' />;
};

export default News;
