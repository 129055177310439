import * as constants from './types/helpActionTypes';
import * as helpService from '../services/helpService';
import { HelpObject, HelpSearchParams } from '../types/help';
import { Dispatch } from 'redux';
import { ResponseData } from '../App.d';
import { setNotification } from './notifActions';
import { track } from '../services/trackingService';

export const setHelpList = (list: Array<HelpObject>) => ({
  type: constants.GETALL_HELP_SUCCESS,
  payload: list
});

export const setDeletedHelpItem = (id: number) => ({
  type: constants.DELETE_HELP_ITEM,
  payload: id
});

export const removeHelpItem =
  (helpId: number) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    try {
      const responseData: ResponseData =
        await helpService.removeHelpItemRequest(helpId);
      dispatch(setDeletedHelpItem(helpId));
      return responseData;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

export const setHelpOpen = (state: boolean) => {
  if (state) {
    track({ context: 'help_center', typeId: 1 });
    return { type: constants.OPEN_HELP_MODAL, payload: state };
  } else {
    return { type: constants.CLOSE_HELP_MODAL, payload: state };
  }
};

export const getHelpList =
  (data: HelpSearchParams = {}) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: constants.GETALL_HELP_REQUEST });
    try {
      const responseData: ResponseData = await helpService.searchHelpRequest(
        data
      );
      const helpList: Array<HelpObject> = responseData.response;
      dispatch(setHelpList(helpList));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: constants.GETALL_HELP_FAILURE, error });
      throw error;
    }
  };

export const updateHelpList = (newHelp: HelpObject) => ({
  type: constants.HELP_UPDATE_SUCCESS,
  payload: newHelp
});

// TODO: actions and reducers for one offs instead of reusing "ALL"
export const createHelpDirectory =
  (data) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    try {
      const responseData = await helpService.createHelpDirectoryRequest(data);
      dispatch(updateHelpList(responseData.response));
      dispatch(
        setNotification({
          type: 'success',
          message: 'Successfully added directory.'
        })
      );
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: constants.GETALL_HELP_FAILURE, error });
      throw error;
    }
  };

export const updateHelpDirectory =
  (data) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    try {
      const responseData = await helpService.updateHelpDirectoryRequest(data);
      dispatch(updateHelpList(responseData.response));
      dispatch(
        setNotification({
          type: 'success',
          message: 'Successfully updated directory.'
        })
      );
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: constants.GETALL_HELP_FAILURE, error });
      throw error;
    }
  };

export const updateHelpPage =
  (data) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    try {
      const responseData = await helpService.updateHelpPageRequest(data);
      dispatch(updateHelpList(responseData.response));
      dispatch(
        setNotification({
          type: 'success',
          message: 'Successfully updated help page.'
        })
      );
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: constants.GETALL_HELP_FAILURE, error });
      throw error;
    }
  };

// TODO: Dispatch store changes for assets
export const updateHelpAssets =
  (helpId: number | string, assets: any[]) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    try {
      const reqBody = { helpId, assets };
      const responseData = await helpService.createAssetsRequest(reqBody);
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: constants.GETALL_HELP_FAILURE, error });
      throw error;
    }
  };
