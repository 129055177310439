import { USER_ENDPOINT } from './endpoints';
import axios from 'axios';
import { ResponseData } from '../App.d';
import { handleServiceError } from '.';

export const searchAlertsRequest = async (data): Promise<ResponseData> => {
  return axios
    .post(USER_ENDPOINT, { action: 'SEARCH_ALERTS', data })
    .then((response) => {
      return response.data;
    })
    .catch(handleServiceError);
};
