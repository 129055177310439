import * as constants from '../actions/types/helpActionTypes';
import { HelpObject } from '../types/help';
import { HelpState } from './reducers.d';
import { Action } from '../App.d';

const initialState: HelpState = {
  activeHelp: 0,
  open: false,
  allHelp: {
    byId: {},
    allIds: [],
    isRequesting: false
  },
  error: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: Action) => {
  switch (action.type) {
    case constants.OPEN_HELP_MODAL:
      return {
        ...state,
        open: true
      };
    case constants.CLOSE_HELP_MODAL:
      return {
        ...state,
        open: false
      };
    case constants.GETALL_HELP_REQUEST:
      return {
        ...state,
        allHelp: {
          ...state.allHelp,
          isRequesting: true
        }
      };
    case constants.GETALL_HELP_SUCCESS: {
      const { payload = [] } = action;

      const allIds = payload.map((help: HelpObject) => help.helpId);
      const byId = payload.reduce(
        (carry: object, value: HelpObject) => ({
          ...carry,
          [value.helpId]: value
        }),
        {}
      );
      return {
        ...state,
        allHelp: {
          allIds,
          byId,
          isRequesting: false
        }
      };
    }
    case constants.GETALL_HELP_FAILURE:
      return {
        ...state,
        allHelp: { ...state.allHelp, isRequesting: false },
        error: action.error
      };
    case constants.HELP_UPDATE_SUCCESS: {
      const { payload = {} } = action;
      if (Object.keys(payload).length === 0)
        return {
          ...state,
          allHelp: {
            ...state.allHelp,
            isRequesting: false
          }
        };
      const { helpId } = payload;
      if (state.allHelp.allIds.includes(helpId)) {
        const byId = { ...state.allHelp.byId };
        byId[helpId] = payload;
        return {
          ...state,
          allHelp: {
            ...state.allHelp,
            byId,
            isRequesting: false
          }
        };
      } else {
        const byId = { ...state.allHelp.byId, [helpId]: payload };
        const allIds = [helpId, ...state.allHelp.allIds];
        return {
          ...state,
          allHelp: {
            byId,
            allIds,
            isRequesting: false
          }
        };
      }
    }
    case constants.DELETE_HELP_ITEM: {
      const newState = {
        ...state,
        allHelp: {
          allIds: state.allHelp.allIds.filter((id) => id !== action.payload),
          byId: state.allHelp.byId
        }
      };
      delete newState.allHelp.byId[action.payload];
      return newState;
    }
    default:
      return state;
  }
};
