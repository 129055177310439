import { FILE_MANAGER_ENDPOINT } from './endpoints';
import axios from 'axios';
import { handleServiceError } from '.';
import { ResponseData } from '../App.d';

enum FileManagerActions {
  get = 'GET_OBJECT',
  addFile = 'ADD_FILE',
  addDir = 'ADD_DIR',
  delete = 'DELETE_OBJECT',
  list = 'LIST_CONTENTS'
}

// TODO: Universal file and folder name sanitization that is both OS and S3 compliant. Goodluck.

export const fetchFileMetaRequest = async (
  path: string
): Promise<ResponseData> => {
  return axios
    .post(FILE_MANAGER_ENDPOINT, {
      action: FileManagerActions.get
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const createFMDirectoryRequest = async (
  path: string
): Promise<ResponseData> => {
  return axios
    .post(FILE_MANAGER_ENDPOINT, {
      action: FileManagerActions.addDir,
      data: { path: path.replaceAll(' ', '_') }
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const createFMFileRequest = async (
  path: string,
  tempPath: string
): Promise<ResponseData> => {
  return axios
    .post(FILE_MANAGER_ENDPOINT, {
      action: FileManagerActions.addFile,
      data: { tempPath, path: path.replaceAll(' ', '_') }
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const listFMDirectoryRequest = async (
  path: string
): Promise<ResponseData> => {
  return axios
    .post(FILE_MANAGER_ENDPOINT, {
      action: FileManagerActions.list,
      data: { path: path.replaceAll(' ', '_') }
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const deleteFMObjectRequest = async (
  path: string
): Promise<ResponseData> => {
  return axios
    .post(FILE_MANAGER_ENDPOINT, {
      action: FileManagerActions.delete,
      data: { path: path.replaceAll(' ', '_') }
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};
