import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PanelType } from './reducers/reducers.d';
import { UIRoutes } from '../constants/routes';
import { setSidePanelState } from './actions/uiActions';
import { selectActiveUser } from './selectors/userSelectors';
import { useQuery } from './hooks';

const UINavigator = () => {
  const query = useQuery();

  const dispatch = useDispatch();

  const { userId, name } = useSelector(selectActiveUser);

  useEffect(() => {
    const queryString = query.get('ui');
    const profileString = query.get('userId');
    switch (queryString) {
      case UIRoutes.editProfile: {
        dispatch(
          setSidePanelState({
            panelType: PanelType.profileEdit,
            title: name,
            userId
          })
        );
        break;
      }
      case UIRoutes.profile: {
        dispatch(
          setSidePanelState({
            panelType: PanelType.profile,
            title: name,
            userId: profileString || userId
          })
        );
        break;
      }
      case UIRoutes.alerts: {
        dispatch(
          setSidePanelState({
            panelType: PanelType.alerts
          })
        );
        break;
      }
      default: {
        dispatch(
          setSidePanelState({
            panelType: PanelType.closed,
            title: '',
            userId: 0
          })
        );
      }
    }
  }, [dispatch, query, name, userId]);
  return <></>;
};

export default UINavigator;
