enum moduleActionTypes {
  getModuleFiltersRequest = 'GET_MODULE_FILTERS_REQUEST',
  getModuleFiltersSuccess = 'GET_MODULE_FILTERS_SUCCESS',
  getModuleFiltersFailure = 'GET_MODULE_FILTERS_FAILURE',
  searchItemsRequest = 'SEARCH_ITEMS_REQUEST',
  searchItemsSuccess = 'SEARCH_ITEMS_SUCCESS',
  searchItemsFailure = 'SEARCH_ITEMS_FAILURE'
}

export default moduleActionTypes;
