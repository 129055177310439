import axios from 'axios';
import { HELP_ENDPOINT, SORT_ENDPOINT } from './endpoints';
import { RequestSort, ResponseData } from '../App.d';
import { handleServiceError } from './index';

export const searchHelpRequest = async (data: any): Promise<ResponseData> => {
  return axios
    .post(HELP_ENDPOINT, { action: 'SEARCH_HELP', data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const createHelpDirectoryRequest = async (
  data: any
): Promise<ResponseData> => {
  return axios
    .post(HELP_ENDPOINT, { action: 'NEW_DIRECTORY_HELP', data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const updateHelpDirectoryRequest = async (
  data: any
): Promise<ResponseData> => {
  return axios
    .post(HELP_ENDPOINT, { action: 'UPDATE_DIRECTORY_HELP', data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const updateHelpPageRequest = async (
  data: any
): Promise<ResponseData> => {
  return axios
    .post(HELP_ENDPOINT, { action: 'UPDATE_PAGE_HELP', data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const getHelpAssetsRequest = async (
  helpId: number
): Promise<ResponseData> => {
  return axios
    .post(HELP_ENDPOINT, { action: 'GET_HELP_ASSETS', data: { helpId } })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const createAssetsRequest = async (data: any): Promise<ResponseData> => {
  return axios
    .post(HELP_ENDPOINT, { action: 'UPDATE_HELP_ASSETS', data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const helpSortRequest: RequestSort = async (ids: number[]) => {
  return axios
    .post(SORT_ENDPOINT, { table: 'help', ids })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const createGuidedTourStepRequest = async (
  data: any
): Promise<ResponseData> => {
  return axios
    .post(HELP_ENDPOINT, { action: 'NEW_GUIDED_HELP', data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const updateGuidedTourStepRequest = async (
  data: any
): Promise<ResponseData> => {
  return axios
    .post(HELP_ENDPOINT, { action: 'UPDATE_GUIDED_HELP', data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const removeHelpItemRequest = async (
  helpId: number
): Promise<ResponseData> => {
  return axios
    .post(HELP_ENDPOINT, { action: 'DELETE_HELP', data: { helpId } })
    .then((response) => response.data)
    .catch(handleServiceError);
};
