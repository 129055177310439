import { Action } from '../App.d';
import { InterruptState } from './reducers.d';

const initialState: InterruptState = {};

const interruptReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_INTERRUPT': {
      const interrupts = action.payload;
      const interruptData = {};
      for (const interrupt of interrupts) {
        interruptData[interrupt.type] = interrupt.data;
      }

      return {
        ...state,
        ...interruptData
      };
    }
    case 'REMOVE_INTERRUPT': {
      const interruptState = { ...state };
      delete interruptState[action.payload];
      return interruptState;
    }
    default:
      return state;
  }
};

export default interruptReducer;
