import { PropsWithChildren } from 'hoist-non-react-statics/node_modules/@types/react';
import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectGuidedTourStatus } from '../../selectors';
import { TourState } from '../../reducers/reducers.d';
import { guidedTourElId } from '../../utils';

interface Props {
  id: string | number;
}

const GuidedTourSelectableElement = ({
  id,
  children
}: PropsWithChildren<Props>) => {
  const tourStatus = useSelector(selectGuidedTourStatus);
  const selectableClass = classNames('guided-tour-selectable', {
    '--no-click': tourStatus === TourState.edit
  });
  return tourStatus !== TourState.inactive ? (
    <div className={selectableClass}>
      <div className='guided-tour-clickable' id={guidedTourElId(id)} />
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export default GuidedTourSelectableElement;
