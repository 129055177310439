export const getStorageItem = (key: string) => {
  const now = new Date();
  const storage = JSON.parse(window.localStorage.getItem(key) || 'null');
  if (storage === null) {
    throw new Error(`No localStorage item found at ${key}.`);
  } else if (storage.expiration && storage.expiration < now.toISOString()) {
    throw new Error(`localStorage item at ${key} is expired.`);
  } else {
    return storage.data ? storage.data : storage;
  }
};

export const setStorageItem = (
  key: string,
  data: any,
  expiration: string = ''
) => {
  try {
    if (typeof data === 'object') {
      window.localStorage.setItem(
        key,
        JSON.stringify({
          expiration,
          data
        })
      );
    } else {
      window.localStorage.setItem(key, JSON.stringify(data));
    }
  } catch (e) {
    console.error(e);
  }
};
