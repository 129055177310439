import React, { ReactNode, useEffect, Suspense, lazy, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { throttle } from 'lodash';

// Views and Components
import Home from './pages/HomeDashboard';
import EnhancedDirectory from './pages/EnhancedDirectory/EnhancedDirectory';
import GlobalLoading from './components/GlobalLoading/GlobalLoading';
import HelpCenter from './components/HelpCenter/HelpModal';
import Interrupts from './components/Interrupts/Interrupts';
import ScrollToTop from './ScrollToTop';
import NoMobileSettings from './components/NoMobileSettings/NoMobileSettings';
import SideNav from './components/SideNavigation';
import GetDocument from './components/GetDocument/GetDocument';
import NavigationControl from './components/NavigationControl/NavigationControl';
import FileManager from './components/FileManager';
import GlobalSearch from './components/GlobalSearch';
import FilePickup from './pages/FilePickup';
import PrivateRoute from './Routes/PrivateRoute';
import Settings from './pages/settings/SettingsHub/Settings';
import SidePanel from './components/SidePanel';
import Quicksight from './pages/Quicksight';
import Dashboards from './components/Dashboards';
import Page from './components/Page';
import NotFound from './components/Error/NotFound';
import UINavigator from './UINavigator';

// Utils, types, functions
import { GlobalState } from './reducers/reducers.d';
import { getNavInfo } from './actions/navActions';
import { searchAlerts } from './actions/alertsActions';
import { getStaticData } from './actions/staticActions';
import { getUsers } from './actions/userActions';
import { getHelpList } from './actions/helpAction';
import { privilege } from './Routes/constants';
import { fetchFeatures } from './actions/featuresActions';
import { Routes } from '../constants/routes';
import { setSideNavOpen } from './actions/uiActions';
import { selectSideNavOpen } from './selectors';
import BloggingManagement from './pages/settings/BloggingManagement';
import DocumentModal from './components/DocumentModal';
import TermsManagement from './pages/settings/TermsManagement';
import ARC from './pages/ARC';
import ResourceLibrary from './pages/ResourceLibrary';
import OktaApps from './pages/OktaApps';

// Lazy loading all management pages
const GuidedTourManagement = lazy(
  () => import('./components/GuidedTour/GuidedTourManagement')
);

const UserNotificationManagement = lazy(
  () => import('./pages/settings/NotificationManagement')
);

const Bedrock = lazy(
  () => import('./pages/Bedrock')
);

const CompanyScores = lazy(
  () => import('./pages/CompanyScores')
);

const DocumentManagerManagement = lazy(
  () => import('./pages/settings/DocumentManager')
);

const UserManagement = lazy(() => import('./pages/settings/UserManagement'));
const NavManagement = lazy(() => import('./pages/settings/NavManagement'));
const HelpManage = lazy(() => import('./pages/settings/HelpManagement'));

const FeatureManagement = lazy(
  () => import('./pages/settings/FeatureManagement')
);

const Parser = lazy(() => import('./pages/settings/Parser/Parsers'));

const DwManagement = lazy(() => import('./pages/settings/DwManagement'));

const useMobile = () => {
  const [mobile, setMobile] = useState<boolean>(window.innerWidth <= 767);

  const resize = () => {
    const mobileWidth = window.innerWidth <= 767;
    if (mobile !== mobileWidth) setMobile(mobileWidth);
  };

  const throttledResize = throttle(resize, 2000, {
    leading: true,
    trailing: true
  });

  useEffect(() => {
    window.addEventListener('resize', throttledResize);
    return () => {
      window.removeEventListener('resize', throttledResize);
    };
  }, [throttledResize]);

  return [mobile];
};

const AppRouter: ReactNode = (): ReactNode => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [initialLoad, setInitialLoad] = useState(false);
  const [mobile] = useMobile();
  const { privs: userPrivs } = useSelector((state: GlobalState) => {
    const activeUser = state.user.users.byId[state.user.activeUserId] || {};
    return activeUser;
  });

  // Nav elements with dmId will create their own Page
  const { allIds: navIds, byId: navsById } = useSelector(
    (state: GlobalState) => state.nav.elements
  );

  const navsWithDmId = navIds?.filter((id) => navsById[id].dmId !== null);

  const DynamicPagesFromDm = navsWithDmId.map((id: number) => {
    const pagePath = navsById[id].url;
    const PageComponent = () => (
      <Page
        isLibrary
        dmId={navsById[id].dmId}
        centerContent
        pageWidth='medium'
        key={id}
      />
    );
    if (navsById[id].privId !== null) {
      return (
        <PrivateRoute
          path={pagePath}
          component={PageComponent}
          privId={navsById[id].privId}
          key={id}
        />
      );
    } else {
      return <Route path={pagePath} component={PageComponent} key={id} />;
    }
  });

  const helpOpen = useSelector((state: GlobalState) => state.help.open);
  const sideNavOpen = useSelector(selectSideNavOpen);
  useEffect(() => {
    if (!userPrivs) dispatch({ type: 'LOADING_TRUE' });
    if (userPrivs) dispatch({ type: 'LOADING_FALSE' });
  }, [userPrivs, dispatch]);

  useEffect(() => {
    const initialDispatch = async () => {
      // Setting default nav items, then requesting for priv dependent ones.
      const navResponse = await dispatch(getNavInfo());
      setInitialLoad(true);
      await dispatch(getUsers());
      await dispatch(getHelpList());
      await dispatch(fetchFeatures());
      // Turned off for now TH 8/3/23
      // await dispatch(searchAlerts({}));
      // TODO: ResponseTyping
      const { buildTimestamp }: any = await navResponse;
      await dispatch(getStaticData(buildTimestamp));
    };
    try {
      initialDispatch();
    } catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  const handleSideNavOpen = () => {
    dispatch(setSideNavOpen(true));
  };
  const handleSideNavClose = () => {
    dispatch(setSideNavOpen(false));
  };

  return (
    <Suspense fallback={<GlobalLoading visible />}>
      <GlobalLoading />
      <Interrupts />
      <HelpCenter visible={helpOpen} />
      <ScrollToTop />
      <DocumentModal />
      <SidePanel />
      <GetDocument />
      <UINavigator />
      <div
        className='content-side-nav'
        style={{ paddingLeft: mobile ? 0 : sideNavOpen ? 325 : 70 }}
      >
        <NavigationControl />
        {!mobile && (
          <SideNav
            onSideNavOpen={handleSideNavOpen}
            onSideNavClose={handleSideNavClose}
          />
        )}
        <Suspense fallback={<GlobalLoading visible />}>
          {initialLoad && (
            <Switch>
              <Route exact path='/not-found' component={NotFound} />
              <Route>
                <Suspense
                  fallback={
                    <GlobalLoading visible className='content-fallback' />
                  }
                >
                  <main className='content-container'>
                    <Switch>
                      {mobile && (
                        <Route
                          path={['/settings', '/favorites_management']}
                          component={NoMobileSettings}
                        />
                      )}
                      {/* {DynamicPagesFromDm} */}
                      <Route path='/news' component={ARC} />
                      <Route path='/apps' component={OktaApps} />
                      <Route
                        path='/resource-library'
                        component={ResourceLibrary}
                      />
                      <Route path={Routes.filePickup} component={FilePickup} />
                      <Route
                        path={Routes.enhancedDirectory}
                        component={EnhancedDirectory}
                      />
                      <Route
                        path={Routes.globalSearch}
                        component={GlobalSearch}
                      />

                      <Route
                        path={Routes.companyScores}
                        component={CompanyScores}
                      />
                      <PrivateRoute
                        path={Routes.bedrock}
                        component={Bedrock}
                        privId={privilege.bedrock}
                      />
                      <PrivateRoute
                        path={Routes.userManagement}
                        component={UserManagement}
                        privId={privilege.userManagement}
                      />
                      <PrivateRoute
                        path={Routes.navManagement}
                        component={NavManagement}
                        privId={privilege.navManagement}
                      />
                      <PrivateRoute
                        path={Routes.helpManagement}
                        component={HelpManage}
                        privId={privilege.helpCenterManagement}
                      />
                      <PrivateRoute
                        path={Routes.parser}
                        component={Parser}
                        privId={privilege.parsers}
                      />
                      <PrivateRoute
                        path={Routes.dwStatus}
                        component={DwManagement}
                        privId={privilege.dwStatus}
                      />
                      <PrivateRoute
                        path={Routes.guidedTourManagement}
                        component={GuidedTourManagement}
                        privId={privilege.helpCenterManagement}
                      />
                      <PrivateRoute
                        path={Routes.featureManagement}
                        component={FeatureManagement}
                        privId={privilege.featuresManagement}
                      />
                      <PrivateRoute
                        path={Routes.notificationManagement}
                        component={UserNotificationManagement}
                        privId={privilege.notificationManagement}
                      />
                      <PrivateRoute
                        path={Routes.termsManagement}
                        component={TermsManagement}
                        privId={privilege.termsManagement}
                      />
                      <PrivateRoute
                        path={Routes.dmManagement}
                        component={DocumentManagerManagement}
                        privId={privilege.dmManagement}
                      />
                      <PrivateRoute
                        path={Routes.commentManagement}
                        component={BloggingManagement}
                        privId={1}
                      />
                      <PrivateRoute
                        path={Routes.biAccess}
                        component={Quicksight}
                        privId={privilege.quicksight}
                      />
                      <PrivateRoute
                        path={Routes.execDashboard + '/:dashboardId?'}
                        render={() => <Dashboards typeId={2} />}
                        privId={privilege.executiveDashboard}
                      />
                      <Route path={Routes.settings} component={Settings} />

                      {userPrivs && (
                        <Route
                          exact
                          path={['/', Routes.getDocument, '/profile/edit']}
                          component={Home}
                        />
                      )}
                      {userPrivs && userPrivs.includes(44) && (
                        <Route
                          path='/test-fm'
                          render={() => <FileManager standAlone />}
                        />
                      )}
                      {/* We are waiting until the users privs have loaded
                to determine if certain routes are accessible to that user.
                Some routes may be locked behind feature flags, but we do
                not want the app to route to /not-found until it has been
                determined that the user does not have that feature enabled
                for them. */}
                      {userPrivs && (
                        <Redirect
                          from='*'
                          to={{
                            pathname: '/not-found',
                            state: { referrer: location.pathname }
                          }}
                        />
                      )}
                    </Switch>
                  </main>
                </Suspense>
              </Route>
            </Switch>
          )}
        </Suspense>
      </div>
    </Suspense>
  );
};

export default AppRouter;
