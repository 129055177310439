import * as featuresActionTypes from './types/featuresActionTypes';
import { Dispatch } from 'redux';
import * as service from '../services/featuresService';
import { ResponseData } from '../App.d';
import { UpdateFeaturesBody } from '../types/features.d';

export const fetchFeatures = () => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  dispatch({ type: featuresActionTypes.FETCH_FEATURES_REQUEST });
  try {
    const responseData: ResponseData = await service.getFeatures();
    dispatch({
      type: featuresActionTypes.FETCH_FEATURES_SUCCESS,
      payload: responseData.response
    });
    return responseData;
  } catch (err: any) {
    console.error(err.message);
    dispatch({ type: featuresActionTypes.FETCH_FEATURES_FAILURE });
    throw err;
  }
};

export const updateFeatures = (features: UpdateFeaturesBody) => async (
  dispatch: Dispatch
): Promise<ResponseData> => {
  dispatch({ type: featuresActionTypes.UPDATE_FEATURES_REQUEST });
  try {
    const responseData: ResponseData = await service.putFeatures(features);
    dispatch({
      type: featuresActionTypes.UPDATE_FEATURES_SUCCESS,
      payload: responseData.response
    });
    return responseData;
  } catch (err: any) {
    console.error(err.message);
    dispatch({ type: featuresActionTypes.UPDATE_FEATURES_FAILURE });
    return err;
  }
};
