import axios from 'axios';

import { DM_ENDPOINT, DM_ASSET } from '../services/endpoints';
import {
  FetchDm,
  FetchDmElement,
  FetchDmFilter,
  FetchDmFilterItem,
  FetchDmGroup,
  FetchDmSearch,
  FetchDmTags,
  FetchElementTags,
  SearchDm,
  EditDm
} from '../App.d';
import { IDmElement, IDmFilter, IDmFilterItem, IDmGroup } from '../types/dm.d';
import { handleServiceError } from '.';
import * as RequestBodies from './types/dmRequestBodies';

export const searchDmsRequest: FetchDmSearch = async (
  data: SearchDm
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'SEARCH_DM',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const addDmRequest = async (data): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'ADD_DM',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const editDmRequest = async (data: EditDm): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'EDIT_DM',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const getTagsRequest: FetchDmTags = async (dmId): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'GET_TAGS',
      data: {
        dmId
      }
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const addTagRequest: FetchDmTags = async (data): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'ADD_TAG',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const getElementTagsRequest: FetchDm = async (data): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'GET_ELEMENT_TAGS',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const addElementTagRequest: FetchElementTags = async (
  data
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'ADD_ELEMENT_TAG',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const deleteElementTagRequest: FetchElementTags = async (
  data
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'DEL_ELEMENT_TAG',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const addElementRequest: FetchDmElement = async (
  data: IDmElement
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ASSET, {
      action: 'ADD_ELEMENT',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const editElementRequest: FetchDmElement = async (
  data: IDmElement
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ASSET, {
      action: 'EDIT_ELEMENT',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const deleteElementRequest = async (data: {
  elementId: number;
}): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'DEL_ELEMENT',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const getGroupsRequest: FetchDmGroup = async (
  data: IDmGroup
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'GET_GROUPS',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const addGroupRequest: FetchDmGroup = async (
  data: IDmGroup
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'ADD_GROUP',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const editGroupRequest: FetchDmGroup = async (
  data: IDmGroup
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'EDIT_GROUP',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const deleteGroupRequest: FetchDmGroup = async (
  data: IDmGroup
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'DEL_GROUP',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const getFiltersRequest: FetchDmFilter = async (
  data: IDmFilter
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'GET_FILTERS',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const addFilterRequest: FetchDmFilter = async (
  data: IDmFilter
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'ADD_FILTER',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const editFilterRequest: FetchDmFilter = async (
  data: IDmFilter
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'EDIT_FILTER',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const deleteFilterRequest: FetchDmFilter = async (
  data: IDmFilter
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'DEL_FILTER',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const getFilterItemsRequest: FetchDmFilterItem = async (
  data: IDmFilterItem
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'GET_FILTER_ITEMS',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const addFilterItemRequest: FetchDmFilterItem = async (
  data: IDmFilterItem
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'ADD_FILTER_ITEM',
      data
    });
    return response;
  } catch (err: any) {
    console.error(`error`, err);
    handleServiceError(err);
  }
};

export const editFilterItemRequest: FetchDmFilterItem = async (
  data: IDmFilterItem
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'EDIT_FILTER_ITEM',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const deleteFilterItemRequest: FetchDmFilterItem = async (
  data: IDmFilterItem
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'DEL_FILTER_ITEM',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const setElementExportRequest: FetchDmFilterItem = async (
  data: IDmFilterItem
): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'SET_ELEMENT_EXPORT',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const getOrgExports = async (): Promise<any> => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'GET_ORG_EXPORTS'
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const exportElementRequest = () => {};

export const shareElementRequest = async (
  data: RequestBodies.ShareElementRequestBody
) => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'SHARE_ELEMENT',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const toggleLikeRequest = async (
  data: RequestBodies.ToggleLikeRequestBody
) => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'TOGGLE_LIKE',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const getCommentsRequest = async (elementIds: Number[]) => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'GET_COMMENTS',
      data: {
        elementIds
      }
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const addCommentRequest = async (
  data: RequestBodies.AddCommentRequestBody
) => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'CREATE_COMMENT',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const updateCommentRequest = async (
  data: RequestBodies.UpdateCommentRequestBody
) => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'UPDATE_COMMENT',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const toggleCommentLikeRequest = async (
  data: RequestBodies.ToggleCommentLikeRequestBody
) => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'TOGGLE_COMMENT_LIKE',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};

export const toggleElementStatusRequest = async (
  data: RequestBodies.ToggleElementStatusRequestBody
) => {
  try {
    const { data: response } = await axios.post(DM_ENDPOINT, {
      action: 'TOGGLE_ELEMENT_STATUS',
      data
    });
    return response;
  } catch (err: any) {
    handleServiceError(err);
  }
};
