import React from 'react';

type Props = {
  content: string | undefined;
  className?: string;
};

const Html = ({ content, className }: Props) => {
  const formattedContentString = content?.replace('<p><br></p>', '<br>') || '';
  return content ? (
    <>
      <div
        className={'custom-html-wrapper ' + (className && className)}
        dangerouslySetInnerHTML={{ __html: formattedContentString }}
      />
    </>
  ) : (
    <div className='error'>
      <p />
    </div>
  );
};

export default Html;
