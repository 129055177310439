import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfilePhoto, Button, Edit } from '@ovis-technologies/ovis-blueprint';
import { useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { selectAllOrgs } from '../../selectors';
import { selectStates } from '../../selectors/staticSelectors';
import {
  selectActiveUser,
  selectUserData
} from '../../selectors/userSelectors';
import { GlobalState } from '../../reducers/reducers';
import { User } from '../../App.d';
import ChangePassword from '../../components/ChangePassword';

import './user-profile.scss';
import CopyBearerToken from './CopyBearerToken';
import FlexContainer from '../../components/FlexContainer';
import Html from '../../components/HTMLBuilder/HtmlBuilder';

import { setSidePanelState } from '../../actions/uiActions';
import { PanelType } from '../../reducers/reducers.d';

const UserProfileStatic = () => {
  // Hooks
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const { pathname } = useLocation();

  // Selectors
  const activeUser: User = useSelector((state: GlobalState) => {
    const { userId } = state.ui.sidePanel;
    if (userId) {
      return selectUserData(state, userId);
    } else {
      return selectActiveUser(state);
    }
  });

  const handleLogout = () => {
    window.localStorage.removeItem('auto-launch');
    oktaAuth.tokenManager.clear();
    oktaAuth.signOut({
      postLogoutRedirectUri: pathname
    });
  };

  const handleSetProfileEdit = () => {
    dispatch(
      setSidePanelState({
        title: 'Edit Profile',
        panelType: PanelType.profileEdit
      })
    );
  };

  const activeUserId = useSelector(
    (state: GlobalState) => state.user.activeUserId
  );
  const { byId: orgsById } = useSelector(selectAllOrgs);
  const { byId: statesById } = useSelector(selectStates);

  let {
    photo,
    address1 = '',
    address2 = '',
    orgId,
    city = '',
    stateId = 0,
    officePhone = '',
    cellPhone = '',
    email,
    createdStampFormat,
    // linkedIn,
    zip = '',
    title = '',
    bio = '',
    fname,
    userId
  } = activeUser || {};
  const state = statesById[stateId]?.name || '';
  const isActiveUser = activeUserId === userId;
  photo = photo === '' ? '/assets/user.png' : photo;
  return (
    <div className='user-profile'>
      <ProfilePhoto size='large' source={photo} />
      {isActiveUser ? (
        <h3 className='section-title'>Your Account</h3>
      ) : (
        <h3 className='section-title'>User Info</h3>
      )}

      <div className='wrapper'>
        <FlexContainer align='center'>
          <p className='label'>Member Since:</p>
          <p> {createdStampFormat}</p>
        </FlexContainer>
        <FlexContainer align='center'>
          <p className='label'>Email:</p>
          <p>{email}</p>
        </FlexContainer>
        {isActiveUser && (
          <FlexContainer classname='edit-profile_btns-container'>
            <ChangePassword />
            <Button
              styleType='default'
              size='fill'
              className='edit-profile_btn'
              onClick={handleSetProfileEdit}
            >
              Edit Profile <Edit />
            </Button>
            <CopyBearerToken />
            <Button
              onClick={handleLogout}
              styleType='warning'
              size='fill'
              content='Sign Out'
            />
          </FlexContainer>
        )}
      </div>
      <h3 className='section-title'>Personal Info</h3>
      <div className='wrapper'>
        <FlexContainer align='center'>
          {' '}
          <p className='label'>Title:</p>
          <p>{title}</p>
        </FlexContainer>
        <FlexContainer align='center'>
          <p className='label'>Organization:</p>
          <p>{orgsById[orgId]?.name}</p>
        </FlexContainer>
        {!isActiveUser && (
          <FlexContainer align='center'>
            <p className='label'>Location:</p>
            <p>
              {city && `${city},`} {state}
            </p>
          </FlexContainer>
        )}
        {isActiveUser && (
          <div className='user-profile_row'>
            <p className='label'>Address:</p>
            <p>{address1}</p>
            {address2 && <p>{address2}</p>}
            <p>
              {city}, {state} {zip}
            </p>
          </div>
        )}

        <FlexContainer align='center' classname='user-profile_phone'>
          <div>
            <p className='label'>Office Phone:</p>
            <p>{officePhone}</p>
          </div>
          {cellPhone && (
            <div>
              <p className='label'>Cell Phone:</p>
              <p>{cellPhone}</p>
            </div>
          )}
        </FlexContainer>
      </div>
      <h3 className='section-title'>About {isActiveUser ? 'You' : fname}</h3>
      <div className='wrapper'>
        {bio.length > 0 ? (
          <Html content={bio} />
        ) : (
          <p>This user hasn't added a biography yet.</p>
        )}
      </div>
    </div>
  );
};

export default UserProfileStatic;
