export enum guidedTourAction {
  inactive = 'GUIDED_TOUR_INACTIVE',
  active = 'GUIDED_TOUR_ACTIVE',
  edit = 'GUIDED_TOUR_EDIT',
  preview = 'GUIDED_TOUR_PREVIEW',
  setTourElementTarget = 'SET_TOUR_ELEMENT_TARGET',
  setTourEditId = 'SET_TOUR_EDIT_ID'
}

export enum modalActions {
  openModal = 'OPEN_MODAL',
  closeModal = 'CLOSE_MODAL'
}

export enum sideNavActions {
  openSideNav = 'OPEN_SIDE_NAV',
  closeSideNav = 'CLOSE_SIDE_NAV'
}

export const OVERRIDE_FEATURE = 'OVERRIDE_FEATURE';
export const OPEN_DIRECTORY = 'OPEN_DIRECTORY';
export const CLOSE_DIRECTORY = 'CLOSE_DIRECTORY';

export const OPEN_ALERTS = 'OPEN_ALERTS';
export const CLOSE_ALERTS = 'CLOSE_ALERTS';

export const OPEN_OKTA = 'OPEN_OKTA';
export const CLOSE_OKTA = 'CLOSE_OKTA';

export const OPEN_EDIT_MODE = 'OPEN_EDIT_MODE';
export const CLOSE_EDIT_MODE = 'CLOSE_EDIT_MODE';

export const OPEN_DOCUMENT_MODAL = 'OPEN_DOCUMENT_MODAL';
export const CLOSE_DOCUMENT_MODAL = 'CLOSE_DOCUMENT_MODAL';

export const OPEN_SIDE_PANEL = 'OPEN_SIDE_PANEL';
export const CLOSE_SIDE_PANEL = 'CLOSE_SIDE_PANEL';
