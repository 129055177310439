import { union } from 'lodash';
import * as types from '../actions/types/navActionTypes';
import { Action, NavDepartment, NavDeparmentDictionary } from '../App.d';
import { NavState } from './reducers.d';

const initialState: NavState = {
  elements: {
    byId: {},
    allIds: [],
    gettingElements: false
  },
  groups: {
    byId: {},
    allIds: [],
    gettingGroups: false
  },
  links: {
    byId: {},
    allIds: [],
    gettingLinks: false
  },
  departments: {
    byId: {},
    allIds: [],
    gettingDepartments: false
  },
  orgConfig: {
    byId: {},
    allIds: [],
    gettingOrgConfig: false
  },
  orgConfigByNavId: {},
  navOrgMappings: [],
  error: null
};

const navReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case types.GET_NAV_REQUEST:
      return {
        ...state,
        elements: { ...state.elements, gettingElements: true }
      };
    case types.GET_NAV_SUCCESS: {
      const { payload = [] } = action;
      const allIds = payload.map((navEl: any) => {
        return navEl.navId;
      });
      const byId = payload.reduce((carry: object, value: any) => {
        return {
          ...carry,
          [value.navId]: value
        };
      }, {});
      return {
        ...state,
        elements: {
          allIds,
          byId,
          gettingElements: false
        }
      };
    }
    case types.GET_NAV_GROUPS_REQUEST:
      return { ...state, groups: { ...state.groups, gettingGroups: true } };
    case types.GET_NAV_GROUPS_SUCCESS: {
      const { payload = [] } = action;
      const allIds = payload.map((navEl: any) => navEl.navId);
      const byId = payload.reduce(
        (carry: object, value: any) => ({
          ...carry,
          [value.navId]: value
        }),
        {}
      );
      return {
        ...state,
        groups: {
          allIds,
          byId,
          gettingGroups: false
        }
      };
    }
    case types.GET_NAV_LINKS_REQUEST:
    case types.POST_NAV_LINKS_REQUEST:
      return { ...state, links: { ...state.links, gettingLinks: true } };
    case types.GET_NAV_LINKS_SUCCESS:
    case types.POST_NAV_LINKS_SUCCESS: {
      const { payload = [] } = action;
      const allIds = payload.map((navEl: any) => navEl.navId);
      const byId = payload.reduce(
        (carry: object, value: any) => ({ ...carry, [value.navId]: value }),
        {}
      );
      return {
        ...state,
        links: {
          allIds,
          byId,
          gettingLinks: false
        }
      };
    }
    case types.GET_NAV_DEPARTMENTS_REQUEST:
      return {
        ...state,
        departments: { ...state.departments, gettingDepartments: true }
      };
    case types.GET_NAV_DEPARTMENTS_SUCCESS: {
      const { payload = [] } = action;
      const departmentsById: NavDeparmentDictionary = {
        ...state.departments.byId
      };
      const departmentIds = payload.map((department: NavDepartment) => {
        departmentsById[department.navId] = department;
        return department.navId;
      });
      return {
        ...state,
        departments: {
          allIds: union(state.departments.allIds, departmentIds),
          byId: departmentsById,
          gettingDepartments: false
        }
      };
    }
    case types.DELETE_NAV_LINK_SUCCESS: {
      const allIds = state.links.allIds.filter((id) => id !== action.payload);
      const byId = { ...state.links.byId };
      delete byId[action.payload];
      return {
        ...state,
        links: {
          allIds,
          byId
        }
      };
    }
    case types.GET_NAV_ORG_REQUEST:
      return {
        ...state,
        orgConfig: { ...state.orgConfig, gettingOrgConfig: true }
      };
    case types.GET_ALL_NAV_ORG_REQUEST:
      return {
        ...state,
        navOrgMappings: action.payload
      };
    case types.GET_NAV_ORG_SUCCESS: {
      const { payload = [] } = action;
      const allIds = payload
        .map((navEl: any) => navEl.navId)
        .sort((a: number, b: number) => a - b);
      const byId = payload.reduce(
        (carry: object, value: any) => ({ ...carry, [value.navId]: value }),
        {}
      );
      return {
        ...state,
        orgConfig: {
          ...state.orgConfig,
          allIds,
          byId,
          gettingOrgConfig: false
        }
      };
    }

    case types.GET_NAV_ORG_BY_NAV_ID_SUCCESS: {
      const { payload = [] } = action;
      const { config = {}, navId } = payload;
      const orgsByNavId = config.reduce(
        (carry: object, value: any) => ({ ...carry, [value.orgId]: value }),
        {}
      );
      return {
        ...state,
        orgConfigByNavId: {
          ...state.orgConfigByNavId,
          [navId]: orgsByNavId
        }
      };
    }

    case types.GET_NAV_FAILURE:
      return {
        ...state,
        error: action.error,
        elements: { ...state.elements, gettingElements: false },
        group: { ...state.groups, gettingGroups: false },
        links: { ...state.links, gettingLinks: false },
        orgConfig: { ...state.orgConfig, gettingOrgConfig: false }
      };
    case types.GET_NAV_GROUPS_FAILURE:
      return {
        ...state,
        error: action.error,
        group: { ...state.groups, gettingGroups: false }
      };
    case types.GET_NAV_LINKS_FAILURE:
    case types.POST_NAV_LINKS_FAILURE:
      return {
        ...state,
        error: action.error,
        links: { ...state.links, gettingLinks: false }
      };
    case types.GET_NAV_DEPARTMENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        departments: { ...state.departments, gettingDepartments: false },
        elements: { ...state.elements, gettingElements: false }
      };
    case types.GET_NAV_ORG_FAILURE:
      return {
        ...state,
        error: action.error,
        orgConfig: { ...state.orgConfig, gettingOrgConfig: false }
      };
    case types.GET_NAV_ORG_BY_NAV_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        orgConfigByNavId: {
          ...state.orgConfigByNavId
        }
      };
    default:
      return state;
  }
};

export default navReducer;
