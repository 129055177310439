import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Layout as Grid,
  stripHTML,
  Card
} from '@ovis-technologies/ovis-blueprint';

import Layout from '../../../components/Page';
import { GlobalState } from '../../../reducers/reducers';

import './settings.scss';
import FlexContainer from '../../../components/FlexContainer';

/**  
URL: /settings
NAME: Settings
AKA:  Settings Hub
**/

const Settings = () => {
  const { settings, settingsTypeById, settingsTypeIds } = useSelector(
    (state: GlobalState) => {
      const { byId, allIds } = state.nav.elements;
      const { allIds: settingsTypeIds, byId: settingsTypeById } =
        state.static.settingsType;
      const settings = {};
      allIds.forEach((id) => {
        const { settingsTypeId } = byId[id];
        if (!settings[settingsTypeId]) {
          settings[settingsTypeId] = [byId[id]];
        } else {
          settings[settingsTypeId].push(byId[id]);
        }
      });
      return {
        settings,
        settingsTypeById,
        settingsTypeIds
      };
    }
  );

  return (
    <Layout className='settings-hub'>
      {settingsTypeIds.map((id) => {
        const { name, description } = settingsTypeById[id];
        const elements = settings[id];
        if (!elements) return <div key={id} />;
        return (
          <SettingsPanel
            description={description}
            name={name}
            key={id}
            elements={elements}
          />
        );
      })}
    </Layout>
  );
};

export default Settings;

const SettingsPanel = ({ description, name, elements }) => {
  return (
    <FlexContainer background>
      <div>
        <p className='component-subtitle'>{name}</p>
        <p>{description}</p>
      </div>
      <Grid layoutType='grid'>
        {elements.map((element) => {
          const { name, description, url } = element || {};
          const strippedDesc = stripHTML(description);
          return (
            <Link to={url} key={name}>
              <Card>
                <p className='document-title'>{name}</p>
                <p className='description-text'>{strippedDesc}</p>
              </Card>
            </Link>
          );
        })}
      </Grid>
    </FlexContainer>
  );
};
