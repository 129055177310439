export interface Feature {
  constant: string;
  statusId: number;
  description: string;
}

// Redux related Types
export enum FeatureOverrideState {
  none = 'No Override',
  off = 'Feature Off',
  on = 'Feature On'
}

export type FeatureOverride = Array<string, FeatureOverrideState>;

// Service related types
export interface UpdateFeaturesBody {
  [featureConstant: string]: number;
}
