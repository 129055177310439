import { STATIC_ACTION_TYPE } from '../actions/types/staticActionTypes';
import { Action } from '../App.d';
import { StaticState } from './reducers.d';
import createAllIds from '../utils/createAllIds';
import createById from '../utils/createById';

const initialState: StaticState = {
  notificationFrequency: {
    allIds: [],
    byId: {}
  },
  notificationAction: {
    allIds: [],
    byId: {}
  },
  privs: {
    allIds: [],
    byId: {}
  },
  orgStatus: {
    allIds: [],
    byId: {}
  },
  orgType: {
    allIds: [],
    byId: {}
  },
  orgRegion: {
    allIds: [],
    byId: {}
  },
  navType: {
    allIds: [],
    byId: {}
  },
  dmActions: {
    allIds: [],
    byId: {}
  },
  dmElementTargetingActions: {
    allIds: [],
    byId: {}
  },
  dmTemplate: {
    allIds: [],
    byId: {}
  },
  dmSortMethod: {
    allIds: [],
    byId: {}
  },
  featureStatus: {
    allIds: [],
    byId: {}
  },
  modules: {
    allIds: [],
    byId: {},
    isRequesting: false
  },
  dmElementFrequency: {
    allIds: [],
    byId: {}
  },
  states: {
    allIds: [],
    byId: {}
  },
  settingsType: {
    allIds: [],
    byId: {}
  },
  isRequesting: false,
  error: null
};

const staticReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case STATIC_ACTION_TYPE.Request: {
      return { ...state, isRequesting: true };
    }
    case STATIC_ACTION_TYPE.Set: {
      let {
        notificationFrequency,
        notificationAction,
        orgStatus,
        orgType,
        navType,
        priv,
        privs,
        dmActions,
        dmTemplate,
        dmSortMethod,
        featureStatus,
        modules,
        dmElementFrequency,
        orgRegion,
        states,
        dmElementTargetingActions,
        settingsType
      } = action.payload;
      notificationFrequency = {
        allIds: createAllIds(notificationFrequency, 'id', true),
        byId: createById(notificationFrequency)
      };
      notificationAction = {
        allIds: createAllIds(notificationAction, 'id', true),
        byId: createById(notificationAction)
      };
      orgStatus = {
        allIds: createAllIds(orgStatus, 'id', true),
        byId: createById(orgStatus)
      };
      orgType = {
        allIds: createAllIds(orgType, 'id', true),
        byId: createById(orgType)
      };
      orgRegion = {
        allIds: createAllIds(orgRegion, 'id', true),
        byId: createById(orgRegion)
      };
      navType = {
        allIds: createAllIds(navType, 'id', true),
        byId: createById(navType)
      };
      privs = {
        allIds: createAllIds(priv, 'id'),
        byId: createById(priv)
      };
      dmActions = {
        allIds: createAllIds(dmActions, 'id', true),
        byId: createById(dmActions)
      };
      dmTemplate = {
        allIds: createAllIds(dmTemplate, 'id', true),
        byId: createById(dmTemplate)
      };
      dmSortMethod = {
        allIds: createAllIds(dmSortMethod, 'id', true),
        byId: createById(dmSortMethod)
      };
      featureStatus = {
        allIds: createAllIds(featureStatus, 'id', false),
        byId: createById(featureStatus)
      };
      modules = {
        allIds: createAllIds(modules, 'id', false),
        byId: createById(modules)
      };
      dmElementFrequency = {
        allIds: createAllIds(dmElementFrequency, 'id', false),
        byId: createById(dmElementFrequency)
      };
      states = {
        allIds: createAllIds(states, 'id', true),
        byId: createById(states)
      };
      dmElementTargetingActions = {
        allIds: createAllIds(dmElementTargetingActions, 'id', true),
        byId: createById(dmElementTargetingActions)
      };
      settingsType = {
        allIds: createAllIds(settingsType, 'id', true),
        byId: createById(settingsType)
      };
      return {
        ...state,
        // PLACE ALPHABETICALLY //
        dmActions,
        dmElementFrequency,
        dmElementTargetingActions,
        dmSortMethod,
        dmTemplate,
        featureStatus,
        modules,
        navType,
        notificationFrequency,
        notificationAction,
        orgRegion,
        orgStatus,
        orgType,
        privs,
        settingsType,
        states,
        // PLACE ALPHABETICALLY //
        isRequesting: false
      };
    }
    case STATIC_ACTION_TYPE.Failure: {
      return {
        ...state,
        error: action.error,
        isRequesting: false
      };
    }
    default:
      return state;
  }
};

export default staticReducer;
