import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from '@ovis-technologies/ovis-blueprint';
import { useLocation } from 'react-router-dom';

import SideNavPanel from './SideNavPanel';
import SideNavElement from './SideNavElement';
import GuidedTourSelectableElement from '../GuidedTour/GuidedTourSelectableElement';
import SideNavToggle from './SideNavToggle';

import { selectNavigationLookup } from '../../selectors/navSelectors';
import { getNavId, getNavToggle } from '../../utils';
import { GlobalState } from '../../reducers/reducers';
import { NAV_TOGGLE } from '../../../constants';
import { setStorageItem } from '../../utils/localStorage';
import { setSideNavOpen } from '../../actions/uiActions';

import './_side_nav.scss';

interface Props {
  hasMenuToggle?: boolean;
  onSideNavOpen: () => void;
  onSideNavClose: () => void;
}

const SideNav: React.FC<Props> = ({
  hasMenuToggle = true,
  onSideNavOpen,
  onSideNavClose
}: Props) => {
  const dispatch = useDispatch();
  const { pathname: url } = useLocation();
  const [openState, setOpenState] = useState(false);
  const [fixedOpen, setFixedOpen] = useState(getNavToggle());
  const [activeElement, setActiveElement] = useState<number | null>(null);
  const [activeSubElement, setActiveSubElement] = useState<number | null>(null);

  const navElementsById = useSelector(
    (state: GlobalState) => state.nav.elements.byId
  );

  const userPrivs = useSelector((state: GlobalState) => {
    const activeUser = state.user.users.byId[state.user.activeUserId] || {};
    return activeUser.privs;
  });

  const navElementIds = useSelector((state: GlobalState) =>
    state.nav.elements.allIds.filter(
      (id) =>
        navElementsById[id].typeId !== 4 &&
        navElementsById[id].typeId !== 5 &&
        (navElementsById[id].privId === null ||
          userPrivs.includes(navElementsById[id].privId))
    )
  );

  const urlLookup = useSelector(selectNavigationLookup);

  useEffect(() => {
    dispatch(setSideNavOpen(getNavToggle()));
  }, [dispatch]);

  useEffect(() => {
    const navId = getNavId(urlLookup, url);
    const { parentNavId } = navElementsById[navId] || {};

    if (parentNavId) {
      setActiveElement(parentNavId);
      setActiveSubElement(navId);
    } else {
      setActiveElement(navId);
      setActiveSubElement(null);
    }
  }, [url, urlLookup, navElementsById]);

  const loading = useSelector((state: GlobalState) => state.loading);

  const handleOnMouseLeave = (e) => {
    if (!fixedOpen) {
      setOpenState(false);
      onSideNavClose();
    }
  };

  const handleOnMouseEnter = (e) => {
    if (e.target.closest('.side-nav_toggle')) return;
    setOpenState(true);
    if (fixedOpen) onSideNavOpen();
  };

  const handleFixOpen = () => {
    setStorageItem(NAV_TOGGLE, true);
    setFixedOpen(true);
    onSideNavOpen();
  };

  const handleCloseContainer = (e) => {
    setOpenState(false);
    setStorageItem(NAV_TOGGLE, false);
    setFixedOpen(false);
    onSideNavClose();
  };

  // Intentionally NOT recursive because we know the limits of the nav...
  const navElements = (element) => {
    const hasSubNav = element?.subNav.length > 0;
    const active =
      element.subNav.includes(activeElement) || activeElement === element.navId;
    const settings = element?.navId === 6;
    if (settings) {
      return (
        <Menu.Item key={`nav-${element.navId}`}>
          <GuidedTourSelectableElement id={element.navId}>
            <SideNavElement
              navId={element.navId}
              isActive={active}
              onClick={!fixedOpen && handleCloseContainer}
            />
          </GuidedTourSelectableElement>
        </Menu.Item>
      );
    }
    if (hasSubNav) {
      return (
        <Menu.SubMenu
          key={`nav-${element.navId}`}
          title={
            <GuidedTourSelectableElement id={element.navId}>
              <SideNavElement navId={element.navId} isActive={active} />
            </GuidedTourSelectableElement>
          }
        >
          {element.subNav.map(
            (
              nestedNavId // Secondary
            ) => (
              <Menu.Item key={`nav-secondary-${nestedNavId}`}>
                <SideNavElement
                  navId={nestedNavId}
                  isActive={nestedNavId === activeSubElement}
                  level={2}
                  onClick={!fixedOpen && handleCloseContainer}
                />
                {/* Render Tertiary Nav Here... */}
              </Menu.Item>
            )
          )}
        </Menu.SubMenu>
      );
    } else {
      return (
        <Menu.Item key={`nav-${element.navId}`}>
          <GuidedTourSelectableElement id={element.navId}>
            <SideNavElement
              navId={element.navId}
              isActive={active}
              onClick={!fixedOpen && handleCloseContainer}
            />
          </GuidedTourSelectableElement>
        </Menu.Item>
      );
    }
  };

  const firstNavElements: Array<JSX.Element> = [];

  navElementIds.forEach((navId: number) => {
    const element = navElementsById[navId];

    if (element.parentNavId === null) {
      firstNavElements.push(navElements(element));
    }
  });

  return (
    <div
      className={`side-nav_container + ${
        loading || !navElementIds.length ? ' hidden' : ''
      }`}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {hasMenuToggle && (
        <SideNavToggle
          fixed={fixedOpen}
          position={fixedOpen || openState ? 'open' : 'closed'}
          onClick={fixedOpen ? handleCloseContainer : handleFixOpen}
        />
      )}
      <SideNavPanel
        elements={firstNavElements}
        openState={openState || fixedOpen}
      />
    </div>
  );
};

export default SideNav;
