import {
  guidedTourAction,
  modalActions,
  sideNavActions,
  OVERRIDE_FEATURE,
  OPEN_DIRECTORY,
  CLOSE_DIRECTORY,
  OPEN_ALERTS,
  CLOSE_ALERTS,
  OPEN_OKTA,
  CLOSE_OKTA,
  CLOSE_DOCUMENT_MODAL,
  OPEN_DOCUMENT_MODAL,
  CLOSE_SIDE_PANEL,
  OPEN_SIDE_PANEL
} from '../actions/types/uiActionTypes';
import { Action } from '../App.d';
import { track } from '../services/trackingService';
import { FeatureOverride } from '../types/features.d';
import { UiState, TourState, PanelType } from './reducers.d';

const initialState: UiState = {
  tour: {
    status: TourState.inactive,
    elementTarget: '',
    editHelpId: 0,
    previewing: false
  },
  modal: {
    open: false
  },
  enhancedDirectory: {
    open: false
  },
  alerts: {
    open: false
  },
  okta: false,
  featureOverride: {},
  sideNavOpen: false,
  documentModal: {
    activeElement: 0,
    visible: false
  },
  sidePanel: {
    title: '',
    panelType: PanelType.closed
  }
};

const uiReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case OPEN_SIDE_PANEL: {
      const { payload } = action;
      const { panelType, title = '', userId } = payload;
      return {
        ...state,
        title,
        sidePanel: {
          panelType,
          title,
          userId
        }
      };
    }
    case CLOSE_SIDE_PANEL: {
      return {
        ...state,
        sidePanel: {
          title: '',
          PanelType: 'closed'
        }
      };
    }
    case guidedTourAction.inactive:
      return {
        ...state,
        tour: {
          ...state.tour,
          status: TourState.inactive,
          previewing: false
        }
      };
    case guidedTourAction.active:
      track({ context: 'guided_tour_view', typeId: 1 });
      return {
        ...state,
        tour: {
          ...state.tour,
          status: TourState.active
        }
      };
    case guidedTourAction.edit:
      return {
        ...state,
        tour: {
          ...state.tour,
          status: TourState.edit
        }
      };
    case guidedTourAction.preview:
      return {
        ...state,
        tour: {
          ...state.tour,
          status: TourState.active,
          previewing: true
        }
      };
    case guidedTourAction.setTourElementTarget: {
      const { payload = '' } = action;
      return {
        ...state,
        tour: {
          ...state.tour,
          elementTarget: payload
        }
      };
    }
    case guidedTourAction.setTourEditId: {
      const { payload = 0 } = action;
      return {
        ...state,
        tour: {
          ...state.tour,
          editHelpId: payload
        }
      };
    }
    case modalActions.openModal: {
      return {
        ...state,
        modal: {
          open: true
        }
      };
    }
    case modalActions.closeModal: {
      return {
        ...state,
        modal: {
          open: false
        }
      };
    }
    case sideNavActions.openSideNav: {
      return {
        ...state,
        sideNavOpen: true
      };
    }
    case sideNavActions.closeSideNav: {
      return {
        ...state,
        sideNavOpen: false
      };
    }
    case OVERRIDE_FEATURE: {
      const [constant, value]: FeatureOverride = action.payload;
      return {
        ...state,
        featureOverride: {
          ...state.featureOverride,
          [constant]: value
        }
      };
    }
    case OPEN_DIRECTORY:
      return {
        ...state,
        enhancedDirectory: {
          open: true
        }
      };
    case CLOSE_DIRECTORY:
      return {
        ...state,
        enhancedDirectory: {
          open: false
        }
      };
    case OPEN_ALERTS:
      return {
        ...state,
        alerts: {
          open: true
        }
      };
    case CLOSE_ALERTS:
      return {
        ...state,
        alerts: {
          open: false
        }
      };
    case OPEN_OKTA:
      return {
        ...state,
        okta: true
      };
    case CLOSE_OKTA:
      return {
        ...state,
        okta: false
      };
    case OPEN_DOCUMENT_MODAL: {
      const { payload } = action;
      return {
        ...state,
        documentModal: {
          visible: true,
          activeElement: payload
        }
      };
    }
    case CLOSE_DOCUMENT_MODAL:
      return {
        ...state,
        documentModal: {
          visible: false,
          activeElement: 0
        }
      };
    default:
      return state;
  }
};

export default uiReducer;
