import React, { useEffect, useState } from 'react';
import {
  FormItemProps,
  Modal,
  Form,
  notification,
  Portal
} from '@ovis-technologies/ovis-blueprint';
import { useDispatch } from 'react-redux';
import HtmlBuilder from '../../../components/HTMLBuilder/HtmlBuilder';
import { getLatestTerm, requestAddTerm } from '../../../actions/userActions';
import { RequestState } from '../../../../constants/requestStates';
import { useHistory } from 'react-router-dom';

interface TermsData {
  termId?: number;
  description: string;
  termsOfUse: string;
  lastModBy?: number;
  lastModDate?: Date;
}

const initialTerms = {
  description: '',
  termsOfUse: ''
};

const TermsForm = ({ refresh, setRefresh }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [latestTerm, setLatestTerm] = useState<TermsData>(initialTerms);
  const [termToBeAdded, setTermToBeAdded] = useState<TermsData>(initialTerms);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<RequestState>(
    RequestState.null
  );

  useEffect(() => {
    const getTermData = async () => {
      setRefresh(true);
      const termData: any = await dispatch(getLatestTerm());
      setLatestTerm({
        termId: termData.termId,
        description: '',
        termsOfUse: termData.termsOfUse
      });
      setRefresh(false);
    };
    getTermData();
  }, [dispatch, setRefresh]);

  // REVIEW MODAL handlers
  const handleReviewModalOpen = (values) => {
    setTermToBeAdded(values);
    setModalVisible(true);
  };

  const handleReviewModalClose = () => {
    setModalVisible(false);
  };

  const handleReviewModalSubmit = async () => {
    setSubmitLoading(RequestState.sending);
    try {
      await dispatch(requestAddTerm(termToBeAdded));
      setSubmitLoading(RequestState.success);
      notification.success({
        message: 'Successfuly added new Terms Of Use',
        duration: 7
      });
      history.push(`/settings/terms_of_use_management`);
    } catch (error) {
      console.error(error);
      setSubmitLoading(RequestState.error);
      notification.error({
        message: 'Failed to add new Terms Of Use',
        duration: 4
      });
    }
    handleReviewModalClose();
  };

  const formFields: FormItemProps[] = [
    {
      name: 'description',
      type: 'text',
      label: 'Description',
      rules: {
        required: true,
        maxLength: 300
      }
    },
    {
      name: 'termsOfUse',
      type: 'rich-text',
      label: 'Terms Of Use Content',
      style: { itemCol: { backgroundColor: 'transparent', color: 'black' } },
      rules: {
        required: true
      }
    }
  ];

  const loadForm = Boolean(latestTerm.termId && !refresh);

  return (
    <>
      {loadForm && (
        <div className='form-page terms-form'>
          <Form
            className='tou-form'
            fields={formFields}
            defaultValues={latestTerm}
            onSubmit={handleReviewModalOpen}
            submitButtonText='Review & Submit'
          />
          <Portal>
            <Modal
              visible={modalVisible}
              title='Review'
              closeButton
              onCancel={handleReviewModalClose}
            >
              <div className='terms-wrapper'>
                <HtmlBuilder content={termToBeAdded.termsOfUse} />
                <Form
                  className='terms-form --review'
                  fields={[]}
                  onSubmit={handleReviewModalSubmit}
                  defaultValues={termToBeAdded}
                  submitButtonText='Submit'
                  loading={submitLoading === RequestState.sending}
                />
              </div>
            </Modal>
          </Portal>
        </div>
      )}
    </>
  );
};

export default TermsForm;
