const createById = (data = [], idName = 'id', transform = (value) => value) => {
  if (!Array.isArray(data) || !data.length) return {};
  const checkId = idName in data[0];
  if (!checkId) return {};
  return data.reduce((carry, value) => {
    const item = { ...carry, [value[idName]]: transform(value) };
    // This if statement is for FormBuilder compatibility. Can be deleted if FormBuilder gets removed
    // or no longer uses 'option.id' for creating Select menu values.
    if (!Object.prototype.hasOwnProperty.call(item[value[idName]], 'id'))
      item[value[idName]].id = value[idName];
    return item;
  }, {});
};

export default createById;
