import { Action } from '../App.d';

const initialState = {
  type: '',
  message: '',
  description: '',
  duration: '7'
};

let increment = 0;

const notificationReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_NOTIFICATION': {
      const {
        payload: { type = 'info', message = '', description = '', duration = 7 }
      } = action;
      increment += 1;
      return {
        type,
        message,
        description,
        duration,
        increment
      };
    }
    case 'CLEAR_NOTIFICATION':
      return initialState;
    default:
      return state;
  }
};

export default notificationReducer;
