import React from 'react';
import Page from '../../components/Page';
import OktaApps from '../../components/OktaApps/OktaApps';

const OktaAppsPage = () => {
  return (
    <Page title='Your Applications'>
      <OktaApps />
    </Page>
  );
};

export default OktaAppsPage;
