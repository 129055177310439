import { DASHBOARD_ENDPOINT } from './endpoints';
import axios from 'axios';
import { handleServiceError } from '.';
import { ResponseData } from '../App.d';
import { DashboardFields } from '@ovis-technologies/ovis-blueprint';

enum DashboardActions {
  get = 'GET_USER_DASHBOARDS',
  create = 'CREATE_DASHBOARD',
  edit = 'UPDATE_DASHBOARD',
  delete = 'DELETE_DASHBOARD',
  getWidget = 'GET_WIDGET'
}

export const fetchDashboardRequest = async (): Promise<ResponseData> => {
  return axios
    .post(DASHBOARD_ENDPOINT, {
      action: DashboardActions.get
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const createDashboardRequest = async (
  newDashboard: DashboardFields
): Promise<ResponseData> => {
  return axios
    .post(DASHBOARD_ENDPOINT, {
      action: DashboardActions.create,
      data: newDashboard
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const editDashboardRequest = async (
  dashboardEdits: DashboardFields
): Promise<ResponseData> => {
  return axios
    .post(DASHBOARD_ENDPOINT, {
      action: DashboardActions.edit,
      data: dashboardEdits
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const deleteDashboardRequest = async (
  dashboardId: number
): Promise<ResponseData> => {
  return axios
    .post(DASHBOARD_ENDPOINT, {
      action: DashboardActions.delete,
      data: { dashboardId }
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const fetchDashboardWidgetRequest = async (
  widgetId: number,
  data: any = {}
): Promise<ResponseData> => {
  return axios
    .post(DASHBOARD_ENDPOINT, {
      action: DashboardActions.getWidget,
      data: { widgetId, ...data }
    })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const getDashboardDefault = (typeId) => {
  return JSON.parse(
    window.localStorage.getItem(`dashboard-default-${typeId}`) || 'null'
  );
};

export const setDashboardDefault = (typeId, dashboardId) => {
  return window.localStorage.setItem(
    `dashboard-default-${typeId}`,
    JSON.stringify(dashboardId)
  );
};
