import { GlobalState } from '../reducers/reducers';
import { createSelector } from 'reselect';

export const selectActiveUserId = (state: GlobalState) =>
  state.user.activeUserId;

export const selectActiveUser = (state: GlobalState) =>
  state.user.users.byId[state.user.activeUserId];

export const selectAllUsers = (state: GlobalState, onlyActive?, orgId?) => {
  const { allIds, byId } = state.user.users;
  const { byId: orgsById } = state.org.allOrgs;
  const userIds = allIds.filter((id) => {
    const { orgId: userOrgId, status } = byId[id];
    const checkIfInOrg = orgId ? orgId === userOrgId : true;
    const checkIfActive = onlyActive ? status === 1 : true;
    return checkIfActive && checkIfInOrg;
  });
  userIds.forEach((id) => {
    const { orgId } = byId[id] || {};
    const { name } = orgsById[orgId] || {};
    byId[id].orgName = name;
  });
  return {
    byId,
    allIds: userIds
  };
};

export const selectUserState = (state: GlobalState) => state.user.users;
export const selectOrgState = (state: GlobalState) => state.org.allOrgs;

export const selectAllUsersMemo = createSelector(
  [(state: GlobalState) => state, (state, obj) => obj],
  (state, obj) => {
    const { allIds, byId } = state.user.users;
    const { byId: orgsById } = state.org.allOrgs;
    const userIds = allIds.filter((id) => {
      const { orgId: userOrgId, status } = byId[id];
      const checkIfInOrg = obj.orgId ? obj.orgId === userOrgId : true;
      const checkIfActive = obj.onlyActive ? status === 1 : true;
      return checkIfActive && checkIfInOrg;
    });
    userIds.forEach((id) => {
      const { orgId } = byId[id] || {};
      const { name } = orgsById[orgId] || {};
      byId[id].orgName = name;
    });
    return {
      byId,
      allIds: userIds
    };
  }
);

export const selectActiveUserPrivs = (state: GlobalState) =>
  state.user.users.byId[state.user.activeUserId].privs;

export const selectUserData = (state, userId) => {
  return state.user.users.byId[userId];
};
