import React from 'react';

import { formatDate } from '@ovis-technologies/ovis-blueprint';

import { TemplateProps } from './';
import Share from '../DMBlogging/ShareElement';
import LinkRender from '../LinkRender/LinkRender';
import './DocumentTemplates.scss';

export const ListTemplate = ({
  layoutClass = '',
  elements = [],
  isCalendarEvent = false,
  sortMethodOverride
}: TemplateProps) => {
  if (isCalendarEvent && !sortMethodOverride) {
    elements.sort((a, b) => {
      return (
        new Date(b.calStartDate).getTime() - new Date(a.calStartDate).getTime()
      );
    });
  }
  return (
    <div className='dm-template --list'>
      <div className='grid'>
        {elements.map((doc) => {
          const { elementId } = doc;
          return (
            <ListItem
              key={elementId}
              doc={doc}
              isCalendarEvent={isCalendarEvent}
            />
          );
        })}
      </div>
    </div>
  );
};

interface ListItemProps {
  doc: any;
  children?;
  isCalendarEvent?;
}

export const ListItem = ({ doc }: ListItemProps) => {
  const { elementId, title, fileLocation, link, contentUrl, postDate } = doc;
  const url = contentUrl || fileLocation || link;
  const dateFormat = formatDate(postDate, {
    format: 'MM/DD/YYYY'
  });
  return (
    <div key={elementId} className='document --list-item'>
      <div className='document_content'>
        <div>
          <LinkRender contentUrl={url} elementId={elementId}>
            <p className='document_title'>{title}</p>
          </LinkRender>

          <span className='document_date'>{dateFormat}</span>
        </div>
      </div>
      <div className='dm-blogging_action dm-blogging_toolbar-btn'>
        <Share text='Share' elementId={elementId} />
      </div>
    </div>
  );
};

export default ListTemplate;
