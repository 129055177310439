import React from 'react';
import classNames from 'classnames';
import { Button, DoubleArrow } from '@ovis-technologies/ovis-blueprint';

interface Props {
  fixed: boolean;
  position: 'open' | 'closed';
  onClick: (e?: any) => any;
}
const SideNavToggle = ({ fixed, position, onClick }: Props) => {
  const toggleMenuClass = classNames('side-nav_toggle', {
    '--active': position === 'open',
    '--fixed': fixed
  });
  return (
    <div className={toggleMenuClass}>
      <Button onClick={onClick} styleType='text'>
        <DoubleArrow fill={'#fff'} />
      </Button>
    </div>
  );
};

export default SideNavToggle;
