import { ResponseData } from '../App.d';

export const setInterrupt = (response: ResponseData) => ({
  type: 'SET_INTERRUPT',
  payload: response
});
export const removeInterrupt = (interruptType) => ({
  type: 'REMOVE_INTERRUPT',
  payload: interruptType
});
