import { Dispatch } from 'redux';
import { fetchStaticInfo } from '../services/staticService';
import { STATIC_ACTION_TYPE } from '../actions/types/staticActionTypes';
import { StaticResponseBody } from '../declarations/static';
import { ResponseData } from '../App.d';
export const setStaticData = (staticData: StaticResponseBody) => ({
  type: STATIC_ACTION_TYPE.Set,
  payload: staticData
});
export const getStaticData =
  (releaseDatetime: string) =>
  async (dispatch: Dispatch): Promise<StaticResponseBody> => {
    try {
      const staticData = JSON.parse(
        window.sessionStorage.getItem('staticData') || 'null'
      );
      let requestStatic = false;

      const now = Date.now();
      if (staticData) {
        const parsedReleasedDate = Date.parse(releaseDatetime);
        // API deployed means new changes and could possibly change /static response
        const newRelease = staticData.dateRequested < parsedReleasedDate;
        // ~86400000 milliseconds in a day
        const expired = now - staticData.dateRequested > 86400000;
        if (expired || newRelease) {
          requestStatic = true;
        }
      } else {
        requestStatic = true;
      }
      if (requestStatic) {
        dispatch({ type: STATIC_ACTION_TYPE.Request });
        const responseData: ResponseData = await fetchStaticInfo();
        const staticDataResponse: StaticResponseBody = responseData.response;
        dispatch(setStaticData(staticDataResponse));
        sessionStorage.setItem(
          'staticData',
          JSON.stringify({ ...staticDataResponse, dateRequested: now })
        );
        return staticDataResponse;
      }
      dispatch(setStaticData(staticData));
      return staticData;
    } catch (error: any) {
      console.error('Error from staticData action', error);
      dispatch({ type: STATIC_ACTION_TYPE.Failure, error });
      throw error;
    }
  };
