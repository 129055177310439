import config from '../config';
import { get } from 'lodash';
import { getStorageItem } from './localStorage';
import { NAV_TOGGLE } from '../../constants';

export { getNavId } from './getNavId';
export { getIdFromUrl } from './getIdFromUrl';
export { getElementInfo } from './getElementInfo';
export { checkIfExternal } from './checkIfExternal';

export const formatAudioTime = (timeInSeconds) => {
  const m = Math.floor((timeInSeconds / 60) % 60);
  const s = '0' + Math.floor(timeInSeconds % 60);
  return `${m}:${s.substr(-2)}`;
};

export const assetUrl = (string, testDomain) => {
  if (typeof string !== 'string') {
    return string;
  }

  const types = {
    prv: config.api.domain || testDomain,
    spv: config.api.domain || testDomain,
    pub: config.assets.domain || testDomain
  };
  const prefix = string.split('/')[0];

  if (types[prefix]) {
    return `${types[prefix]}/${string}`;
  }

  return string;
};

export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

// WARNING: This can basically never change because this string will be persisted in the DB.
export const guidedTourElId = (id) => `gt-nav-${id}`;

export const getUrlFileName = (url) => {
  const urlArr = url.split('/');
  return urlArr[urlArr.length - 1];
};

export const filterInactive = (
  dataArray: any[] = [],
  referenceObject: any,
  noReference: boolean = false
) => {
  if (!referenceObject && !noReference) return dataArray;
  return dataArray.filter((id) => {
    if (noReference) {
      const { startDate, endDate, status } = id;
      const date = new Date().getTime();
      let started = false;
      let ended = false;
      let isActive = false;
      started = new Date(startDate).getTime() >= date || startDate === '';
      ended = new Date(endDate).getTime() <= date || endDate === '';
      isActive = status === 1;
      return started && ended && isActive;
    } else {
      return get(referenceObject[id], 'status', 0) !== 2;
    }
  });
};

export const pseudoRandomNum = () => Math.random() * 10000000;

export const hasProperty = (obj, key) =>
  Object.prototype.hasOwnProperty.call(obj, key);

export const truncate = (text, maxLength, stringEnd = '') => {
  let formattedText = text.trim();
  if (formattedText.length > maxLength) {
    formattedText = `${text.slice(0, maxLength)}${stringEnd}`;
  }
  return formattedText;
};

export const nameToUrl = (string) => {
  return string
    .replace(/[,()~!@#$%^&*-./]+/g, '')
    .replace(/\s+/g, '_')
    .toLowerCase();
};

// export const dmIdToDeptId = (dmId) => {
//   const state = store.getState() as GlobalState;
//   return state.nav.departments.allIds.filter(
//     (id) => state.nav.departments.byId[id].dmId === dmId
//   )[0];
// };

export const base64ToArrayBuffer = (base64) => {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const parseFilterItems = (data) => {
  return Object.keys(data)
    .filter((key) => key.includes('filter-') && data[key])
    .map((key) => data[key]);
};

export const downloadBlob = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const isOldIE = (): boolean => {
  return !!(
    typeof window !== 'undefined' &&
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob &&
    window.Blob
  );
};

export const isIOSSafari = (): boolean => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);

  return iOS && webkit && !ua.match(/CriOS/i);
};

export const isIOSChrome = (): boolean => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);

  return iOS && !!ua.match(/CriOS/i);
};

export const getBasePath = (pathname): string => {
  return '/' + pathname.split('/')[1];
};

export const parseQueryString = (string: string): { [x: string]: any } => {
  return string
    .split('?')
    .join('')
    .replaceAll('%20', ' ')
    .split('=')
    .reduce(
      (prev, cur, idx, array) =>
        idx % 2 === 0 ? { ...prev, [cur]: array[idx + 1] } : { ...prev },
      {}
    );
};

export const getNavToggle = () => {
  let navToggle = true;
  try {
    navToggle = getStorageItem(NAV_TOGGLE);
  } catch (err: any) {}
  return navToggle;
};

export const getSelectDefaultValue = (values = [], selectId, byId, idName) => {
  let defaultId;
  values.forEach((id) => {
    if (byId[id][idName] === selectId) defaultId = id;
  });
  return defaultId;
};

export const getElementIdFromUrl = (path, name) => {
  if (path.includes(name)) {
    const pathArr = path.split(`${name}/`);
    if (pathArr.length <= 1) return 0;
    const isolatedPathArr = pathArr[1].split('/');
    const id = isolatedPathArr[0];
    return Number(id);
  }
  return 0;
};
