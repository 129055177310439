import React, { useState, ReactNode, useMemo } from 'react';
import {
  Modal,
  Preview,
  isStreamingVideo,
  notification,
  isPreviewable,
  Tooltip
} from '@ovis-technologies/ovis-blueprint';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { RequestState } from '../../../constants/requestStates';
import getPrivateAssetUrl from '../../services/getPrivateAssetUrl';
import errorMessage from '../../../constants/standardErrorMessage';
import { checkIfExternal } from '../../utils';
import { track } from '../../services/trackingService';

export interface Props {
  onClick?: (e?: any) => any;
  onClose?: (e?: any) => any;
  contentUrl?: string;
  disabled?: boolean;
  buttonText?: string;
  className?: string;
  children?: ReactNode;
  asIcon?: boolean;
  shouldTrack?: boolean;
  elementId?: number;
}

const PreviewButton = ({
  onClick = () => {},
  onClose = () => {},
  contentUrl,
  disabled,
  buttonText = '',
  className,
  children,
  asIcon = false,
  shouldTrack = true,
  elementId
}: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [signedUrl, setSignedUrl] = useState<string>('');
  const [assetRequest, setAssetRequest] = useState<RequestState>(
    RequestState.null
  );
  const [isStreamingLink] = isStreamingVideo(contentUrl);
  const isExternalLink =
    contentUrl && checkIfExternal(contentUrl) && !isStreamingLink;
  const canPreview = isPreviewable(contentUrl) && !isExternalLink;
  if (!canPreview) disabled = true;

  const handleTrackClick = () => {
    track({ elementId, context: 159, typeId: 5 });
  };

  const ovisPreviewButtonClassName = classNames(
    'ovis-preview-button',
    className,
    {
      '--disabled': disabled
    }
  );

  const handleClick = async (e) => {
    if (disabled) return;
    setVisible(true);
    if (elementId && shouldTrack) handleTrackClick();
    onClick(e);
    if (contentUrl) {
      if (!isStreamingLink) {
        try {
          setAssetRequest(RequestState.sending);
          setSignedUrl(await getPrivateAssetUrl(contentUrl));
          setAssetRequest(RequestState.success);
        } catch (err) {
          setAssetRequest(RequestState.error);
          notification.error({
            message: 'Failed to retrieve asset. ' + errorMessage,
            duration: 0
          });
        }
      }
    }
  };

  const handleClose = (e) => {
    setVisible(false);
    setSignedUrl('');
    onClose(e);
  };

  const Portal = useMemo(
    () =>
      ({ children }) => {
        return createPortal(children, document.body);
      },
    []
  );

  const buttonContent = buttonText || children;

  const href = isStreamingLink ? contentUrl : signedUrl;

  const button = (
    <button
      type='button'
      className={ovisPreviewButtonClassName}
      onClick={handleClick}
    >
      {buttonContent}
    </button>
  );

  return (
    <>
      {asIcon ? <Tooltip content='Preview'>{button}</Tooltip> : button}

      <Portal>
        <div className='ovis-preview-button_modal'>
          <Modal
            onCancel={handleClose}
            visible={!(disabled || !visible)}
            loading={assetRequest === RequestState.sending}
          >
            {href && <Preview href={href} />}
          </Modal>
        </div>
      </Portal>
    </>
  );
};

export default PreviewButton;
