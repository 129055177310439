/**
 *
 *
 * This should be redone to similar to DMWrapper and used in multiple places/pages
 *
 *
 *
 *  **/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  createDashboard,
  deleteDashboard,
  editDashboard,
  getDashboards,
  getDashboardWidget
} from '../../actions/dashboardActions';
import { GlobalState } from '../../reducers/reducers';
import {
  Button,
  ConfigurableDashboard,
  Modal,
  notification,
  DashboardFields,
  WidgetMeta
} from '@ovis-technologies/ovis-blueprint';
import { get, sortBy } from 'lodash';

import Layout from '../Page';
import { selectActiveUser } from '../../selectors';
import {
  getDashboardDefault,
  setDashboardDefault
} from '../../services/dashboardService';
import { GenericObject } from '@ovis-technologies/ovis-blueprint/build/constants/declarations';

type Props = {
  typeId: number;
};

interface modalContent {
  title: string;
  text: string;
  action: DashboardAction;
  payload: DashboardFields;
}

enum DashboardAction {
  none = '',
  save = 'SAVE',
  saveAs = 'SAVE_AS',
  delete = 'DELETE'
}

const modalContentDefault: modalContent = {
  title: '',
  text: '',
  action: DashboardAction.none,
  payload: {}
};

const Dashboards = ({ typeId }: Props) => {
  const userDefaultDashboard = getDashboardDefault(typeId);
  const { allIds: dashboardIds, byId: dashboardsById } = useSelector(
    (state: GlobalState) => state.dashboard
  );
  // In the future, we'll need to find a better way to filter between dashboard _pages_
  // .filter((id) => dashboardsById[id].typeId === typeId)
  const dashboards =
    // sort by global then alphabetical
    sortBy(
      dashboardIds.map((id) => dashboardsById[id]),
      [
        function (dash) {
          return dash.userId !== null;
        },
        'name'
      ]
    );
  const userDefaultIndex = dashboards.findIndex(
    (dash) => dash.dashboardId === userDefaultDashboard
  );
  const systemDefaultIndex = dashboards.findIndex(
    (dash) => dash.typeId === typeId && dash.systemDefault
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState(modalContentDefault);
  const [defaultDashboard, setDefaultDashboard] = useState<number>(0);
  const activeUser = useSelector(selectActiveUser);
  const isPrivileged: boolean = activeUser.privs.includes(16);
  const dispatch = useDispatch();
  const { dashboardId: pathId } = useParams<GenericObject>();
  // Set via child to force setEditing
  let resetToDashboard;

  const widgetMeta = useSelector((state: GlobalState) =>
    Object.values(state.widgets.widgetMeta as WidgetMeta)
      .filter(
        (widgetMeta): boolean =>
          widgetMeta?.typeId === typeId &&
          activeUser.privs.includes(widgetMeta.privId)
      )
      .reduce(
        (acc, widgetMeta) => ({ ...acc, [widgetMeta.widgetId]: widgetMeta }),
        {}
      )
  );

  const handleWidgetRender = async (id) => {
    const widgetData: any = await dispatch(getDashboardWidget(id));
    return widgetData;
  };

  const handleSave = (input: DashboardFields) => {
    setModalContent({
      title: 'Confirm Save',
      text: `Are you sure you want to save and overwrite dashboard ${input.name}'s settings?`,
      action: DashboardAction.save,
      payload: input
    });
  };

  const handleSaveAs = (input: DashboardFields) => {
    setModalContent({
      title: 'Confirm Save As',
      text: `Are you sure you want to create a new dashboard with the current settings?`,
      action: DashboardAction.saveAs,
      payload: input
    });
  };

  const handleDelete = (input) => {
    setModalContent({
      title: 'Confirm Delete',
      text: `Are you sure you want to delete dashboard ${input.name}?`,
      action: DashboardAction.delete,
      payload: input.dashboardId
    });
  };

  const onSave = async (input: DashboardFields) => {
    let message: string;
    let id: number | undefined = input.dashboardId;
    try {
      const payload = {
        ...input,
        typeId,
        userId: getUserId(input)
      };

      if (input.dashboardId) {
        await dispatch(editDashboard(payload));
        message = `Dashboard ${input.name} edited successfully!`;
      } else {
        const nextRecordIndex = dashboards.length;
        const { response: dashboardResult } = await dispatch(
          createDashboard(payload)
        );
        message = `Dashboard ${input.name} created successfully!`;
        id = dashboardResult.dashboardId;
        resetToDashboard(nextRecordIndex);
      }
      notification.success({ message });
      if (input.userDefault && id) {
        setDashboardDefault(typeId, id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSaveAs = async (input: DashboardFields) => {
    const nextRecordIndex = dashboards.length;
    try {
      const payload = {
        ...input,
        typeId,
        userId: getUserId(input)
      };
      const previousName: string = get(
        dashboardsById,
        [payload.dashboardId, 'name'],
        ''
      );

      // Intentionally done on the frontend
      if (previousName === payload.name) {
        payload.name = `${payload.name} (Copy)`;
      }
      // Create copy by excluding ID and using create...
      delete payload.dashboardId;
      const { response: dashboardResult } = await dispatch(
        createDashboard(payload)
      );
      notification.success({
        message: `Dashboard ${payload.name} created successfully!`
      });

      if (input.userDefault && dashboardResult.dashboardId) {
        setDashboardDefault(typeId, dashboardResult.dashboardId);
      }
      setDefaultDashboard(nextRecordIndex);
    } catch (e) {
      console.log(e);
    }
  };

  const onDelete = async (input) => {
    const name: string = get(dashboardsById, [input, 'name'], '');
    try {
      await dispatch(deleteDashboard(input));
      notification.success({
        message: `Dashboard ${name} deleted successfully!`
      });
      resetToDashboard(0);
    } catch (e) {
      console.log(e);
    }
  };

  const getUserId = (input: DashboardFields): number | null => {
    if (isPrivileged) {
      if (input.global || input.systemDefault) {
        return null;
      }
    }
    return activeUser.userId;
  };

  const handleModalConfirm = async () => {
    setActionLoading(true);
    switch (modalContent.action) {
      case DashboardAction.save:
        await onSave(modalContent.payload);
        break;
      case DashboardAction.saveAs:
        await onSaveAs(modalContent.payload);
        break;
      case DashboardAction.delete:
        await onDelete(modalContent.payload);
        break;
      default:
        break;
    }
    setActionLoading(false);
    setModalContent(modalContentDefault);
  };

  const handleModalCancel = () => setModalContent(modalContentDefault);

  useEffect(() => {
    async function getDash() {
      await dispatch(getDashboards());
      setLoading(false);
    }
    getDash();
  }, []); // eslint-disable-line

  // Prioritize path ID then user default
  useEffect(() => {
    let dashIndex = 0;

    if (!pathId) {
      if (systemDefaultIndex >= 0) {
        dashIndex = systemDefaultIndex;
      }
      if (userDefaultIndex >= 0) {
        dashIndex = userDefaultIndex;
      }
    } else {
      const pathIdIndex = dashboardIds.findIndex((id) => id === Number(pathId));
      if (pathIdIndex !== -1) {
        dashIndex = pathIdIndex;
      }
    }

    setDefaultDashboard(dashIndex);
    resetToDashboard();
  }, [
    systemDefaultIndex,
    userDefaultIndex,
    pathId,
    dashboardIds,
    resetToDashboard
  ]);

  return (
    <>
      <Layout>
        <ConfigurableDashboard
          admin={isPrivileged}
          userDefault={userDefaultDashboard}
          loading={loading}
          dashboards={dashboards}
          defaultDashboard={defaultDashboard}
          widgetMeta={widgetMeta}
          resetEdit={(reset) => (resetToDashboard = reset)}
          onDashboardSave={handleSave}
          onDashboardSaveAs={handleSaveAs}
          onDashboardDelete={handleDelete}
          onWidgetRender={handleWidgetRender}
        />
      </Layout>
      <Modal
        confirm
        closeButton
        onCancel={handleModalCancel}
        title={modalContent.title}
        visible={Boolean(modalContent.text)}
      >
        <div className='dashboard-modal-content'>
          <p>{modalContent.text}</p>
          <Button
            disabled={actionLoading}
            loading={actionLoading}
            onClick={handleModalConfirm}
            size='large'
            styleType={
              modalContent?.action === 'DELETE' ? 'warning' : 'secondary'
            }
            content={modalContent?.action === 'DELETE' ? 'Delete' : 'Confirm'}
          />
        </div>
      </Modal>
    </>
  );
};

export default Dashboards;
