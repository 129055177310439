import React, { useEffect, useState } from 'react';
import { DnDFileArea } from '@ovis-technologies/ovis-blueprint';
import classnames from 'classnames';

import fileUpload from '../../services/fileUpload';

/*
  This component is client specific because of how we upload files with a self cleaning
  temporary S3 bucket. `tempLookup` accommodates that.
*/
interface Props {
  className?: string;
  onChange?: (obj: { [fileName: string]: string }) => void;
}

const MultiUpload = ({ className = '', onChange }: Props) => {
  const [files, setFiles] = useState<string[]>([]);
  const [tempLookup, setTempLookup] = useState<{ [fileName: string]: string }>(
    {}
  );
  const containerClass = classnames('multi-upload', className, {
    '--disabled': false
  });

  useEffect(() => {
    if (onChange) {
      onChange(
        files.reduce(
          (prev, curr) => ({ ...prev, [curr]: tempLookup[curr] }),
          {}
        )
      );
    }
  }, [files, tempLookup]); // eslint-disable-line

  const handleFileChange = (files) => {
    setFiles(files.map((file) => file.name));
  };

  const handleFileAdd = async (file) => {
    const result = await fileUpload(file);
    setTempLookup((prev) => ({ ...prev, [file.name]: result }));
  };

  return (
    <div className={containerClass}>
      <DnDFileArea
        onFileAdd={handleFileAdd}
        onChange={handleFileChange}
        manualAddPlaceholder='Choose files'
      />
    </div>
  );
};

export default MultiUpload;
