import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { get, debounce } from 'lodash';

import { setInterrupt } from './actions/interruptActions';
import { notifActions } from './actions/types/notifActionTypes';
import { setNotification } from './actions/notifActions';

interface Props {
  children?: any;
}

const Axios: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const throttledDispatch = debounce(dispatch, 20000, {
    leading: true,
    trailing: false
  });
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        if (response.data.interrupt) {
          if (response.data.interrupt.length)
            dispatch(setInterrupt(response.data.interrupt));
        }

        const { message, status } = response.data;
        if (status === false) {
          dispatch(
            setNotification({
              type: 'error',
              message: message
            })
          );
          throw response;
        }
        return response;
      },
      (error) => {
        dispatch({ type: 'REQUEST_ERROR_TRUE' });

        const status = get(error.response, 'status', 400);

        switch (status) {
          case 401: {
            throttledDispatch({
              type: notifActions.setNotification,
              payload: {
                type: 'info',
                message: 'Session Expired',
                description: 'Please sign in again'
              }
            });
            oktaAuth.tokenManager.clear();
            oktaAuth.signOut({
              postLogoutRedirectUri: window.location.origin + pathname
            });
            window.sessionStorage.setItem('referrerPath', pathname);
            window.localStorage.removeItem('auto-launch');
            break;
          }
          case 406:
            history.push('/user-not-mapped');
            break;
          case 500:
          case 502:
          case 504:
          default:
            throttledDispatch({
              type: notifActions.setNotification,
              payload: {
                type: 'error',
                message: 'Server error',
                description:
                  "There's a problem on our end. Refresh the page, if the issue persists contact your administrator.",
                duration: 0
              }
            });
            break;
        }

        return Promise.reject(error);
      }
    );

    axios.interceptors.request.use((request) => {
      const idToken = oktaAuth.getIdToken();

      if (idToken) {
        request.headers['x-api-key'] = `Bearer ${idToken}`;
        // request.headers.Email = 'lalmeida@ovistechnologies.com';
        // request.headers.Email = 'thradek@ovistechnologies.com';
      }
      return request;
    }, error => {
        console.log('error :>> ', error);
        return Promise.reject(error);
    });
  }, []); // eslint-disable-line

  return <>{props.children}</>;
};

export default Axios;
