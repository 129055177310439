import { union } from 'lodash';
import * as types from '../actions/types/dmActionTypes';
import { Action } from '../App.d';
import { DmState } from './reducers.d';
import createAllIds from '../utils/createAllIds';
import createById from '../utils/createById';

export const initialState: DmState = {
  dms: {
    allIds: [],
    byId: {}
  },
  elements: {
    allIds: [],
    byId: {}
  },
  groups: {
    allIds: [],
    byId: {}
  },
  filters: {
    allIds: [],
    byId: {}
  },
  filterItems: {
    allIds: [],
    byId: {}
  },
  tags: {
    allIds: [],
    byId: {}
  },
  dmTags: {},
  exportedElements: [],
  isRequesting: false
};

const dmReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case types.GET_DMS_REQUEST:
    case types.UPDATE_DM_REQUEST:
    case types.GET_DM_REQUEST:
    case types.GET_DM_GROUPS_REQUEST:
    case types.GET_DM_TAGS_REQUEST:
      return {
        ...state,
        isRequesting: true
      };
    case types.SET_ELEMENT_ORDER:
      return {
        ...state,
        elements: {
          ...state.elements,
          allIds: action.payload || []
        }
      };
    case types.GET_DMS_SUCCESS: {
      let {
        dms = [],
        groups = [],
        filters = [],
        filterItems = [],
        elements = []
      } = action.payload;

      dms = {
        allIds: createAllIds(dms, 'dmId'),
        byId: createById(dms, 'dmId')
      };
      groups = {
        allIds: createAllIds(groups, 'groupId'),
        byId: createById(groups, 'groupId')
      };
      filters = {
        allIds: createAllIds(filters, 'filterId'),
        byId: createById(filters, 'filterId')
      };
      filterItems = {
        allIds: createAllIds(filterItems, 'itemId'),
        byId: createById(filterItems, 'itemId')
      };
      elements = {
        allIds: createAllIds(elements, 'elementId'),
        byId: createById(elements, 'elementId')
      };
      elements.allIds.forEach((id: number) => {
        const element = elements.byId[id];
        if (element.startDate === null) {
          element.startDate = '';
        }
        if (element.startDateFormat === null) {
          element.startDateFormat = '';
        }
        if (element.endDate === null) {
          element.endDate = '';
        }
        if (element.endDateFormat === null) {
          element.endDateFormat = '';
        }
        if (element.link === null) {
          element.link = '';
        }
        element.filters =
          element.filterItems?.map(
            (itemId) => filterItems.byId[itemId]?.filterId
          ) || [];
      });
      return {
        ...state,
        dms,
        groups,
        filters,
        filterItems,
        elements,
        isRequesting: false
      };
    }
    case types.GET_DM_SUCCESS: {
      let {
        dms = [],
        groups = {},
        filters = {},
        filterItems = {},
        elements = {}
      } = action.payload;
      dms = {
        allIds: union(state.dms.allIds, createAllIds(dms, 'dmId')),
        byId: { ...state.dms.byId, ...createById(dms, 'dmId') }
      };
      groups = {
        allIds: union(state.groups.allIds, createAllIds(groups, 'groupId')),
        byId: { ...state.groups.byId, ...createById(groups, 'groupId') }
      };
      filters = {
        allIds: union(state.filters.allIds, createAllIds(filters, 'filterId')),
        byId: { ...state.filters.byId, ...createById(filters, 'filterId') }
      };
      filterItems = {
        allIds: union(
          state.filterItems.allIds,
          createAllIds(filterItems, 'itemId')
        ),
        byId: {
          ...state.filterItems.byId,
          ...createById(filterItems, 'itemId')
        }
      };
      elements = {
        allIds: union(
          createAllIds(elements, 'elementId'),
          state.elements.allIds
        ),
        byId: { ...state.elements.byId, ...createById(elements, 'elementId') }
      };
      elements.allIds.forEach((id: number) => {
        const element = elements.byId[id];
        if (element.startDate === null) {
          element.startDate = '';
        }
        if (element.startDateFormat === null) {
          element.startDateFormat = '';
        }
        if (element.endDate === null) {
          element.endDate = '';
        }
        if (element.endDateFormat === null) {
          element.endDateFormat = '';
        }
        if (element.link === null) {
          element.link = '';
        }
        element.filters =
          element.filterItems?.map(
            (itemId) => filterItems.byId[itemId]?.filterId
          ) || [];
      });
      return {
        ...state,
        dms,
        groups,
        filters,
        filterItems,
        elements,
        isRequesting: false
      };
    }

    case types.GET_DM_GROUPS_SUCCESS: {
      const { payload = [] } = action;
      const groups = {
        allIds: union(state.groups.allIds, createAllIds(payload, 'groupId')),
        byId: { ...state.groups.byId, ...createById(payload, 'groupId') }
      };
      return { ...state, groups };
    }
    case types.GET_DM_FILTERS_SUCCESS: {
      const { payload = [] } = action;
      const filters = {
        allIds: union(state.filters.allIds, createAllIds(payload, 'filterId')),
        byId: { ...state.filters.byId, ...createById(payload, 'filterId') }
      };
      return { ...state, filters };
    }
    case types.GET_DM_TAGS_SUCCESS: {
      const { payload } = action;
      const { dmTags: dmTagsPayload, dmId } = payload;
      const tags = {
        allIds: union(
          ...state.tags.allIds,
          createAllIds(dmTagsPayload, 'tagId')
        ),
        byId: {
          ...state.tags.byId,
          ...createById(dmTagsPayload, 'tagId')
        }
      };
      const dms = { ...state.dms };
      const dmTags = { ...state.dmTags };
      if (dmId) {
        dms.byId = {
          ...state.dms?.byId,
          [dmId]: { ...state.dms.byId[dmId], tags }
        };
        dmTags[dmId] = createAllIds(dmTagsPayload, 'tagId');
      }
      return {
        ...state,
        tags,
        dmTags,
        dms,
        isRequesting: false
      };
    }
    case types.GET_FILTER_ITEMS_SUCCESS: {
      const { payload } = action;
      const filterItems = {
        allIds: union(
          state.filterItems.allIds,
          createAllIds(payload, 'itemId')
        ),
        byId: { ...state.filterItems.byId, ...createById(payload, 'itemId') }
      };
      return {
        ...state,
        filterItems,
        isRequesting: false
      };
    }
    case types.CREATE_DM_TAG_SUCCESS: {
      const { payload } = action;
      const { tagId } = payload;
      const tags = {
        allIds: [...state.tags.allIds, tagId],
        byId: { ...state.tags.byId, [tagId]: payload }
      };
      return {
        ...state,
        tags,
        isRequesting: false
      };
    }
    case types.CREATE_DM_FILTER_SUCCESS: {
      const { payload = {} } = action;
      const { dmId, filterId } = payload;
      return {
        ...state,
        dms: {
          ...state.dms,
          byId: {
            ...state.dms.byId,
            [dmId]: {
              ...state.dms.byId[dmId],
              filterIds: [...state.dms.byId[dmId].filterIds, filterId]
            }
          }
        },
        filters: {
          ...state.filters,
          allIds: [...state.filters.allIds, filterId],
          byId: {
            ...state.filters.byId,
            [filterId]: payload
          }
        },
        isRequesting: false
      };
    }
    case types.CREATE_FILTER_ITEM_SUCCESS: {
      const { payload = {} } = action;
      const { dmId, itemId } = payload;
      return {
        ...state,
        dms: {
          ...state.dms,
          byId: {
            ...state.dms.byId,
            [dmId]: {
              ...state.dms.byId[dmId],
              filterItemIds: [...state.dms.byId[dmId].filterItemIds, itemId]
            }
          }
        },
        filterItems: {
          ...state.filterItems,
          allIds: [...state.filterItems.allIds, itemId],
          byId: {
            ...state.filterItems.byId,
            [itemId]: payload
          }
        },
        isRequesting: false
      };
    }
    case types.CREATE_DM_GROUP_SUCCESS: {
      const { payload = {} } = action;
      const { dmId, groupId } = payload;

      return {
        ...state,
        dms: {
          ...state.dms,
          byId: {
            ...state.dms.byId,
            [dmId]: {
              ...state.dms.byId[dmId],
              groupIds: [...state.dms.byId[dmId].groupIds, groupId]
            }
          }
        },
        groups: {
          ...state.groups,
          allIds: [...state.groups.allIds, groupId],
          byId: {
            ...state.groups.byId,
            [groupId]: { ...payload, id: groupId }
          }
        },
        isRequesting: false
      };
    }
    case types.UPDATE_DM_GROUP_ORDER: {
      const { payload = [] } = action;
      const { dmId, sortOrder } = payload;

      return {
        ...state,
        dms: {
          ...state.dms,
          byId: {
            ...state.dms.byId,
            [dmId]: {
              ...state.dms.byId[dmId],
              groupIds: sortOrder
            }
          }
        }
      };
    }
    case types.UPDATE_DM_FILTER_ORDER: {
      const { payload = [] } = action;
      const { dmId, sortOrder } = payload;

      return {
        ...state,
        dms: {
          ...state.dms,
          byId: {
            ...state.dms.byId,
            [dmId]: {
              ...state.dms.byId[dmId],
              filterIds: sortOrder
            }
          }
        }
      };
    }
    case types.UPDATE_DM_FILTER_ITEM_ORDER: {
      const { payload = [] } = action;
      const { dmId, sortOrder } = payload;

      return {
        ...state,
        dms: {
          ...state.dms,
          byId: {
            ...state.dms.byId,
            [dmId]: {
              ...state.dms.byId[dmId],
              filterItemIds: sortOrder
            }
          }
        }
      };
    }
    case types.UPDATE_DM_FILTER_SUCCESS: {
      return {
        ...state,
        isRequesting: false
      };
    }
    case types.CREATE_DM_ELEMENT_SUCCESS:
    case types.UPDATE_DM_ELEMENT_SUCCESS: {
      return {
        ...state,
        isRequesting: false
      };
    }
    case types.GET_DMS_FAILURE:
    case types.GET_DM_FAILURE:
      return {
        ...state,
        isRequesting: false,
        error: action.error
      };
    case types.DELETE_DM_ELEMENT_SUCCESS: {
      const elements = {
        allIds: [...state.elements.allIds].filter(
          (id: number) => id !== action.payload
        ),
        byId: { ...state.elements.byId }
      };
      delete elements.byId[action.payload];
      return {
        ...state,
        elements
      };
    }
    case types.DELETE_DM_GROUP_SUCCESS: {
      const { groupId, dmId } = action.payload;
      const dms = {
        ...state.dms,
        byId: {
          ...state.dms.byId,
          [dmId]: {
            ...state.dms.byId[dmId],
            groupIds: state.dms.byId[dmId].groupIds.filter(
              (id: number) => id !== groupId
            )
          }
        }
      };
      return {
        ...state,
        dms
      };
    }
    case types.DELETE_DM_FILTER_SUCCESS: {
      const { filterId, dmId } = action.payload;
      const dms = {
        ...state.dms,
        byId: {
          ...state.dms.byId,
          [dmId]: {
            ...state.dms.byId[dmId],
            filterIds: state.dms.byId[dmId].filterIds.filter(
              (id: number) => id !== filterId
            )
          }
        }
      };
      return {
        ...state,
        dms
      };
    }
    case types.DELETE_FILTER_ITEM_SUCCESS: {
      const { itemId, dmId } = action.payload;
      const dms = {
        ...state.dms,
        byId: {
          ...state.dms.byId,
          [dmId]: {
            ...state.dms.byId[dmId],
            filterItemIds: state.dms.byId[dmId].filterItemIds.filter(
              (id: number) => id !== itemId
            )
          }
        }
      };

      return {
        ...state,
        dms
      };
    }
    case types.GET_EXPORTED_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        exportedElements: action.payload
      };
    }
    case types.UPDATE_EXPORTED_DOCUMENTS_SUCCESS: {
      const dmId = action.payload;
      const exportedElements = [...state.exportedElements];
      exportedElements.push(dmId);
      return {
        ...state,
        exportedElements
      };
    }
    case types.REMOVE_EXPORTED_DOCUMENTS_SUCCESS: {
      const dmId = action.payload;
      const exportedElements = [...state.exportedElements];
      exportedElements.splice(exportedElements.indexOf(Number(dmId)), 1);
      return {
        ...state,
        exportedElements
      };
    }
    case types.UPDATE_DM_ELEMENT_LIKE_SUCCESS: {
      const { elementId, like } = action.payload;
      const { byId } = state.elements;
      if (like) {
        byId[elementId].likes = byId[elementId].likes + 1;
        byId[elementId].hasLiked = 1;
      } else {
        byId[elementId].likes = byId[elementId].likes - 1;
        byId[elementId].hasLiked = 0;
      }
      return {
        ...state,
        elements: {
          ...state.elements,
          byId
        }
      };
    }
    case types.UPDATE_DM_ELEMENT_COMMENTS: {
      const { elementId, amount } = action.payload;
      const { byId } = state.elements;
      if (byId[elementId]) {
        byId[elementId].comments = byId[elementId].comments + amount;
      }
      return {
        ...state,
        elements: {
          ...state.elements,
          byId
        }
      };
    }
    case types.TOGGLE_ELEMENT_STATUS_SUCCESS: {
      const { byId } = state.elements;
      const updatedElement = action.payload;
      const { elementId } = updatedElement;
      const updated = {
        ...byId
      };

      if (elementId && byId[elementId]) {
        const previous = byId[elementId];
        updated[elementId] = { ...previous, ...updatedElement };
      }

      return {
        ...state,
        elements: {
          ...state.elements,
          byId: updated
        }
      };
    }
    case types.UPDATE_DM_SUCCESS: {
      const { payload } = action;
      const { dmId } = payload;
      return {
        ...state,
        dms: {
          ...state.dms,
          byId: {
            ...state.dms.byId,
            [dmId]: {
              ...state.dms.byId[dmId],
              ...payload
            }
          }
        }
      };
    }
    default:
      return state;
  }
};

export default dmReducer;
