import React, { ReactElement } from 'react';
import { withRouter, Route, Switch, useHistory } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import Login from './pages/Login';
import AppRouter from './AppRouter';
import Axios from './Axios';
import Notifications from './components/Notifications/Notifications';
import config, { CALLBACK_PATH } from './config.js';
import UserNotMapped from './components/Error/UserNotMapped';
import LoginFacade from './pages/Login/SystemMessage/LoginFacade';
export const oktaAuth = new OktaAuth(config.oidc);

const App: React.FC = (): ReactElement => {
  const history = useHistory();
  const onAuthRequired = () => {
    history.push('/login');
  };

  // This boolean is responsible for activating the System Maintenance message (and disabling signin/contact us)
  // It should be set and unset with a hotfix.
  // You can alter the message within the <Maintenance /> component
  // false by default (false displays the app normally)
  const hasSystemMessage = false;

  return (
    <>
      {hasSystemMessage ? (
        <LoginFacade />
      ) : (
        <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired}>
          <div className='App'>
            <Axios />
            <Notifications />
            <Switch>
              <Route exact path='/user-not-mapped' component={UserNotMapped} />
              <Route path='/login' component={Login} />
              <SecureRoute path='/' component={AppRouter} />
              <Route path={CALLBACK_PATH} component={LoginCallback} />
            </Switch>
          </div>
        </Security>
      )}
    </>
  );
};

export default withRouter(App);
