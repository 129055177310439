import React from 'react';
import classNames from 'classnames';
import { Menu } from '@ovis-technologies/ovis-blueprint';

interface Props {
  elements: any;
  openState: any;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
}

const SideNavPanel = ({ elements, openState }: Props) => {
  const panelClass = classNames('side-nav-panel', {
    '--open': openState
  });

  const handleOnClick = (e) => {
    const target = e.target as HTMLInputElement;
    if (target.className.includes('side-nav-panel')) e.stopPropagation();
  };

  return (
    <div className={panelClass} onClick={handleOnClick}>
      <div className='wrapper'>
        {elements.length > 0 && (
          <Menu
            currentSubMenuOnly
            className='side-nav-menu'
            closeSubMenus={!openState}
          >
            {elements}
          </Menu>
        )}
      </div>
    </div>
  );
};

export default SideNavPanel;
