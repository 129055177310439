import * as commentActionTypes from './types/commentActionTypes';
import { setUpdatedElementComments } from './dmActions';
import { ActionCreator, Dispatch, Action } from 'redux';
import {
  addCommentRequest,
  updateCommentRequest,
  getCommentsRequest,
  toggleCommentLikeRequest
} from '../services/dmService';
import * as RequestBodies from '../services/types/dmRequestBodies';

import { ResponseData } from '../App.d';

export const setComments: ActionCreator<Action> = (data) => ({
  type: commentActionTypes.GET_COMMENTS_SUCCESS,
  payload: data
});

export const getComments: ActionCreator<any> =
  (elementIds: number[]) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: commentActionTypes.COMMENT_REQUEST });
    try {
      const responseData: ResponseData = await getCommentsRequest(elementIds);
      dispatch(setComments(responseData.response));
      return responseData;
    } catch (err: any) {
      console.error(err);
      dispatch({ type: commentActionTypes.COMMENT_FAIL });
      throw err;
    }
  };

export const setAddComment: ActionCreator<Action> = (data) => ({
  type: commentActionTypes.ADD_COMMENTS_SUCCESS,
  payload: data
});

export const addComments: ActionCreator<any> =
  (data: RequestBodies.AddCommentRequestBody) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    try {
      const responseData: ResponseData = await addCommentRequest(data);
      dispatch(
        setAddComment({
          ...responseData.response,
          status: 1
        })
      );
      dispatch(
        setUpdatedElementComments({ elementId: data.elementId, amount: 1 })
      );
      return responseData;
    } catch (err: any) {
      console.error(err);
      throw err;
    }
  };

export const setUpdateComments: ActionCreator<Action> = (data) => ({
  type: commentActionTypes.UPDATE_COMMENTS_SUCCESS,
  payload: data
});

export const updateComments: ActionCreator<any> =
  (data: RequestBodies.UpdateCommentRequestBody) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    try {
      const responseData: ResponseData = await updateCommentRequest(data);
      dispatch(setUpdateComments(data));
      if (data.status !== undefined) {
        dispatch(
          setUpdatedElementComments({
            elementId: data.elementId,
            amount: data.status === 1 ? 1 : -1
          })
        );
      }
      return responseData;
    } catch (err: any) {
      console.error(err);
      dispatch({ type: commentActionTypes.COMMENT_FAIL });
      throw err;
    }
  };

export const updateCommentLike =
  (commentId: number, like: boolean) =>
  async (dispatch): Promise<ResponseData> => {
    dispatch({ type: commentActionTypes.UPDATE_COMMENT_LIKE_REQUEST });

    try {
      const responseData: ResponseData = await toggleCommentLikeRequest({
        commentId
      });
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: commentActionTypes.UPDATE_COMMENT_LIKE_FAILURE, error });
      throw error;
    }
  };
