import { GlobalState } from '../reducers/reducers';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Feature, FeatureOverrideState } from '../types/features.d';
import { privilege } from '../Routes/constants';

export default function useFeature(featureConstant: string): boolean {
  const featureOverride: FeatureOverrideState = useSelector(
    (state: GlobalState) =>
      get(
        state,
        `ui.featureOverride.${featureConstant}`,
        FeatureOverrideState.none
      )
  );
  const { statusId }: Feature = useSelector((state: GlobalState) =>
    get(state, `features.byId.${featureConstant}`, {})
  );
  const hasFeaturePrivilege: boolean = useSelector((state: GlobalState) => {
    const privs = state.user.users.byId[state.user.activeUserId]?.privs || [];
    return privs.includes(privilege.featuresManagement);
  });

  if (featureOverride === FeatureOverrideState.off) {
    return false;
  } else if (featureOverride === FeatureOverrideState.on) {
    return true;
  }

  if (statusId === 3) {
    return true;
  } else if (statusId === 2) {
    return hasFeaturePrivilege;
  }

  return false;
}
