import React from 'react';
import { useMobile } from '@ovis-technologies/ovis-blueprint';
import { useSelector } from 'react-redux';

import Header from '../Header';
import { elementsLoading } from '../../selectors';

const MobileNav = React.lazy(() => import('../MobileNav/MobileNav'));

const NavigationControl = () => {
  const [mobile] = useMobile(767);

  const loading = useSelector(elementsLoading);

  return (
    <>
      {!loading ? (
        <>
          {!mobile && <Header />}
          {mobile && <MobileNav />}
        </>
      ) : null}
    </>
  );
};

export default NavigationControl;
