import React, { useEffect, useState, useCallback } from 'react';
import {
  TableColumn,
  Table,
  Button,
  TableAction,
  TableRecord,
  Modal,
  formatDate,
  Portal
} from '@ovis-technologies/ovis-blueprint';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllUsers } from '../../../selectors/userSelectors';
import { getAllTerms } from '../../../actions/userActions';
import { exportTOUreportRequest } from '../../../services/exportService';
import { downloadBlob } from '../../../utils';
import HtmlBuilder from '../../../components/HTMLBuilder/HtmlBuilder';
import FlexContainer from '../../../components/FlexContainer';

const ManageTerms = ({ refresh, setRefresh }) => {
  const dispatch = useDispatch();

  const [rowData, setRowData] = useState<TableRecord[]>([]);

  const [exportLoading, setExportLoading] = useState<boolean>(false);

  // PREVIEW MODAL States
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewVersion, setPreviewVersion] = useState<number>(0);
  const [previewContent, setPreviewContent] = useState<string>('');

  const { byId: usersById } = useSelector(selectAllUsers);

  const requestData = useCallback(async () => {
    setRefresh(true);
    let results;
    try {
      results = await dispatch(getAllTerms());
      const termsData = results.map((row) => ({
        key: row.termId,
        lastMod: {
          date: formatDate(row.lastModDate, { format: 'MM/DD/YYYY' }),
          name: usersById[row.lastModBy]?.name || ''
        },
        ...row
      }));
      setRowData(termsData);
    } catch (error) {
      console.error(error);
      throw new Error('Error getting Terms Of Use Data');
    }
    setRefresh(false);
  }, [dispatch, setRefresh, usersById]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  // PREVIEW MODAL handlers
  const handlePreviewModalOpen = (id: number) => {
    const selected = rowData.find((ele) => {
      return ele.termId === id;
    });
    setPreviewVersion(id);
    setPreviewContent(selected?.termsOfUse);
    setPreviewVisible(true);
  };

  const handlePreviewModalClose = () => {
    setPreviewVisible(false);
    setPreviewContent('');
    setPreviewVersion(0);
  };

  const handleExportReport = async (termId?) => {
    setExportLoading(true);
    try {
      const { buffer, fileName } = await exportTOUreportRequest(termId);
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      downloadBlob(blob, fileName);
    } catch (error) {
      console.error(error);
    }
    setExportLoading(false);
  };

  const tableActions: TableAction[] = [
    {
      type: 'opinion',
      name: 'Review',
      onClick: handlePreviewModalOpen
    },
    {
      type: 'pie',
      name: 'Report',
      onClick: handleExportReport
    }
  ];

  const columns: TableColumn[] = [
    {
      title: 'Version',
      dataIndex: 'termId',
      key: 'termId'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Last Modified By',
      dataIndex: 'lastMod',
      key: 'lastMod',
      render: ({ name, date }) => (
        <>
          <p style={{ margin: 0 }}>{name}</p>
          <p>{date}</p>
        </>
      )
    }
  ];

  return (
    <>
      <FlexContainer justify='space-between'>
        <h2 className='component-subtitle'>Versions</h2>
        <Button
          className='btn export-btn'
          onClick={() => handleExportReport()}
          loading={exportLoading}
          content='Export User Agreement'
          styleType='primary'
        />
      </FlexContainer>

      <Table
        actions={tableActions}
        rowData={rowData}
        columns={columns}
        loading={refresh}
        pagination={rowData?.length > 20 && !refresh}
      />
      <Portal>
        <Modal
          visible={previewVisible}
          title={`Viewing Version ${previewVersion}`}
          closeButton
          onCancel={handlePreviewModalClose}
        >
          <div className='terms-wrapper'>
            <HtmlBuilder content={previewContent} />
          </div>
        </Modal>
      </Portal>
    </>
  );
};

export default ManageTerms;
