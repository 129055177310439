import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';

interface Props {
  children?: ReactNode;
  onHomeClick?: (e?: any) => any;
}

const FullscreenRedirect = ({ children, onHomeClick }: Props) => {
  return (
    <div className='fullscreen-redirect'>
      <img
        className='app-logo'
        src={config.app.logo}
        alt={config.app.appName}
      />
      {children}
      <Link to='/' onClick={onHomeClick}>
        Back to the home page
      </Link>
    </div>
  );
};

export default FullscreenRedirect;
