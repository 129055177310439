import { AppDispatch } from '../store';

import * as service from '../services/dmService';
import * as types from './types/dmActionTypes';
import { ResponseData, SearchDm } from '../App.d';
import { IDmFilter, IDmGroup } from '../types/dm';

export const setDmsInfo = (dmInfo: any) => ({
  type: types.GET_DMS_SUCCESS,
  payload: dmInfo
});

export const setDmInfo = (dmInfo: any) => ({
  type: types.GET_DM_SUCCESS,
  payload: dmInfo
});

export const createdDmInfo = (dmInfo: any) => ({
  type: types.GET_DM_SUCCESS,
  payload: dmInfo
});

export const setDmGroups = (dmGroups: any) => ({
  type: types.GET_DM_GROUPS_SUCCESS,
  payload: dmGroups
});
export const setNewGroup = (data: IDmGroup) => ({
  type: types.CREATE_DM_GROUP_SUCCESS,
  payload: data
});
export const setUpdatedGroup = (dmGroups: any) => ({
  type: types.UPDATE_DM_GROUP_SUCCESS,
  payload: dmGroups
});
export const setDeletedGroup = (payload: {
  groupId: number;
  dmId: number;
}) => ({
  type: types.DELETE_DM_GROUP_SUCCESS,
  payload
});
export const setDmTags = (dmTags: any, dmId) => ({
  type: types.GET_DM_TAGS_SUCCESS,
  payload: { dmTags, dmId }
});
export const setCreatedTag = (dmTags: any) => ({
  type: types.CREATE_DM_TAG_SUCCESS,
  payload: dmTags
});
export const setCreatedElement = (elementData: number) => ({
  type: types.CREATE_DM_ELEMENT_SUCCESS,
  payload: elementData
});
export const setUpdatedElement = (elementData: number) => ({
  type: types.UPDATE_DM_ELEMENT_SUCCESS,
  payload: elementData
});
export const setUpdatedElementComments = (payload) => ({
  type: types.UPDATE_DM_ELEMENT_COMMENTS,
  payload
});
export const setDeletedElement = (elementId: number) => ({
  type: types.DELETE_DM_ELEMENT_SUCCESS,
  payload: elementId
});
export const setNewDmFilter = (filter: any) => ({
  type: types.CREATE_DM_FILTER_SUCCESS,
  payload: filter
});
export const setUpdatedFilter = (filter: any) => ({
  type: types.UPDATE_DM_FILTER_SUCCESS,
  payload: filter
});
export const setDeletedFilter = (payload: {
  dmId: number;
  filterId: number;
}) => ({
  type: types.DELETE_DM_FILTER_SUCCESS,
  payload
});
export const setDmFilters = (data: any[]) => ({
  type: types.GET_DM_FILTERS_SUCCESS,
  payload: data
});
export const setFilterItems = (filter: any) => ({
  type: types.GET_FILTER_ITEMS_SUCCESS,
  payload: filter
});
export const setNewFilterItem = (filter: any) => ({
  type: types.CREATE_FILTER_ITEM_SUCCESS,
  payload: filter
});
export const setUpdatedFilterItem = (filter: any) => ({
  type: types.UPDATE_FILTER_ITEM_SUCCESS,
  payload: filter
});
export const setDeletedFilterItem = (payload: {
  itemId: number;
  dmId: number;
}) => ({
  type: types.DELETE_FILTER_ITEM_SUCCESS,
  payload
});
export const setToggleElementStatus = (data: {
  elementId: number;
  status: number;
  lastModDate?: string;
  lastModBy?: number;
}) => ({
  type: types.TOGGLE_ELEMENT_STATUS_SUCCESS,
  payload: data
});
export const updateDmElementOrder = (sortOrder: number[]) => ({
  type: types.SET_ELEMENT_ORDER,
  payload: sortOrder
});
export const updateDmGroupOrder = (payload: {
  dmId?: number;
  sortOrder: number[];
}) => ({
  type: types.UPDATE_DM_GROUP_ORDER,
  payload
});
export const updateDmFilterOrder = (payload: {
  dmId?: number;
  sortOrder: number[];
}) => ({
  type: types.UPDATE_DM_FILTER_ORDER,
  payload
});
export const updateDmFilterItemOrder = (payload: {
  dmId?: number;
  sortOrder: number[];
}) => ({
  type: types.UPDATE_DM_FILTER_ITEM_ORDER,
  payload
});

// Create DMs
export const createDm = (data) => async (dispatch) => {
  dispatch({ type: types.CREATE_DM_REQUEST });
  try {
    const responseData: ResponseData = await service.addDmRequest(data);
    dispatch({ type: types.CREATE_DM_SUCCESS });
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: types.CREATE_DM_FAILURE });
    throw error;
  }
};

// Update DMs
export const updateDm = (data) => async (dispatch) => {
  dispatch({ type: types.UPDATE_DM_REQUEST });
  try {
    const responseData: ResponseData = await service.editDmRequest(data);
    dispatch({ type: types.UPDATE_DM_SUCCESS, payload: data });
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: types.UPDATE_DM_FAILURE });
    throw error;
  }
};

// Search DMs
export const searchDms = (data: SearchDm) => async (dispatch: AppDispatch) => {
  if (data.source) dispatch({ type: types.GET_DMS_REQUEST });
  else dispatch({ type: types.GET_DM_REQUEST });
  try {
    const responseData: ResponseData = await service.searchDmsRequest(data);
    const { response: dmInfo } = responseData;
    if (data.source) dispatch(setDmsInfo(dmInfo));
    dispatch(setDmInfo(dmInfo));
    return dmInfo;
  } catch (error: any) {
    console.error(error);
    if (data.source) dispatch({ type: types.GET_DMS_FAILURE });
    else dispatch({ type: types.GET_DM_FAILURE });
    throw error;
  }
};

// Get all Dm groups
export const getDmGroups = (dmId: number) => async (dispatch: AppDispatch) => {
  dispatch({ type: types.GET_DM_GROUPS_REQUEST });
  try {
    const responseData: ResponseData = await service.getGroupsRequest({ dmId });
    const { response: groups } = responseData;
    dispatch(setDmGroups(groups));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: types.GET_DM_GROUPS_FAILURE, error });
    throw error;
  }
};

export const createDmGroup = (data: IDmGroup) => async (dispatch) => {
  dispatch({ type: types.CREATE_DM_GROUP_REQUEST });
  try {
    const responseData: ResponseData = await service.addGroupRequest(data);
    dispatch(setNewGroup({ ...responseData.response, ...data }));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: types.CREATE_DM_GROUP_FAILURE, error });
    throw error;
  }
};

export const updateDmGroup =
  (data: IDmGroup) =>
  async (dispatch: AppDispatch): Promise<ResponseData> => {
    dispatch({ type: types.UPDATE_DM_GROUP_REQUEST });
    try {
      const responseData: ResponseData = await service.editGroupRequest(data);
      const { response: groups } = responseData;
      dispatch(setUpdatedGroup(groups));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.UPDATE_DM_GROUP_FAILURE, error });
      throw error;
    }
  };

export const deleteDmGroup =
  (payload: { groupId: number; dmId: number }) =>
  async (dispatch: AppDispatch): Promise<ResponseData> => {
    dispatch({ type: types.DELETE_DM_GROUP_REQUEST });
    try {
      const responseData: ResponseData = await service.deleteGroupRequest(
        payload
      );
      dispatch(setDeletedGroup(payload));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.DELETE_DM_GROUP_FAILURE, error });
      throw error;
    }
  };

export const getFilterItems = (filterId) => async (dispatch: AppDispatch) => {
  dispatch({ type: types.GET_FILTER_ITEMS_REQUEST });
  try {
    const responseData: ResponseData = await service.getFilterItemsRequest({
      filterId
    });
    const { response = {} } = responseData;
    dispatch(setFilterItems(response));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: types.GET_FILTER_ITEMS_FAILURE, error });
    throw error;
  }
};

export const createFilterItem =
  (data: { filterId: number; name: string }) =>
  async (dispatch: AppDispatch): Promise<ResponseData> => {
    dispatch({ type: types.CREATE_FILTER_ITEM_REQUEST });
    try {
      const responseData: ResponseData = await service.addFilterItemRequest(
        data
      );
      const { response: newFilterItemResponse = {} } = responseData;
      dispatch(setNewFilterItem({ ...data, ...newFilterItemResponse }));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.CREATE_FILTER_ITEM_FAILURE, error });
      throw error;
    }
  };

export const updateFilterItem =
  (data: IDmFilter) =>
  async (dispatch: AppDispatch): Promise<ResponseData> => {
    dispatch({ type: types.UPDATE_FILTER_ITEM_REQUEST });
    try {
      const responseData: ResponseData = await service.editFilterItemRequest(
        data
      );
      const { response: updatedFilterItemResponse = {} } = responseData;
      dispatch(setUpdatedFilterItem({ ...data, ...updatedFilterItemResponse }));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.UPDATE_FILTER_ITEM_FAILURE, error });
      throw error;
    }
  };
export const deleteFilterItem =
  (payload: { itemId: number; dmId: number }) =>
  async (dispatch: AppDispatch): Promise<ResponseData> => {
    dispatch({ type: types.DELETE_DM_FILTER_REQUEST });
    try {
      const responseData: ResponseData = await service.deleteFilterItemRequest(
        payload
      );
      dispatch(setDeletedFilterItem(payload));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.DELETE_DM_FILTER_FAILURE, error });
      throw error;
    }
  };
export const getDmFilters = (dmId) => async (dispatch: AppDispatch) => {
  dispatch({ type: types.GET_DM_FILTERS_REQUEST });
  try {
    const responseData: ResponseData = await service.getFiltersRequest({
      dmId
    });
    const { response: newFilterResponse = {} } = responseData;
    dispatch(setDmFilters(newFilterResponse));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: types.GET_DM_FILTERS_FAILURE, error });
    throw error;
  }
};

export const createDmFilter = (data: IDmFilter) => async (dispatch) => {
  dispatch({ type: types.CREATE_DM_FILTER_REQUEST });
  try {
    const responseData: ResponseData = await service.addFilterRequest(data);
    dispatch(setNewDmFilter({ ...data, ...responseData.response }));
    return responseData;
  } catch (error: any) {
    console.error(error);
    dispatch({ type: types.CREATE_DM_FILTER_FAILURE, error });
    throw error;
  }
};

export const updateDmFilter =
  (data: IDmFilter) =>
  async (dispatch: AppDispatch): Promise<ResponseData> => {
    dispatch({ type: types.UPDATE_DM_FILTER_REQUEST });
    try {
      const responseData: ResponseData = await service.editFilterRequest(data);
      const { response: updatedFilterResponse = {} } = responseData;
      dispatch(setUpdatedFilter({ ...data, ...updatedFilterResponse }));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.UPDATE_DM_FILTER_FAILURE, error });
      throw error;
    }
  };
export const deleteDmFilter =
  (payload: { dmId: number; filterId: number }) =>
  async (dispatch: AppDispatch): Promise<ResponseData> => {
    dispatch({ type: types.DELETE_DM_FILTER_REQUEST });
    try {
      const responseData: ResponseData = await service.deleteFilterRequest({
        filterId: payload.filterId
      });
      dispatch(setDeletedFilter(payload));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.DELETE_DM_FILTER_FAILURE, error });
      throw error;
    }
  };

export const getDmTags =
  (dmId?) =>
  async (dispatch: AppDispatch): Promise<ResponseData> => {
    dispatch({ type: types.GET_DM_TAGS_REQUEST });
    try {
      const responseData: ResponseData = await service.getTagsRequest(dmId);
      const { response: data } = responseData;
      dispatch(setDmTags(data, dmId));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.GET_DM_GROUPS_FAILURE, error });
      throw error;
    }
  };

export const createDmTag =
  (name: string) =>
  async (dispatch: AppDispatch): Promise<ResponseData> => {
    dispatch({ type: types.CREATE_DM_TAG_REQUEST });
    try {
      const responseData: ResponseData = await service.addTagRequest({ name });
      const { response: data } = responseData;
      dispatch(setCreatedTag({ ...data, name }));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.CREATE_DM_TAG_FAILURE, error });
      throw error;
    }
  };

export const createDmElement =
  (data) =>
  async (dispatch): Promise<ResponseData> => {
    dispatch({ type: types.CREATE_DM_ELEMENT_REQUEST });
    try {
      const responseData: ResponseData = await service.addElementRequest(data);
      dispatch(setCreatedElement(data));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.CREATE_DM_ELEMENT_FAILURE, error });
      throw error;
    }
  };

export const updateDmElement =
  (data) =>
  async (dispatch): Promise<ResponseData> => {
    dispatch({ type: types.UPDATE_DM_ELEMENT_REQUEST });

    try {
      const responseData: ResponseData = await service.editElementRequest(data);
      dispatch(setUpdatedElement(data));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.UPDATE_DM_ELEMENT_FAILURE, error });
      throw error;
    }
  };

export const setDmLike = (elementInfo) => ({
  type: types.UPDATE_DM_ELEMENT_LIKE_SUCCESS,
  payload: elementInfo
});

export const updateDmElementLike =
  (elementId: number, like: boolean) =>
  async (dispatch): Promise<ResponseData> => {
    dispatch({ type: types.UPDATE_DM_ELEMENT_LIKE_REQUEST });

    try {
      const responseData: ResponseData = await service.toggleLikeRequest({
        elementId
      });
      dispatch(setDmLike({ elementId, like }));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.UPDATE_DM_ELEMENT_LIKE_FAILURE, error });
      throw error;
    }
  };

export const deleteDmElement =
  (elementId, dmId) =>
  async (dispatch): Promise<any> => {
    dispatch({ type: types.DELETE_DM_ELEMENT_REQUEST });
    try {
      const responseData: ResponseData = await service.deleteElementRequest({
        elementId
      });
      dispatch(setDeletedElement(elementId));
      dispatch(searchDms({ dmId, elementActive: 0 }));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.DELETE_DM_ELEMENT_FAILURE, error });
      throw error;
    }
  };

export const setExportedElements = (elements) => ({
  type: types.GET_EXPORTED_DOCUMENTS_SUCCESS,
  payload: elements
});

export const getExportedElements =
  () =>
  async (dispatch): Promise<any> => {
    dispatch({ type: types.GET_EXPORTED_DOCUMENTS_REQUEST });
    try {
      const responseData: ResponseData = await service.getOrgExports();
      const { response } = responseData;
      dispatch(setExportedElements(response));
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.UPDATE_EXPORTED_DOCUMENTS_FAILURE, error });
      throw error;
    }
  };

export const setUpdatedExportedElements = (elementId) => ({
  type: types.UPDATE_EXPORTED_DOCUMENTS_SUCCESS,
  payload: elementId
});

export const updateExportedElements =
  (elementId) =>
  async (dispatch): Promise<any> => {
    dispatch({ type: types.UPDATE_EXPORTED_DOCUMENTS_REQUEST });
    try {
      await service.setElementExportRequest({
        elementId,
        action: 1
      });
      dispatch(setUpdatedExportedElements(elementId));
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.UPDATE_EXPORTED_DOCUMENTS_FAILURE, error });
      throw error;
    }
  };

export const setRemoveExportedElements = (elementId) => ({
  type: types.REMOVE_EXPORTED_DOCUMENTS_SUCCESS,
  payload: elementId
});

export const removeExportedElements =
  (elementId) =>
  async (dispatch): Promise<any> => {
    dispatch({ type: types.REMOVE_EXPORTED_DOCUMENTS_REQUEST });
    try {
      await service.setElementExportRequest({
        elementId,
        action: 0
      });
      dispatch(setRemoveExportedElements(elementId));
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.REMOVE_EXPORTED_DOCUMENTS_FAILURE, error });
      throw error;
    }
  };

export const toggleElementStatus =
  (data) =>
  async (dispatch): Promise<any> => {
    dispatch({ type: types.REMOVE_EXPORTED_DOCUMENTS_REQUEST });
    try {
      const responseData: ResponseData =
        await service.toggleElementStatusRequest(data);
      const { response } = responseData;
      const element = response[0];
      data.lastModDate = element.lastModDate;

      dispatch(setToggleElementStatus(data));

      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.REMOVE_EXPORTED_DOCUMENTS_FAILURE, error });
      throw error;
    }
  };
