import * as types from './types/dashboardActionTypes';
import {
  fetchDashboardRequest,
  createDashboardRequest,
  editDashboardRequest,
  deleteDashboardRequest,
  fetchDashboardWidgetRequest
} from '../services/dashboardService';
import * as localStorage from '../utils/localStorage';

import { ActionCreator, Dispatch } from 'redux';
import { DashboardFields } from '@ovis-technologies/ovis-blueprint';
import { ResponseData } from '../App.d';

export const getDashboards: ActionCreator<any> =
  () =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.FETCH_DASHBOARDS_REQUEST });
    try {
      const responseData: ResponseData = await fetchDashboardRequest();
      dispatch({
        type: types.FETCH_DASHBOARDS_SUCCESS,
        payload: responseData.response.dashboards
      });

      dispatch({
        type: types.FETCH_WIDGET_META_SUCCESS,
        payload: responseData.response.widgetMeta
      });
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.FETCH_DASHBOARDS_FAILURE, error });
      throw error;
    }
  };

export const createDashboard: ActionCreator<any> =
  (dashboard: DashboardFields) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.CREATE_DASHBOARD_REQUEST });
    try {
      const responseData: ResponseData = await createDashboardRequest(
        dashboard
      );
      dispatch({
        type: types.CREATE_DASHBOARD_SUCCESS,
        payload: responseData.response
      });
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.CREATE_DASHBOARD_FAILURE, error });
      throw error;
    }
  };

export const editDashboard: ActionCreator<any> =
  (dashboard: DashboardFields) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.EDIT_DASHBOARD_REQUEST });
    try {
      const responseData: ResponseData = await editDashboardRequest(dashboard);
      dispatch({
        type: types.EDIT_DASHBOARD_SUCCESS,
        payload: responseData.response
      });
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.EDIT_DASHBOARD_FAILURE, error });
      throw error;
    }
  };

// See widgetReducer for store changes
export const deleteDashboard: ActionCreator<any> =
  (dashboardId: number) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.DELETE_DASHBOARD_REQUEST });
    try {
      const responseData: ResponseData = await deleteDashboardRequest(
        dashboardId
      );
      dispatch({
        type: types.DELETE_DASHBOARD_SUCCESS,
        payload: dashboardId
      });
      return responseData;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.DELETE_DASHBOARD_FAILURE, error });
      throw error;
    }
  };

// See widgetReducer for store changes
export const getDashboardWidget: ActionCreator<any> =
  (widgetId: number) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.FETCH_DASHBOARD_WIDGET_REQUEST });
    try {
      let payload;
      try {
        payload = localStorage.getStorageItem(`widget-${widgetId}`);
      } catch (e) {
        const responseData: ResponseData = await fetchDashboardWidgetRequest(
          widgetId
        );
        payload = responseData.response;
        localStorage.setStorageItem(
          `widget-${widgetId}`,
          payload,
          payload.expiration
        );
      }

      dispatch({
        type: types.FETCH_DASHBOARD_WIDGET_SUCCESS,
        payload
      });
      return payload;
    } catch (error: any) {
      console.error(error);
      dispatch({ type: types.FETCH_DASHBOARD_WIDGET_FAILURE, error });
      throw error;
    }
  };

export const getUserAudit =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.FETCH_DASHBOARD_WIDGET_REQUEST });
    try {
      const responseData: ResponseData = await fetchDashboardWidgetRequest(22, {
        userId
      });

      const payload = responseData.response;
      dispatch({
        type: types.FETCH_DASHBOARD_WIDGET_SUCCESS,
        payload
      });
      return responseData;
    } catch (error: any) {
      dispatch({ type: types.FETCH_DASHBOARD_WIDGET_FAILURE, error });
      return error;
    }
  };

export const getDmElementAudit =
  (elementId: number) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.FETCH_DASHBOARD_WIDGET_REQUEST });
    try {
      const responseData: ResponseData = await fetchDashboardWidgetRequest(23, {
        elementId
      });

      dispatch({
        type: types.FETCH_DASHBOARD_WIDGET_SUCCESS,
        payload: responseData.response
      });
      return responseData;
    } catch (error: any) {
      dispatch({ type: types.FETCH_DASHBOARD_WIDGET_FAILURE, error });
      return error;
    }
  };

export const getDmElementEngagementReport =
  (elementId: number) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: types.FETCH_DASHBOARD_WIDGET_REQUEST });
    try {
      const responseData: ResponseData = await fetchDashboardWidgetRequest(25, {
        elementId
      });
      dispatch({
        type: types.FETCH_DASHBOARD_WIDGET_SUCCESS,
        payload: responseData.response
      });
      return responseData;
    } catch (error: any) {
      dispatch({ type: types.FETCH_DASHBOARD_WIDGET_FAILURE, error });
      return error;
    }
  };
