import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import axios from 'axios';
import HtmlBuilder from '../HTMLBuilder/HtmlBuilder';
import { removeInterrupt } from '../../actions/interruptActions';
import { RequestState } from '../../../constants/requestStates';
import { USER_ENDPOINT } from '../../services/endpoints';
import { GlobalState } from '../../reducers/reducers';
import { Button, Modal, Checkbox } from '@ovis-technologies/ovis-blueprint';
import { interruptTypes } from '../../../constants';

import './_terms.scss';

interface Props {}

const Terms: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState<boolean>(false);
  const [formLoadState, setFormLoadState] = useState<string>(RequestState.null);

  const interrupt = useSelector((state: GlobalState) =>
    get(state, ['interrupt', interruptTypes.terms], {})
  );

  const { terms, termId } = interrupt;

  const onSubmit = async (e: any) => {
    e.preventDefault();
    e.persist();
    setFormLoadState(RequestState.sending);
    try {
      await axios.post(USER_ENDPOINT, {
        action: 'NEW_USER_AGREEMENT',
        data: { termId }
      });
      dispatch(removeInterrupt(interruptTypes.terms));
    } catch (err: any) {
      console.error(err);
    }
    setFormLoadState(RequestState.null);
  };

  return (
    <Modal
      visible={Boolean(terms)}
      outsideClickCancel={false}
      closeButton={false}
      title='Terms of Agreement'
    >
      <div className='terms-wrapper'>
        <HtmlBuilder content={terms} />
        <form onSubmit={onSubmit} className='terms-form'>
          <Checkbox
            _checked={checked}
            onChange={() => setChecked(!checked)}
            label="I've read and agree to the Terms of Use"
            value=''
          />
          <Button
            className='btn-primary'
            type='submit'
            disabled={!checked}
            loading={formLoadState === RequestState.sending}
            styleType='secondary'
          >
            Agree
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default Terms;
