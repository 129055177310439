import React from 'react';
import FullscreenRedirect from '../FullscreenRedirect/FullscreenRedirect';

interface Props {}

const NoMobileSettings = (props: Props) => {
  return (
    <FullscreenRedirect>
      <h3>Sorry, this page cannot be viewed on mobile.</h3>
    </FullscreenRedirect>
  );
};

export default NoMobileSettings;
