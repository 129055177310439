import React from 'react';
import classnames from 'classnames';
import {
  FileExcel,
  FileIcon as GenericFileIcon,
  FilePdf,
  FilePpt,
  FileWord
} from '@ovis-technologies/ovis-blueprint';
import { getExtension } from '../../utils/validateFiles';

interface Props {
  url: string;
}
const FileIcon = ({ url }: Props) => {
  const extension = getExtension(url);
  const className = 'file-icon-render';
  switch (extension) {
    case 'xls':
    case 'xlsx':
      return <FileExcel className={className} />;
    case 'ppt':
    case 'pptx':
      return <FilePpt className={className} />;
    case 'doc':
    case 'docx':
      return <FileWord className={className} />;
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'svg':
      return (
        <div
          className={classnames(className, '--icon')}
          style={{
            backgroundImage: `url(${url})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
      );
    case 'mp4':
    case 'mp3':
      return (
        <div className={className}>
          <GenericFileIcon />
        </div>
      );
    case 'pdf':
      return <FilePdf className={className} />;
    case 'none':
    case 'txt':
    default:
      return (
        <div className={className}>
          <GenericFileIcon />
        </div>
      );
  }
};

export default FileIcon;
