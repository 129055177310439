import React, { useState, useEffect } from 'react';
import { getQuicksightEmbedRequest } from '../../services/quicksightEmbedService';
import { Loader } from '@ovis-technologies/ovis-blueprint';
import { RequestState } from '../../../constants/requestStates';
import Layout from '../../components/Page';

/**  
URL: /bi_access
NAME: Quicksight
AKA: Businness Inteligence, Quicksight
**/

interface Props {}

const Quicksight = (props: Props) => {
  const [quicksightEmbedUrl, setQuicksightEmbedUrl] = useState<string>('');
  const [loading, setLoading] = useState<RequestState>();
  const [misconfigurationError, setMisconfigurationError] =
    useState<boolean>(false);

  useEffect(() => {
    const getQuickSightData = async () => {
      try {
        setLoading(RequestState.sending);
        const { response } = await getQuicksightEmbedRequest('Placeholder');
        setQuicksightEmbedUrl(response.aumFlow);
        setLoading(RequestState.success);
      } catch (error: any) {
        if (error.response.error === 'Misconfiguration error') {
          setMisconfigurationError(true);
          setLoading(RequestState.success);
        } else {
          setLoading(RequestState.error);
        }
      }
    };
    getQuickSightData();
  }, []);

  return (
    <Layout title='Performance Dashboard' className='quicksight-wrapper'>
      {loading !== RequestState.success ? (
        <Loader />
      ) : (
        <>
          {misconfigurationError ? (
            <>
              <h3>
                Misconfiguration error in advisor dashboard, please contact your
                administrator and let them know. Please include this message in
                your e-mail.
              </h3>
            </>
          ) : (
            <div>
              <div className='quicksight-padding-embed'>
                <div className='wrapper'>
                  <iframe
                    title='quicksight'
                    id='quicksightIframe'
                    width='100%'
                    height='1800'
                    src={quicksightEmbedUrl}
                  />
                  <div className='bar' />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export default Quicksight;
