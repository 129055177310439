import React from 'react';
import { TemplateProps } from '.';
import CoverImage from '../CoverImage/CoverImage';
import { ActionToolbar } from '../DMBlogging';
import Details from './Details';

export const FeedTemplate = ({
  layoutClass = '',
  elements = [],
  isCalendarEvent = false,
  sortMethodOverride
}: TemplateProps) => {
  if (isCalendarEvent && !sortMethodOverride) {
    elements.sort((a, b) => {
      return (
        new Date(b.calStartDate).getTime() - new Date(a.calStartDate).getTime()
      );
    });
  }
  return (
    <div className={layoutClass}>
      {elements.map((doc) => {
        const { elementId } = doc;
        return (
          <Card key={elementId} doc={doc} isCalendarEvent={isCalendarEvent} />
        );
      })}
    </div>
  );
};

interface CardProps {
  doc: any;
  children?;
  isCalendarEvent?;
}

export const Card = ({ doc, children, isCalendarEvent }: CardProps) => {
  const { elementId, coverImage, title, fileLocation, link } = doc;

  return (
    <div key={elementId} className='document --card-document'>
      <Details
        elementId={elementId}
        isCalendarEvent={isCalendarEvent}
        children={children}
      />
      <CoverImage
        contentUrl={fileLocation || link}
        className=''
        coverImageSrc={coverImage}
        alt='Cover Image'
        elementId={elementId}
      />
      <ActionToolbar elementId={elementId} title={title} moduleId={1} />
    </div>
  );
};

export default FeedTemplate;
