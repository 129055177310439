/**
 * This component expects to recieve a linkPaths array containing objects with
 * a path and text property.
 * 
 * ex:
  const linkPaths = [
    { path: 'edit', text: 'EDIT PROFILE' },
    { path: 'change_password', text: 'CHANGE PASSWORD' },
    { path: 'notifications', text: 'NOTIFICATION MANAGEMENT' }
  ];
 */

import React from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { track } from '../../services/trackingService';
import {
  selectNavElements,
  selectNavigationLookup
} from '../../selectors/navSelectors';
import { selectSideNavOpen } from '../../selectors';

import './_page_nav.scss';

export interface Path {
  url: string;
  name: string;
  id?: number;
  dmId?: number;
}

export interface PageNavType {
  linkPaths?: Array<Path>;
  onChange?: (id: number, navId?: number) => void;
  actions?: {
    action?: () => {};
    type: 'link' | 'function';
    title: string;
    path?: string;
  }[];
}

const PageNav: React.FC<PageNavType> = ({
  linkPaths = [],
  onChange = () => {},
  actions = []
}) => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const sideNavOpen = useSelector(selectSideNavOpen);
  const pathLookup = useSelector(selectNavigationLookup);
  const { byId: elementsById, allIds: elementsIds } =
    useSelector(selectNavElements);
  if (elementsIds.length < 1) return null;
  // Get the subnav for parent nav. Ex. ExecutiveDashboard:{subnav = [61,63];}
  // Build page links using nav store rather than hard coding it everywhere or building it in component
  // Have optional linkPaths in case of needed hardcoding
  const navElements: Path[] | number[] =
    linkPaths || get(pathLookup, `[${url}].subNav`, []);
  const links = navElements.map((navElement) => {
    const element =
      typeof navElement === 'number' ? elementsById[navElement] : navElement;
    const { url: path, navId, name, id, dmId, groupId } = element;
    const link = linkPaths
      ? path[0] === '/'
        ? url + path
        : url + '/' + path
      : path;
    const basePathname = pathname.split('#')[0];
    const checkIfActive = basePathname.includes(link);
    const classString = classnames('page-nav-link ', {
      active: checkIfActive
    });

    return (
      <Link
        key={path}
        className={classString}
        to={{
          pathname: link,
          state: { navId, dmId, groupId }
        }}
        onClick={() => {
          track({ context: basePathname, typeId: 1 });
          onChange(id, navId);
        }}
      >
        {name}
      </Link>
    );
  });

  const actionElements = actions.map((navElement) => {
    const element =
      typeof navElement === 'number' ? elementsById[navElement] : navElement;
    const { path, navId, title, id, dmId, groupId } = element;
    const link = actions
      ? path === '/'
        ? url + path
        : url + '/' + path
      : path;
    const basePathname = pathname.split('#')[0];
    const checkIfActive = basePathname.includes(link);
    const classString = classnames('page-nav-link ', {
      active: checkIfActive
    });

    return (
      <Link
        key={path}
        className={classString}
        to={{
          pathname: link,
          state: { navId, dmId, groupId }
        }}
        onClick={() => {
          track({ context: basePathname, typeId: 1 });
          onChange(id, navId);
        }}
      >
        {title}
      </Link>
    );
  });

  return (
    <div className='page-nav' style={{ left: sideNavOpen ? 300 : 52.8 }}>
      {links}
      {actionElements}
    </div>
  );
};

export default PageNav;
