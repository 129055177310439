import axios, { AxiosResponse } from 'axios';
import { USER_ENDPOINT, USER_ASSET } from './endpoints';
import { FetchUser, ResponseData, User } from '../App.d';
import { handleServiceError } from './index';

export const fetchUserInfo: FetchUser = (
  userId: number
): Promise<ResponseData> => {
  return axios
    .post(USER_ENDPOINT, { action: 'GET_USER', data: { userId } })
    .then((res) => {
      return res.data;
    })
    .catch(handleServiceError);
};

export const fetchUsers: any = (): Promise<ResponseData> => {
  return axios
    .post(USER_ENDPOINT, { action: 'GET_USERS', data: {} })
    .then((res) => {
      return res.data;
    })
    .catch(handleServiceError);
};

export const putUserInfo = (data: User): Promise<ResponseData> => {
  return axios
    .post(USER_ASSET, { action: 'UPDATE_USER', data })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch(handleServiceError);
};

export const addUser = (data: User): Promise<ResponseData> => {
  return axios
    .post(USER_ASSET, { action: 'NEW_USER', data })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch(handleServiceError);
};

export const fetchUserTemplate = async (orgId: number | undefined) => {
  return axios
    .post(USER_ASSET, { action: 'GET_USERS_TEMPLATE', data: { orgId } })
    .then((res: AxiosResponse) => res.data)
    .catch(handleServiceError);
};

export const postUsers = async (
  fileLocation: string,
  orgId: number | undefined
) => {
  return axios
    .post(USER_ASSET, {
      action: 'PROCESS_USERS_TEMPLATE',
      data: { fileLocation, orgId }
    })
    .then((res: AxiosResponse) => res.data)
    .catch(handleServiceError);
};

export const fetchAccessByPrivReport = async (privId: number) => {
  return axios
    .post(USER_ENDPOINT, {
      action: 'GET_ACCESS_BY_PRIV',
      data: { privId }
    })
    .then((res: AxiosResponse) => res.data)
    .catch(handleServiceError);
};

export const getAllTermsOfUse = async (): Promise<ResponseData> => {
  try {
    const res = await axios.post(USER_ENDPOINT, { action: 'GET_ALL_TERMS' });
    return res.data;
  } catch (err) {
    return handleServiceError(err);
  }
};

export const fetchLatestTerm = async (): Promise<ResponseData> => {
  try {
    const res = await axios.post(USER_ENDPOINT, { action: 'GET_LATEST_TERM' });
    return res.data;
  } catch (err) {
    return handleServiceError(err);
  }
};

export const addTerm = async (data: any): Promise<ResponseData> => {
  try {
    const res = await axios.post(USER_ENDPOINT, {
      action: 'NEW_USER_TERM',
      data
    });
    return res.data;
  } catch (err) {
    return handleServiceError(err);
  }
};

export const getUsersByPriv = (data): Promise<ResponseData> => {
  return axios
    .post(USER_ENDPOINT, { action: 'GET_USERS_WITH_ACCESS_TO_ELEMENT', data })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch(handleServiceError);
};
