import classNames from 'classnames';
import React, { ReactNode, Children } from 'react';

import './simple-grid.scss';

type Props = {
  colsSize?: 1 | 2 | 4 | 3 | 6 | 12;
  rows?: boolean; // On by default
  children?: ReactNode;
};

const Grid = ({ children, colsSize, rows = true }: Props) => {
  // Subcomponents
  let childrenFormatted: ReactNode[] = [];
  if (colsSize) {
    if (!rows) {
      childrenFormatted = Children.toArray(children).map((child, index) => {
        return (
          <div key={index} className={`col-${colsSize}`}>
            {child}
          </div>
        );
      });
    } else {
      const childrenArray = Children.toArray(children);
      const childrenRows: any[] = [];
      while (childrenArray.length) {
        childrenRows.push(childrenArray.splice(0, 12 / colsSize));
      }
      childrenFormatted = childrenRows.map((row, i) => {
        return (
          <div className='row' key={i}>
            {row.map((el, index) => {
              return (
                <div key={index} className={`col-${colsSize}`}>
                  {el}
                </div>
              );
            })}
          </div>
        );
      });
    }
  }

  const classes = classNames('container', {
    '--flex': colsSize && !rows
  });

  return (
    <div className={classes}>
      {colsSize ? childrenFormatted : <div className='row'>{children}</div>}
    </div>
  );
};

export default Grid;
