import {
  modalActions,
  OVERRIDE_FEATURE,
  OPEN_ALERTS,
  CLOSE_ALERTS,
  OPEN_OKTA,
  CLOSE_OKTA,
  OPEN_EDIT_MODE,
  CLOSE_EDIT_MODE,
  sideNavActions,
  OPEN_DOCUMENT_MODAL,
  CLOSE_DOCUMENT_MODAL,
  OPEN_SIDE_PANEL,
  CLOSE_SIDE_PANEL
} from './types/uiActionTypes';
import { Dispatch } from 'redux';
import { FeatureOverride } from '../types/features';
import { track } from '../services/trackingService';
import { SidePanelState } from '../reducers/reducers.d';

export const setModalOpen = (state: boolean) => (dispatch: Dispatch) => {
  if (state) {
    dispatch({ type: modalActions.openModal });
  } else {
    dispatch({ type: modalActions.closeModal });
  }
};

export const setSidePanelState =
  (state: SidePanelState) => (dispatch: Dispatch) => {
    if (state) dispatch({ type: OPEN_SIDE_PANEL, payload: state });
    else dispatch({ type: CLOSE_SIDE_PANEL });
  };

export const setSideNavOpen = (state: boolean) => (dispatch: Dispatch) => {
  if (state) dispatch({ type: sideNavActions.openSideNav });
  else dispatch({ type: sideNavActions.closeSideNav });
};

export const setFeatureOverrides =
  (featureOverride: FeatureOverride) => (dispatch: Dispatch) => {
    dispatch({ type: OVERRIDE_FEATURE, payload: featureOverride });
  };

export const setAlertsOpen = (state: boolean) => {
  if (state) {
    track({ context: 'alerts_view', typeId: 1 });
    return { type: OPEN_ALERTS, payload: state };
  } else {
    return { type: CLOSE_ALERTS, payload: state };
  }
};

export const setOktaOpen = (payload: boolean) => {
  if (payload) {
    // track({ context: 'okta_dashboard_view', typeId: 1 });
    return { type: OPEN_OKTA, payload };
  } else {
    return { type: CLOSE_OKTA, payload };
  }
};

export const setToggleEditMode = (payload) => {
  const { visible, activeElementId } = payload;
  if (visible) {
    return { type: OPEN_EDIT_MODE, payload: activeElementId };
  } else {
    return { type: CLOSE_EDIT_MODE, payload: null };
  }
};

export const setToggleDocumentModal = (payload) => {
  const { visible, activeElement } = payload;
  if (visible) {
    return { type: OPEN_DOCUMENT_MODAL, payload: activeElement };
  } else {
    return { type: CLOSE_DOCUMENT_MODAL, payload: 0 };
  }
};
