import { union } from 'lodash';
import * as types from '../actions/types/dwActionTypes';
import { Action } from '../App.d';
import { DwStatusState } from './reducers.d';
import createAllIds from '../utils/createAllIds';
import createById from '../utils/createById';

export const initialState: DwStatusState = {
  vendors: { allIds: [], byId: {} },
  alerts: { allIds: [], byId: {} },
  sources: { allIds: [], byId: {} }
};

const dmReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case types.getVendors: {
      let { vendors = [] } = action.payload;
      vendors = {
        allIds: createAllIds(vendors, 'vendorId'),
        byId: createById(vendors, 'vendorId')
      };
      return {
        ...state,
        vendors,
        isRequesting: false
      };
    }
    case types.getSources: {
      let { sources = [] } = action.payload;
      sources = {
        allIds: createAllIds(sources, 'sourceId'),
        byId: createById(sources, 'sourceId')
      };
      return {
        ...state,
        sources,
        isRequesting: false
      };
    }
    case types.getAlerts: {
      let { alerts = [] } = action.payload;
      alerts = {
        allIds: createAllIds(alerts, 'sourceAlertId'),
        byId: createById(alerts, 'sourceAlertId')
      };
      return {
        ...state,
        alerts,
        isRequesting: false
      };
    }
    case types.updateAlert: {
      const { allIds, byId } = state.alerts;
      const {
        payload: { sourceAlertId }
      } = action;
      const alertIds = union(allIds, [sourceAlertId]);
      const alertsById = { ...byId };
      alertsById[sourceAlertId] = {
        ...alertsById[sourceAlertId],
        ...action.payload
      };
      return {
        ...state,
        alerts: {
          allIds: alertIds,
          byId: alertsById
        }
      };
    }
    default:
      return state;
  }
};

export default dmReducer;
