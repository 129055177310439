import React, { useEffect } from 'react';
import {
  CheckboxGroup,
  Checkbox,
  Input,
  Search,
  Dropdown,
  Button,
  RadioGroup,
  Chevron,
  Menu,
  Plus,
  Close
} from '@ovis-technologies/ovis-blueprint';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { GlobalState } from '../../reducers/reducers';
import {
  setFilterDM,
  removeFilterDM,
  removeFilterType,
  updateFilterGroup,
  updateFilterText,
  updateFilterType
} from '../../actions/filtersAction';

import './_search.scss';
import FlexContainer from '../FlexContainer';

const { SubMenu } = Menu;

type SearchFormProps = {
  dmId: number;
  isMulti?: boolean;
  filterOverride?: number[];
  hasGroupFilterCategories?: boolean;
  hasTopics?: boolean;
};

const SearchForm = ({ dmId, filterOverride }: SearchFormProps) => {
  const dispatch = useDispatch();
  const {
    filterItemsById,
    filterItemIds,
    filtersById,
    filterIds,
    groupIds,
    groupsById,
    searchFilters,
    groupFilters
  } = useSelector((state: GlobalState) => {
    const { dms, filterItems, filters, groups, elements } = state.dm;
    const { filters: searchFilters, groups: groupFilters } =
      state.filters || {};
    const { byId: elementsById } = elements;
    const { filterIds, filterItemIds, groupIds, elementIds } =
      dms.byId[dmId] || {};
    return {
      filtersById: filters?.byId,
      filterIds: filterIds || [],
      filterItemsById: filterItems?.byId,
      filterItemIds: filterItemIds || [],
      elementIds: elementIds || [],
      elementsById,
      groupIds: groupIds || [],
      groupsById: groups?.byId,
      searchFilters,
      groupFilters
    };
  });

  useEffect(() => {
    dispatch(setFilterDM(dmId));
    return () => {
      dispatch(removeFilterDM());
    };
  }, [dmId, dispatch]);

  const handleTextQueryChange = (e) => {
    setTimeout(() => {
      dispatch(updateFilterText(e));
    }, 500);
  };

  const handleGroupsChange = (groups) => {
    dispatch(updateFilterGroup(groups));
  };

  const handleFilterChange = (filter, filterItem) => {
    if (!filterItem) {
      dispatch(removeFilterType(filter));
    } else {
      dispatch(updateFilterType({ filter, filterItem }));
    }
  };

  const handleRemoveGroup = (value: number) => {
    const groupsCopy = [...groupFilters];
    groupsCopy.splice(groupsCopy.indexOf(value), 1);
    dispatch(updateFilterGroup(groupsCopy));
  };

  const groups = (
    <SubMenu
      title={
        <>
          <p>Groups</p>
          <Plus />
          <></>
        </>
      }
    >
      <CheckboxGroup onChange={handleGroupsChange} value={groupFilters}>
        {groupIds.map((id) => {
          const { name } = groupsById[id];
          return (
            <Checkbox
              key={id + '-group-r-categories'}
              value={id}
              name={name}
              label={name}
            >
              {name}
            </Checkbox>
          );
        })}
      </CheckboxGroup>
    </SubMenu>
  );

  const filtersBody = (
    <div className='search_filters-body'>
      <Menu currentSubMenuOnly>
        {groups}
        {filterIds?.map((filterId, index) => {
          if (filterOverride && filterOverride.includes(filterId)) return <></>;
          const { name } = filtersById[filterId];
          const filterItems = filterItemIds.filter((filterItemId) => {
            const { filterId: filterParentId } =
              filterItemsById[filterItemId] || {};
            return filterId === filterParentId;
          });
          if (filterItems.length > 0) {
            return (
              <SubMenu
                key={filterId}
                title={
                  <>
                    <p>{name}</p>
                    <Plus />
                  </>
                }
              >
                <RadioGroup
                  onChange={(value) => handleFilterChange(name, value)}
                  canToggle
                  value={searchFilters[name]}
                  options={filterItems.map((id) => {
                    return {
                      label: filterItemsById[id]?.name,
                      value: id,
                      _checked: searchFilters[name] === id
                    };
                  })}
                />
              </SubMenu>
            );
          }
        })}
      </Menu>
    </div>
  );

  return (
    <div className='search_form-body search'>
      <div className='search_wrapper wrapper'>
        <div className='search_text'>
          <Input
            type='text'
            onChange={handleTextQueryChange}
            placeholder='Search by keyword...'
            withPrefixIcon={<Search />}
          />
        </div>
        <div className='search_filters'>
          <Dropdown body={filtersBody} noArrow>
            <Button styleType='none'>
              Filters
              <Chevron direction='down' />
            </Button>
          </Dropdown>
        </div>
      </div>
      {(groupFilters.length > 0 || !isEmpty(searchFilters)) && (
        <FlexContainer classname='search_active-filters' justify='flex-start'>
          {groupFilters.length > 0 && (
            <>
              {groupFilters.map((id) => {
                const { name } = groupsById[id];
                return (
                  <div key={name} className='--highlight active-filter'>
                    <Button
                      onClick={() => handleRemoveGroup(id)}
                      styleType='text'
                    >
                      <Close />
                    </Button>
                    <p>{name}</p>
                  </div>
                );
              })}
            </>
          )}
          {!isEmpty(searchFilters) &&
            Object.keys(searchFilters).map((key) => {
              const value = searchFilters[key];
              return (
                <div key={value} className='--highlight active-filter'>
                  <Button
                    onClick={() => handleFilterChange(key, null)}
                    styleType='text'
                  >
                    <Close />
                  </Button>
                  <p>{filterItemsById[value]?.name || ''}</p>
                </div>
              );
            })}
        </FlexContainer>
      )}
    </div>
  );
};

export default SearchForm;
