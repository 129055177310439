import React, { useState, ReactNode } from 'react';
import classNames from 'classnames';
import LinkRender from '../LinkRender/LinkRender';

import './cover_image.scss';

interface Props {
  contentUrl?: string;
  onClick?: () => void;
  onClose?: () => void;
  className?: string;
  coverImageSrc: string;
  alt?: string;
  children?: ReactNode;
  hasCustomFunctionality?: boolean;
  priority?: 'download' | 'preview';
  elementId?: number; // Required if is an element from DM, for tracking
}

const CoverImage = ({
  onClick = () => {},
  onClose = () => {},
  contentUrl = '',
  className = '',
  coverImageSrc = '',
  alt = '',
  children,
  hasCustomFunctionality,
  priority = 'preview',
  elementId
}: Props) => {
  const containerClass = classNames('cover-image', className);
  const [imageErrored, setImageErrored] = useState<boolean>(false);
  const handleClick = () => {
    onClick();
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {coverImageSrc ? (
        <div className={containerClass}>
          <LinkRender
            onClose={handleClose}
            onClick={handleClick}
            contentUrl={contentUrl}
            priority={priority}
            hasCustomFunctionality={hasCustomFunctionality}
            elementId={elementId}
          >
            {children || (
              <img
                className='cover-image_image'
                src={coverImageSrc}
                alt={alt}
                onError={() => setImageErrored(true)}
                style={imageErrored ? { display: 'none' } : undefined}
              />
            )}
          </LinkRender>
        </div>
      ) : null}
    </>
  );
};

export default CoverImage;
