import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
  Select,
  Loader,
  Portal,
  notification,
  Button
} from '@ovis-technologies/ovis-blueprint';
import { Link, useLocation } from 'react-router-dom';
import { get } from 'lodash';

import HtmlBuilder from '../HTMLBuilder/HtmlBuilder';
import SearchInput from '../SearchInput/SearchInput';
import AuthRequiredLink from '../AuthRequiredLink/AuthRequiredLink';
import PlaySquare from '../svgs/PlaySquare';

import { setHelpOpen } from '../../actions/helpAction';
import { guidedTourAction } from '../../actions/types/uiActionTypes';
import { setModalOpen } from '../../actions/uiActions';
import { getHelpAssetsRequest } from '../../services/helpService';
import { HelpAssetObject, HelpObject } from '../../types/help';
import { RequestState } from '../../../constants/requestStates';
import { GlobalState } from '../../reducers/reducers';
import { helpLookup } from '../../selectors/navSelectors';
import { getIdFromUrl } from '../../utils';

import config from '../../config';
import './_help_modal.scss';

// Filter Function for user search return
const searchFilterFunction = (helpData: any[], searchText: string) => {
  const filteredResults = helpData.filter((datum) => {
    const nameMatch: boolean = datum.name
      .toLowerCase()
      .includes(searchText.toLowerCase());
    const descriptionMatch = datum.description
      ? datum.description.toLowerCase().includes(searchText.toLowerCase())
      : false;
    return descriptionMatch || nameMatch;
  });
  return filteredResults;
};

enum Tabs {
  page = 1,
  directory = 2,
  feedback = 3
}

type Props = {
  visible: boolean;
  onCancel?: any;
  onOpen?: any;
  preventScroll?: boolean;
};

const HelpCenter = ({ visible }: Props) => {
  const dispatch = useDispatch();
  const [currentDir, setCurrentDir] = useState<number>(0);
  const [tabId, setTab] = useState<Tabs>(Tabs.page);
  const [searchResults, setSearchResults] = useState<HelpObject[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>();

  const { byId, directoryIds, allIds } = useSelector((state: GlobalState) => {
    const { byId, allIds } = state.help.allHelp;
    const pageIds = allIds.filter((id: number) => byId[id].typeId === 1);
    const directoryIds = allIds.filter((id: number) => byId[id].typeId === 2);
    return { byId, pageIds, directoryIds, allIds };
  });

  const getAssets = useCallback(async (id: number) => {
    // setPageAssetsLoading(RequestState.sending);
    let response;
    try {
      response = await getHelpAssetsRequest(id);
      if (get(response, 'status', false) !== false) {
        const { response: assets = [] } = response;
        if (assets.length > 0) {
          return assets;
          // if (type === 1) {

          //   // setHelpPageAssets(assets);
          // } else {
          //   setHelpDirAssets(assets);
          // }
        }
      }
    } catch (err: any) {
      console.error(err);
    }
  }, []);

  const handleTabChange = (tabId: Tabs) => {
    setTab(tabId);
  };

  const handlePageChange = (id) => {
    setCurrentPage(id);
  };

  const handleResultClick = (id: number, type: number) => {
    if (type === 2) {
      handleDirectoryChange(id);
      setTab(Tabs.directory);
    } else {
      handlePageChange(id);
      setTab(Tabs.page);
    }
    handleSearchClose();
  };

  const handleSearchClose = () => {
    setSearchResults([]);
    setSearchFilter('');
  };

  const handleDirectoryChange = (id: number) => {
    setCurrentDir(id);
  };

  const handleModalClose = () => {
    dispatch(setHelpOpen(false));
    dispatch(setModalOpen(false));
  };

  const handleSetFilter = (text: string) => {
    setSearchFilter(text);
  };

  useEffect(() => {
    if (searchFilter.length > 0) {
      const helpArray = allIds
        .map((help) => {
          return byId[help];
        })
        .filter((helpObj) => helpObj.typeId !== 3);
      const results = searchFilterFunction(helpArray, searchFilter);
      setSearchResults(results);
    }
  }, [searchFilter, byId, allIds]);

  const title = (
    <div className='help-center_title-wrapper'>
      <h3 className='page-title'>Help Center</h3>
      <SearchInput
        value={searchFilter}
        onClose={handleSearchClose}
        onChange={handleSetFilter}
      />
    </div>
  );

  return (
    <Portal>
      <Modal
        title={title}
        closeButton
        onCancel={handleModalClose}
        visible={visible}
        className='help-center'
      >
        {searchFilter.length > 0 ? (
          <HelpSearch
            searchResults={searchResults}
            handleResultClick={handleResultClick}
          />
        ) : (
          <div className='help-center_body'>
            <div className='help-center_navigation'>
              <Button
                styleType='text'
                onClick={() => handleTabChange(Tabs.page)}
                className={`${tabId === Tabs.page ? '--focused' : ''}`}
              >
                About this Page
              </Button>
              <Button
                styleType='text'
                className={`${tabId === Tabs.directory ? '--focused' : ''}`}
                onClick={() => handleTabChange(Tabs.directory)}
              >
                Need Assistance?
              </Button>
              <Button
                styleType='text'
                className={`${tabId === Tabs.feedback ? '--focused' : ''}`}
                onClick={() => handleTabChange(Tabs.feedback)}
              >
                Provide Feedback
              </Button>
            </div>
            <HelpAboutPage
              getAssets={getAssets}
              currentPage={currentPage}
              visible={visible}
              pagesById={byId}
              handlePageChange={handlePageChange}
              tabId={tabId}
            />
            <HelpDirectory
              getAssets={getAssets}
              currentDir={currentDir}
              visible={visible}
              byId={byId}
              directoryIds={directoryIds}
              handleDirChange={handleDirectoryChange}
              tabId={tabId}
            />
            {tabId === Tabs.feedback && (
              <div className='about-page-content help-center_content-section'>
                <div>
                  To provide feedback email{' '}
                  <a href={`mailto:${config.app.contactEmail}`}>
                    {config.app.contactEmail}
                  </a>
                </div>
              </div>
            )}
            <div
              className='title-wrap help-center_guided-tour'
              onClick={() => {
                dispatch(setHelpOpen(false));
                dispatch({ type: guidedTourAction.active });
              }}
            >
              <h3 className='component-title'>Take our Guided Tour</h3>
              <div className='guided-tour-play-btn'>
                <PlaySquare />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </Portal>
  );
};

const HelpAboutPage = ({
  getAssets,
  currentPage,
  visible,
  pagesById,
  handlePageChange,
  tabId
}) => {
  const { pathname: url } = useLocation();
  const urlLookup = useSelector(helpLookup);

  const [getPageError, setGetPageError] = useState<boolean>(false);
  const [helpPageAssets, setHelpPageAssets] = useState<HelpAssetObject[]>([]);
  const [pageAssetsLoading, setPageAssetsLoading] = useState<RequestState>(
    RequestState.null
  );

  useEffect(() => {
    const getPageAssets: (helpPageId: number) => Promise<void> = async (
      helpPageId
    ) => {
      try {
        const assets = await getAssets(helpPageId);
        setHelpPageAssets(assets);
      } catch (err: any) {
        console.error(err);
      }
    };

    // Set Help Page Info
    if (visible) {
      const helpPageId = getIdFromUrl(urlLookup, url, 'helpId');
      if (helpPageId) {
        setPageAssetsLoading(RequestState.sending);
        handlePageChange(helpPageId);
        getPageAssets(helpPageId);
      } else {
        setGetPageError(true);
      }
    }
  }, [pagesById, visible, url, urlLookup, handlePageChange, getAssets]);

  const { description: pageDescription = '', name = '' } = currentPage
    ? pagesById[currentPage]
    : {};

  if (tabId !== Tabs.page) return <></>;

  return (
    <div className='about-page-content help-center_content-section'>
      <div className='help-center_title-wrap'>
        <h3 className='component-title'>{name}</h3>
      </div>
      {pageAssetsLoading === RequestState.sending && currentPage === 0 ? (
        <Loader />
      ) : (
        <>
          {getPageError || pageDescription === '' ? (
            <div>
              We are working on adding help content, please check back soon.
            </div>
          ) : (
            <HtmlBuilder
              className='help-center_description'
              content={pageDescription}
            />
          )}

          {helpPageAssets?.length > 0 && (
            <>
              <p className='help-center_assets-title'>Helpful Links:</p>
              <div className='help-center_assets'>
                {helpPageAssets.map((asset) => {
                  const { name, url, assetId } = asset;
                  return (
                    <div key={`help-page-asset-${assetId}`}>
                      <AuthRequiredLink href={url} name={name} />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const HelpDirectory = ({
  getAssets,
  currentDir,
  visible,
  byId,
  handleDirChange,
  directoryIds,
  tabId
}) => {
  const [helpDirAssets, setHelpDirAssets] = useState<HelpAssetObject[]>([]);
  const [directoryAssetsLoading, setDirectoryAssetsLoading] =
    useState<RequestState>(RequestState.null);

  useEffect(() => {
    const getDirAssets: (currentDir: number) => Promise<void> = async (
      helpPageId
    ) => {
      try {
        const assets = await getAssets(helpPageId);
        setHelpDirAssets(assets);
      } catch (err: any) {
        console.error(err);
        notification.error({
          message: 'Failed to get directory assets.',
          duration: 1
        });
      } finally {
        setDirectoryAssetsLoading(RequestState.success);
      }
    };

    // Set Help Page Info
    if (visible) {
      const id = currentDir && directoryIds[0];
      setDirectoryAssetsLoading(RequestState.sending);
      getDirAssets(id);
    }
  }, [byId, visible, handleDirChange, getAssets, currentDir, directoryIds]);

  let directory: any = {};

  if (currentDir) {
    directory = byId[currentDir];
  } else if (byId[directoryIds[0]]) {
    directory = byId[directoryIds[0]];
  }

  const { description = '', helpId = '' } = directory;

  if (tabId !== Tabs.directory) return <></>;

  return (
    <div className='directories help-center_content-section'>
      <div className='row'>
        <div className='col-md-4'>
          <Select
            id='directory-drop'
            isSearchable
            placeholder='Select a directory'
            onChange={(dataId) => handleDirChange(dataId)}
            defaultValue={helpId !== 0 ? helpId : 'Select a directory'}
            options={
              directoryIds !== null
                ? directoryIds.map((dir) => {
                    return {
                      value: byId[dir].helpId,
                      label: byId[dir].name
                    };
                  })
                : [{ value: 0, label: 'Failed to load directories' }]
            }
          />
        </div>
        {directoryAssetsLoading === RequestState.sending && (
          <div className='col-md-1'>
            <Loader size='small' />
          </div>
        )}
      </div>
      <>
        <HtmlBuilder
          className='help-center_description'
          content={description}
        />
        {helpDirAssets?.length > 0 && (
          <>
            <p className='asset-title'>Helpful Links:</p>
            <div className='help-assets'>
              {helpDirAssets.map((asset) => {
                const { name, url, assetId } = asset;
                return (
                  <div key={`help-page-asset-${assetId}`}>
                    <AuthRequiredLink href={url} name={name} />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </>
    </div>
  );
};

type HelpSearchProps = {
  searchResults: HelpObject[];
  handleResultClick: (helpId: number, typeId: number) => void;
};

const HelpSearch = ({ searchResults, handleResultClick }: HelpSearchProps) => {
  return (
    <div className='wrapper help-center_search-wrapper'>
      {searchResults.length > 0 ? (
        <div className='help-center_search-table'>
          {searchResults.map((result) => {
            const { url, helpId, description, name } = result;
            return (
              <div
                className='search-result'
                key={`help-center-result-${helpId}`}
              >
                {url !== null && url.length > 0 ? (
                  <Link
                    to={url}
                    className='search-result_link'
                    onClick={() => {
                      handleResultClick(helpId, 1);
                    }}
                  >
                    <div className='search-result_text'>
                      <p className='search-result_name'>{name}</p>
                      <HtmlBuilder
                        className='search-result_description'
                        content={description}
                      />
                    </div>
                  </Link>
                ) : (
                  <button
                    className='search-result_link'
                    type='button'
                    onClick={() => {
                      handleResultClick(helpId, 2);
                    }}
                  >
                    <div className='search-result_text'>
                      <p className='search-result_name'>{name}</p>
                      <HtmlBuilder
                        className='search-result_description'
                        content={description}
                      />
                    </div>
                  </button>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <h5>
          Sorry. No results were found. Please contact {config.app.clientName}{' '}
          for further assistance.
        </h5>
      )}
    </div>
  );
};

export default HelpCenter;
