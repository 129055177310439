import React, { useEffect, useState } from 'react';
import { Like } from '@ovis-technologies/ovis-blueprint';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { updateDmElementLike } from '../../../actions/dmActions';
import { GlobalState } from '../../../reducers/reducers';

type Props = {
  className?: string;
  text?: string;
  elementId: number;
};

const LikeElement = ({ className, elementId }: Props) => {
  // State
  const [likeRequest, setLikeRequest] = useState(false);
  const [liked, setLiked] = useState<boolean>(false);

  // Selectors
  const { byId: elementsById } = useSelector(
    (state: GlobalState) => state.dm.elements
  );

  // Hooks
  const dispatch = useDispatch();

  // UseEffect
  useEffect(() => {
    const { hasLiked } = elementsById[elementId] || {};
    setLiked(Boolean(hasLiked));
  }, [elementsById, elementId]);

  const handleClick = async () => {
    if (likeRequest) return;
    const { hasLiked } = elementsById[elementId] || {};
    setLiked(!hasLiked);
    setLikeRequest(true);
    try {
      await dispatch(updateDmElementLike(elementId, !hasLiked));
    } catch (err: any) {
      console.error(err);
      setLiked(false);
    }
    setLikeRequest(false);
  };

  const classes = classnames('like-btn', className, {
    '--has-liked': liked
  });
  return (
    <button onClick={handleClick} className={classes}>
      <Like />
    </button>
  );
};

export default LikeElement;
