import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { track } from '../../services/trackingService';
import getPathState from '../../utils/getPathState';
import { selectNavElements } from '../../selectors/navSelectors';
import { checkIfExternal } from '../../utils';

const SideNavElement = ({
  navId,
  onClick = () => {},
  isActive = false,
  level
}: any) => {
  const { byId } = useSelector(selectNavElements);
  const navElement = byId[navId] ?? {};
  const {
    parentNavId,
    groupId,
    dmId,
    typeId,
    name,
    favId,
    subNav = [],
    url
  } = navElement;

  if (typeId !== 3 && !parentNavId) {
    return null;
  }

  const containerClass = classNames('side-nav-element-container', {
    active: isActive,
    '--level-two': level === 2,
    '--level-three': level === 3,
    '--has-subnav': subNav.length > 0 && url !== '/settings'
  });
  const isSubElement = Boolean(parentNavId) || Boolean(favId && groupId);
  const elementClass = 'side-nav-element';

  const [path, stateNavId] = getPathState(
    byId,
    subNav, // TODO
    url,
    isSubElement ? navId : null
  );

  const handleClick = (e) => {
    onClick && onClick(navId);
    if (subNav.length > 0 && url !== '/settings') {
      return e.preventDefault();
    }
    if (level > 1 && url) {
      track({ context: url });
    }
  };

  const createIconElement = () => {
    const { icon, name, navId } = navElement;

    if (!navElement || !icon) {
      return undefined;
    }

    const image = (
      <img
        className='side-nav-icon side-nav-element default'
        src={icon}
        alt={`${name} icon`}
      />
    );

    return (
      <div className='icon-link-spacer-container' key={`icon-${navId}`}>
        {image}
      </div>
    );
  };

  const isExternalLink = checkIfExternal(path || url);

  return (
    <div className={containerClass} onClick={handleClick}>
      {isExternalLink ? (
        <a
          key={`nav-${navId}`}
          onClick={handleClick}
          rel='noopener noreferrer'
          target='_blank'
          href={path || url}
          className={elementClass}
        >
          {createIconElement()}
          <p>{name}</p>
        </a>
      ) : (
        <Link
          to={{
            pathname: path || url,
            state: {
              navId: stateNavId,
              dmId: dmId,
              groupId: groupId
            }
          }}
          key={`nav-${navId}`}
          onClick={handleClick}
          className={elementClass}
        >
          {createIconElement()}
          <p>{name}</p>
        </Link>
      )}
    </div>
  );
};

export default SideNavElement;
