import React, { ReactElement } from 'react';
import { get } from 'lodash';

import FullscreenRedirect from '../FullscreenRedirect/FullscreenRedirect';

interface Props {
  location?: any;
}

const NotFound: React.FC<Props> = ({ location, ...rest }): ReactElement => {
  const referrer = get(location, 'state.referrer', '');

  return (
    <FullscreenRedirect>
      <h3>
        Sorry, we were unable to find a page for your destination:{' '}
        <code>{typeof referrer === 'string' ? referrer : ''}</code>
      </h3>
    </FullscreenRedirect>
  );
};

export default NotFound;
