import React from 'react';
import { useMobile } from '@ovis-technologies/ovis-blueprint';

import Terms from '../Terms/Terms';
import GuidedTourWrapper from '../GuidedTour/GuidedTourWrapper';
import { interruptTypes } from '../../../constants';
import { useSelector } from 'react-redux';
import { GlobalState, TourState } from '../../reducers/reducers.d';
import { isEmpty } from 'lodash';

interface Props {}

const Interrupts: React.FC<Props> = () => {
  const [mobile] = useMobile();
  const component = useSelector((state: GlobalState) => {
    const tourState = state.ui.tour.status;
    const interrupt = state.interrupt;
    const renderTour =
      interrupt[interruptTypes.guidedTour] ||
      (isEmpty(interrupt) && tourState !== TourState.inactive);
    if (interrupt[interruptTypes.terms]) {
      return <Terms />;
    } else if (renderTour && !mobile) {
      return <GuidedTourWrapper />;
    } else {
      return null;
    }
  });
  return <div className='interrupt-wrapper'>{component}</div>;
};

export default Interrupts;
