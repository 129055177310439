import { Dispatch } from 'redux';
import { filterActions as actions } from './types/filterActionTypes';

export const setFilterDM = (dmId: number) => (dispatch: Dispatch) => {
  dispatch({ type: actions.setFilterDM, payload: dmId });
};

export const removeFilterDM = () => (dispatch: Dispatch) => {
  dispatch({ type: actions.removeFilterDM });
};

export const updateFilterText = (query: string) => (dispatch: Dispatch) => {
  dispatch({ type: actions.updateFilterText, payload: query });
};

export const updateFilterType =
  ({ filter, filterItem }: { filter: number; filterItem: number }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: actions.updateFilterType,
      payload: { filter, filterItem }
    });
  };

export const removeFilterType = (filter: number) => (dispatch: Dispatch) => {
  dispatch({
    type: actions.removeFilterType,
    payload: filter
  });
};

export const updateFilterGroup = (groupArray) => (dispatch: Dispatch) => {
  dispatch({ type: actions.updateFilterGroup, payload: groupArray });
};
