const createAllIds = (data = [], idName = 'id', sort = false) => {
  if (!Array.isArray(data) || !data.length) return [];
  const checkId = idName in data[0];
  if (!checkId) return [];
  const mapped = data.map((item) => {
    return item[idName];
  });
  if (sort) return mapped.sort((a, b) => a - b);
  return mapped;
};

export default createAllIds;
