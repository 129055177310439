import React, { useState } from 'react';

// To change the color, change the a and b class in _help_modal.scss
const PlaySquare = () => {
  const [classString, setClassString] = useState<string>('');
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='46'
      height='46'
      viewBox='0 0 46 46'
      onMouseEnter={() => {
        setClassString('hover');
      }}
      onMouseLeave={() => {
        setClassString('');
      }}
    >
      <rect className={'a ' + classString} width='46' height='46' />
      <path
        className='b'
        d='M1314.325,144.62l-20,12v-24Z'
        transform='translate(-1279.325 -121.62)'
      />
    </svg>
  );
};

export default PlaySquare;
