import React, { ReactElement } from 'react';
import config from '../../config';
import { useOktaAuth } from '@okta/okta-react';

interface Props {}

const UserNotMapped: React.FC<Props> = (): ReactElement => {
  const { oktaAuth } = useOktaAuth();
  const onClick = () => {
    oktaAuth.tokenManager.clear();
    oktaAuth.signOut({
      postLogoutRedirectUri: '/'
    });
  };

  return (
    <div className='system-message'>
      <img
        className='app-logo'
        src={config.app.logo}
        alt={config.app.appName}
      />
      <h3>
        Okta is reporting a problem with your account. Please trying logging out
        (Click the logout below) and logging back in. If this error persists,
        please email{' '}
        <a
          href={`mailto:${config.app.contactEmail}?subject=Okta User Error&body=Please let us know your username/email address you logged in with:`}
        >
          {config.app.contactEmail}
        </a>{' '}
        and let them know your Okta account is not properly mapped to{' '}
        {config.app.appName}.
      </h3>
      <button onClick={onClick}>Logout</button>
    </div>
  );
};

export default UserNotMapped;
