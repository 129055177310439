import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './app/App';
import ErrorBoundary from './app/ErrorBoundary';
import store from './app/store';
import * as serviceWorker from './serviceWorker';

import '@ovis-technologies/ovis-blueprint/build/index.css';
import './app/styles/index.scss';

const render = (App) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Router>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

render(App);

// @ts-ignore
if (module.hot && process.env === 'development') {
  // @ts-ignore
  module.hot.accept('./app/App', () => {
    const NextApp = require('./app/App').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
