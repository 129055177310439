interface DateConfig {
  format?:
    | 'MM-DD-YYYY'
    | 'YYYY-MM-DD'
    | 'MM/DD/YYYY'
    | 'YYYY/MM/DD'
    | 'MM-YYYY'
    | 'MM/YYYY';
  time?: boolean;
  day?: boolean;
}
export const formatDate = (dateString, config: DateConfig = {}) => {
  const { format = 'YYYY-MM-DD', time = true } = config;
  if (
    !dateString ||
    dateString === '00-00-00' ||
    dateString === 'NA' ||
    dateString === 'N/A'
  )
    return '';
  const date = new Date(dateString);
  const month =
    date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
  switch (format) {
    case 'YYYY-MM-DD':
      return `${date.getFullYear() + '-' + month + '-' + date.getDate()} ${
        time ? date.toTimeString().split(' ')[0] : ''
      }`;
    case 'YYYY/MM/DD':
      return `${date.getFullYear() + '/' + month + '/' + date.getDate()} ${
        time ? date.toTimeString().split(' ')[0] : ''
      }`;
    case 'MM-DD-YYYY':
      return `${month + '-' + date.getDate() + '-' + date.getFullYear()} ${
        time ? date.toTimeString().split(' ')[0] : ''
      }`;
    case 'MM/DD/YYYY':
      return `${month + '/' + date.getDate() + '/' + date.getFullYear()} ${
        time ? date.toTimeString().split(' ')[0] : ''
      }`;
    case 'MM-YYYY':
      return `${month + '-' + date.getFullYear()}`;
    case 'MM/YYYY':
      return `${month + '/' + date.getFullYear()}`;
  }
};

export const formatTime = (date) => {
  const formattedDate = new Date(date);
  const hour = ((formattedDate.getHours() + 11) % 12) + 1;
  const minutes =
    formattedDate.getMinutes() < 10
      ? `0${formattedDate.getMinutes()}`
      : formattedDate.getMinutes();
  const suffix = formattedDate.getHours() >= 12 ? 'PM' : 'AM';
  return `${hour}:${minutes} ${suffix}`;
};

export const checkDate = (date) => {
  const today = new Date();
  const endDate = new Date(date);
  return today.getTime() > endDate.getTime();
};
