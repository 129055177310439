import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAllUsers,
  selectActiveUserId
} from '../../selectors/userSelectors';
import { LogoSmall } from '../Icons';
import LinkRender from '../LinkRender/LinkRender';
import { shareElementRequest } from '../../services/dmService';
import { GlobalState } from '../../reducers/reducers';
import {
  Animate,
  useMobile,
  ProfilePhoto
} from '@ovis-technologies/ovis-blueprint';
import { RequestState } from '../../../constants/requestStates';
import { Like } from '../svgs';
import { setToggleDocumentModal, setAlertsOpen } from '../../actions/uiActions';
import { SUPERUSER_ID } from '../../../constants';
import { selectAlerts } from '../../selectors';
import config from '../../config';
import './_alerts.scss';

type AlertProps = {
  title?: string;
  description?: string;
  contentUrl?: string;
  hasSeen?: 0 | 1;
  moduleId?: number;
  modulePkId?: number;
  entryDateFormat?: number;
  favicon?: boolean;
  key?: number;
  originatingUserId?: number;
  name?: string;
  photo?: string;
  hasReplied?: 0 | 1;
  alertId: number;
  onClick: (id: any) => {};
  userEntryDateFormat?: number;
};

const Alerts = () => {
  // Hooks
  const dispatch = useDispatch();
  const [mobile] = useMobile(767);

  const { alerts } = useSelector((state: GlobalState) => {
    const { byId = {}, allIds = [], notSeenCount = 0 } = state.alert;
    const alerts = allIds.map((id) => {
      const alert = byId[id];
      const isSystemOrg = alert.originatingOrgId === SUPERUSER_ID;
      const handleMobileClick = () => {
        if (mobile) {
          dispatch(setAlertsOpen(false));
        }
      };
      const clickEvent =
        alert.moduleId === 4 || alert.moduleId === 5
          ? handleMobileClick
          : undefined;
      return {
        key: `alert-${id}`,
        ...alert,
        favicon: isSystemOrg,
        onClick: clickEvent
      };
    });

    return {
      notSeenCount,
      alerts
    };
  });

  // Functions
  const handleStopPropagationClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className='alerts'>
      <div className='alerts'>
        <div className='alerts_container'>
          <div
            className='alert_panel-container'
            onClick={handleStopPropagationClick}
          >
            <div className='alert_card-container'>
              {alerts &&
                alerts.map((alert) => (
                  <Alert key={alert.alertId} alert={alert} />
                ))}
              <div className='alerts_empty component-subtitle'>
                You're all caught up!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alerts;

export const Alert = ({ alert }) => {
  // Hooks
  const dispatch = useDispatch();
  const [onHover, setOnHover] = useState(false);
  const [sendAlertLoading, setSendAlertLoading] = useState<RequestState>(
    RequestState.null
  );

  /// Selectors
  const { byId: usersById } = useSelector((state: GlobalState) =>
    selectAllUsers(state, true, true)
  );
  const { byId: alertsById } = useSelector(selectAlerts);

  const activeUserId = useSelector((state: GlobalState) =>
    selectActiveUserId(state)
  );

  // Variables
  const {
    title,
    description,
    contentUrl,
    hasSeen,
    moduleId,
    modulePkId = 0,
    entryDateFormat,
    favicon,
    key,
    originatingUserId = 0,
    onClick,
    hasReplied,
    userEntryDateFormat
  } = alert as AlertProps;

  const { name = '', photo = '' } = usersById[originatingUserId] || {};
  const hasParent = moduleId === 8;
  const parentAlert = (hasParent && alertsById[modulePkId]) || {};

  // Function
  const handleSendReply = async () => {
    const { originatingUserId, modulePkId, title, alertId } = alert;
    if (!originatingUserId || !modulePkId) return;
    const { name } = usersById[activeUserId] || {};
    const titleString = `${name} likes an article you shared.`;
    const descString = `Thanks for sharing the article: ${title}`;
    setSendAlertLoading(RequestState.sending);
    try {
      await shareElementRequest({
        modulePkId: alertId,
        description: descString,
        title: titleString,
        orgIds: [],
        userIds: [originatingUserId],
        status: 1,
        moduleId: 8,
        allOrgs: 0
      });
      setSendAlertLoading(RequestState.success);
    } catch (err: any) {
      console.error(err);
      setSendAlertLoading(RequestState.error);
    }
  };

  const handleOpenDocumentModal = () => {
    dispatch(
      setToggleDocumentModal({
        visible: true,
        activeElement: parentAlert.modulePkId || modulePkId
      })
    );
  };

  const handleClick = () => {
    onClick && onClick(modulePkId);
  };

  // SubComponents
  const titleElement =
    moduleId !== 7 && moduleId !== 1 && moduleId !== 8 ? (
      <LinkRender
        buttonText={title}
        contentUrl={contentUrl}
        hasCustomFunctionality={moduleId === 3}
        elementId={modulePkId}
        onClick={handleClick}
      />
    ) : (
      <button onClick={handleOpenDocumentModal}>{title}</button>
    );

  const likeContent =
    sendAlertLoading === RequestState.success || hasReplied === 1
      ? 'Sent!'
      : sendAlertLoading === RequestState.null
      ? 'Like'
      : 'Like failed';

  const formattedDate = userEntryDateFormat || entryDateFormat;

  return (
    <div
      key={key}
      className='alert'
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      {!hasSeen && <p className='alert_has-seen'>(NEW) </p>}
      <div className='alert_favicon'>
        <div className='alert_favicon-image'>
          {originatingUserId ? (
            <>
              <ProfilePhoto source={photo} size='small' />
            </>
          ) : (
            favicon && <LogoSmall />
          )}
        </div>
      </div>
      <div className='alert-content'>
        <p className='small-text'>
          <span className='--medium'>
            {originatingUserId ? name : config.app.clientName}
          </span>{' '}
          | {formattedDate && <span>{formattedDate}</span>}
        </p>
        <div className='alert_title document-title'>{titleElement}</div>
        <p className='alert_description description-text small-text'>
          {description}
        </p>
      </div>
      {originatingUserId && !hasParent && (
        <div className='alert_reply'>
          <Animate.Fade
            direction='left'
            show={onHover || Boolean(sendAlertLoading === RequestState.sending)}
          >
            <button
              className='alert_reply-btn'
              onClick={handleSendReply}
              disabled={
                sendAlertLoading === RequestState.success || hasReplied === 1
              }
            >
              <Like />
              <p className='subtext --medium'>
                {sendAlertLoading === RequestState.sending
                  ? 'Sending...'
                  : likeContent}
              </p>
            </button>
          </Animate.Fade>
        </div>
      )}
    </div>
  );
};
