/** Convert to hook
 *
 * BACKLOG ITEM
 */
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

interface Props {}

const ScrollToTop = (props: Props) => {
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    // @ts-ignore
    const unlisten = history.listen((location, action) => {
      const prevBaseRoute = pathname.split('/')[1];
      const prevSecondaryRoute = pathname.split('/')[2];
      const newBaseRoute = location.pathname.split('/')[1];
      const newSecondaryRoute = location.pathname.split('/')[2];
      if (
        prevBaseRoute !== newBaseRoute ||
        (prevBaseRoute === 'settings' &&
          newBaseRoute === 'settings' &&
          prevSecondaryRoute !== newSecondaryRoute)
      ) {
        window.scrollTo(0, 0);
      }
    });

    return () => {
      unlisten();
    };
  }, [history, pathname]);

  return <></>;
};

export default ScrollToTop;
