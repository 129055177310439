import * as alertsActionTypes from './types/alertsActionTypes';
import { ActionCreator, Dispatch, Action } from 'redux';
import { searchAlertsRequest } from '../services/alertsService';
import { ResponseData } from '../App.d';

export const setAlerts: ActionCreator<Action> = (data) => ({
  type: alertsActionTypes.SEARCH_SUCCESS,
  payload: data
});

export const searchAlerts: ActionCreator<any> =
  (data) =>
  async (dispatch: Dispatch): Promise<ResponseData> => {
    dispatch({ type: alertsActionTypes.SEARCH_REQUEST });
    try {
      const responseData: ResponseData = await searchAlertsRequest(data);
      dispatch(
        setAlerts({
          alerts: responseData.response,
          setToSeen: Boolean(data?.setToSeen === 1)
        })
      );
      return responseData;
    } catch (err: any) {
      console.error(err.message);
      dispatch({ type: alertsActionTypes.SEARCH_FAILURE });
      throw err;
    }
  };
