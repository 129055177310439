import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Bell, Button } from '@ovis-technologies/ovis-blueprint';
import { GlobalState, PanelType } from '../../reducers/reducers.d';
import classNames from 'classnames';
import { searchAlerts } from '../../actions/alertsActions';
import { setSidePanelState } from '../../actions/uiActions';

interface Props {
  className?: string;
  onClick?: (e?: any) => any;
}

const AlertsToggle = ({ className, onClick = () => {} }: Props) => {
  const dispatch = useDispatch();

  const [allSeen, setAllSeen] = useState<boolean>(false);

  const { notSeenCount } = useSelector((state: GlobalState) => {
    const { notSeenCount = 0 } = state.alert;

    return {
      notSeenCount
    };
  });

  const { panelType } = useSelector((state: GlobalState) => state.ui.sidePanel);

  const toggleOpen = (e) => {
    if (panelType === PanelType.alerts) {
      onClick(e);
      setAllSeen(true);
      dispatch(setSidePanelState({ panelType: PanelType.closed, title: '' }));
      dispatch(searchAlerts({ setToSeen: 1 }));
    } else {
      onClick(e);
      dispatch(
        setSidePanelState({ panelType: PanelType.alerts, title: 'Alerts' })
      );
    }
  };

  const alertDisplayCount = notSeenCount && !allSeen ? notSeenCount : 0;

  const iconClass = classNames(
    'alert-toggle-icon',
    {
      '--no-count': alertDisplayCount === 0
    },
    className
  );

  return (
    <>
      <Button className={iconClass} onClick={toggleOpen} styleType='text'>
        {alertDisplayCount > 0 && (
          <div className='alert-toggle_count'>
            {alertDisplayCount > 99 ? 99 : alertDisplayCount}
          </div>
        )}
        <Bell />
      </Button>
    </>
  );
};

export default AlertsToggle;
