import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import config from '../../../config';
import { selectAllUsers } from '../../../selectors';
import { getUsersByPriv } from '../../../services/userService';
import {
  Loader,
  Modal,
  notification,
  Input,
  ProfilePhoto,
  Portal,
  Share as ShareIcon,
  Search,
  Checkbox
} from '@ovis-technologies/ovis-blueprint';
import classnames from 'classnames';
import { GlobalState } from '../../../reducers/reducers';
import { shareElementRequest } from '../../../services/dmService';

type Props = {
  className?: string;
  text?: string;
  elementId: number;
};

const Share = ({ className, text, elementId }: Props) => {
  // State
  const [visible, setVisible] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');

  // Selectors
  const { byId: usersById } = useSelector((state: GlobalState) =>
    selectAllUsers(state, true, false)
  );

  const { byId: elementsById } = useSelector(
    (state: GlobalState) => state.dm.elements
  );

  const {
    title = '',
    description,
    link = '',
    fileLocation = '',
    contentUrl = '',
    elementId: shareElementId
  } = elementsById[elementId] || {};

  // Functions
  const handleSearchChange = (searchString) => {
    setSearch(searchString.toLowerCase());
  };

  const handleOpenShareModal = async () => {
    setVisible(true);
    const usersWithPriv = await getFilteredUsersByPriv();
    setFilteredUsers(usersWithPriv);
  };

  const handleCloseShareModal = () => {
    setVisible(false);
    setFilteredUsers([]);
  };

  const handleShare = async (userId) => {
    try {
      await shareElementRequest({
        modulePkId: shareElementId,
        description,
        title,
        orgIds: [],
        userIds: [userId],
        status: 1,
        moduleId: 7,
        allOrgs: 0
      });
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleCopyUrl = (e) => {
    const url = contentUrl || fileLocation || link;
    const domain = config.api.domain;
    const formattedUrl = url
      ? url.includes(domain!)
        ? window.location.origin + '/document?url=' + encodeURIComponent(url)
        : url
      : '';
    navigator.clipboard.writeText(formattedUrl);
    e.target.focus();
    notification.success({ message: 'Link copied!', duration: 2 });
  };

  const classes = classnames('share-btn', className);

  const noUrlCheck = link === '' && fileLocation === '' && contentUrl === '';

  const getFilteredUsersByPriv = async () => {
    try {
      const filteredUsers = await getUsersByPriv({
        elementId: shareElementId
      });
      const newFilteredUsers = filteredUsers.response;
      return newFilteredUsers;
    } catch (err) {
      notification.error({ message: 'Error loading users to share' });
    }
  };

  const userOptions = filteredUsers.map((id, idx) => {
    if (id !== 1) {
      const { name, orgName, photo, status } = usersById[id] || {};
      if (!name.toLowerCase().includes(search) || !status) {
        return null;
      } else {
        return (
          <UserShare
            name={name}
            orgName={orgName}
            photo={photo}
            userId={id}
            key={id}
            onShare={handleShare}
          />
        );
      }
    } else {
      return <></>;
    }
  });

  return (
    <>
      <button onClick={handleOpenShareModal} className={classes}>
        <ShareIcon />{' '}
        <p style={{ top: 1 }} className='small-text'>
          {text}
        </p>
      </button>
      <Portal>
        <Modal
          onCancel={handleCloseShareModal}
          visible={visible}
          title='Share'
          size='small'
          smallPadding
          borderRadius
          closeButton
          className='share_modal'
        >
          <div className='share'>
            <div className='share_user-search'>
              <Input
                type='text'
                onChange={handleSearchChange}
                placeholder='Search users...'
                withPrefixIcon={<Search fill='#9c9eac' />}
                styleType='outlined'
              />
            </div>
            {filteredUsers.length ? (
              <ul className='share_user-list'>{userOptions}</ul>
            ) : (
              <Loader />
            )}

            {!noUrlCheck && (
              <div className='share-toolbar'>
                <p className='--w-medium --dark --sb-margin'>Share the link</p>
                <Input
                  value={contentUrl || fileLocation || link}
                  onClick={handleCopyUrl}
                  styleType='inverted'
                  withAddonAfter='Copy'
                  isClearable={false}
                />
              </div>
            )}
          </div>
        </Modal>
      </Portal>
    </>
  );
};

export default Share;

type UserShareProps = {
  userId: number;
  name: string;
  photo: string;
  orgName: string;
  onShare: (userId: number) => {};
};

export const UserShare = ({
  userId,
  name,
  orgName,
  photo,
  onShare
}: UserShareProps) => {
  const [shareLoading, setShareLoading] = useState(false);
  const [shareSuccess, setShareSuccess] = useState(false);

  const handleShare = async () => {
    if (shareSuccess) return;
    try {
      setShareLoading(true);
      await onShare(userId);
      setShareLoading(false);
      setShareSuccess(true);
    } catch (error) {
      console.log(error);
      setShareLoading(false);
      notification.error({ message: 'Share failed.' });
    }
  };

  return (
    <li className='share_user' onClick={handleShare}>
      <div className='share_user-info'>
        <ProfilePhoto source={photo} size='small' />
        <div>
          <p className='share_user-name'>{name}</p>
          <p className='share_user-org small-text --medium'>{orgName}</p>
        </div>
      </div>
      {shareLoading ? (
        <Loader type='dots' />
      ) : (
        <Checkbox disabled={shareSuccess} value={0} _checked={shareSuccess} />
      )}
    </li>
  );
};
