export const FETCH_DASHBOARDS_REQUEST = 'FETCH_DASHBOARDS_REQUEST';
export const FETCH_DASHBOARDS_SUCCESS = 'FETCH_DASHBOARDS_SUCCESS';
export const FETCH_DASHBOARDS_FAILURE = 'FETCH_DASHBOARDS_FAILURE';

export const CREATE_DASHBOARD_REQUEST = 'CREATE_DASHBOARD_REQUEST';
export const CREATE_DASHBOARD_SUCCESS = 'CREATE_DASHBOARD_SUCCESS';
export const CREATE_DASHBOARD_FAILURE = 'CREATE_DASHBOARD_FAILURE';

export const EDIT_DASHBOARD_REQUEST = 'EDIT_DASHBOARD_REQUEST';
export const EDIT_DASHBOARD_SUCCESS = 'EDIT_DASHBOARD_SUCCESS';
export const EDIT_DASHBOARD_FAILURE = 'EDIT_DASHBOARD_FAILURE';

export const DELETE_DASHBOARD_REQUEST = 'DELETE_DASHBOARD_REQUEST';
export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_FAILURE = 'DELETE_DASHBOARD_FAILURE';

export const FETCH_DASHBOARD_WIDGET_REQUEST = 'FETCH_DASHBOARD_WIDGET_REQUEST';
export const FETCH_DASHBOARD_WIDGET_SUCCESS = 'FETCH_DASHBOARD_WIDGET_SUCCESS';
export const FETCH_DASHBOARD_WIDGET_FAILURE = 'FETCH_DASHBOARD_WIDGET_FAILURE';

export const FETCH_WIDGET_META_REQUEST = 'FETCH_WIDGET_META_REQUEST';
export const FETCH_WIDGET_META_SUCCESS = 'FETCH_WIDGET_META_SUCCESS';
export const FETCH_WIDGET_META_FAILURE = 'FETCH_WIDGET_META_FAILURE';
