export const getElementInfo = (element) => {
  const {
    top = 0,
    right = 0,
    bottom = 0,
    left = 0,
    width = 0,
    height = 0,
    x = 0,
    y = 0
  } = element?.getBoundingClientRect() || {};
  return {
    top,
    right,
    bottom,
    left,
    width,
    height,
    x,
    y
  };
};
