export const GET_NAV_REQUEST = 'GET_NAV_REQUEST';
export const GET_NAV_SUCCESS = 'GET_NAV_SUCCESS';
export const GET_NAV_FAILURE = 'GET_NAV_FAILURE';

export const GET_NAV_GROUPS_REQUEST = 'GET_NAV_GROUPS_REQUEST';
export const GET_NAV_GROUPS_SUCCESS = 'GET_NAV_GROUPS_SUCCESS';
export const GET_NAV_GROUPS_FAILURE = 'GET_NAV_GROUPS_FAILURE';

export const POST_NAV_GROUPS_REQUEST = 'POST_NAV_GROUPS_REQUEST';
export const POST_NAV_GROUPS_SUCCESS = 'POST_NAV_GROUPS_SUCCESS';
export const POST_NAV_GROUPS_FAILURE = 'POST_NAV_GROUPS_FAILURE';

export const GET_NAV_LINKS_REQUEST = 'GET_NAV_LINKS_REQUEST';
export const GET_NAV_LINKS_SUCCESS = 'GET_NAV_LINKS_SUCCESS';
export const GET_NAV_LINKS_FAILURE = 'GET_NAV_LINKS_FAILURE';

export const GET_NAV_DEPARTMENTS_REQUEST = 'GET_NAV_DEPARTMENTS_REQUEST';
export const GET_NAV_DEPARTMENTS_SUCCESS = 'GET_NAV_DEPARTMENTS_SUCCESS';
export const GET_NAV_DEPARTMENTS_FAILURE = 'GET_NAV_DEPARTMENTS_FAILURE';

export const POST_NAV_LINKS_REQUEST = 'POST_NAV_LINKS_REQUEST';
export const POST_NAV_LINKS_SUCCESS = 'POST_NAV_LINKS_SUCCESS';
export const POST_NAV_LINKS_FAILURE = 'POST_NAV_LINKS_FAILURE';

export const DELETE_NAV_LINK_REQUEST = 'DELETE_NAV_LINK_REQUEST';
export const DELETE_NAV_LINK_SUCCESS = 'DELETE_NAV_LINK_SUCCESS';
export const DELETE_NAV_LINK_FAILURE = 'DELETE_NAV_LINK_FAILURE';

export const DELETE_NAV_REQUEST = 'DELETE_NAV_REQUEST';
export const DELETE_NAV_SUCCESS = 'DELETE_NAV_SUCCESS';
export const DELETE_NAV_FAILURE = 'DELETE_NAV_FAILURE';

export const GET_NAV_ORG_REQUEST = 'GET_NAV_ORG_REQUEST';
export const GET_ALL_NAV_ORG_REQUEST = 'GET_ALL_NAV_ORG_REQUEST';
export const GET_NAV_ORG_SUCCESS = 'GET_NAV_ORG_SUCCESS';
export const GET_NAV_ORG_FAILURE = 'GET_NAV_ORG_FAILURE';

export const GET_NAV_ORG_BY_NAV_ID_REQUEST = 'GET_NAV_ORG_BY_NAV_ID_REQUEST';
export const GET_NAV_ORG_BY_NAV_ID_SUCCESS = 'GET_NAV_ORG_BY_NAV_ID_SUCCESS';
export const GET_NAV_ORG_BY_NAV_ID_FAILURE = 'GET_NAV_ORG_BY_NAV_ID_FAILURE';

export const POST_NAV_ORG_REQUEST = 'POST_NAV_ORG_REQUEST';
export const POST_NAV_ORG_SUCCESS = 'POST_NAV_ORG_SUCCESS';
export const POST_NAV_ORG_FAILURE = 'POST_NAV_ORG_FAILURE';

export const SET_SIDENAV = 'SET_SIDE_NAV';
