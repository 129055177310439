import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Share from '../ShareElement';
import LikeElement from '../LikeElement/';
import classnames from 'classnames';
import { getComments } from '../../../actions/commentActions';
import { GlobalState } from '../../../reducers/reducers';
import '../_dm_blogging.scss';
import { Button } from '@ovis-technologies/ovis-blueprint';
import Comments from '../Comments';

type Props = {
  elementId: number;
  title: string;
  moduleId: number;
  className?: string;
  noCommentToggle?: boolean;
  likes?: number;
  comments?: number;
  hideTotals?: boolean;
  hasLiked?: boolean;
  noLikeToggle?: boolean;
};

const ActionToolbar = ({
  moduleId,
  elementId,
  className = '',
  noCommentToggle = false,
  hideTotals = false
}: Props) => {
  // Hooks
  const dispatch = useDispatch();

  // State
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [openInput, setOpenInput] = useState(false);

  // Selectors
  const { byId: elementsById } = useSelector(
    (state: GlobalState) => state.dm.elements
  );

  const { byId: groupsById } = useSelector(
    (state: GlobalState) => state.dm.groups
  );

  // Variables
  const { likes, comments, hasLiked, groupId } = elementsById[elementId] || {};

  const { allowComments = 0 } = (groupId && groupsById[groupId]) || {};

  const classes = classnames('dm-blogging_toolbar-wrapper', className);

  const likeText = `${
    hasLiked
      ? likes > 1
        ? 'You and ' + (likes - 1) + ' others'
        : 'You liked this'
      : `${likes} likes`
  }`;

  const hideTotalsCheck = !hideTotals && allowComments;

  // Helpers
  const handleGetComments = async () => {
    setOpenInput(true);
    if (comments === 0) return;
    setCommentsLoading(true);
    try {
      await dispatch(getComments([elementId]));
    } catch (err: any) {
      console.error(err);
    } finally {
      setCommentsLoading(false);
    }
  };

  return (
    <>
      <div className={classes}>
        <div className=' dm-blogging_toolbar'>
          <div className='dm-blogging_actions'>
            {Boolean(hideTotalsCheck) && (
              <div className='dm-blogging_action dm-blogging_likes'>
                <LikeElement elementId={elementId} />
                <p className='small-text'>{likeText}</p>
              </div>
            )}
            <div className='dm-blogging_action dm-blogging_toolbar-btn'>
              <Share text='Share' elementId={elementId} />
            </div>
          </div>
          {Boolean(allowComments) && (
            <Button
              className='dm-blogging_totals-likes'
              onClick={handleGetComments}
              styleType='none'
            >
              <p className='small-text'>
                {comments} {comments !== 1 ? 'comments' : 'comment'}
              </p>
            </Button>
          )}
        </div>
      </div>
      <Comments
        elementId={elementId}
        requesting={commentsLoading}
        openInput={openInput}
      />
    </>
  );
};

export default ActionToolbar;
